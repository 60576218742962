import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Div } from 'qdm-component-library'
import { ConfirmBook } from '../../components'
import { UIColor } from '../../utils'

class ConfirmationPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  componentDidMount () {}

  shouldComponentUpdate (props, state) {
    return true
  }

  render () {
    return (
      <Div
        style={
          !this.props.hidden
            ? {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // height: '100vh',
                overflow: 'auto',
                backgroundColor: UIColor.greyBackground.color
              }
            : {}
        }
      >
        {/* multipleAppointments */}
        <ConfirmBook
          multipleAppointments={!!this.props?.location?.state?.isMulti}
          appointmentInfo={this.props?.location?.state?.appointmentInfo || []}
          appointmentId={
            this.props?.location?.state?.appointmentId || this.props?.match?.params?.id
          }
          hidden={this.props.hidden} 
        />
      </Div>
    )
  }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, actions)(withRouter(ConfirmationPage))
