import * as React from "react";
// import { makeStyles } from "@material-ui/core";

// const useStyle = makeStyles((theme) => ({
//   cls1: (props) => ({
//     fill: props.htmlColor,
//   }),
// }));

export const Videoconsultation = (props) => {
  // const classes = useStyle(props);
  return (
    // <SvgIcon>
    <svg
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size : "12"}
      height={props.size ? props.size : "9"}
      viewBox="0 0 12 9"
    >
      <path
        id="icons8-video-call_2_"
        fill={props.color ? props.color : "#6f6f6f"}
        data-name="icons8-video-call (2)"
        class="cls-1"
        d="M12.4,16.05A1.95,1.95,0,0,1,10.45,18H5.95A1.95,1.95,0,0,1,4,16.05v-5.1A1.95,1.95,0,0,1,5.95,9h4.5a1.95,1.95,0,0,1,1.95,1.95Zm3.372-5.792a.45.45,0,0,0-.453.006L13,11.655v3.689l2.319,1.391A.45.45,0,0,0,16,16.35v-5.7A.45.45,0,0,0,15.772,10.258Z"
        transform="translate(-4 -9)"
      />
    </svg>
    // </SvgIcon>
  );
};
