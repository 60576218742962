import React from "react";
// import Eye from "../../../assets/img/eye.svg";
// import CancelAppointment from "../../../assets/img/cancel_appointment.svg";
// import Modify from "../../../assets/img/modify.svg";
import Calendar from "../../../assets/img/calendar.svg";
import warning from "../../../assets/img/warning.svg";
import DotMenu from "../../../assets/img/dot_menu.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { Avatar, Button } from "@material-ui/core";
import { H6 } from "qdm-component-library";
import "./style.css";
import { Routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
import { convertTime, getImgUrl, makeName, TimeStampToMnthandDays, utcTOLocal } from "../../../utils";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import { Eye, Modify, CancelAppointment } from "../../svg";
import { Direct } from "../../../assets/img/directconsultation.js";
import { Videoconsultation } from "../../../assets/img/videoconsultation.js";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/CheckCircle';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
	hover: {
		width: 24,
		height: 24,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"&:hover": {
			backgroundColor: "#01205C",
			"& svg path": {
				fill: "#fff",
			},
		},
	},
	icon: {
		fontSize: 16,
		marginRight: "4px"
	},
	boxStatus: {
		top: "-12%",
		position: "absolute",
		right: "4%",
		display: "flex",
		alignItems: "center",
	},
	removeIcon:{
		backgroundColor: "#6F6F6F",
		borderRadius: "50px", 
		color: "#fff", 
		fontSize: 12,
		marginRight: "4px"
	}
}));

function ViewCard(props) {
	const classes = useStyles();

	const dispatch = useDispatch();
	const {
		openView = (id) => null,
		handlesidebarCancelbooking = (id) => null,
		showBtn = false,
		isShowLabel = false,
		isFollowUp = false,
		val,
		past = false,
	} = props;
	const id = val?.id || 0;
  let imgId = val?.resourcecode?.photo?.[0]?.fileid || "";
	let speciality = val?.Speciality?.[0]?.coding?.[0]?.display || " ";
	
	const status = val?.status;
	const comment = val?.comment;
	let name = val?.resourcecode?.shortdesc;
	if ((val?.resourcetype || "")?.toLowerCase() === "practitioner") {
		name = makeName(val?.resourcecode?.name?.[0] ?? {});
	}
	const consultation = val?.consultationMode || "";
	const isVideo =
		val?.consultationMode?.toLowerCase?.() === "video consultation";
	const dateOfAppointment = utcTOLocal(val?.start,"DD MMM YY | hh:mm a");
	if (isFollowUp) {
    name = makeName(val?.practitionerid?.[0]?.name?.[0] ?? {});
    speciality = " "
    imgId = val?.practitionerid?.[0]?.photo?.[0]?.fileid || "";
  }
	const fetchAppInfo = async (id) => {
		const data = await dispatch(
			actions.APPOINTMENT_READ({ appointmentId: id })
		);
		return data?.payload?.data || {};
	};

	const modificationFun = async () => {
		// props.history.push(Routes.doctorListingWithIdCall+"123")
		// props.history.push(Routes.doctorListing)
		const appInfo = JSON.parse(JSON.stringify(await fetchAppInfo(val.id)));
		appInfo["HealthcareService"] = appInfo["healthservice"];
		delete appInfo["healthservice"];
		props.history.push({
			pathname: Routes.doctorListing,
			state: {
				cityName: 0,
				location: {
					address: "Chennai, Tamil Nadu, India",
					latitude: 13.0826802,
					longitude: 80.2707184,
					city: "Chennai",
					location: "Chennai",
				},
				hospital: {
					label: "",
					value: appInfo?.orgid?.id || 0,
				},
				specialities: [],
				symptoms: [
					{
						label: "Fever",
						type: "Symptom",
						value: "SymptomsMapping/10000",
					},
				],
				selectedUser: {
					name: makeName(appInfo?.Person?.[0]?.name?.[0]) ?? "",
					gender: appInfo?.Person?.[0]?.gender ?? "",
					birthDay: appInfo?.Person?.[0]?.birthDay,
          fileid: appInfo?.Person?.[0]?.photo?.[0]?.fileid,
				},
				slotId: appInfo.slotID[0],
				symptomsSearch: [],
				modifyUser: true,
				newBook: false,
				appointMentInfo: appInfo,
			},
		});
	};
	const BookNow = async () => {
		const appId = val?.appointmentid ? JSON.parse(val?.appointmentid) : undefined;
    const appInfo = JSON.parse(JSON.stringify(await fetchAppInfo(appId || val.id)));
	const followUpId = isFollowUp ? val?._id : null;
		appInfo["HealthcareService"] = appInfo["healthservice"];
		delete appInfo["healthservice"];
		props.history.push({
			pathname: Routes.doctorListing,
			state: {
				cityName: 0,
				location: {
					address: "Chennai, Tamil Nadu, India",
					latitude: 13.0826802,
					longitude: 80.2707184,
					city: "Chennai",
					location: "Chennai",
				},
				hospital: {
					label: "",
					value: appInfo?.orgid?.id || 0,
				},
				specialities: [],
				symptoms: [
					{
						label: "Fever",
						type: "Symptom",
						value: "SymptomsMapping/10000",
					},
				],
				selectedUser: {
					name: makeName(appInfo?.Person?.[0]?.name?.[0]) ?? "",
					gender: appInfo?.Person?.[0]?.gender ?? "",
					birthDay: appInfo?.Person?.[0]?.birthDay,
          fileid: appInfo?.Person?.[0]?.photo?.[0]?.fileid,
				},
				symptomsSearch: [],
				modifyUser: true,
				newBook: true,
				appointMentInfo: appInfo,
				isFollowUp,
        		followUpId
			},
		});
	};
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const idopen = open ? "simple-popoverddddddddd" : undefined;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const follow_up_month_days = TimeStampToMnthandDays(val);
	debugger;
	return (
		<div
			style={{
				marginBottom: "8px",
				boxShadow: "none",
				border: "1px solid #F0F0F0",
				backgroundColor: "#FFFFFF",
				minHeight: 133,
				padding: "15px",
				borderRadius: 8,
				position: "relative",
				marginTop: "4%",
				height: props.title === "UPCOMING" && val?.avc?.passCode  ? 176 :  !val?.avc?.passCode ? 140 : 0, 
				display: "flex",
				flexDirection: "column",
			}}
			id={`${props.val._key}-dashboard-CardList-${props.title}-ViewCard`}
		>
			{isShowLabel && (
				<H6
					className={`${classes.boxStatus} label-btn pc_medium`}
					id={`${props.val._key}dashboard-CardList-${props.title}-H6`}
					style={{
						backgroundColor: "#fff",
						color: status ? "#6F6F6F" : comment === "completed" ?  "#3BB213" :  "#FF4D4A",
					}}
				>
					{status ? <><RemoveIcon className={classes.removeIcon} /> IN COMPLETE</> : comment === "completed" 	? <><CheckIcon  className={classes.icon} /> COMPLETED</> : <><CancelIcon  className={classes.icon} /> CANCELLED </>}
				</H6>
			)}
			<Popover
				id={idopen}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Typography
					className={clsx("viewDocText", "pc_regular")}
					style={{
						padding: 8,
						fontSize: 14,
						cursor: "pointer",
						color: "#101010",
					}}
					onClick={() => {
						props.history.push({
							pathname: Routes.viewreport,
							state: {
								appNo: props.val.appno,
								created: props.val.createddate,
								report_docs: props.val.report_docs,
								data: props.val,
							},
						});
					}}
					id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-onClick`}
				>
					View Uploaded Report
				</Typography>
			</Popover>
			<div style={{ display: "flex", marginBottom: "16px" }}>
				<div
					style={{
						width: "40px",
						height: "40px",
						borderRadius: "50%",
						overflow: "hidden",
					}}
				>
					{/* <img
            alt="img"
            src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
            style={{
              width: '100%',
              height: 'auto'
            }}
            id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-userImage`}
          /> */}
					<Avatar
            src={getImgUrl(imgId) || undefined}
						id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-userImage`}
					>
						{name
							?.replaceAll("Mrs", "")
							?.replaceAll("Mr", "")
							?.replaceAll(".", "")?.[0] ?? ""}
					</Avatar>
				</div>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						marginLeft: "16px",
						flex: 1,
					}}
					id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-name-div`}
				>
					<div>
						<H6
							style={{
								fontSize: "16px",
								color: "#101010",
								letterSpacing: 0,
								lineHeight: 1,
								margin: "0px 0px 8px",
							}}
							className="pc_medium"
							id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-name-span`}
						>
							{name}
						</H6>
						<H6
							className="pc_regular"
							style={{
								color: "#6F6F6F",
								fontSize: "12px",
								letterSpacing: 0,
								lineHeight: 1,
							}}
							id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-speciality-span`}
						>
							{speciality}
						</H6>
					</div>
					<div
						style={{ marginRight: "8px" }}
						id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-report_docs-div`}
					>
						{props.val.report_docs?.length > 0 && (
							<img
								alt="img"
								src={DotMenu}
								style={{ cursor: "pointer" }}
								onClick={handleClick}
								id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-report_docs-handleClick`}
							/>
						)}
					</div>
				</div>
			</div>
			<div style={{ display: "flex" }}>
				<div
					style={{
						width: "40px",
						height: "40px",
						backgroundColor: isFollowUp ? "#F4F7FC" : "#F9F9F9",
						borderRadius: "50px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginRight: "16px",
					}}
					id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-isFollowUp`}
				>
					{!isFollowUp ? (
						isVideo ? <Videoconsultation size={14} color={"#6A7888"} />  : <Direct size={14} color={"#6A7888"} />
						// <img
						// 	alt="img"
						// 	src={Calendar}
						// 	id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-Calendar`}
						// />
					) : (
						<img
							alt="img"
							src={warning}
							id={`${props.val._key}dashboard-CardList-${props.title}-ViewCard-warning`}
						/>
					)}
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "flex-end",
						width: "100%",
						flex: 1,
					}}
					id={`${props.val._key}dashboard-CardList-${props.title}-isFollowUp-div`}
				>
					<div>
						<H6
							className="pc_medium"
							style={{
								fontSize: "14px",
								color: isFollowUp ? "#2A60BC" : "#101010",
								lineHeight: 1,
								letterSpacing: 0,
								margin: "0px 0px 8px",
							}}
							id={`${props.val._key}dashboard-CardList-${props.title}-Appointment-div`}
						>
							{isFollowUp ? `Follow up ${follow_up_month_days}` : dateOfAppointment}
						</H6>
						<H6
							className="pc_regular"
							style={{
								color: "#6F6F6F",
								fontSize: "12px",
								letterSpacing: 0,
								lineHeight: 1,
								margin: 0,
							}}
							id={`${props.val._key}dashboard-CardList-${props.title}-consultation-span`}
						>
							{consultation}
						</H6>
					</div>
					<div style={{ display: "inherit" }}>
						{!showBtn && (
							<React.Fragment>
								<Tooltip title="View" arrow placement="top">
									<div
										data-tip="View"
										className={classes.hover}
										style={{
											padding: "1px 6px",
											borderRadius: "5px",
											marginLeft: "3px",
											cursor: "pointer",
										}}
										onClick={() => openView(id)}
										id={`${props.val._key}dashboard-CardList-UPCOMING-${props.title}-openView-div`}
									>
										{/* <img alt={"Eye"} src={Eye} /> */}
										<Eye />
									</div>
								</Tooltip>

								<div
									style={{
										display: past ? "none" : "unset",
										width: "1px",
										backgroundColor: "#E0E0E0",
										marginLeft: "3px",
										height: "12px",
										marginTop: "6px",
									}}
								/>
								{past ? null : (
									<Tooltip title="Modify Appointment" arrow placement="top">
										<div
											className={classes.hover}
											style={{
												padding: "1px 6px",
												borderRadius: "5px",
												marginLeft: "3px",
												cursor: "pointer",
											}}
											onClick={() => modificationFun()}
											id={`${props.val._key}dashboard-CardList--${props.title}-Tooltip`}
										>
											{/* <img
                        alt={"Modify"}
                        src={Modify}
                        id={`${props.val._key}dashboard-CardList--${props.title}-Modify`}
                      /> */}
											<Modify />
										</div>
									</Tooltip>
								)}

								<div
									className="hover"
									style={{
										display: past ? "none" : "unset",
										width: "1px",
										backgroundColor: "#E0E0E0",
										marginLeft: "3px",
										height: "12px",
										marginTop: "6px",
									}}
									id={`${props.val._key}dashboard-CardList-${props.title}-hover-div`}
								/>

								{past ? null : (
									<Tooltip
										title="Cancel Appointment"
										arrow
										placement="top"
										id={`${props.val._key}dashboard-CardList-${props.title}-Cancel-Appointment-div`}
									>
										<div
											className={classes.hover}
											style={{
												padding: "1px 6px",
												borderRadius: "5px",
												marginLeft: "3px",
												cursor: "pointer",
											}}
											onClick={() => handlesidebarCancelbooking(id)}
											id={`${props.val._key}dashboard-CardList--${props.title}-Cancel-onClick-div`}
										>
											{/* <img
                        id={`${props.val._key}dashboard-CardList-${props.title}-onClick-img`}
                        alt={"CancelAppointment"}
                        src={CancelAppointment}
                      /> */}
											<CancelAppointment />
										</div>
									</Tooltip>
								)}
							</React.Fragment>
						)}
						{showBtn && (
							<React.Fragment>
								<div style={{ position: "relative" }}>
									<Button
										variant="outlined"
										color="primary"
										className="pd-book-btn"
										onClick={() => BookNow()}
										style={{
											color: "#01205C",
											borderColor: "#DFE5EB",
											borderRadius: 8,
											padding: "6px 21px",
											backgroundColor: "#E5E8EE",
											textTransform: "capitalize"
										}}
										id={`${props.val._key}dashboard-CardList-${props.title}-Button`}
									>
										Book Again
									</Button>
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
			{(props.upcoming  && val?.avc?.passCode ) && (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
						marginTop: "15px",
						marginRight: "10px",
						flex: 1,
					}}
					id={`${props.val._key}dashboard-CardList-${props.title}-isFollowUp-div`}
				>
					<H6
						id={`${props.val._key}viewCard_video_passcode`}
						className="pc_regular"
						style={{
							fontSize: 13,
							margin: "5px 0px",
							fontWeight: 400,
							color: isVideo ? "#2A60BC" : "#B6B6B6",
						}}
					>
						Passcode :{" "}
						{isVideo
							? val?.avc?.passCode
								? val?.avc?.passCode
								: "******"
							: ""}
					</H6>
					<Button
						variant="outlined"
						color="primary"
						className="pd-book-btn"
						onClick={() => {
							if (isVideo) {
								props.history.push({
									pathname: Routes.avcLoginRedirect + "/" + val?._key,
									state: { fromApp: true },
								});
							} else {
							}
						}}
						style={{
							color: "#2A60BC",
							borderColor: "#2A60BC",
							borderRadius: 8,
							padding: "4px 15px",
							fontSize: 13,
						}}
						id={`${props.val._key}viewCard_back_button`}
					>
						{!isVideo ? "Pay Now" : "Join Now"}
					</Button>
				</div>
			)}
		</div>
	);
}

export default withRouter(ViewCard);
