import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";
import PrivateRoute from "./privateRouter";
import {
	NotFound,
	SignIn,
	SignInOTP,
	SignUp,
	DoctorConsultation,	
	DoctorListing,
	ConfirmationPage,
	AppointmentsListingPage,
	YourAppointmentHasBeenBooked,
	PatientRegistration,
	Confirmbookingscreen,
	PatientDashBoard,
	ViewReport,
} from "./../screens";
import QueryPage from "../screens/queryPage";
import { AVCLoginPage, MainViewAVC, VideoCall } from "../screens/AVC";
import { SupportPages } from "../screens/AVC/supportPages";
import AvcPrivateRoute from "./avcPrivateRoute";
const RouterApp = (props) => {
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={SignIn} />

				{/*Sign In Route */}
				<Route path={Routes.signIn} component={SignIn} />

				{/*Sign In OTP Route */}
				<Route path={Routes.signInOTP} component={SignInOTP} />

				{/*Sign Up Route */}
				<Route path={Routes.signUp} component={SignUp} />

				<Route path={Routes.queryPage} component={QueryPage} />

				{/*Appointment Route */}
				<Route
					exact
					path={Routes.appointMentPage}
					// component={ConfirmationPage}
				>
					<ConfirmationPage />
				</Route>

				{/* AVCSupportPage */}
				<Route path={Routes.avcSupport} component={SupportPages} />
				
				{/* AVCLoginPage */}
				<Route path={Routes.avcLogin} component={AVCLoginPage} />

				{/* AVCLandingPage */}
				<Route path={Routes.avcLanding} component={MainViewAVC} />

				{/* VideoCall */}
				<Route path={Routes.videoCall} component={VideoCall} />

				<PrivateRoute
					exact
					path={Routes.viewreport}
					// component={Confirmbookingscreen}
				>
					<ViewReport />
				</PrivateRoute>

				{/*Doctor Consultation Route */}
				<PrivateRoute
					exact
					path={Routes.doctorConsultation}
					// component={DoctorConsultation}
				>
					<DoctorConsultation />
				</PrivateRoute>

				{/*DoctorListing Route */}
				<PrivateRoute
					exact
					path={Routes.doctorListing}
					//  component={DoctorListing}
				>
					<DoctorListing />
				</PrivateRoute>

				{/*ConfirmationPage Route */}
				<PrivateRoute
					exact
					path={Routes.confirmBooking}
					//  component={ConfirmationPage}
				>
					<ConfirmationPage />
				</PrivateRoute>

				{/* patient dashboard */}
				<PrivateRoute
					exact
					path={Routes.AppointmentsListingPage}
					// component={PatientDashBoard}
				>
					<PatientDashBoard />
				</PrivateRoute>
				<PrivateRoute
					exact
					path={Routes.PatientDashBoard}
					// component={AppointmentsListingPage}
				>
					<AppointmentsListingPage />
				</PrivateRoute>

				<PrivateRoute
					exact
					path={Routes.yourAppointmentHasBeenBooked}
					// component={YourAppointmentHasBeenBooked}
				>
					<YourAppointmentHasBeenBooked />
				</PrivateRoute>

				{/* assemble registration */}
				<PrivateRoute
					exact
					path={Routes.assemblePatioentRegister}
					// component={PatientRegistration}
				>
					<PatientRegistration />
				</PrivateRoute>

				<PrivateRoute
					exact
					path={Routes.confirmbookingscreen}
					// component={Confirmbookingscreen}
				>
					<Confirmbookingscreen />
				</PrivateRoute>

				{/* For unknow/non-defined path */}
				<Route path="*" component={NotFound} />
			</Switch>
		</Router>
	);
};

export default RouterApp;
