import * as React from "react";

export const MiniAvcComp = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="3"
			viewBox="0 0 14 3"
		>
			<rect
				id="Rectangle_47309"
				data-name="Rectangle 47309"
				width="3"
				height="14"
				rx="1.5"
				transform="translate(0 3) rotate(-90)"
				fill={props.isactive ? "#0071F2" : "#FFF"}
			/>
		</svg>
	);
};
