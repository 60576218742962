import React from 'react';
import { makeStyles,Typography,Button,IconButton } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const useStyles = makeStyles(()=>({
    flexRow:{
        display:'flex',
        alignItems:'center',
        flex:1
    },
    header:{
        display:'flex',
        alignItems:'center',
        borderBottom:'1px solid #F0F0F0',
        padding:'10px 13px 10px 25px'
    },
    warningText:{
        paddingLeft:10,
        color:'#000000',
        fontSize:16,
        fontFamily:'pc_semibold'
    },
    body:{
        padding:'25px',
        fontFamily:'pc_regular',
        color:'#6F6F6F',
        '& span':{
            color:'#000000',
            fontFamily:'pc_medium',
            fontSize:14
        }
    },
    actions:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        paddingBottom:25,
        '& .MuiButton-root':{
            textTransform:'capitalize',
            borderRadius:8,
            fontFamily:'pc_medium'
        },
        '& .MuiButton-outlined':{
            borderColor:'#F0F0F0',
            marginRight:16,
        },
        '& .MuiButton-contained':{
            borderColor:'#0071F2',
            backgroundColor:'#0071F2',
            color:'#fff'
        },
    }
}))

export const WarningDialog = (props) =>{

    const classes = useStyles(props);

    return(
        <div>
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <ReportProblemOutlinedIcon htmlColor="#FF9F40" />
                    <Typography variant="body1" className={classes.warningText}>Warning</Typography>
                </div>
                <IconButton onClick={props?.onCancel}>
                    <CloseOutlinedIcon />
                </IconButton>
            </div>
            <div className={classes.body}>
                You are joining prior to the scheduled time <span>{props?.date}</span>. You will not be allowed to proceed further after logging in, till your scheduled appointment date and time.
            </div>
            <div className={classes.actions}>
                <Button variant="outlined" onClick={props?.onCancel} >Cancel</Button>
                <Button variant="contained" onClick={props?.proceed} >Proceed</Button>
            </div>
        </div>
    )
}