import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Div, Text, Divider, TermsAndConditions } from "qdm-component-library";

import Instructions from "../../assets/img/Instructions.svg";
import { UIColor } from "../../utils";

const styles = (theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding:16,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
});

class DoctorAndAppointmentSlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded1: true,
      expanded2: true,
    };
  }

  handleExpandClick = () => {
    this.setState({
      expanded1: !this.state.expanded1,
    });
  };
  handleExpandClick2 = () => {
    this.setState({
      expanded2: !this.state.expanded2,
    });
  };

  componentDidMount() { }

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    const { classes, parent_id } = this.props;
    return (
      <div
        id={`${parent_id}_appoinmentinstuction_parent_div`}
      >
        <Card
          id={`${parent_id}_appoinmentinstuction_parent_card`}
          style={{ border: "1.5px solid #E0E0E0",
          borderRadius: 8,
          boxShadow:'none'
          }}>
          <CardActions
            id={`${parent_id}_appoinmentinstuction_cardactions`}
            onClick={this.handleExpandClick}
            disableSpacing
            style={{
              padding: 0,
              paddingLeft: 16,
              paddingBottom: 0,
              cursor: "pointer",
            }}
          >
            <img
              id={`${parent_id}_appoinmentinstuction_cardactions_img`}
              alt="Instructions"
              src={Instructions}
              style={{ marginRight: 12 }}
            />
            <Text
              id={`${parent_id}_appoinmentinstuction_cardactions_text`}
              key={"0"}
              name="General Instructions"
              style={{ fontSize: "16px", color: "#001C3C",letterSpacing:0 }}
              className={"pc_semibold"}
            />

            <IconButton
              id={`${parent_id}_appoinmentinstuction_iconbutton`}
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded1,
              })}
              aria-expanded={this.state.expanded1}
              aria-label="show more"
            >
              <ExpandMoreIcon
                id={`${parent_id}_appoinmentinstuction_expandmoreIcon`}
              />
            </IconButton>
          </CardActions>
          <Collapse
            id={`${parent_id}_appoinmentinstuction_collapse`}
            className={classes.body}
            in={this.state.expanded1}
            timeout="auto"
            unmountOnExit
          >
            <Div className="qdm" style={{ margin: "0px" }}
              id={`${parent_id}_appoinmentinstuction_generalinstruction_div`}
            >
              <Divider
                id={`${parent_id}_appoinmentinstuction_generalinstruction_divider`}
                textWidth="50"
                textColor="#000"
                borderColor="lightgray"
                borderWidth="1"
                orientation="horizontal"
                variant="middle"
                style={{ margin: "0px",marginBottom:12 }}
              ></Divider>
              {
                this.props.generalInstruction?.data?.generalInstruction?.map((d, key) => (
                  <Div
                    id={`${parent_id}_appoinmentinstuction_generalinstruction_list_div`}
                    key={key}
                    className="qdm"
                    style={{ marginBottom:key < this.props.generalInstruction?.data?.generalInstruction?.length - 1 ? 8 : 0}}
                  >
                    <Text
                      id={`${parent_id}_appoinmentinstuction_generalinstruction_text`}
                      inLineStyles={{ fontSize: "12px",lineHeight:1.5, color: UIColor.secondaryText.color }}
                      className={"pc_regular"}
                      name={`${key + 1}. ${d}`}
                    />
                  </Div>
                ))
              }
            </Div>
          </Collapse>
        </Card>

        <Card
          id={`${parent_id}_appoinmentinstuction_appointmentinstruction_card`}
          style={{
            marginTop: "8px",
            boxShadow: "none",
            border: "1.5px solid #E0E0E0",
            borderRadius: 8,
          }}
        >
          <CardActions
            id={`${parent_id}_appoinmentinstuction_appointmentinstruction_cardactions`}
            onClick={this.handleExpandClick2}
            disableSpacing
            style={{
              padding: 0,
              paddingLeft: 16,
              paddingBottom: 0,
              cursor: "pointer",
            }}
          >
            <img
              id={`${parent_id}_appoinmentinstuction_appointmentinstruction_img`}
              alt="Instructions"
              src={Instructions}
              style={{ marginRight: 12 }}
            />
            <Text
              id={`${parent_id}_appoinmentinstuction_appointmentinstruction_text`}
              key={"0"}
              name="Appointment Instructions"
              style={{ fontSize: "16px", color: "#001C3C",letterSpacing:0 }}
              className={"pc_semibold"}
            />

            <IconButton
              id={`${parent_id}_appoinmentinstuction_appointmentinstruction_iconbutton`}
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded2,
              })}
              aria-expanded={this.state.expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon
                id={`${parent_id}_appoinmentinstuction_appointmentinstruction_expandmoreIcon`}
              />
            </IconButton>
          </CardActions>
          <Collapse
            className={classes.body}
            in={this.state.expanded2}
            timeout="auto"
            unmountOnExit
            id={`${parent_id}_appoinmentinstuction_appointmentinstruction_collapse`}
          >
            <Div className="qdm" style={{ margin: "0px" }}
              id={`${parent_id}_appoinmentinstuction_appointmentinstruction_collapse_div`}
            >
              <Divider
                id={`${parent_id}_appoinmentinstuction_appointmentinstruction_divider`}
                textWidth="50"
                textColor="#000"
                borderColor="lightgray"
                borderWidth="1"
                orientation="horizontal"
                variant="middle"
                style={{ margin: "0px",marginBottom:12 }}
              ></Divider>
              {
                this.props.generalInstruction?.data?.appointmentInstruction?.map((d, key) => (
                  <Div
                    id={`${parent_id}_appoinmentinstuction_appointmentinstruction_list`}
                    key={key}
                    className="qdm"
                    style={{ marginBottom:key < this.props.generalInstruction?.data?.appointmentInstruction?.length - 1 ? 8 : 0}}
                  >
                    <Text
                      id={`${parent_id}_appoinmentinstuction_appointmentinstruction_text`}
                      inLineStyles={{ fontSize: "12px",lineHeight:1.5, color: UIColor.secondaryText.color }}
                      className={"pc_regular"}
                      name={`${key + 1}. ${d}`}
                    />
                  </Div>
                ))
              }
            </Div>
            <Div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0px",
                width: "100%",
                marginTop: "6%",
              }}
              id='doctor_listing_iagreeinstructions_div'
            >
              <TermsAndConditions
                checkboxTickColor={"#fff"}
                activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
                checkboxBorderColor={UIColor.lineBorderFill.color}
                checkboxBackgroundColor={UIColor.differentiationBackground.color}
                activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
                value={this.props.iagree}
                // key={"4"}
                id='doctor_listing_iagreeinstructions_termsAndconditions_div'
                lable={
                  <span
                    id='doctor_listing_iagreeinstructions_termsAndconditions_span'
                    style={{
                      fontSize: "12px",
                      color: "#001C3C",
                      marginBottom: "4px",
                      marginTop: "-10px",
                    }}
                    className="pc_medium"
                  >
                    {"I agree that I've read the instructions "}{" "}
                    <span
                      id='doctor_listing_iagreeinstructions_termsAndconditions_star'
                      style={{ color: "red" }}>*</span>
                  </span>
                }
                inLineStyles={{ marginBottom: 16 }}
                className={classes.termsandcondition}
                onChange={() =>
                  this.props.changeIAgree()
                }
              />
            </Div>
          </Collapse>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appointmentInstruction: state?.appointmentReducer?.appointmentInstruction,
  generalInstruction: state?.appointmentReducer?.generalInstruction
});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(DoctorAndAppointmentSlot))
);
