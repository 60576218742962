import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ButtonBase from '@material-ui/core/ButtonBase';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F2F4FC",
	},
    DetailSection:{
        backgroundColor:"yellow"
    },
    listSection:{
        backgroundColor:"red"
    },
    listTitle:{
        padding:"14px 0px 8px 12px", 
        borderBottom:"1px solid #E0E0E0",
        alignItems:"center", 
        display:"flex"
    },
    listCheck:{
        padding:"14px 0px 8px 12px", 
        borderBottom:"1px solid #E0E0E0"
    },
    listBtn:{
        width:"100%", 
        textAlign:"left",
    }
}));

export const DetailPageList = (props) => {
	const classes = useStyles();
	return (
			<Grid item xs={12}>
				<Grid container xs={12} sm={12}>
					{props.data.map((val)=> {
                        return(
                            <ButtonBase className={classes.listBtn} onClick={()=>props.listClick(val.shotName)} style={props.vitalsSelect === val.shotName ? {backgroundColor:"#F5FAFF"} : {}}>
                                <Grid container xs={12} sm={12} style={props.vitalsSelect === val.shotName ? { borderLeft:"3px solid #0071F2" }: {}}>
                                    <Grid xs={2} sm={10} className={classes.listTitle}>
                                        <span style={props.vitalsSelect === val.shotName ? { color:"black" } : val?.value ? { color: "#6c6c6c" } : { color: "#787878" }}>
                                            {val.fullName}
                                        </span>
                                    </Grid>
                                    <Grid xs={2} sm={2} className={classes.listCheck} >
                                        {
                                            val?.notable ? (
                                                <RemoveCircleOutlineIcon style={{ color:"#FF9F40" }}/>
                                            ) : val?.value ? <CheckCircleOutlineIcon style={{ color:"#38C20A" }}/> : 
                                            <CheckCircleOutlineIcon style={{ color:"#F0F0F0" }}/>
                                        }
                                    </Grid>
                                </Grid>
                            </ButtonBase>
                        )
                    })}
				</Grid>
			</Grid>
	);
}
