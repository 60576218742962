import React from "react";
import { Div, Paper, Select, TextInput, Upload, H6, Col, Row, Text } from 'qdm-component-library'
import "./style.css"
class PatientIdentification extends React.Component {
    render() {
        return <Div className="pi-root">
            <Paper className="pi-paper">
                <Div>
                    <H6 className="pi-title">PATIENT IDENTIFICATION</H6>
                    <Text className="pi-addbtn">+ Add New</Text>
                </Div>
                <Row className="pi-actions-root">
                    <Col md={2} lg={2} sm={6} xs={12}>
                        <Select label="Id Type" placeholder="select..." />
                    </Col>
                    <Col md={2} lg={2} sm={6} xs={12}>
                        <TextInput label="Id Number" placeholder="type..." />
                    </Col>
                    <Col md={2} lg={2} sm={6} xs={12}>
                        <TextInput  label="Expiry Date" type="date" />
                    </Col>
                    <Col md={2} lg={2} sm={6} xs={12}>
                        <Select label="Id Source" placeholder="select..." />
                    </Col>
                    <Col md={4} lg={4} sm={12} xs={12} className="pi-upload-root">
                        <Upload label="Upload Document" className="pi-upload-btn" labelColor="blue" iconColor="blue" />
                    </Col>
                </Row>
            </Paper>
        </Div>

    }
}


export default PatientIdentification;