import React from "react";

export const Modify = (props) => {
  let width = props.width ? props.width : "12.666";
  let height = props.height ? props.height : "14";
  let color = props.color ? props.color : "#6f6f6f";
  return (
    <svg 
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12.666 14"
    >
        <defs>
            <style>{`.cls-7{fill:${color};}}`}</style>
        </defs>
        <path xmlns="http://www.w3.org/2000/svg" class="cls-7" d="M9.5,4A1.5,1.5,0,0,0,8,5.5V15.833a1.5,1.5,0,0,0,1.5,1.5h3.181a1.167,1.167,0,0,1,.062-.245h0c.018-.075.04-.153.063-.234l.15-.522H9.5a.5.5,0,0,1-.5-.5V5.5A.5.5,0,0,1,9.5,5h3.833V7.833a1.5,1.5,0,0,0,1.5,1.5h2.833v1.277a2.208,2.208,0,0,1,1-.547V8.833a.5.5,0,0,0-.146-.353L14.187,4.146A.5.5,0,0,0,13.833,4Zm4.833,1.707,2.626,2.626H14.833a.5.5,0,0,1-.5-.5Zm4.833,4.96a1.568,1.568,0,0,0-.277.025,1.545,1.545,0,0,0-.814.445l-3.933,3.933a1,1,0,0,0-.253.428l-.523,1.827a.506.506,0,0,0-.033.2A.494.494,0,0,0,13.81,18a.508.508,0,0,0,.2-.033l1.827-.523a1,1,0,0,0,.428-.253L20.2,13.258a1.545,1.545,0,0,0,.445-.814,1.6,1.6,0,0,0,.025-.277,1.5,1.5,0,0,0-1.5-1.5Z" transform="translate(-8 -4)"/>
      </svg>
  );
};
