import React from "react";
import { Div, Button } from "qdm-component-library";

function PatientDashboardNavBar(props) {
  const {
    navBarArr,
    btnName,
    btnClick = () => null,
    clickNav = () => null,
    activeId = 1,
  } = props;
  return (
    <Div
      inLineStyles={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 36px",
        backgroundColor: "#fff",
      }}
      id="patientDashboard-navBar-parent-div"
    >
      <Div>
        {navBarArr && (
          <ul
            style={{
              display: "inline-flex",
              listStyleType: "none",
              padding: 0,
              margin: 0,
              position: "absolute",
              bottom: 0,
            }}
            id="patientDashboard-navBar-navBarArr-ul"
          >
            {navBarArr.map((val) => (
              <li
                className={activeId === val.id ? "pc_medium" : "pc_regular"}
                onClick={() => clickNav(val.id)}
                style={{
                  cursor: "pointer",
                  marginRight: "24px",
                  borderBottom: activeId === val.id ? "2px solid #EC6A49" : "",
                  textDecoration: "none",
                  padding: "0px 0px 21px",
                  letterSpacing: "-0.13px",
                  color: activeId === val.id ? "#EC6A49" : "#6F6F6F",
                  fontSize: activeId === val.id ? "16px" : "14px",
                  lineHeight:1
                }}
                id={`${val.id}-patientDashboard-navBar-navBarArr-li`}
              >
                {val.label}
              </li>
            ))}
          </ul>
        )}
      </Div>
      <Div>
        <Button
          className="pc_medium"
          name={btnName}
          inLineStyles={{
            fontSize: "14px",
            borderRadius: "8px",
            backgroundColor: "#EC6A49",
            border: "none",
            padding: "10px 16px",
            letterSpacing:0,
            height:40
          }}
          onClick={() => btnClick()}
          id={`patientDashboard-navBar-Button`}
        ></Button>
      </Div>
    </Div>
  );
}

export default PatientDashboardNavBar;
