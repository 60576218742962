import React from 'react';
import { Div,H6,TextInput } from 'qdm-component-library';
import { makeStyles } from '@material-ui/core';
import moment from "moment";
import { UIColor } from '../../utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const useStyles = makeStyles(() => ({
    root:{
        display:'flex',
        alignItems:'center',
    },
    dobDateTextInput:props=>({
        '& input':{
            fontFamily:(props?.dateValue && props?.dateValue !== 'Invalid date') ? 'pc_medium' : 'pc_regular',
        }
    })
}));

export const DobAge = (props) =>{

    const classes = useStyles(props);

    const styles = {
        labelStyle: {
            color: UIColor.primaryText.color,
            fontSize: '12px',
            marginBottom: '8px',
            fontFamily: `pc_regular !important`,
            lineHeight:1,
            letterSpacing:0,
            marginTop:"8px",
            ...props?.labelStyle
        },
        borderStyle: {
            borderRadius: 8,
            // marginTop:"-1px",
            border: `1px solid ${props?.error ? UIColor.error.color : UIColor.lineBorderFill.color}`
        }
    }
    return(
        <>
        <Div id={`${props.parentId}_dobAge_root`} className={classes.root}>
            <div
                id={`${props.parentId}_dob_first_div`}
                style={{
                    flex:1,
                    marginRight:props?.showAge ? 5 : 0
                    }
                }>
                {/* <TextInput
                    id={`${props.parentId}_dob_date_input`}
                    onChange={(e) => props?.onChange(e.target.value)}
                    value={moment(props?.dateValue,'DD-MM-YYYY').format('YYYY-MM-DD')}//send format in DD-MM-YYYY
                    label={props?.dateLabel} 
                    dateIcon="/images/icons8-calendar.svg" 
                    placeholder={props?.placeholder}
                    labelStyle={styles.labelStyle}
                    isRequired={props?.isRequired}
                    style={styles.borderStyle}
                    type="date"
                    max={props?.maxDate}
                    min={props?.minDate}
                    className={classes.dobDateTextInput}
                    borderColor= {UIColor.lineBorderFill.color}
                    error={props.error}
                /> */}
                <DatePicker
                    minDate={props?.minDate}
                    maxDate={props?.maxDate}
                    selected={props?.dateValue?.length > 0 ? new Date(
                        moment(
                            props?.dateValue,
                          "DD-MM-YYYY"
                        ).format("MM-DD-YYYY")
                      ) : new Date()}
                    customInput={
                        <div style={{ position: "relative" }}>
                            <TextInput
                                id={`${props.parentId}_dob_date_input`}
                                value={props?.dateValue ? moment(props?.dateValue, "DD-MM-YYYY").format(props?.format ?? 'DD-MM-YYYY') : ""}//send format in DD-MM-YYYY
                                label={props?.dateLabel} 
                                dateIcon="/images/icons8-calendar.svg" 
                                placeholder={props?.placeholder}
                                labelStyle={styles.labelStyle}
                                style={styles.borderStyle}
                                borderColor= {props?.error ? UIColor.error.color : UIColor.lineBorderFill.color}
                                isRequired={props?.isRequired}
                                onKeyDown={props?.handleBack}
                            />
                            <img
                                src="images/icons8-calendar-grey.svg"
                                style={{ position: "absolute", top:props?.top ?? 35, right:props?.right ?? 10 }}
                            />
                        </div>
                    }
                    placeholderText={props?.placeholderText}
                    dateFormat="dd/MM/yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    calendarStartDay={1}
                    onChange={(value) => {
                        if (value) {
                            props.onChange(moment(value).format("YYYY-MM-DD"));
                        } else {
                            props.onChange(null);
                        }
                    }}
                />
            </div>
            {
                props?.showAge &&
                <Div id={`${props.parentId}_age_root`}>
                    <H6 className="pc_regular" id={`${props.parentId}_age_label`} style={styles.labelStyle}>Age</H6>
                    <Div style={{
                        border:`1px solid ${UIColor.lineBorderFill.color}`,
                        borderRadius:8,
                        padding:10,
                        height:40,
                        display:'flex',
                        minWidth:100
                    }}
                    id={`${props.parentId}_age_inputBorder`}
                    >
                        <H6 
                            className="pc_medium"
                            id={`${props.parentId}_age_value`}
                            style={{
                                fontSize:14,
                                letterSpacing:0,
                                lineHeight:1
                            }}
                        >
                            {props?.ageValue ? props?.ageValue : ' '}
                        </H6>
                    </Div>
                </Div>
            }
        </Div>
        {(props?.dateValue && props?.dateValue !== 'Invalid date') && props?.error && 
        <Div
            id={`${props.parentId}_age_error_div`}
            style={{
                fontSize: "12px",
                color: UIColor.error.color,
                marginTop:5
            }}
            className={"pc_regular"}
        >
            {props?.error}
        </Div>
        }
        </>
    )
}

DobAge.defaultProps={
    onChange:null,
    dateValue:'',//send format in DD-MM-YYYY
    dateLabel:'',
    showAge:false,
    ageValue:'',
    isRequired:false,
    placeholder:'',
    maxDate:'',
    minDate:'',
    error:'',
    parentId:'',
    labelStyle:{}
}