import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { makeName, AlertProps, checkError, convertTime, getImgUrl, mobileNumberValidation } from "../../utils";
import {
  Container,
  H6,
  Divider,
  Text,
  Div,
  TextInput,
  Button,
  //Checkbox,
  Avatar,
  Dropdown,
  MobileNumberWithCode,
  TermsAndConditions,
} from "qdm-component-library";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { AlertContext } from "../../contexts";
import { UIColor } from "../../utils/constants";

const styles = (theme) => ({
  expand: {
    padding: 16,
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
  },
  TapSelect: {
    "& div": {
      "& span": {
        fontSize: 13,
        fontWeight: "600",
        letterSpacing: "0.9px",
      },
    },
  },
  dropdown: {
    "& .dropDownButton": {
      height: 40,
      border: "1px solid #DEE5EC",
      borderRadius: 8,
      width: "130px",
      "& .placeHolder": {
        fontSize: 12,
        fontFamily: "pc_regular",
        color: UIColor.tertiaryText.color,
        letterSpacing: "-0.29px",
      },
      "& .selectHolder": {
        fontSize: 12,
        fontFamily: "pc_regular",
        color: UIColor.primaryText.color,
        letterSpacing: "-0.29px",
      },
      "& .svgIcon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  dropdownErr: {
    "& .dropDownButton": {
      height: 40,
      border: "1px solid red",
      borderRadius: 8,
      width: "130px",
      "& .placeHolder": {
        fontSize: 12,
        fontFamily: "pc_regular",
        color: "#B6B6B6",
        letterSpacing: "-0.29px",
      },
      "& .selectHolder": {
        fontSize: 12,
        fontFamily: "pc_regular",
        color: "#101010",
        letterSpacing: "-0.29px",
      },
      "& .svgIcon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  mobile: {
    "& .callingCodes,input": {
      fontSize: 14,
      color: UIColor.primaryText.color,
      fontFamily: "pc_medium",
      letterSpacing: "-0.34px",
      fontWeight: "normal",
    },
    "& input::placeholder": {
      fontFamily: "pc_medium",
    },
    "& #guestsignin_addmember_sidemenu_mobilenumberwithcode_textfield": {
      borderColor: UIColor.lineBorderFill.color,
    },
    "& .mobileCountryDivider": {
      borderColor: UIColor.lineBorderFill.color,
    },
  },
  textField: {
    "& input": {
      fontFamily: "pc_medium",
      "&::placeholder": {
        fontFamily: "pc_medium",
      },
    },
  },
  termsandcondition: {
    "& .termsConditionsLabel": {
      margin: 0,
    },
    "& .termsConditionCheckbox": {
      // margin:0,
    },
  },
  htwc: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #DEE5EC",
    borderRadius: "10px",
    marginTop: "16px",
    marginBottom: "16px",
    position: "relative",
    padding: "12px",
    cursor: "pointer",
  },
  htwc1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #01205C",
    borderRadius: "10px",
    marginTop: "16px",
    marginBottom: "0px",
    position: "relative",
    padding: "12px",
    cursor: "pointer",
  },
  cccb_ef_d_db_efbfbd: {
    fontSize: "14px",
    lineHeight: 1,
    marginBottom: 4,
  },
  ed_c_f_be_ebccac: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    letterSpacing: 0.1,
    lineHeight: 1,
  },
  RrEm: {
    color: UIColor.secondaryColor.color,
    backgroundColor: UIColor.secondaryColor.color, //"#F58B000D",
    borderColor: UIColor.secondaryColor.color, //"#F58B000D",
    marginTop: "-22px",
    marginRight: "8px",
    fontSize: "12px",
    position: "absolute",
    right: "0",
    padding: "0px 6px",
  },
  RrEm1: {
    borderRadius: "3px",
    fontSize: "12px",
    outline: "none",
    cursor: "pointe",
    padding: "6px",
    color: "#fff",
    backgroundColor: "#3f51b5",
    border: "1px solid #3f51b5",
    margin: "auto",
    marginRight: "10px"
  },
  Hdva1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    borderColor: UIColor.primaryColor.color,
    borderStyle: "solid",
    borderRadius: "8px",
    color: "#fff",
    fontSize: "14px",
    marginTop: "16px",
    padding: "8px 16px",
    marginBottom: "16px",
    height: "40px",
    background: UIColor.primaryColor.color,
  },
});

class AppointmentPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMembers: false,
      error: null,
      expanded1: true,
      expanded2: true,
      addmemberlist: null,
      type: "text",
      limit: 20,
      listMmbr: [],
      loading: false,
      // dropdownData: [
      //   {
      //     name: "India",
      //     flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
      //     altSpellings: "IND",
      //     callingCodes: "91",
      //   },
      // ],
    };
  }
  handleExpandClick = (val) => {
    console.log(val + ' vimport clsx from "clsx";');
    val === 1
      ? this.setState({
        expanded1: !this.state.expanded1,
      })
      : this.setState({
        expanded2: !this.state.expanded2,
      });
  };
  changeState = (key, val) => {
    let value = val;
    if (key === "Aadhaar Number") {
      if (
        value &&
        value.length < this.state.limit &&
        value.replaceAll("-", "")?.length % 4 === 0
      ) {
        value += "-";
      }
    }

    this.setState({
      [key]: value,
    });
  };

  onKeyDown = (key, e) => {
    if (key === "Aadhaar Number") {
      let value = e.target.value;
      if (e.keyCode === 8) {
        if (value[value.length - 1] === "-") {
          value = value.substring(0, value.length - 2);
          e.preventDefault();
        } else if (value[value.length - 2] === "-") {
          value = value.substring(0, value.length - 1);
          e.preventDefault();
        }
        this.setState({
          [key]: value,
        });
      } else if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault();
      }
    }
  };

  componentDidMount = async () => { 
    await this.props.GET_COUNTRY_CODE_LIST();
    // await this.props?.PRACTITIONER_MASTERS()
    // await this.props?.USEMASTER()
   }

  shouldComponentUpdate(props, state) {
    return true;
  }
  senOtpMMr = async () => {
    const SearchUserData = await this.props.GENERATE_OTP({
      mobileNo: this.state.selectData?.telecom?.find(
        (tel) => tel.system === "Phone" && tel.use === "Mobile"
      )?.value,
    });
    if (SearchUserData.payload.data.error) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: SearchUserData.payload.data.errorMessage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    } else {
      const countryCode = `${this.state[this.props?.chooseSearch?.value]?.countryData
          ?.callingCodes ?? "00"
        }`;
      const phoneNumber = this.state.selectData?.telecom
        ?.find((tel) => tel.system === "Phone" && tel.use === "Mobile")
        ?.value?.split(`+${countryCode}`);
      // this.props.serchMMrDatafuncydata(SearchUserData)
      const signUpData = {
        mobileNo: {
          mobileNo: phoneNumber[1] || phoneNumber[0],
          countryData: {
            callingCodes: countryCode,
          },
        },
        selectData: this.state?.selectData,
        id: this.state?.selectData.Id,
        SearchUserData,
        firstName:
          this.state?.selectData?.name?.text ||
          this.state?.selectData?.name?.[0]?.text ||
          this.state?.selectData?.name?.given ||
          this.state?.selectData?.name?.[0]?.given,
        lastName:
          this.state?.selectData?.name.family ||
          this.state?.selectData?.name?.[0]?.family,
        dob: this.state?.selectData?.birthDay,
        gender: this.state?.selectData?.gender,
        emailId: this.state?.selectData?.telecom?.find(
          (tel) => tel.system === "Email" && tel.use === "Work"
        )?.value,
        middleName: "",
        image: {
          url: getImgUrl(this.state?.selectData?.photo?.[0]?.fileid),
        },
      };
      this.props.nextSection("addmemberotp", "addMember", signUpData);
    }
  };
  searchMember = async () => {
    this.changeState("loading", true);
    let searchType = this.props?.chooseSearch?.value;
    let value = this.state?.[searchType];
    let checkErr = this.checkErr(searchType, value);
    const info = {
      addmemberlist: null,
    };
    if (searchType && searchType === "Mobile Number" && value && checkErr) {
      let res__ = mobileNumberValidation(this.state[this.props?.chooseSearch?.value]?.mobileNo, this.state[this.props?.chooseSearch?.value]?.countryData?.callingCodes);
			debugger
			if (res__ === "Invalid Country code") {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: res__,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
				return
			} else if (res__ === false) {
				this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter valid mobile number",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
				return
			}

      const SearchUserData = await this.props.SEARCH_PERSON({
        phone: this.state[this.props?.chooseSearch?.value]?.mobileNo,
        // phone: '8056895305',
        use: "Mobile",
      });

      if (SearchUserData?.payload?.data) {
        // this.setState({
        //   ...this.state,
        //   showMembers: true,
        //   error: null,
        //   listMmbr: SearchUserData?.payload?.data,
        // });
        if (SearchUserData?.payload?.data?.length === 0) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Could Not Find Any Users.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
        info["listMmbr"] = SearchUserData?.payload?.data;
      }
      // this.setState({
      //   ...this.state,
      //   showMembers: true,
      //   error: null,
      // });
      info.showMembers = true;
      info.error = null;
    } else if (searchType && searchType === "PID Number" && value && checkErr) {
      const SearchUserData = await this.props.READ_PATIENT({
        mrnnumber: value,
      });
      if (SearchUserData?.payload?.data) {
        // this.setState({
        //   ...this.state,
        //   showMembers: true,
        //   error: null,
        //   listMmbr: SearchUserData?.payload?.data,
        // });
        if (SearchUserData?.payload?.data?.length === 0) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Could Not Find Any Users.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
        info["listMmbr"] = SearchUserData?.payload?.data;
      }
      // this.setState({
      //   ...this.state,
      //   showMembers: true,
      //   error: null,
      // });
      info.showMembers = true;
      info.error = null;
    } else if (
      searchType &&
      searchType === "Aadhaar Number" &&
      value &&
      checkErr
    ) {
      let Adhaar = value.replaceAll("-", "");
      const SearchUserData = await this.props.SEARCH_BY_ADHAAR({
        adhaarNo: Adhaar,
        //adhaarNo: "21323",
      });

      if (SearchUserData?.payload?.data) {
        // this.setState({
        //   ...this.state,
        //   showMembers: true,
        //   error: null,
        //   listMmbr: SearchUserData?.payload?.data,
        // });
        if (SearchUserData?.payload?.data?.result?.length === 0) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Could Not Find Any Users.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
        let data = SearchUserData?.payload?.data.result.map((v) => {
          const data = JSON.parse(JSON.stringify(v || {}));
          const person = JSON.parse(JSON.stringify(data?.person?.[0] || {}));
          delete data.person;
          const newInfo = {
            ...person,
            patient: [
              {
                ...data,
              },
            ],
          };
          return newInfo;
        });
        info["listMmbr"] = data;
      }
      info.showMembers = true;
      info.error = null;
    } else {
      // this.setState({
      //   ...this.state,
      //   showMembers: false,
      //   error: this.errorMessage(searchType, value),
      // });
      info.showMembers = false;
      info.error = this.errorMessage(searchType, value);
    }
    this.setState({
      ...this.state,
      ...info,
      loading: false,
    });
  };

  checkErr = (stateName, val) => {
    if (stateName === "PID Number") {
      return /MRN00_[0-9]+$/.test(val) ? true : false;
    } else if (stateName === "Aadhaar Number") {
      return val?.length === 14 ? true : false;
    } else if (stateName === "Mobile Number") {
      // return val?.countryData?.callingCodes && val?.mobileNo?.length === 10
      //   ? true
      //   : false;

        let res__ = mobileNumberValidation(val?.mobileNo, val?.countryData?.callingCodes);
        debugger
        if (res__ === "Invalid Country code") {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res__,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return false
        } else if (res__ === false) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please enter valid mobile number",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return false
        }
        return true;
    } else {
      return true;
    }
  };

  errorMessage = (searchType, value) => {
    if (searchType === "PID Number") {
      return "Please enter valid MRN Number";
    } else if (searchType === "Aadhaar Number") {
      return "Please enter valid Aadhaar Number";
    } else if (searchType === "Mobile Number") {
      if (!value?.countryData?.callingCodes) {
        return "Please select country code";
      } 
      else {
        let res__ = mobileNumberValidation(value?.mobileNo, value?.countryData?.callingCodes);
        debugger
        if (res__ === "Invalid Country code") {
          return res__;
        } else if (res__ === false) {
          return "Please enter valid Mobile Number";
        }
      // else if (value?.mobileNo?.length !== 10) {
      //   return "Please enter valid Mobile Number";
      // }
      }
    } else {
      return "Please fill all the mandatory fields";
    }
  };

  handleCheked = (checked, v, l) => {
    if (checked) {
      this.setState({
        ...this.state,
        addmemberlist: l,
        selectData: v,
      });
    } else {
      this.setState({
        ...this.state,
        addmemberlist: null,
        selectData: null,
      });
    }
  };

  handleChange = (e) => {
    if (this.props.chooseSearch?.value === e.value) {
      return;
    }
    let type, limit;
    if (e.value === "PID Number") {
      type = "text";
      limit = 20;
    } else if (e.value === "Aadhaar Number") {
      type = "text";
      limit = 14;
    }

    this.props.handelChangeSelect && this.props.handelChangeSelect(e);

    if (e.value === "Mobile Number") {
      if (this.state?.dropdownData?.length === 1) {
        let value = {
          countryData: this.state?.dropdownData?.[0],
          mobileNo: "",
        };
        this.setState({
          ...this.state,
          type,
          limit,
          listMmbr: [],
          // eslint-disable-next-line no-useless-computed-key
          ["Mobile Number"]: value,
        });
      }
    } else {
      this.setState({
        ...this.state,
        type,
        limit,
        listMmbr: [],
        [e.value]: "",
      });
    }
  };

  onChangeStateName = () => {
    return (
      (this.props.chooseSearch?.value === "PID Number" && "PID Number") ||
      (this.props.chooseSearch?.value === "Aadhaar Number" &&
        "Aadhaar Number") ||
      (this.props.chooseSearch?.value === "Emirate ID" && "Emirate ID") ||
      (this.props.chooseSearch?.value === "SSN Number" && "SSN Number")
    );
  };

  render() {
    const { classes, parent_id } = this.props;
    const { addmemberlist } = this.state;
    return (
      <div id={`${parent_id}_addmember_parent_div`}>
        <Container
          id={`${parent_id}_addmember_parent_container`}
          key={"0"}
          name={"container"}
          fluid={true}
          style={qdmstyles.rpP}
        >
          <H6
            id={`${parent_id}_addmember_sidemenu_title`}
            className={"pc_semibold"}
            key={"0"}
            name={this.props.name}
            style={{
              fontSize: "20px",
              color: UIColor.primaryText.color,
              letterSpacing: 0,
              lineHeight: 1,
            }}
          ></H6>

          <Divider
            id={`${parent_id}_addmember_sidemenu_title_divider`}
            key={"1"}
            textWidth={"50"}
            textColor={"#000"}
            borderColor={"lightgray"}
            borderWidth={"1"}
            orientation={"horizontal"}
            variant={"middle"}
            style={qdmstyles.BKv}
          ></Divider>

          {/* <Collapse
            key={"2"}
            title={"Existing Member"}
            subtitle={""}
            body={"Collapse Body"}
            iconPosition={"left"}
            iconHeight={"20"}
            iconWidth={"20"}
            url={""}
            style={qdmstyles.aGaSE}
            openIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
            }
            closeIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root jss107"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
              </svg>
            }
          > */}

          <Card
            id={`${parent_id}_addmember_sidemenu_search_member_card`}
            style={{
              overflow: "visible",
              boxShadow: "none",
              border: "1px solid #DEE5EC",
              borderRadius: 8,
            }}
          >
            <CardActions
              id={`${parent_id}_addmember_sidemenu_search_member_cardaction`}
              onClick={() => this.handleExpandClick(1)}
              disableSpacing
              style={{
                padding: 0,
                paddingLeft: 16,
                paddingBottom: 0,
                cursor: "pointer",
              }}
            >
              <Text
                id={`${parent_id}_addmember_sidemenu_existing_member_text`}
                key={"0"}
                name="Existing Member"
                style={{
                  fontSize: "16px",
                  color: UIColor.primaryText.color,
                  letterSpacing: 0,
                  lineHeight: 1,
                }}
                className={"pc_semibold"}
              />

              <IconButton
                id={`${parent_id}_addmember_sidemenu_existing_member_icon_button`}
                className={clsx(classes.expand, {
                  [classes.expandOpen]: this.state.expanded1,
                })}
                aria-expanded={this.state.expanded1}
                aria-label="show more"
              >
                <ExpandMoreIcon
                  id={`${parent_id}_addmember_sidemenu_existing_member_icon`}
                />
              </IconButton>
            </CardActions>
            <Collapse
              id={`${parent_id}_addmember_sidemenu_existing_member_collapse`}
              className={classes.body}
              in={this.state.expanded1}
              timeout="auto"
              unmountOnExit
            >
              <H6
                id={`${parent_id}_addmember_sidemenu_existing_member_discription_text`}
                key={"0"}
                name={"If the member already registered with the hospital."}
                style={{
                  width: "100%",
                  display: "flex",
                  fontSize: "12px",
                  marginBottom: "24px",
                  color: UIColor.secondaryText.color,
                  letterSpacing: 0,
                  lineHeight: 1,
                }}
                className="pc_regular"
              ></H6>

              <H6
                id={`${parent_id}_addmember_sidemenu_existing_member_chooseid_text`}
                key={"1"}
                className="pc_regular"
                name={
                  <span
                    id={`${parent_id}_addmember_sidemenu_existing_member_chooseid_span`}
                    className="pc_regular"
                    style={{
                      fontSize: 12,
                      letterSpacing: 0,
                      lineHeight: 1,
                      color: UIColor.secondaryText.color,
                    }}
                  >
                    Choose ID and enter criteria{" "}
                    <span
                      style={{ color: UIColor.error.color }}
                      id={`${parent_id}_addmember_sidemenu_existing_member_chooseid_staricon`}
                    >
                      *
                    </span>
                  </span>
                }
                style={{
                  width: "100%",
                  color: UIColor.secondaryText.color,
                  fontSize: "12px",
                }}
              ></H6>

              <Div
                key={"2"}
                className={"qdm"}
                style={{
                  width: "100%",
                  color: UIColor.secondaryText.color,
                  fontSize: "12px",
                  display: "flex",
                  marginTop: "10px",
                }}
                id={`${parent_id}_addmember_sidemenu_chooseid_here_dropdown_parent_div`}
              >
                <div
                  id={`${parent_id}_addmember_sidemenu_chooseid_here_dropdown_sublevel_div`}
                  style={{ marginRight: 8 }}
                >
                  <Dropdown
                    id={`${parent_id}_addmember_sidemenu_chooseid_here_dropdown`}
                    inLineStyles={{
                      height: "42px",
                      fontWeight: "400",
                      fontSize: "13px",
                      color: UIColor.secondaryText.color,
                    }}
                    name="Choose ID here"
                    onChange={(e) => {
                      if (this.state.error) {
                        this.setState(
                          {
                            ...this.state,
                            error: null,
                          },
                          () => {
                            this.handleChange(e);
                          }
                        );
                      } else {
                        this.handleChange(e);
                      }
                    }}
                    placeholder="Choose ID here"
                    className={ (!this.props.chooseSearch && this.state.error) ? classes.dropdownErr : classes.dropdown}
                    options={[
                      {
                        value: "Mobile Number",
                        label: "Mobile Number",
                      },
                      {
                        value: "PID Number",
                        label: "MRN Number",
                      },
                      {
                        value: "Aadhaar Number",
                        label: "Aadhaar Number",
                      },
                      // {
                      //   value: 'Emirate ID',
                      //   label: 'Emirate ID'
                      // },
                      // {
                      //   value: 'SSN Number',
                      //   label: 'SSN Number'
                      // }
                    ]}
                    // options = { this.props?.practitionerMaster?.id_type_master?.data}
                    value={this.props.chooseSearch}
                  />
                </div>
                {this.props.chooseSearch?.value === "Mobile Number" ? (
                  <div className={classes.mobile}>
                    <MobileNumberWithCode
                      id={`${parent_id}_addmember_sidemenu_mobilenumberwithcode_textfield`}
                      value={this.state["Mobile Number"]?.mobileNo}
                      defaultValue={this.state["Mobile Number"]?.mobileNo}
                      dropdown={this.state["Mobile Number"]?.countryData}
                      key={"2"}
                      type="number"
                      label="Mobile No"
                      borderColor={UIColor.lineBorderFill.color}
                      borderRadius="6"
                      maxLength={30}
                      name=""
                      width=""
                      dropdownData={this?.props?.CountryCode?.data}
                      disabled={false}
                      size=""
                      marginAll="0"
                      marginLR="0"
                      marginTB="0"
                      borderWidth="0"
                      textWidth="0"
                      style={{
                        height: "40px",
                        borderRadius: "8px",
                        // width: "110%",
                      }}
                      onChange={(e) => {
                        if (
                          this.state.error &&
                          (this.state.error === "Please select country code" ||
                            this.state.error ===
                            "Please enter valid Mobile Number")
                        ) {
                          let errMsg = null;
                          if (
                            !e?.countryData?.callingCodes &&
                            this.state.error === "Please select country code"
                          ) {
                            errMsg = "Please select country code";
                          } 
                          // else if (
                          //   e?.mobileNo?.length !== 10 &&
                          //   this.state.error ===
                          //   "Please enter valid Mobile Number"
                          // ) {
                          //   errMsg = "Please enter valid Mobile Number";
                          // }
                          this.setState(
                            {
                              ...this.state,
                              error: errMsg,
                            },
                            () => {
                              this.changeState("Mobile Number", e);
                            }
                          );
                        } else {
                            this.setState({
                                ...this.state,
                                error: "",
                              });
                          this.changeState("Mobile Number", e);
                        }
                      }}
                      className={this.state.error && "errorBorder"}
                    />
                  </div>
                ) : (
                  <TextInput
                    id={`${parent_id}_addmember_sidemenu_choose_member_textfield`}
                    className={classes.textField}
                    key={"0"}
                    type={this.state.type}
                    // onInput={e=>e>10 && }
                    variant={"outlined"}
                    value={this.state[this.props?.chooseSearch?.value]}
                    onChange={(e) => {
                      const type = this.onChangeStateName();
                      if (
                        this.state.error &&
                        this.state.error ===
                        "Please enter valid Aadhaar Number" &&
                        type === "Aadhaar Number"
                      ) {
                        const value = e?.target?.value || "";
                        let errMsg = null;
                        if (
                          value?.length !== 14 &&
                          this.state.error ===
                          "Please enter valid Aadhaar Number"
                        ) {
                          errMsg = "Please enter valid Aadhaar Number";
                        }
                        this.setState(
                          {
                            ...this.state,
                            error: errMsg,
                          },
                          () => {
                            this.changeState(type, value);
                          }
                        );
                      } else if (
                        this.state.error &&
                        this.state.error === "Please enter valid MRN Number" &&
                        type === "PID Number"
                      ) {
                        const value = e?.target?.value || "";
                        let errMsg = null;
                        if (
                          !/MRN00_[0-9]+$/.test(value) &&
                          this.state.error === "Please enter valid MRN Number"
                        ) {
                          errMsg = "Please enter valid MRN Number";
                        }
                        this.setState(
                          {
                            ...this.state,
                            error: errMsg,
                          },
                          () => {
                            this.changeState(type, value);
                          }
                        );
                      } else {
                        this.changeState(type, e.target.value);
                      }
                    }}
                    onKeyDown={(e) =>
                      this.onKeyDown(this.onChangeStateName(), e)
                    }
                    helperTextType={"error"}
                    autoFocus={false}
                    autoComplete={"off"}
                    maxLength={this.state.limit}
                    search={false}
                    borderColor={UIColor.lineBorderFill.color}
                    elevation={"0"}
                    placeholder={
                      (this.props.chooseSearch?.value === "PID Number" &&
                        "MRN00_000") ||
                      (this.props.chooseSearch?.value === "Aadhaar Number" &&
                        "4444-4444-4444") ||
                      (this.props.chooseSearch?.value === "Emirate ID" &&
                        "989-2345-2345-1") ||
                      (this.props.chooseSearch?.value === "SSN Number" &&
                        "123-12-1234")
                    }
                    size={"medium"}
                    style={{
                      borderRadius: "8px",
                      height: "40px",
                      //borderColor: UIColor.lineBorderFill.color,
                    }}
                    name={""}
                    label={""}
                    error={this.state.error}
                  ></TextInput>
                )}
              </Div>
              {this.state.error && (
                <div
                  id={`${parent_id}_addmember_sidemenu_choose_member_error_div`} style={{ marginTop: "10px" }}
                >
                  <Text
                    id={`${parent_id}_addmember_sidemenu_choose_member_error_text`}
                    style={{ color: UIColor.error.color, fontSize: 12 }}
                    className="pc_regular"
                  >
                    {this.state.error}
                  </Text>
                </div>
              )}
              <div style={{ margin: "16px auto", textAlign: "center" }}>
                <Button
                  id={`${parent_id}_addmember_sidemenu_choose_member_search_button`}
                  key={"1"}
                  type={"button"}
                  variant={"text"}
                  disabled={this.state.loading}
                  name={this.state.loading ? "Searching..." : "Search"}
                  // inLineStyles={{
                  //   display: "flex",
                  //   margin: "auto",
                  //   marginTop: "16px !important",
                  //   marginBottom: "16px",
                  // }}
                  style={{
                    padding: "10px 16px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    borderColor: UIColor.lineBorderFill.color,
                    color: UIColor.primaryColor.color,
                    //color: "#fff",
                    backgroundColor: UIColor.lineBorderFill.color,
                    height: "40px",
                    lineHeight: 1,
                    letterSpacing: 0
                  }}
                  className="pc_medium"
                  onClick={() => this.searchMember()}
                ></Button>
              </div>
              {this.state?.showMembers && (
                <>
                  {this.state?.listMmbr?.map((v, key) => {
                    return (
                      <Div
                        id={`${parent_id}_addmember_sidemenu_existing_member_list_div`}
                        key={"3"}
                        //className={"qdm"}
                        className={
                          addmemberlist !== key ? classes.htwc : classes.htwc1
                        }
                      // onClick={(e) => this.handleCheked(v, key)}
                      >
                        {!this.props?.memberIds?.includes(v?.Id) ? (
                          <TermsAndConditions
                            // value={true}
                            // key={"4"}
                            id="doctor_listing_iagreeinstructions_termsAndconditions"
                            value={addmemberlist === key ? true : false}
                            disabled={this.props?.memberIds?.includes(v?.Id)}
                            checkboxTickColor={"#fff"}
                            activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
                            checkboxBorderColor={UIColor.lineBorderFill.color}
                            checkboxBackgroundColor={UIColor.differentiationBackground.color}
                            activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
                            checkboxSize={14}
                            lable={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  id={`${parent_id}_addmember_sidemenu_existing_member_list_avatar`}
                                  key={"1"}
                                  variant={"circle"}
                                  letter={v?.name?.text || v?.name?.given}
                                  src={getImgUrl(v?.photo?.[0]?.fileid || "")}
                                  alt={"Image"}
                                  inLineStyles={{
                                    padding: 0,
                                    height: 40,
                                    width: 40,
                                    margin: "0px 8px",
                                  }}
                                ></Avatar>
                                <Div
                                  key={"2"}
                                  id={`${parent_id}_addmember_sidemenu_existing_member_list_rightside_content_parent_div`}
                                >
                                  <Div
                                    key={"0"}
                                    id={`${parent_id}_addmember_sidemenu_existing_member_list_rightside_content_sublevel_div`}
                                  >
                                    <H6
                                      className="pc_medium"
                                      id={`${parent_id}_addmember_sidemenu_existing_member_list_username_h6`}
                                      key={"0"}
                                      name={makeName(v?.name?.[0] || v?.name || "")}
                                      style={{"fontSize": "14px",
                                      "lineHeight":1,
                                      "marginBottom":4}}
                                    ></H6>

                                    <H6
                                      className="pc_regular"
                                      key={"1"}
                                      id={`${parent_id}_addmember_sidemenu_existing_member_list_discription_text`}
                                      name={`${
                                        v?.patient?.[0]?.alias ||
                                        (this.props?.chooseSearch?.value ===
                                          "PID Number" &&
                                          this.state[
                                            this.props?.chooseSearch?.value
                                          ]) ||
                                        "----"
                                      } ,${
                                        v?.telecom?.find(
                                          (tel) =>
                                            tel.system === "Phone" &&
                                            tel.use === "Mobile"
                                        )?.value
                                        }, ${convertTime(
                                          v?.birthDay,
                                          "DD/MM/YYYY",
                                          "years",
                                          "diff"
                                        )} Yrs, ${v?.gender}`}
                                      // name={'PID 24***74 , +91 98******14 , 31 Yrs, Male'}
                                      style={{"color": "#B6B6B6",
                                      "fontSize": "12px",
                                      "letterSpacing":0.1,
                                      "lineHeight":1}}
                                    ></H6>
                                  </Div>
                                </Div>
                              </div>
                            }
                            className={classes.termsandcondition}
                            onChange={(e) => this.handleCheked(e, v, key)}
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              id={`${parent_id}_addmember_sidemenu_existing_member_list_avatar`}
                              key={"1"}
                              variant={"circle"}
                              letter={v?.name?.text || v?.name?.given}
                              src={getImgUrl(v?.photo?.[0]?.fileid || "")}
                              alt={"Image"}
                              inLineStyles={{
                                padding: 0,
                                height: 40,
                                width: 40,
                                margin: "0px 8px",
                              }}
                            ></Avatar>
                            <Div
                              key={"2"}
                              id={`${parent_id}_addmember_sidemenu_existing_member_list_rightside_content_parent_div`}
                            >
                              <Div
                                key={"0"}
                                id={`${parent_id}_addmember_sidemenu_existing_member_list_rightside_content_sublevel_div`}
                              >
                                <H6
                                  className="pc_medium"
                                  id={`${parent_id}_addmember_sidemenu_existing_member_list_username_h6`}
                                  key={"0"}
                                  name={
                                    v?.name?.text || v?.name?.[0]?.text || v?.name?.given || v?.name?.[0]?.given || " "
                                  }
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: 1,
                                    marginBottom: 4,
                                  }}
                                ></H6>

                                <H6
                                  className="pc_regular"
                                  key={"1"}
                                  id={`${parent_id}_addmember_sidemenu_existing_member_list_discription_text`}
                                  name={`${
                                    v?.patient?.[0]?.alias ||
                                    (this.props?.chooseSearch?.value ===
                                      "PID Number" &&
                                      this.state[
                                        this.props?.chooseSearch?.value
                                      ]) ||
                                    "----"
                                  } ,${
                                    v?.telecom?.find(
                                      (tel) =>
                                        tel.system === "Phone" &&
                                        tel.use === "Mobile"
                                    )?.value
                                  }, ${convertTime(
                                    v?.birthDay,
                                    "DD/MM/YYYY",
                                    "years",
                                    "diff"
                                  )} Yrs, ${v?.gender}`}
                                  // name={'PID 24***74 , +91 98******14 , 31 Yrs, Male'}
                                  style={{
                                    color: UIColor.tertiaryText.color,
                                    fontSize: "12px",
                                    letterSpacing: 0.1,
                                    lineHeight: 1,
                                  }}
                                ></H6>
                              </Div>
                            </Div>
                          </div>
                        )}
                        {this.props?.memberIds?.includes(v?.Id) && 
                        // <Button
                        //   id={`${parent_id}_addmember_sidemenu_existing_member_list_added_tag`}
                        //   key={"1"}
                        //   type={"button"}
                        //   variant={"outlined"}
                        //   name={
                        //     this.props?.memberIds?.includes(v?.Id)
                        //       ? "Added"
                        //       : ""
                        //   }
                        //   className={this.props?.memberIds?.includes(v?.Id) ? classes.RrEm1 : classes.RrEm}
                        // ></Button>
                        <div style={{
                          width: "40px",
                          height: "15px",
                          letterSpacing: "0px",
                          color: "rgb(42, 96, 188)",
                          opacity: 1,
                          fontSize: "10px",
                          fontWeight: 500
                          }}>
                          <span style={{
                                border: "1px solid #c9d8f0",
                                borderRadius: "5px",
                                padding: "2px 4px 4px 4px",
                                background: "#c9d8f0"
                          }}>Added</span>
                        </div>
                  }
                      </Div>
                    );
                  })}

                  {addmemberlist !== null && (
                    <Button
                      id={`${parent_id}_addmember_sidemenu_existing_member_list_sendotp_button`}
                      key={"1"}
                      className="pc_medium"
                      type={"button"}
                      variant={"text"}
                      name={
                        this.props.signUp
                          ? this.props?.registerUsers?.loading ? "Sending OTP..." : "Send OTP & Sign Up"
                          : this.props?.generateOTP?.loading ? "Sending OTP..." : "Send OTP & Add Member"
                      }
                      style={{
                        padding: "10px 16px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        borderColor: UIColor.primaryColor.color,
                        // color: UIColor.primaryColor.color,
                        color: "#fff",
                        backgroundColor: UIColor.primaryColor.color,
                        height: "40px",
                        lineHeight: 1,
                        letterSpacing: 0,
                      }}
                      className={classes.Hdva1}
                      onClick={async () => {
                        if (this.props.signUp) {
                          const firstName =
                            this.state.selectData?.name?.[0]?.text ||
                            this.state.selectData?.name?.text ||
                            this.state.selectData?.name?.[0]?.given ||
                            this.state.selectData?.name?.given ||
                            "";
                          const lastName =
                            this.state.selectData?.name?.[0]?.family ||
                            this.state.selectData?.name?.family ||
                            "";
                          const info = {
                            mobileNo: this.state.selectData?.telecom?.find(
                              (tel) =>
                                tel.system === "Phone" && tel.use === "Mobile"
                            )?.value,
                            firstName,
                            lastName,
                            emailId:
                              this.state.selectData?.telecom?.find(
                                (t) => t.system === "Email" && t.use === "Work"
                              )?.value || "",
                          };
                          const data = await this.props.REGISTER_USERS(info);
                          const status = checkError(data?.payload);
                          if (status.isError) {
                            if (status.errMsg === "User already exists.") {
                              status.errMsg =
                                "User already exists. Please Try Sign in.";
                            }
                            this.context.setSnack({
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: status.errMsg,
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.right,
                              tone: true,
                            });
                          } else {
                            const countryCode = ``;
                            const phoneNumber = info.mobileNo.split("+");
                            this.props.nextSection("addmemberotp", {
                              existingPerson: true,
                              mobileNo: {
                                mobileNo: phoneNumber[1] || phoneNumber[0],
                                countryData: {
                                  callingCodes: countryCode,
                                },
                              },
                              selectData: this.state?.selectData,
                              id: this.state?.selectData.Id,
                              firstName: info.firstName,
                              lastName: info.lastName,
                              dob: this.state?.selectData?.birthDay,
                              gender: this.state?.selectData?.gender,
                              emailId: info.emailId,
                              middleName: "",
                              otpNumber: info.mobileNo,
                            });
                          }
                          return;
                        }
                        if (
                          this.props?.memberIds?.includes(
                            this.state.selectData.Id
                          )
                        ) {
                          this.context.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Member Already Added To Your Profile",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                          });
                        } else {
                          addmemberlist !== null
                            ? this.senOtpMMr()
                            : this.props.nextSection(
                              "addmemberotp",
                              "addMember"
                            );
                        }
                      }}
                    ></Button>
                  )}
                </>
              )}
            </Collapse>
          </Card>

          {/* </Collapse> */}

          {/* <Collapse
            key={"3"}
            title={"New Member"}
            subtitle={""}
            body={"Collapse Body"}
            iconPosition={"left"}
            iconHeight={"20"}
            iconWidth={"20"}
            url={""}
            style={qdmstyles.JgF}
            openIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
            }
            closeIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root jss107"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
              </svg>
            }
          > */}
          <Card
            style={{
              marginTop: "16px",
              boxShadow: "none",
              border: "1px solid #DEE5EC",
              borderRadius: 8,
            }}
            id={`${parent_id}_addmember_sidemenu_addnewmember_parent_card`}
          >
            <CardActions
              id={`${parent_id}_addmember_sidemenu_addnewmember_parent_cardactions`}
              onClick={() => this.handleExpandClick(2)}
              disableSpacing
              style={{
                padding: 0,
                paddingLeft: 16,
                paddingBottom: 0,
                cursor: "pointer",
              }}
            >
              <Text
                id={`${parent_id}_addmember_sidemenu_addnewmember_title`}
                key={"0"}
                name="New Member"
                style={{
                  fontSize: "16px",
                  letterSpacing: 0,
                  lineHeight: 1,
                  color: UIColor.primaryText.color,
                }}
                className={"pc_semibold"}
              />

              <IconButton
                id={`${parent_id}_addmember_sidemenu_addnewmember_showmoreicon_button`}
                className={clsx(classes.expand, {
                  [classes.expandOpen]: this.state.expanded2,
                })}
                aria-expanded={this.state.expanded2}
                aria-label="show more"
              >
                <ExpandMoreIcon
                  id={`${parent_id}_addmember_sidemenu_addnewmember_showmoreicon_icon`}
                />
              </IconButton>
            </CardActions>
            <Collapse
              id={`${parent_id}_addmember_sidemenu_addnewmember_parent_collapse`}
              className={classes.body}
              in={this.state.expanded2}
              timeout="auto"
              unmountOnExit
            >
              <H6
                id={`${parent_id}_addmember_sidemenu_addnewmember_discription`}
                key={"0"}
                name={
                  "If the member is not registered with the hospital before. Please proceed with Add New Member."
                }
                style={{
                  color: UIColor.secondaryText.color,
                  display: "flex",
                  fontSize: "12px",
                  letterSpacing: 0,
                  lineHeight: 1.6,
                }}
                className={"pc_regular"}
              ></H6>

              <Button
                id={`${parent_id}_addmember_sidemenu_addnewmember_submit_button`}
                key={"1"}
                type={"button"}
                variant={"text"}
                name={this.props.name}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  borderColor: UIColor.primaryColor.color,
                  borderStyle: "solid",
                  borderRadius: "8px",
                  color: "#fff",
                  fontSize: "14px",
                  marginTop: "16px",
                  padding: "8px 16px",
                  marginBottom: "16px",
                  height: "40px",
                  background: UIColor.primaryColor.color
                }}
                onClick={() => this.props.nextSection("addmemberform")}
                className={"pc_medium"}
              ></Button>
            </Collapse>
          </Card>
          {/* </Collapse> */}
        </Container>
      </div>
    );
  }
}
AppointmentPageDrawer.contextType = AlertContext;

const mapStateToProps = (state) => ({
  readPerson: state?.personApiSlice?.readPerson,
  readPatient: state?.personApiSlice?.readPatient,
  registerUsers: state?.authSlice?.registerUsers,
  generateOTP: state?.authSlice?.generateOTP,
  CountryCode: state?.authSlice?.country_code_list

});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(AppointmentPageDrawer))
);

// export default connect(
//   mapStateToProps,
//   actions
// )(withRouter(AppointmentPageDrawer));
