/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Providing the DrawerMeetingContext from /src/context which is used in /src/App.js
 */

import React from "react";
import { DrawerMeetingContext } from "./contexts";
import { Drawer, withStyles, Avatar } from "@material-ui/core";
import { DrawerMeetingProps } from "./utils";
import { VideoCallComponent } from "../src/components";
import { AvcSideDock } from "../src/assets/img/avc_sideDock";
import { AvcFullScreen } from "../src/assets/img/avcFullScreen";
import { MiniAvcComp } from "../src/assets/img/avc_mini";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import config from "../src/config";
const styles = (theme) => ({
	paper: {
		zIndex:9999
	},
	minimize:{
		zIndex: 9999,
		top: 'calc(100vh - 80px)',
		position:"fixed",
		width: 286,
		display:"unset",
		height: 40,
		overflow:'hidden',
		right: 0,
		bottom: 20,
		borderRadius: 8,
	}
});

class AppMeeting extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();

		this.state = {
			open: false,
			direction: DrawerMeetingProps.direction.right,
			variant: DrawerMeetingProps.variant.temporary,
			user_name: "",
			isLarge: null,
			jwt: "",
			RoomName: "",
			widthAdjState: this.props.widthAdjState,
			component: <>Hai</>,
			counter: this.props.counter,
            pos: {
				top: 250,
				left: 150
			},
            dragging: false,
            rel: null,
		};

		this.mouseMove = this.mouseMove.bind(this)
		this.mouseDown = this.mouseDown.bind(this)
		this.mouseUp = this.mouseUp.bind(this)
	}

	close = () => {
		document.getElementById("jitsi-iframe").getElementsByTagName("iframe")[0].src="";
		document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("left");
		document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("top");
		document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("right");
		document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("bottom")
		this.setState({
			...this.state,
			open: false,
			isLarge: null,
			component: <></>,
		});
		this.props.onClose_ && this.props.onClose_();
	};

	mouseDown(e) {
		console.log("================================================")
		console.log(e.pageX)
		console.log(e.pageY)
		console.log("================================================")
		if(this.state.widthAdjState === "mini"){
			document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.left= e.x +"px";
			document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.top= e.y +"px";
			document.getElementsByTagName("body")[0].style.removeProperty("userSelect");
		}
		document.addEventListener('mousemove', this.mouseMove);
		document.addEventListener('mouseup', this.mouseUp);
	  }
	  mouseMove(e) {
		console.log(e.pageX + " x")
		console.log(e.pageY + " y")
		debugger;
		if(this.state.widthAdjState === "mini") {
			document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.left = e.x+"px";
			document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.top = e.y+"px";
			document.getElementsByTagName("body")[0].style.userSelect = "none";
		}
	  }

	  mouseUp(e) {
		console.log("__________________________________________")
		console.log("__________________ mouse up ________________")
		console.log("__________________________________________")
		if(this.state.widthAdjState === "mini"){
			debugger;
			document.getElementsByTagName("body")[0].style.userSelect = "none";
		}
		document.removeEventListener('mousemove', this.mouseMove);
		document.removeEventListener('mouseup', this.mouseUp);
	  }

	setSceript = () => {
		let src = `${config.portal_call}://${config.avc_url}/external_api.js`;
		const script = document.createElement("script");
		// script.src = "https://avcprimarycare.demo.ainqaplatform.in/external_api.js";
		script.src = src;
		script.async = true;
		document.head.appendChild(script);
	}

	componentDidMount(){
		this.setSceript();
	}

	set = (props) => {
		this.setState({ ...props });
	};

	widthAdj = (val) => {
		if(this.state.widthAdjState === "mini"){
			debugger;
			document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("left");
			document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("top");
			document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.right = "0px";
			document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.bottom = "0px";
			this.setState({
				...this.state,
				widthAdjState: val,
			});
		}
		else{
			document.getElementsByTagName("body")[0].style.userSelect = "none";
			this.setState({
				...this.state,
				widthAdjState: val,
			});
		}
	};

	render() {
		const { classes } = this.props;
		const { direction, variant, open, component, isLarge } = this.state;

		return (
			<DrawerMeetingContext.Provider
				value={{
					...this.state,
					setMeetingDrawer: this.set,
					onClose: this.close,
				}}
				
			>
				{this.props.children}
				<Drawer
					anchor={direction}
					open={open}
					onClose={() => this.close()}
					hideBackdrop={true}
					variant={"persistent"}
					classes={{
						paper: this.state.widthAdjState === "mini" ? classes.minimize : classes.paper
					}}
					ref={this.myRef} 
					onMouseDown={(e) => this.mouseDown(e)}
				>
					<div
						style={
							this.state.widthAdjState === "full"
								? { width: "100vw" }
								: { width: "20vw" }
						}
					>
						<div
							style={{
								backgroundColor: "#000",
								padding: 8,
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<IconButton
								component="span"
								style={{ padding: 2,fontSize:18,backgroundColor:"red" }}
								onClick={() => this.close()}
							>
								<CloseIcon htmlColor="#fff" style={{fontSize:18,}} />
							</IconButton>
							
							 <div>
							 
							&nbsp;&nbsp;&nbsp;
							
							</div>
							
							<div style={{
								backgroundColor: "#000",
								display: "flex",
								justifyContent: "end",
								alignItems: "center",
								width: '100%'
							}}>
								<IconButton
                                 component="span"
                                 style={{ padding: 2 }}
                                 onClick={() => this.widthAdj("mini")}
                             >
                                 <MiniAvcComp
                                     isactive={this.state.widthAdjState === "mini" ? true : false}
                                 />
                             </IconButton>
							&nbsp;&nbsp;&nbsp;
								<IconButton
									component="span"
									style={{ padding: 2 }}
									onClick={() => this.widthAdj("side")}
								>
									<AvcSideDock
										isactive={this.state.widthAdjState === "side" ? true : false}
									/>
								</IconButton>
								&nbsp;&nbsp;&nbsp;
								<IconButton
									component="span"
									style={{ padding: 2 }}
									onClick={() => this.widthAdj("full")}
								>
									<AvcFullScreen
										isactive={this.state.widthAdjState === "full" ? true : false}
									/>
								</IconButton>
							</div>
							&nbsp;&nbsp;&nbsp;
						</div>
						<VideoCallComponent
							userName={this.state.user_name}
							open={open}
							RoomName={this.state.RoomName}
							// jwt={this.state.jwt}
							closeFun={this.close}
							domain={config.avc_url}
						/>
					</div>
				</Drawer>
			</DrawerMeetingContext.Provider>
		);
	}
}

export default withStyles(styles)(AppMeeting);
