import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Divider, Text } from "qdm-component-library";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "qdm-component-library";
import { UIColor } from "../../utils";
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  autoComplete: {
    '& .MuiInputBase-input': {
      padding: '4.5px 4px !important'
    },
    '& .MuiInputLabel-marginDense': {
      fontSize: 14,
      fontFamily: 'pc_regular'
    },
  },
});

class BookMultipleAppointmentsDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultationMode: "",
      practitionerGender: "",
      practitionerPreferredLanguage: [],
      specialties: {},
      language: this.props.allLang ?? []
    };
    this.submitFilter = this.submitFilter.bind(this);
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  resetFilter = () => {
    const specialties = {};
    (this.props.selectedSpec || []).forEach(s => {
      specialties[s.value] = true;
    })
    this.setState({
      consultationMode: "",
      practitionerGender: "",
      practitionerPreferredLanguage: [],
      specialties,
    }, () => {
      this.props.resetFilter();
    });
  };

  componentDidMount() {
    let updatedObj = {};
    if (Object.keys(this.props.filterData || {}).length === 0) {
      updatedObj["specialties"] = {};
      (this.props.selectedSpec || []).forEach(s => {
        updatedObj["specialties"][s.value] = true;
      })
    }
    this.setState({
      ...(this.props.filterData || {}),
      ...updatedObj,
    })
  }

  shouldComponentUpdate(props, state) {
    return true;
  }

  submitFilter = () => {
    const data = this.state;
    this.props.ApplyFilter(data)
  }

  selectSpeciality = (name) => {
    let { specialties } = this.state;
    specialties[name] = !specialties[name];
    this.setState({
      specialties
    })
  }

  handleChip = (n, v, l) => {
    const filteredData = this.state.practitionerPreferredLanguage?.filter(l => l?.val !== v?.val);
    this.changeState("practitionerPreferredLanguage", filteredData)
  };


  render() {
    const { specialties } = this.state;
    const { allSpeciality = [], classes } = this.props;
    const parentIds = this.props.parent_id ? this.props.parent_id : 'doctorlisting'
    return (
      <div id={`${parentIds}_filterdrawer_parent_div`}>
        <Text
          id={`${parentIds}_filterdrawer_filter_text`}
          style={{
            fontSize: 22,
            fontWeight: "600",
            color: UIColor.primaryText.color,
            marginLeft: "4%",
          }}
          name={<span style={{ fontSize: "16px" }}>Filter</span>}
        ></Text>
        <div
          id={`${parentIds}_filterdrawer_subtitle_parent_div`}
          style={{ overflow: "auto", height: "78vh" }}>
          <Divider
            id={`${parentIds}_filterdrawer_subtitle_bottom_divider`}
            style={{ marginLeft: 0, marginRight: 0 }} />
          <div
            id={`${parentIds}_filterdrawer_subtitle_div`}
            style={{ padding: 20, paddingTop: 0, paddingBottom: 0 }}>
            <div
              id={`${parentIds}_filterdrawer_subtitle_sub_div`}
              style={{ marginBottom: "1%" }}>
              <Text
                id={`${parentIds}_filterdrawer_subtitle_consulatation_text`}
                name={
                  <span
                    style={{
                      fontSize: 12,
                      color: UIColor.tertiaryText.color,
                      letterSpacing: "0.9",
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    Consultation Mode
                  </span>
                }
              ></Text>
            </div>

            <FormControl
              id={`${parentIds}_filterdrawer_subtitle_consulatation_formcontrol`}
              component="fieldset">
              <RadioGroup
                id={`${parentIds}_filterdrawer_subtitle_consulatation_radiogroup`}
                aria-label="Consultation"
                value={this.state.consultationMode}
                onChange={(e) =>
                  this.changeState("consultationMode", e.target.value)
                }
                name="Consultation"
              >
                <FormControlLabel
                  id={`${parentIds}_filterdrawer_subtitle_consulatation_direct_consultation_formcontrollaabel`}
                  value="direct"
                  control={<Radio
                    id={`${parentIds}_filterdrawer_subtitle_consulatation_direct_consultation_radio`}
                    color="primary"
                  //style={{ color: UIColor.secondaryButtonColor.color }}
                  />}
                  //label="Direct Consultation"
                  label={<Typography style={{ color: UIColor.secondaryText.color, fontSize: "14px" }}>Direct Consultation</Typography>}

                />
                <FormControlLabel
                  id={`${parentIds}_filterdrawer_subtitle_consulatation_video_formcontrollaabel`}
                  value="video"
                  control={<Radio
                    id={`${parentIds}_filterdrawer_subtitle_consulatation_video_consultation_radio`}
                    //style={{ color: UIColor.secondaryButtonColor.color }} 
                    color="primary"
                  />}
                  //label="Video Consultation"
                  label={<Typography style={{ color: UIColor.secondaryText.color, fontSize: "14px" }}>Video Consultation</Typography>}
                />
                <FormControlLabel
                  id={`${parentIds}_filterdrawer_subtitle_consulatation_both_formcontrollaabel`}
                  value="Both"
                  control={<Radio
                    id={`${parentIds}_filterdrawer_subtitle_consulatation_both_radio`}
                    //style={{ color: UIColor.secondaryButtonColor.color }} 
                    color="primary"
                  />}
                  //label="Both"
                  label={<Typography style={{ color: UIColor.secondaryText.color, fontSize: "14px" }}>Both</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <Divider
            id={`${parentIds}_filterdrawer_subtitle_consulatation_botton_divider`}
            style={{ marginLeft: 0, marginRight: 0 }} />
          <div
            id={`${parentIds}_filterdrawer_subtitle_practitioner_gender_parent_div`}
            style={{ marginTop: "6%", paddingLeft: 20 }}>
            <Text
              id={`${parentIds}_filterdrawer_subtitle_practitioner_gender_text`}
              name={
                <span
                  id={`${parentIds}_filterdrawer_subtitle_practitioner_gender_span`}
                  style={{
                    fontSize: 12,
                    color: UIColor.tertiaryText.color,
                    letterSpacing: "0.9",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    marginBottom: "1%",
                  }}
                >
                  PRACTITIONER Gender
                </span>
              }
            ></Text>
            <FormControl
              id={`${parentIds}_filterdrawer_subtitle_practitioner_gender_formcontrol`}
              component="fieldset">
              <RadioGroup
                id={`${parentIds}_filterdrawer_subtitle_practitioner_gender_radiogroup`}
                row
                aria-label="gender"
                value={this.state.practitionerGender}
                onChange={(e) =>
                  this.changeState("practitionerGender", e.target.value)
                }
                name="gender"
              >
                <FormControlLabel
                  id={`${parentIds}_filterdrawer_subtitle_practitioner_gender_male_radiogroup`}
                  value="Male"
                  control={<Radio id="4XAHN1" //style={{ color: UIColor.secondaryButtonColor.color }}
                    color="primary" />}
                  //label="Male"
                  label={<Typography style={{ color: UIColor.secondaryText.color, fontSize: "14px" }}>Male</Typography>}
                />
                <FormControlLabel
                  id={`${parentIds}_filterdrawer_subtitle_practitioner_gender_female_formcontrollabel`}
                  value="Female"
                  control={<Radio id="4XAHN2" //style={{ color: UIColor.secondaryButtonColor.color }} 
                    color="primary" />}
                  //label="Female"
                  label={<Typography style={{ color: UIColor.secondaryText.color, fontSize: "14px" }}>Female</Typography>}
                />
                <FormControlLabel
                  id={`${parentIds}_filterdrawer_subtitle_practitioner_gender_both_formcontrollabel`}
                  value="Both"
                  control={<Radio id="4XAHN3" //style={{ color: UIColor.secondaryButtonColor.color }} 
                    color="primary" />}
                  //label="Both"
                  label={<Typography style={{ color: UIColor.secondaryText.color, fontSize: "14px" }}>Both</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <Divider
            id={`${parentIds}_filterdrawer_subtitle_practitioner_gender_bottom_divider`}
            style={{ marginLeft: 0, marginRight: 0 }} />
          <div
            id={`${parentIds}_filterdrawer_subtitle_practitioner_laguange_parent_div`}
            className={classes.autoComplete}
            style={{
              marginTop: "6%",
              paddingLeft: 20,
              paddingRight: 20,
              marginBottom: "6%",
            }}
          >
            <Text
              id={`${parentIds}_filterdrawer_subtitle_practitioner_laguange_text`}
              name={
                <span
                  id={`${parentIds}_filterdrawer_subtitle_practitioner_preferred_laguange_span`}
                  style={{
                    fontSize: 12,
                    color: UIColor.tertiaryText.color,
                    letterSpacing: "0.9",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    marginBottom: "1%",
                  }}
                >
                  PRACTITIONER preferred language
                </span>
              }
            ></Text>
            {/* <Autocomplete
              id={`${parentIds}_filterdrawer_subtitle_practitioner_preferred_laguange_search_language_autocomplete`}
              multiple
              options={this.language}
              getOptionLabel={(option) => option.title}
              value={this.state.practitionerPreferredLanguage}
              onChange={(e, value) =>
                this.changeState("practitionerPreferredLanguage", value)
              }
              renderInput={(params) => (
                <TextField
                  id={`${parentIds}_filterdrawer_subtitle_practitioner_preferred_laguange_search_language_text`}
                  {...params}
                  variant="outlined"
                  label="Search Language here"
                  placeholder="Search Language here"
                />
              )}
              style={{ marginTop: "3%" }}
            /> */}
            <Autocomplete
              id={`${parentIds}_filterdrawer_subtitle_practitioner_preferred_laguange_search_language_autocomplete`}
              size="small"
              multiple
              options={this.state.language}
              value={this.state.practitionerPreferredLanguage}
              getOptionLabel={(option) => option.title}
              filterSelectedOptions
              onChange={(e, value) =>
                this.changeState("practitionerPreferredLanguage", value)
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => null)
              }
              renderInput={(params) => (
                <TextField
                  id={`${parentIds}_filterdrawer_subtitle_practitioner_preferred_laguange_search_language_text`}
                  {...params}
                  variant="outlined"
                  label="Search Language here"
                  placeholder="Search Language here"
                />
              )}
              style={{ marginTop: "3%" }}
              className={"pc_regular"}
            />
            {this.state?.practitionerPreferredLanguage?.map((option, index) => {
              return (
                <Chip
                  id={`${parentIds}_${index}_collapse_body_autocomplete_title_chip`}
                  size="medium"
                  label={option.title}
                  deleteIcon={<CloseIcon
                    id={`${parentIds}_${index}_collapse_body_autocomplete_closeicon`}
                    style={{ width: 18, height: 18, color: 'rgb(0 112 242 / 1)' }} />}
                  onDelete={(event) => this.handleChip(event, option, "remove")}
                  // color="primary"
                  variant="outlined"
                  style={{
                    marginRight: 6,
                    marginTop: 6,
                    color: "#0071F2",
                    border: "1px solid #0071F2",
                  }}
                />
              );
            })}
          </div>
          <Divider
            id={`${parentIds}_filterdrawer_subtitle_practitioner_preferred_laguange_search_language_bottom_divider`}
            style={{ marginLeft: 0, marginRight: 0 }} />
          <div style={{ marginTop: "6%", paddingLeft: 20 }}>
            <Text
              id={`${parentIds}_filterdrawer_subtitle_specialties_text`}
              name={
                <span
                  id={`${parentIds}_filterdrawer_subtitle_specialties_span`}
                  style={{
                    fontSize: 12,
                    color: UIColor.tertiaryText.color,
                    letterSpacing: "0.9",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  Specialties
                </span>
              }
            ></Text>
            <div
              id={`${parentIds}_filterdrawer_subtitle_specialties_parent_div`}
            >
              <FormControl
                id={`${parentIds}_filterdrawer_subtitle_specialties_formcontrol`}
                component="fieldset">
                {/* <FormControlLabel
                  control={<Checkbox
                    checked={specialties?.["ENT Specialties"]}
                    onChange={() => this.selectSpeciality("ENT Specialties")}
                    name="checkedB" color="primary" />}
                  label="ENT Specialties"
                /> */}
                {
                  allSpeciality.map((val, i) => {
                    return <FormControlLabel
                      id={`${parentIds}_filterdrawer_subtitle_specialties_checkbox_formcontrollabel_+ i`}
                      control={<Checkbox
                        // id={"xiitb_" + i}
                        checked={specialties[val.value] ? true : false}
                        onChange={() => this.selectSpeciality(val.value)}
                        name="checkedB" //style={{ color: UIColor.secondaryButtonColor.color }} 
                        color="primary" />}
                      label={<Typography style={{ fontSize: "14px", color: UIColor.secondaryText.color }}>{val.label}</Typography>}
                    />
                  })
                }

                {/* <FormControlLabel
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Both"
                /> */}
              </FormControl>
            </div>
          </div>
        </div>
        <Divider
          id={`${parentIds}_filterdrawer_subtitle_specialties_checkbox_bottom_divider`}
          style={{ marginLeft: 0, marginRight: 0 }} />
        <div
          id={`${parentIds}_filterdrawer_subtitle_specialties_button_div`}
          style={{
            padding: 20,
            paddingBottom: 0,
            paddingTop: 10,
            paddingLeft: 0,
            paddingRight: 0,
            margin: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            id={`${parentIds}_filterdrawer_subtitle_specialties_reset_button`}
            variant="outlined"
            onClick={this.resetFilter}
            style={{
              marginRight: "5px",
              //padding: "8px 74px",
              backgroundColor: "#fff",
              fontSize: 12,
              color: "#FF4D4A",
              border: `1px solid ${UIColor.lineBorderFill.color}`,
              width: "134px",
              height: "32px",
            }}
            disabled={this.props?.docterListing?.loading}
          >
            Reset
          </Button>
          <Button
            id={`${parentIds}_filterdrawer_subtitle_specialties_apply_button`}
            style={{
              marginLeft: "5px",
              //padding: "8px 74px",
              //float: "right",
              backgroundColor: UIColor.primaryColor.color,
              fontSize: 12,
              border: UIColor.primaryColor.color,
              width: "134px",
              height: "32px",
            }}
            disabled={this.props?.docterListing?.loading}
            onClick={() => this.submitFilter()}
          >
            {
              this.props?.docterListing?.loading
                ? "Applying"
                : "Apply"
            }
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  docterListing: state?.docterListingReducer?.docterListing,
});

export default withStyles(styles)(connect(
  mapStateToProps,
  actions
)(withRouter(BookMultipleAppointmentsDrawer)));
