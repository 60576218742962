import React from "react";

export const ShareIcon = (props) => {
  let width = props.width ? props.width : "14";
  let height = props.height ? props.height : "14";
  let color = props.color ? props.color : "#6f6f6f";
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14">
        <path id="icons8-share" d="M16.421,5a2.587,2.587,0,0,0-2.579,2.579,2.46,2.46,0,0,0,.124.609L9.548,10.4a2.539,2.539,0,0,0-1.969-.977,2.579,2.579,0,0,0,0,5.158A2.539,2.539,0,0,0,9.548,13.6l4.417,2.21a2.46,2.46,0,0,0-.124.609,2.579,2.579,0,1,0,2.579-2.579,2.539,2.539,0,0,0-1.969.977l-4.417-2.21A2.46,2.46,0,0,0,10.158,12a2.46,2.46,0,0,0-.124-.609l4.417-2.21a2.539,2.539,0,0,0,1.969.977,2.579,2.579,0,0,0,0-5.158Zm0,1.105a1.474,1.474,0,1,1-1.474,1.474A1.465,1.465,0,0,1,16.421,6.105ZM7.579,10.526A1.474,1.474,0,1,1,6.105,12,1.465,1.465,0,0,1,7.579,10.526Zm8.842,4.421a1.474,1.474,0,1,1-1.474,1.474A1.465,1.465,0,0,1,16.421,14.947Z" transform="translate(-5 -5)" fill="#2a60bc"/>
    </svg>
  );
};