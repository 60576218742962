import { makeStyles, Typography, Grid, Button,LinearProgress,withStyles,TextField } from "@material-ui/core";
import React, { useState,useContext } from "react";
import Dropzone from 'react-dropzone'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";
import broucher from "../../../assets/img/icons8-brochure.svg";
import uploadBackground from "../../../assets/img/uploadBack.svg";
import pngFile from "../../../assets/img/icons8-png.svg";
import trash from "../../../assets/img/icons8-trash.svg";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ToBase64 } from '../../../utils';
import { BackdropContext } from "../../../contexts";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px",
    minHeight: "90vh",
  },
  textBody: {
    fontFamily: "pc_medium !important",
    fontSize: "14px",
    color: "#0A6265",
    paddingBottom: "10px",
  },
  topDescription: {
    border: "1px solid #C3EEF1",
    padding: "20px 20px 10px",
    borderRadius: "8px",
    margin: "10px",
    backgroundColor: "#F2FEFF",
  },
  contentAlign: {
    display: "flex",
    alignItems: "center",
  },
  numbers: {
    fontSize: "12px",
    paddingTop: "1px",
    color: "#548f93",
  },
  uploadDiv: {
    backgroundImage: `url(${uploadBackground})`,
    margin: "10px 10px 17px 10px",
    height: "200px",
  },
  uplaodHeader: {
    fontFamily: "pc_medium !important",
    color: "#6F6F6F",
    fontSize: "18px",
  },
  ButtonAlign: {
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "8px",
    backgroundColor: "#0071F2",
    fontFamily: "pc_regular !important",
    color: "#fff",
    padding: "5px 20px",
    border: "none",
    "&:hover": {
      backgroundColor: "#0551a9",
    },
  },
  autoComplete:{
    '& .MuiInputBase-root':{
      padding:0,
      paddingLeft:16
    },
    '& input':{
      padding:7,
      fontFamily:'pc_medium',
      fontSize:14,
      color:'#101010'
    },
    '& fieldset':{
      borderColor:'#E0E0E0',
      borderRadius:8
    }
  },
  deleteFile:{
    cursor:'pointer'
  }
}));

const proofKind = [
  "Patient ID Proof",
  "Refferal Letter",
  "Financial document",
  "Miscellaneous",
];

const proofsKind = [
  {
    label:"Patient ID Proof",
    value:"Patient ID Proof"
  },
  {
    label:"Financial document",
    value:"Financial document"
  },
  {
    label:"Refferal Letter",
    value:"Refferal Letter"
  },
  {
    label:"Miscellaneous",
    value:"Miscellaneous"
  }
]

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 7,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    background: "linear-gradient(to right, #11e417 , #058b3a)",
  },
}))(LinearProgress);

export function UserProofAVC(props) {
  const classes = useStyles();
  const [fileUpload, setFileUpload] = useState([]);
  const [progress, setProgress] = React.useState(0);
  const [timer,setTimer] = React.useState(true);

  const { setBackDrop, open: loading } = useContext(BackdropContext);

  const handleDocType = (value,index) =>{

    let newFileUpload = [...fileUpload];

    if(newFileUpload?.length && newFileUpload?.[index]){
      newFileUpload[index]['documentType']=value
      setFileUpload(newFileUpload)
    }
  }

  const handleDeleteFile = (index) =>{
    index >-1 && setFileUpload(fileUpload?.filter((_,i)=>index !== i))
  }

  const onDrop = (acceptedFiles) => {
    
    setBackDrop({
      open: true,
      message: "Uploading Files",
    });
    //duplicate files
    const checkFiles = (acceptedFiles?.length && fileUpload?.length) ? acceptedFiles.filter(_=> !fileUpload.some(l=> l?.name === _?.name )) : acceptedFiles;

    checkFiles.map(async (val)=>{
      const base64 = await ToBase64(val);
      setFileUpload((prev) => [...prev,{
        name:val?.name,
        path:val?.path,
        size:val?.size,
        type:val?.type,
        base64
      }]);
    })

    setBackDrop({
      open: false,
      message: "",
  });
  }

  const styles = {
		labelStyle: {
			fontSize: "14px",
			marginBottom: "6px",
		},
		borderStyle: {
			borderRadius: 8,
			borderColor: "#E0E0E0",
			background: "#fff",
      fontFamily: `pc_medium !important`,
      boxShadow:"2px 10px 12px #092f5a17"
		},
		borderStyleDisable: {
			borderRadius: 8,
			border: "1px solid #E0E0E0",
			background: "#F0F0F0",
		},
	};

  const proceed = () =>{
		props?.proceed && props.proceed()
	}


  return (
    <div className={classes.root}>
      <div className={classes.topDescription}>
        <Grid container>
          <Grid item xs={1} style={{ display: "contents" }}>
            <img src={broucher} />
          </Grid>
          <Grid item xs={11} style={{ padding: "0px 20px" }}>
            <Typography className={classes.textBody}>
              Upload Patient Documents
            </Typography>
            <Typography
              className={classes.textBody}
              style={{ fontSize: "12px", color: "#549193" }}
            >
              Kindly upload the below list of specified documents to help us
              ease your registration process
            </Typography>
            <div className={classes.contentAlign}>
              {proofKind.map((val, i) => (
                <div
                  className={classes.contentAlign}
                  style={{ marginRight: "8px" }}
                >
                  <Typography className={classes.numbers}>{i + 1}. </Typography>
                  <Typography
                    className={classes.textBody}
                    style={{
                      fontSize: "12px",
                      color: "#549193",
                      paddingBottom: "0px",
                    }}
                  >
                    {val}
                  </Typography>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.uploadDiv} >
      <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}>
      {({getRootProps, getInputProps}) => (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div style={{ display: "block", textAlign: "center" }}>
            <Typography className={classes.uplaodHeader}>
              Drag and Drop your files here
            </Typography>
            <Typography
              className={classes.uplaodHeader}
              style={{ fontSize: "14px" }}
            >
              or <span style={{ color: "#0071F2" }}>Browse</span>
            </Typography>
          </div>
        </div>
        )}
        </Dropzone>
      </div>
      <div style={{height:"200px",overflow:"auto"}}>
      {fileUpload.map((value, index) => (
        <div
          style={{
            border: "1px solid #E0E0E0",
            borderRadius: "8px",
            padding: "10px",
            margin: "16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Grid container style={{alignItems:"center"}}>
              <Grid item xs={6} style={{ display: "flex" }}>
                <img src={pngFile} />
                <div style={{ marginLeft: "10px" }}>
                  <Typography
                    className={classes.textBody}
                    style={{ color: "#000", paddingBottom: "0px" }}
                  >
                    {value?.name}
                  </Typography>
                  <Typography
                    className={classes.textBody}
                    style={{ color: "#B6B6B6", fontSize: "10px" }}
                  >
                    {(value?.size/1000000).toFixed(2)} mb
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Grid container style={{alignItems:"center"}}>
                  <Grid item xs={7}>
                   {timer ? ( <Autocomplete
                      options={proofsKind}
                      className={classes.autoComplete}
                      getOptionLabel={(option) => option.label}
                      // value={data?.proof}
                      value={value?.documentType ?? null}
                      onChange={(e,newValue)=>handleDocType(newValue,index)}
                      style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} placeholder={'Choose the document type'} variant="outlined" />}
                    /> ) : (
                      <BorderLinearProgress variant="determinate" value={100} />
                    )}
                  </Grid>
                  <Grid item xs={5} style={{ display: "flex", justifyContent: "end",alignItems:"center" }}>
                    <div className={classes.deleteFile} onClick={()=>handleDeleteFile(index)}>
                      <img src={trash} />

                      {/* ---------- Icon to be uploaded while integration ------------
                      <CloseIcon style={{color:"#FF4D4A"}} /> */}

                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ))} 
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20px",
          marginTop:fileUpload.length < 1 ? "20px" : "37px"
        }}
      >
        <Button
          className={classes.ButtonAlign}
          style={{
            backgroundColor: fileUpload.length < 1 ? "#F0F0F0" : "#0071F2",
            color: fileUpload.length < 1 ? "#B6B6B6" : "#fff",
            // marginTop:fileUpload.length < 1 ? "30px" : "0px"
          }}
          disabled={fileUpload.length < 1 ? true : false}
          onClick={proceed}
        >
          Confirm & Proceed
        </Button>
        <Button
          onClick={proceed}
          className={classes.ButtonAlign}
          style={{
            backgroundColor: "#fff",
            color: "#000",
            marginLeft: "10px",
            border: "1px solid #E0E0E0",
            // marginTop:fileUpload.length < 1 ? "30px" : "0px"  
          }}
        >
          Skip For Now
        </Button>
      </div>
    </div>
  );
}
