import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Routes } from "../../router/routes";
import { Typography, Popover } from "@material-ui/core";
import {
  Addmember,
  Addmemberform,
  Addmemberotp,
  ModelComponent,
} from "../../components";
import AppointmentAddMemberSuccessPage from "../../components/appointment_booked/index";
import Logo from "../../assets/img/logo_B.svg";
import ConfirmationPage from "../confirmBooking";
import CancelBookingPage from "../../components/cancelBooking/index";

import Eye from "../../assets/img/eye.svg";
// import noData from "../../assets/img/noData.svg";
import CancelAppointment from "../../assets/img/cancel_appointment.svg";
import Modify from "../../assets/img/modify.svg";
import Calendar from "../../assets/img/calendar.svg";
import DotMenu from "../../assets/img/dot_menu.svg";
import Warning from "../../assets/img/warning.svg";

import Tooltip from "@material-ui/core/Tooltip";
import { getUserInfo, UIColor } from "../../utils";

import "./style.css";
import {
  Container,
  Row,
  Col,
  Avatar,
  Card,
  TopHeader,
  Div,
  Icons,
  Divider,
  Text,
  ListItem,
  Button,
  SideMenu,
} from "qdm-component-library";
import { Logout } from "../../utils";

// const NoData = () => {
//   return (
//     <div
//       style={{
//         marginTop: "10px",
//         boxShadow: "none",
//         border: "1px solid #F0F0F0",
//         backgroundColor: "white",
//         padding: "15px 17px 18px 15px",
//         borderRadius: 8,
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <img
//           alt={"No Appoinment data"}
//           src={noData}
//           style={{ marginRight: "20px" }}
//         />
//         <div style={{ marginTop: "14px" }}>
//           <Text
//             key={"0"}
//             name="No appointment"
//             style={{
//               color: "#6F6F6F",
//               fontSize: "14px",
//               fontWeight: "600",
//             }}
//           ></Text>
//           <div>
//             <span style={{ color: "#6F6F6F", fontSize: "12px" }}>
//               Hope,your are fit and healthy
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

class AppointmentsListingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
      collpaseOpen: 0,
      newmembersteps: 0,
      singleupload: [],
      searchMenber: true,
      memberForm: false,
      memberOtp: false,
      memberSuccessPage: false,
      chooseSearch: null,
      open: false,
      cancelbooking: false,
      cancelbookingreason: {},
      cancelbookings: 0,
      anchorEl: null,
      addmmber: false,
      signUpData: {},
      searchmmrdata: null,
    };
    this.getUserInfo = getUserInfo.bind(this)
  }
  serchMMrDatafuncy = (value) => {
    this.setState({
      searchmmrdata: value,
    });
  };
  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const info = await this.getUserInfo();
    this.props.APPOINTMENT_READ();
    this.props.READ_PERSON();
    this.setState({ ...this.state, loggedInState: info });
  };

  shouldComponentUpdate(props, state) {
    return true;
  }

  toggleSideBar = (boolean) => {
    this.setState({
      ...this.state,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
      addmmber: false,
      memberSuccessPage: false,
    });
  };

  nextSection = (member, signUpData, l) => {
    if (signUpData === "addMember") {
      this.setState({
        ...this.state,
        signUpData: l ? l : signUpData,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
        memberSuccessPage: false,
        addmmber: true,
      });
      return;
    } else if (member === "addmemberform") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
        addmmber: false,
        signUpData,
      });
    } else if (member === "addmemberotp") {
      if (signUpData) {
        this.setState({
          ...this.state,
          signUpData,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
          addmmber: false,
        });
      } else {
        this.setState({
          ...this.state,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
        });
      }
    } else if (member === "back") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        memberSuccessPage: false,
      });
    } else if (member === "member_succss") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: false,
        memberOtp: false,
        sidebar: true,
        memberSuccessPage: true,
        signUpData,
      });
    } else if (member === "movenextscreen") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        memberSuccessPage: false,
      });

      window.location.href = "/doctor_consultation";
    }
  };
  handleOpen = (val) => {
    const state = this.state;
    const values = state.collpaseOpen === val ? null : val;
    this.setState({
      collpaseOpen: values,
    });
  };

  handlenewmembersteps = (val) => {
    this.setState({
      newmembersteps: val,
    });
  };
  ImageUploads = (url) => {
    const state = this.state;
    state.singleupload.push({ url: url });
    this.setState({ ...state });
  };
  handelChangeSelect = (v) => {
    this.setState({
      chooseSearch: v,
    });
  };
  openView = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handlesidebarCancelbooking = () => {
    this.setState({
      cancelbooking: !this.state.cancelbooking,
    });
  };
  handleSelectCancelbooking = (v) => {
    this.setState({
      cancelbookingreason: v,
    });
  };
  handlecahngeCancelbooking = (v) => {
    if (v === "back") {
      this.setState({
        cancelbookings: 0,
      });
      return;
    }
    if (!v) {
      this.handlesidebarCancelbooking();
      this.setState({
        cancelbookings: 0,
      });
      return;
    }
    this.setState({
      cancelbookings: v,
    });
  };

  handleProfileClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleProfileClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const { sidebar, cancelbooking, cancelbookingreason, cancelbookings } =
      this.state;

    return (
      <div id="appontmentlistingpage_parent_div">
        <Container
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: "#fff",
            height: "100vh",
            padding: "0px",
          }}
          id="appontmentlistingpage_container"
        >
          <SideMenu
            id="appointmentlistingpage_cancelbooking_sidemenu"
            open={cancelbooking}
            direction="right"
            width={435}
            color="default"
            onClose={() => this.handlesidebarCancelbooking()}
          >
            <CancelBookingPage
              cancelbookingreason={cancelbookingreason}
              cancelbookings={cancelbookings}
              handleSelectCancelbooking={this.handleSelectCancelbooking}
              handlecahngeCancelbooking={this.handlecahngeCancelbooking}
            />
          </SideMenu>
          <SideMenu
            id="appointmentlistingpage_signup_sidemenu"
            open={sidebar}
            direction="right"
            width={435}
            color="default"
            onClose={() => this.toggleSideBar()}
          >
            {this.state.searchMenber ? (
              <Addmember
                name={"Add Member"}
                serchMMrDatafuncydata={(v) => this.serchMMrDatafuncy(v)}
                nextSection={(member, v, l) => this.nextSection(member, v, l)}
                handelChangeSelect={(v) => this.handelChangeSelect(v)}
                signUpData={this.state.signUpData}
                chooseSearch={this.state.chooseSearch}
              />
            ) : this.state.memberForm ? (
              <Addmemberform
                insideSubmit={true}
                signUpData={this.state.signUpData}
                nextSection={(member, signUpInfo) =>
                  this.nextSection(member, signUpInfo)
                }
              />
            ) : this.state.memberOtp ? (
              <Addmemberotp
                addmmber={this.state.addmmber}
                addmember={true}
                signUpData={this.state.signUpData}
                nextSection={(member, v) => this.nextSection(member, v)}
                insideSubmit={true}
                appointmentDetails={true}
              />
            ) : null}
            {this.state.memberSuccessPage ? (
              <AppointmentAddMemberSuccessPage
                addmmber={this.state.addmmber}
                insideSubmit={true}
                signUpData={this.state.signUpData}
                nextSection={(member, v, l) => this.nextSection(member, v, l)}
                toggleSideBar={(member) => this.toggleSideBar(member)}
              />
            ) : null}
          </SideMenu>

          <TopHeader
            id="appointmentlistingpage_topheader"
            position="static"
            inLineStyles={{ height: "64px", backgroundColor: "#0071f2" }}
          >
            <Row
              id="appointmentlistingpage_row_1"
              key={"0"}
              alignItems="stretch"
              direction="row"
              justify="flex-start"
              spacing="2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Col
                id="appointmentlistingpage_col_1"
                key={"0"}
                name="qdm-col-12"
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                textAlign="left"
                lg="1"
                xs="12"
                md="1"
                sm="1"
              >
                <div
                  id="appointmentlistingpage_div_1"
                  style={{
                    width: "8%",
                    height: "58%",
                  }}
                >
                  <img
                    id="appointmentlistingpage_logo_img"
                    key={"0"}
                    variant="rounded"
                    letter="Logo"
                    src={Logo}
                    alt="Logo"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </Col>
              <Col
                key={"1"}
                name="qdm-col-12"
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                textAlign="left"
                lg="6"
                xs="12"
                md="6"
                sm="6"
              >
                <Div
                  id="appointmentlistingpage_div_2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingTop: "4px",
                  }}
                >
                  <Card
                    style={{ height: "50px", marginTop: "-1px", width: "40%" }}
                  >
                    <ListItem
                      id="appointmentlistingpage_listitem_1"
                      style={{
                        height: "48px",
                        color: "#101010",
                        fontWeight: "600",
                        padding: "8px",
                      }}
                      profile={
                        // <img
                        //   alt="img"
                        //   style={{ borderRadius: "10px", marginTop: "-8px" }}
                        //   src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                        // />
                        <Avatar
                          id="appointmentlistingpage_v_avatar"
                          alt={"B"}
                          name={"v"}
                          variant={"square"}
                          inLineStyles={{
                            margin: 0,
                            width: 30,
                            height: 30,
                            marginBottom: "8px",
                            borderRadius: "4px",
                            fontSize: "12px",
                          }}
                        >
                          {this.state?.loggedInState?.name?.[0] ?? "Guest"?.[0]}
                        </Avatar>
                      }
                      action={
                        <Icons
                          id="appointmentlistingpage_ellipsis-v_icons"
                          inLineStyles={{ color: " #B6B6B6" }}
                          fontIcon="ellipsis-v"
                        ></Icons>
                      }
                      title={
                        <span
                          id="appointmentlistingpage_span_1"
                          style={{
                            fontWeight: "600",
                            fontSize: "14px",
                            color: "#101010",
                          }}
                        >
                          {this.state?.loggedInState?.name ?? "Guest"}
                        </span>
                      }
                      description={
                        <span
                          id="appointmentlistingpage_span_2"
                          style={{
                            fontSize: "12px",
                            marginTop: "-4px",
                            color: "#B6B6B6",
                          }}
                        >
                          PID ----- | You
                        </span>
                      }
                    />
                  </Card>
                  <Divider
                    id="appointmentlistingpage_divider_1"
                    key={"1"}
                    className=""
                    label=""
                    borderColor=""
                    textColor=""
                    orientation="vertical"
                    variant="middle"
                    style={{
                      height: "34px",
                      borderColor: "#ffffff",
                      backgroundColor: "#ffffff",
                    }}
                  ></Divider>
                  <Div
                    id="appointmentlistingpage_div_3"
                    inLineStyles={{ display: "flex", alignItems: "center" }}
                  >
                    {/* <div style={{ position: "relative" }}>
                      <Avatar
                        variant="rounded"
                        inLineStyles={{ height: "40px", width: "40px" }}
                        src={
                          "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                        }
                      />
                      <span
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#FF9100",
                          position: "absolute",
                          top: "6px",
                          right: "6px",
                        }}
                      />
                    </div> */}
                    {/* <div style={{ position: "relative" }}>
                      <Avatar
                        variant="rounded"
                        style={{ height: "40px", width: "40px" }}
                        src={
                          "https://images.firstpost.com/wp-content/uploads/2017/06/Sundar_Pichai_New.jpg"
                        }
                      />
                      <span
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#FF9100",
                          position: "absolute",
                          top: "6px",
                          right: "6px",
                        }}
                      />
                    </div> */}
                    <Avatar
                      id="appointmentlistingpage_avatar_1"
                      variant="rounded"
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                        color: "#6F6F6F",
                        fontSize: "16px",
                      }}
                      letter="+"
                      onClick={() => this.toggleSideBar(true)}
                    />
                  </Div>
                </Div>
              </Col>
              <Col
                id="appointmentlistingpage_col_2"
                key={"2"}
                name="qdm-col-12"
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                textAlign="left"
                lg="1"
                xs="12"
                md="1"
                sm="1"
              >
                <Div
                  id="appointmentlistingpage_div_4"
                  onClick={(e) => this.handleProfileClick(e)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  {this.state?.loggedInState?.pic ? (
                    <Avatar
                      id="appointmentlistingpage_loggedin_avatar"
                      variant="circle"
                      style={{ height: "38px", width: "38px" }}
                      src={this.state?.loggedInState?.pic}
                    />
                  ) : (
                    <Avatar
                      id="appointmentlistingpage_loggedin_avatar"
                      variant="circle"
                      style={{
                        height: "38px",
                        width: "38px",
                        fontSize: 16,
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      {this.state?.loggedInState?.name?.[0] ?? "G"}
                    </Avatar>
                  )}

                  <Icons
                    id="appointmentlistingpage_icon_1"
                    key={"2"}
                    fontIcon="angle-down"
                    ariaHidden="true"
                    className=""
                    size="medium"
                    style={{ color: "#ffffff" }}
                  ></Icons>
                </Div>{" "}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={() => this.handleProfileClose()}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography
                    id="helperfunction_appointmentlistingpage_logout_typography"
                    onClick={() => Logout()}
                    style={{ padding: "10px", cursor: "pointer", color: UIColor.primaryText.color }}
                  >
                    Log Out
                  </Typography>
                </Popover>
              </Col>
            </Row>
          </TopHeader>
          <Row
            id="appointmentlistingpage_row_2"
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Col
              id="appointmentlistingpage_col_3"
              key={"0"}
              name="qdm-col-12"
              alignItems="stretch"
              direction="row"
              justify="flex-start"
              textAlign="left"
              lg="12"
              xs="12"
              md="12"
              sm="12"
              inLineStyles={{
                padding: 0,
              }}
            >
              <Div
                id="appointmentlistingpage_div_5"
                inLineStyles={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px 30px 11px 34px",
                  backgroundColor: "#F9F9F9",
                }}
              >
                <Div id="appointmentlistingpage_listing_div">
                  <ul
                    id="appointmentlistingpage_ul-li"
                    style={{
                      display: "inline-flex",
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                      position: "absolute",
                      bottom: 0,
                    }}
                  >
                    <li
                      id="appointmentlistingpage_appoinments_li"
                      style={{
                        cursor: "pointer",
                        marginRight: "24px",
                        borderBottom: "2px solid #0071F2",
                        textDecoration: "none",
                        padding: "0px 0px 14px",
                        letterSpacing: ".9",
                        color: "#0071F2",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Appointments
                    </li>
                    <li
                      id="appointmentlistingpage_vitals_li"
                      style={{
                        cursor: "pointer",
                        marginRight: "24px",
                        textDecoration: "none",
                        padding: "0px 0px 14px",
                        letterSpacing: ".9",
                        color: "#6F6F6F",
                        fontSize: "14px",
                      }}
                    >
                      Vitals
                    </li>
                    <li
                      id="appointmentlistingpage_reports_li"
                      style={{
                        cursor: "pointer",
                        marginRight: "24px",
                        textDecoration: "none",
                        padding: "0px 0px 14px",
                        letterSpacing: ".9",
                        color: "#6F6F6F",
                        fontSize: "14px",
                      }}
                    >
                      Reports
                    </li>
                    <li
                      id="appointmentlistingpage_bill_li"
                      style={{
                        cursor: "pointer",
                        marginRight: "24px",
                        textDecoration: "none",
                        padding: "0px 0px 14px",
                        letterSpacing: ".9",
                        color: "#6F6F6F",
                        fontSize: "14px",
                      }}
                    >
                      Bill Info
                    </li>
                  </ul>
                </Div>
                <Div>
                  <Button
                    id="profile_appointmentlistingpage_bokknewappointment_button"
                    name="Book New Appointment"
                    inLineStyles={{
                      fontSize: "14px",
                      borderRadius: "8px",
                      backgroundColor: "#0071F2",
                      border: "none",
                      padding: "10px 14px 10px 14px",
                      fontWeight: "600",
                      letterSpacing: "0.9",
                    }}
                    onClick={() =>
                      this.props.history.push("/doctor_consultation")
                    }
                  ></Button>
                </Div>
              </Div>
              <Row
                id="appointmentlistingpage_row_5"
                key={"0"}
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                spacing="2"
                inLineStyles={{
                  margin: "14px !important",
                  border: "1px solid #F0F0F0",
                  borderRadius: "8px",
                }}
              >
                <Col
                  id="appointmentlistingpage_col_4"
                  key={"0"}
                  name="qdm-col-12"
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  textAlign="left"
                  lg="4"
                  xs="12"
                  md="4"
                  sm="4"
                  style={{
                    borderRight: "1px solid #E0E0E0",
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <ListItem
                    id="appointmentlistingpage_listitem_2"
                    title={
                      <span
                        id="appointmentlistingpage_upcoming_span"
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#101010",
                        }}
                      >
                        UPCOMING
                      </span>
                    }
                    inLineStyles={{
                      marginTop: "3%",
                      marginBottom: "12px",
                      paddingLeft: 0,
                    }}
                    description={false}
                    profile={false}
                    action={
                      <div
                        id="appointmentlistingpage_div_6"
                        style={{
                          display: "flex",
                          right: "-12px",
                          position: "absolute",
                          top: "-12px",
                          cursor: "pointer",
                        }}
                      >
                        <Div
                          id="appointmentlistingpage_div_7"
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            marginRight: "8px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            id="appointmentlistingpage_icon_2"
                            key={"2"}
                            fontIcon="search"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            inLineStyles={{
                              color: "#B6B6B6",
                              fontSize: "12px",
                            }}
                          ></Icons>
                        </Div>
                        <Div
                          id="appointmentlistingpage_div_8"
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            id="appointmentlistingpage_icon_3"
                            key={"2"}
                            fontIcon="filter"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            style={{ color: "#B6B6B6", fontSize: "12px" }}
                          ></Icons>
                        </Div>
                      </div>
                    }
                  />
                  <Text
                    id="appointmentlistingpage_today_text"
                    key={"0"}
                    name="Tod"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      fontWeight: "600",

                      textTransform: "uppercase",
                    }}
                  ></Text>
                  {/* Nodata UI Card */}
                  {/* {NoData()} */}
                  <div
                    id="appointmentlistingpage_div_8"
                    style={{
                      marginTop: "10px",
                      boxShadow: "none",
                      border: "1px solid #F0F0F0",
                      backgroundColor: "white",
                      height: 138,
                      padding: "15px 17px 18px 15px",
                      borderRadius: 8,
                    }}
                  >
                    <div style={{ display: "flex", marginBottom: "16px" }}>
                      <img
                        alt="img"
                        src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                        style={{
                          marginRight: "16px",
                          width: "46px",
                          height: "42px",
                          borderRadius: "50%",
                        }}
                      />
                      <div
                        id="appointmentlistingpage_div_9"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <span
                            id="appointmentlistingpage_span_4"
                            style={{
                              fontSize: "16px",
                              color: "#101010",
                              fontWeight: "600",
                            }}
                          >
                            Dr. Priya Dharshini
                          </span>
                          <br />
                          <span
                            id="appointmentlistingpage_span_5"
                            style={{ color: "#6F6F6F", fontSize: "12px" }}
                          >
                            General Physician
                          </span>
                        </div>
                        <div
                          id="appointmentlistingpage_dotmenu_div"
                          style={{ marginRight: "8px" }}
                        >
                          <img
                            id="appointmentlistingpage_dotmenu_img"
                            alt="img"
                            src={DotMenu}
                            style={{}}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      id="appoimentbook_appointmentlistingpage_div"
                      style={{ display: "flex" }}
                    >
                      <div
                        style={{
                          width: "46px",
                          height: "42px",
                          backgroundColor: "#F9F9F9",
                          borderRadius: "50px",
                          padding: "10px",
                          textAlign: "center",
                          marginRight: "16px",
                        }}
                      >
                        <img
                          id="appoimentbook_appointmentlistingpage_img"
                          alt="img"
                          src={Calendar}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          width: "100%",
                        }}
                      >
                        <div id="appoimentbook_appointmentlistingpage_calender_div">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#101010",
                              fontWeight: "600",
                            }}
                          >
                            20 Apr 21 | 10:00 AM
                          </span>
                          <br />
                          <span
                            id="appoimentbook_appointmentlistingpage_direct_span"
                            style={{ color: "#6F6F6F", fontSize: "12px" }}
                          >
                            Direct Consultation
                          </span>
                        </div>
                        <div
                          style={{ display: "inherit", marginBottom: "8px" }}
                        >
                          <Tooltip title="View" arrow placement="top">
                            <div
                              data-tip="View"
                              className="hover"
                              style={{
                                padding: "1px 6px",
                                borderRadius: "5px",
                                marginLeft: "6px",
                              }}
                              onClick={() => this.openView()}
                            >
                              <img alt={"Eye"} src={Eye} />
                            </div>
                          </Tooltip>

                          <div
                            style={{
                              width: "1px",
                              backgroundColor: "#E0E0E0",
                              marginLeft: "8px",
                              height: "12px",
                              marginTop: "6px",
                            }}
                          />
                          <Tooltip
                            title="Modify Appointment"
                            arrow
                            placement="top"
                          >
                            <div
                              className="hover"
                              style={{
                                padding: "1px 6px",
                                borderRadius: "5px",
                                marginLeft: "6px",
                              }}
                              onClick={() =>
                                this.props.history.push({
                                  pathname: Routes.doctorListing,
                                  state: {
                                    cityName: 0,
                                    location: {
                                      address: "Chennai, Tamil Nadu, India",
                                      latitude: 13.0826802,
                                      longitude: 80.2707184,
                                      city: "Chennai",
                                      location: "Chennai",
                                    },
                                    hospital: {
                                      label: "Apollo Clinic Chennai",
                                      value: 12,
                                    },
                                    specialities: [],
                                    symptoms: [
                                      {
                                        label: "Fever",
                                        type: "Symptom",
                                        value: "SymptomsMapping/10000",
                                      },
                                    ],
                                    symptomsSearch: [],
                                    modifyUser: true,
                                  },
                                })
                              }
                            >
                              <img alt={"Modify"} src={Modify} />
                            </div>
                          </Tooltip>

                          <div
                            className="hover"
                            style={{
                              width: "1px",
                              backgroundColor: "#E0E0E0",
                              marginLeft: "8px",
                              height: "12px",
                              marginTop: "6px",
                            }}
                          />

                          <Tooltip
                            title="Cancel Appointment"
                            arrow
                            placement="top"
                          >
                            <div
                              className="hover"
                              style={{
                                padding: "1px 6px",
                                borderRadius: "5px",
                                marginLeft: "6px",
                              }}
                              onClick={() => this.handlesidebarCancelbooking()}
                            >
                              <img
                                alt={"CancelAppointment"}
                                src={CancelAppointment}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col
                  key={"1"}
                  name="qdm-col-12"
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  textAlign="left"
                  lg="4"
                  xs="12"
                  md="4"
                  sm="4"
                  style={{
                    borderRight: "1px solid #E0E0E0",
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <ListItem
                    title={
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          textTransform: "uppercase",
                        }}
                      >
                        Follow up reminder
                      </span>
                    }
                    description={false}
                    profile={false}
                    inLineStyles={{
                      marginTop: "3%",
                      marginBottom: "12px",
                      paddingLeft: 0,
                    }}
                    action={
                      <div
                        style={{
                          display: "flex",
                          right: "-12px",
                          position: "absolute",
                          top: "-12px",
                          cursor: "pointer",
                        }}
                      >
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            marginRight: "8px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="search"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            inLineStyles={{
                              color: "#B6B6B6",
                              fontSize: "12px",
                            }}
                          ></Icons>
                        </Div>
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="filter"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            style={{ color: "#B6B6B6", fontSize: "12px" }}
                          ></Icons>
                        </Div>
                      </div>
                    }
                  />
                  <Text
                    key={"0"}
                    name="This week"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  ></Text>

                  {/* Nodata UI Card */}
                  {/* {NoData()} */}

                  {/* data card */}
                  <div
                    style={{
                      marginTop: "10px",
                      boxShadow: "none",
                      border: "1px solid #F0F0F0",
                      backgroundColor: "white",
                      height: 138,
                      padding: "15px 17px 18px 15px",
                      borderRadius: 8,
                    }}
                  >
                    <div style={{ display: "flex", marginBottom: "16px" }}>
                      <img
                        alt="img"
                        src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                        style={{
                          marginRight: "16px",
                          width: "46px",
                          height: "42px",
                          borderRadius: "50%",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#101010",
                              fontWeight: "600",
                            }}
                          >
                            Dr. Priya Dharshini
                          </span>
                          <br />
                          <span style={{ color: "#6F6F6F", fontSize: "12px" }}>
                            General Physician
                          </span>
                        </div>
                        <div style={{ marginRight: "8px" }}>
                          <img alt="img" src={DotMenu} style={{}} />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "46px",
                          height: "42px",
                          backgroundColor: "#fff9f2",
                          borderRadius: "50px",
                          padding: "10px",
                          textAlign: "center",
                          marginRight: "16px",
                        }}
                      >
                        <img alt="img" src={Warning} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          width: "100%",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#F58B00",
                              fontWeight: "600",
                            }}
                          >
                            Follow up in 2 days
                          </span>
                          <br />
                          <span style={{ color: "#6F6F6F", fontSize: "12px" }}>
                            Direct Consultation
                          </span>
                        </div>
                        <div
                          style={{ display: "inherit", marginBottom: "8px" }}
                        >
                          <button
                            style={{
                              color: "#0071F2",
                              fontSize: "14px",
                              fontWeight: "600",
                              backgroundColor: "white",
                              border: "1px solid #0071F2",
                              padding: "6px 16px",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col
                  key={"2"}
                  name="qdm-col-12"
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  textAlign="left"
                  lg="4"
                  xs="12"
                  md="4"
                  sm="4"
                  style={{
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <ListItem
                    title={
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          textTransform: "uppercase",
                        }}
                      >
                        PAST
                      </span>
                    }
                    inLineStyles={{ marginTop: "3%", marginBottom: "12px" }}
                    description={false}
                    profile={false}
                    action={
                      <div
                        style={{
                          display: "flex",
                          right: "-12px",
                          position: "absolute",
                          top: "-12px",
                          cursor: "pointer",
                        }}
                      >
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            marginRight: "8px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="search"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            inLineStyles={{
                              color: "#B6B6B6",
                              fontSize: "12px",
                            }}
                          ></Icons>
                        </Div>
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="filter"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            style={{ color: "#B6B6B6", fontSize: "12px" }}
                          ></Icons>
                        </Div>
                      </div>
                    }
                  />
                  <Text
                    key={"0"}
                    name="This Month"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      fontWeight: "600",
                      padding: "0px 14px",
                      textTransform: "uppercase",
                    }}
                  ></Text>
                  <div style={{ height: "70vh" }}>
                    {/* Nodata UI Card */}
                    {/* {NoData()} */}

                    <div
                      style={{
                        marginTop: "10px",
                        boxShadow: "none",
                        border: "1px solid #F0F0F0",
                        backgroundColor: "white",
                        height: 138,
                        padding: "15px 17px 18px 15px",
                        borderRadius: 8,
                      }}
                    >
                      <div style={{ display: "flex", marginBottom: "16px" }}>
                        <img
                          alt="img"
                          src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                          style={{
                            marginRight: "16px",
                            width: "46px",
                            height: "42px",
                            borderRadius: "50%",
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontSize: "16px",
                                color: "#101010",
                                fontWeight: "600",
                              }}
                            >
                              Dr. Priya Dharshini
                            </span>
                            <br />
                            <span
                              style={{ color: "#6F6F6F", fontSize: "12px" }}
                            >
                              General Physician
                            </span>
                          </div>
                          <div style={{ marginRight: "8px" }}>
                            <img alt="img" src={DotMenu} />
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "46px",
                            height: "42px",
                            backgroundColor: "#F9F9F9",
                            borderRadius: "50px",
                            padding: "10px",
                            textAlign: "center",
                            marginRight: "16px",
                          }}
                        >
                          <img alt="img" src={Calendar} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            width: "100%",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#101010",
                                fontWeight: "600",
                              }}
                            >
                              20 Apr 21 | 10:00 AM
                            </span>
                            <br />
                            <span
                              style={{ color: "#6F6F6F", fontSize: "12px" }}
                            >
                              Direct Consultation
                            </span>
                          </div>
                          <div
                            style={{
                              display: "inherit",
                              marginBottom: "8px",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                color: "#258007",
                                fontSize: "10px",
                                fontWeight: "600",
                                backgroundColor: "#f0fcef",
                                border: "none",
                                padding: "5px 22px",
                                borderRadius: "8px",
                                position: "absolute",
                                top: "-32px",
                                letterSpacing: "1px",
                              }}
                            >
                              COMPLETED
                            </div>
                            <button
                              style={{
                                color: "#0071F2",
                                fontSize: "14px",
                                fontWeight: "600",
                                backgroundColor: "white",
                                border: "1px solid #0071F2",
                                padding: "6px 16px",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                            >
                              Book Again
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Col>
              </Row>
            </Col>
          </Row>
          <ModelComponent
            open={this.state.open}
            onClose={this.openView}
            hiddencloseIcon={true}
            childrens={
              <div
                style={{
                  borderRadius: 16,
                  overflow: "hidden",
                }}
              >
                <ConfirmationPage appointmentDetails={true} hidden={true} />
              </div>
            }
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appointments: state?.appointmentReducer?.readAppointment,
  members: state?.personApiSlice?.readPerson,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(AppointmentsListingPage));
