/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc The first component of the application, all the required context and routes are used here
 * to setup the application.
 */

import React from "react";

import AppAuth from "./App.auth";
// import AppTheme from "./App.theme";
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";
import { store as ReduxStore } from "./redux";
import { Provider } from "react-redux";
import AppMeeting from "./App.meeting";
import AVCContext from "./contexts/avc";

// import { CssBaseline } from "@material-ui/core";
import AppDialog from "./App.dialog";
// import AppBackdrop from "./App.backdrop";
import AppFirebase from "./App.firebase"; //For Push Notification
import { DragDropContainer } from "react-draggable-hoc";


function App() {
	return (
		<Provider store={ReduxStore}>
			<AppAuth>
				<AppErrorBoundary>
					{/* <AppTheme> */}
					{/* <CssBaseline /> */}
					<AppMeeting>
						<AVCContext>
							<AppAlert>
								<AppDialog>
									<AppFirebase>
										<DragDropContainer>
											<RouterApp />
										</DragDropContainer>
									</AppFirebase>
								</AppDialog>
							</AppAlert>
						</AVCContext>
					</AppMeeting>
					{/* </AppTheme> */}
				</AppErrorBoundary>
			</AppAuth>
		</Provider>
	);
}

export default App;
