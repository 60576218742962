import React from 'react';
import { Div, Avatar, H6, Text } from 'qdm-component-library';
import { UIColor } from '../../utils';

export const PatientDetails = ({
  parent_id,
  name = "",
  title = "",
  subTitle = "",
  url = "",
}) => {

  const styles = {
    avatar: {
      width: 40,
      height: 40,
      color: UIColor.secondaryText.color,
      backgroundColor: UIColor.lineBorderFill.color,
      fontSize: "1.25rem"
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      padding: '5px 10px 10px 10px',
      flex: 1
    },
    h6: {
      color: UIColor.secondaryText.color,
      fontSize: 14,
      margin: 0,
    },
    span: {
      color: UIColor.tertiaryText.color,
      fontSize: 12,
    },
  }
  const {
    h6,
    span,
    flexColumn,
    avatar,
  } = styles;

  return (
    <div
      id={`${parent_id}_patientdetails_parent_div`}
      style={{ flex: 1 }}>
      <Div
        id={`${parent_id}_patientdetails_sub_div`}
        style={{ display: "flex", padding: "5px 10px" }}>
        {url ? <Avatar
          id={`${parent_id}_patientdetails_avatar_div`}
          variant="circle"
          letter="Avatar"
          src={url}
          alt="Image"
          style={avatar}
        /> : <Avatar
          id={`${parent_id}_patientdetails_avatar_div`}
          variant="circle"
          style={avatar}
        >{name.replaceAll("Mrs", "").replaceAll("Mr", "").replaceAll(".", "")[0]}</Avatar>}
        <Div
          id={`${parent_id}_patientdetails_title_div`}
          style={flexColumn}>
          <H6
            id={`${parent_id}_patientdetails_title_h6`}
            className="pc_medium" style={h6}>{name}</H6>
          <Text
            id={`${parent_id}_patientdetails_title_span`}
            className="pc_regular" style={{ ...span, marginBottom: '4px' }}>
            {title}
          </Text>
          <Text
            id={`${parent_id}_patientdetails_title_sub_span`}
            className="pc_regular" style={{ fontSize: 12, color: UIColor.secondaryText.color }}>
            {subTitle}
          </Text>
        </Div>
      </Div>
    </div>
  )
}
