import React from "react";

export const DownloadIcon = (props) => {
  let width = props.width ? props.width : "13.992";
  let height = props.height ? props.height : "14";
  let color = props.color ? props.color : "#6f6f6f";
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13.992 14">
    <path id="icons8-download" d="M12.987,5.978a.583.583,0,0,0-.574.591v8.309l-1.337-1.337a.583.583,0,1,0-.824.824L12.584,16.7a.583.583,0,0,0,.824,0l2.332-2.332a.583.583,0,1,0-.824-.824l-1.337,1.337V6.57a.583.583,0,0,0-.592-.591ZM8.138,10.262A2.147,2.147,0,0,0,6,12.4v5.441a2.147,2.147,0,0,0,2.138,2.138h9.716a2.147,2.147,0,0,0,2.138-2.138V12.4a2.147,2.147,0,0,0-2.138-2.138H15.522a.583.583,0,1,0,0,1.166h2.332a.963.963,0,0,1,.972.972v5.441a.963.963,0,0,1-.972.972H8.138a.963.963,0,0,1-.972-.972V12.4a.963.963,0,0,1,.972-.972H10.47a.583.583,0,1,0,0-1.166Z" transform="translate(-6 -5.978)" fill="#2a60bc"/>
    </svg>
  );
};
