import React from "react";
import PropTypes from "prop-types";
import {
  Div,
  Paper,
  H6,
  Divider,
  Row,
  Col,
  Button,
  Image,
} from "qdm-component-library";
import "./navBar.css";
import { withRouter } from "react-router-dom";
import { Popover, makeStyles } from "@material-ui/core";
import { UploadFiles } from "./";

const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
  },
}));

export const Component = (props) => {
  const {
    sucessBtnName,
    navBarArr = [],
    title,
    noUpload,
    successfun,
    activeId = 1,
    navClick = () => null,
  } = props;
  const [isUpload, setIsUpload] = React.useState(null);
  const redirectLink = (data) => {
    navClick(data.id);
  };
  const uploadFun = (e) => {
    setIsUpload(e?.currentTarget);
  };
  const goback = () => {
    props.history.goBack();
  };
  const classes = useStyles();
  return (
    <div
      style={{ height: "56px" }}
      id={"patientDashboard_topNavBar_parent_Div"}
    >
      <Paper
        style={{
          margin: "0px",
          position: "fixed",
          zIndex: "99999",
          background: "white",
          boxShadow: "0px 3px 6px #0000000A",
        }}
        id={"patientDashboard_topNavBar_paper"}
      >
        <Row
          className={
            navBarArr.length > 0 ? "topNav-top-Parent" : "topNav-top-Parent1"
          }
          id={"patientDashboard_topNavBar_Row"}
        >
          <Col id={"patientDashboard_topNavBar_col"}>
            <Div
              className={"topNav-parent"}
              id={"patientDashboard_topNav-parent_Div"}
            >
              {/* <i onClick={() => goback()} className="fa fa-angle-left topNav-backIcon" aria-hidden="true"></i> */}
              <Div
                className="uploadIcon"
                onClick={() => goback()}
                id={"patientDashboard_uploadIcon_Div"}
              >
                <Image
                  src="images/icons8-left.svg"
                  alt="left"
                  id={"patientDashboard_uploadIcon_image"}
                />
              </Div>
              <H6
                id={"patientDashboard_topNav-title_h6"}
                className={"topNav-title"}
              >
                {title}
              </H6>
              {navBarArr.length > 0 ? (
                <Divider
                  className={"topNav-divider"}
                  id={"patientDashboard_topNav-divider"}
                  orientation="vertical"
                />
              ) : (
                <></>
              )}
            </Div>
          </Col>
          <Col id={"patientDashboard_topNav-navBarArr_navBarArr_col"}>
            {navBarArr.map((val, i) => {
              return (
                <p
                  onClick={() => redirectLink(val)}
                  className={`${
                    activeId === val.id ? "topNav-active" : ""
                  } topNav-tabs`}
                  id={"patientDashboard_topNav-navBarArr_redirectLink_p"}
                >
                  {val.label}
                  {activeId === val.id && (
                    <Div
                      className="bottomDivider"
                      id={"patientDashboard_topNav-navBarArr_bottomDivider"}
                    ></Div>
                  )}
                </p>
              );
            })}
          </Col>
          <Col
            className={`topNav-actions-parent`}
            id={"patientDashboard_topNav-actions-parent"}
          >
            <Button
              className={"topNav-actions successBtn"}
              id={"patientDashboard_topNav-actions"}
              onClick={
                successfun
                  ? () => {
                      successfun();
                    }
                  : () => null
              }
            >
              {sucessBtnName ? sucessBtnName : "Register & continue"}
            </Button>
            <Button
              variant="outlined"
              className={"topNav-actions topNav-actions-empty"}
              id={"patientDashboard_topNav-actions-empty"}
            >
              Put on Hold
            </Button>
            <Button
              variant="text"
              className={"topNav-actions cancelBtn"}
              id={"patientDashboard_topNav-actions-cancelBtn"}
            >
              Cancel
            </Button>
            {!noUpload && (
              <Divider
                className={"topNav-divider topNav-divider-actions"}
                orientation="vertical"
                id={"patientDashboard_topNav-divider"}
              />
            )}
            {!noUpload && (
              <Div
                className="uploadIcon"
                id={"patientDashboard_topNav-uploadIcon"}
              >
                <Image
                  src="images/icons8-upload.svg"
                  alt="upload"
                  onClick={(e) => uploadFun(e)}
                  className=""
                  id={"patientDashboard_topNav-uploadIcon-Image"}
                />
              </Div>
            )}
          </Col>
        </Row>
        <Popover
          anchorEl={isUpload}
          open={Boolean(isUpload)}
          onClose={() => uploadFun(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ zIndex: 99999 }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.muiPopovers }}
          id={"patientDashboard_topNav-isUpload-Popover"}
        >
          <UploadFiles />
        </Popover>
      </Paper>
    </div>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
  titleIcon: PropTypes.element,
  onTitleIconClicked: PropTypes.func,
};

Component.defaultProps = {
  withBackButton: false,
};

const TopNavbar = (props) => <Component {...props} />;

export default withRouter(TopNavbar);
