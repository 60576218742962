import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Div, Row, SideMenu, Button, Paper, H6 } from "qdm-component-library";
import {
  TopHeader,
  PatientCard,
  PatientDashboardNavBar,
  Addmemberotp,
  CardList,
  EmptyCard,
  ViewCard,
  ModelComponent,
  Addmember,
  Addmemberform,
  ConfirmBook,
} from "../../components";
import { TableList, BillNav } from "./";
// import ConfirmationPage from "../confirmBooking";
import CancelBookingPage from "../../components/cancelBooking/index";
import { Routes } from "../../router/routes";
import { getImgUrl, getUserInfo, makeName } from "../../utils";
import AppointmentAddMemberSuccessPage from "../../components/appointment_booked/index";

const tableHeader = [
  "S.No",
  "Bill.No",
  "Date",
  "Gross Amt",
  "Discount",
  "Net Payable",
  "Paid",
  "Outstanding",
  "Settle Now",
];
class PatientDashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      followUpAppointmentData: [],
      cancelbooking: false,
      cancelbookingreason: {},
      cancelbookings: 0,
      activeId: 1,
      addmmber: false,
      sidebar: this.props.location?.state?.addMember,
      memberForm: this.props.location?.state?.addMember,
      searchMenber: this.props.location?.state?.addMember,
      memberSuccessPage: this.props.location?.state?.addMember,
      appointmentId: 0,
      choosemember: [],
      memberIds: [],
      chooseSearch: null,
      searchmmrdata: null,
      loggedInState: {},
    };
    this.btnClick = this.btnClick.bind(this);
    this.openView = this.openView.bind(this);
    this.setActiveId = this.setActiveId.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
    this.changeSideBar = this.changeSideBar.bind(this);
    this.getUserInfo = getUserInfo.bind(this);
  }

  handelChangeSelect = (v) => {
    this.setState({
      chooseSearch: v,
    });
  };

  changeSideBar() {
    this.setState(
      {
        ...this.state,
        sidebar: false,
        memberForm: false,
        searchMenber: false,
        memberSuccessPage: false,
      },
      () => {
        window.location.reload();
      }
    );
  }

  async fetchInfo() {
    const persondataInfo = await this.getUserInfo();
    const info = JSON.parse(JSON.stringify(persondataInfo));
    if ((info.type === 'guest') || (!info)) {
      window.location = '/';
    }
    let userId = Number(info.id);
    const persondata = info.persondata;
    userId = persondata?.payload?.data?.id;
    info["name"] = persondata?.payload?.data?.name || info["name"];
    info["gender"] = persondata?.payload?.data?.gender;
    info["birthDay"] = persondata?.payload?.data?.birthDay;
    info["url"] = getImgUrl(persondata?.payload?.data?.imgUrl);
    if (info.type === "token") {
      if (!(this.props.values && this.props.values?.selectedUser)) {
        info["userId"] = userId;
      }
    }
    this.setState({
      loggedInState: { ...info },
    });
    const data = await this.props.GET_RELATED_PERSON({ userid: userId });
    if (data?.payload?.data && Array.isArray(data?.payload?.data)) {
      const choosemember = [];
      const memberIds = [userId];
      data.payload.data.forEach((val) => {
        choosemember.push({
          src: getImgUrl(val?.person?.[0]?.photo?.[0]?.fileid),
          label: makeName(val?.name?.[0]),
          id: val?.PersonID,
        });
        memberIds.push(val?.PersonID);
      });
      this.setState({
        choosemember,
        memberIds,
      });
    }
    const val = await this.props.GET__FOLLOW_UP_APPOINTMENT({
      patientId: persondata.payload?.data?.pid,
    });
    this.setState({
      followUpAppointmentData: val?.payload?.data,
    });
    this.props.ALL_APPOINTMENTS({
      userId,
    });
  }

  componentDidMount() {
    this.fetchInfo();
  }
  btnClick() {
    this.props.history.push(Routes.doctorConsultation);
  }
  selectPayBills() {
    this.props.history.push({
      pathname: Routes.collection,
      state: { nextRoute: Routes.PatientDashBoard },
    });
  }
  openView = (appointmentId) => {
    this.setState({
      open: !this.state.open,
      appointmentId,
    });
  };
  handlesidebarCancelbooking = (id) => {
    if (id === "closing") {
      this.getUserInfo().then(persondataInfo => {
        const info = JSON.parse(JSON.stringify(persondataInfo));
        let userId = Number(info.id);
        this.props.ALL_APPOINTMENTS({
          userId,
        });
      })
    }
    this.setState({
      cancelbooking: !this.state.cancelbooking,
      cancelbookingreason: {},
      cancelbookings: 0,
      appointmentId: id || this.state.appointmentId,
    });
  };
  handleSelectCancelbooking = (v) => {
    this.setState({
      cancelbookingreason: v,
    });
  };

  changeMembers = (newData) => {
    this.setState({
      ...this.state,
      choosemember: [...this.state.choosemember, ...newData],
    })
  }

  handlecahngeCancelbooking = async (v, number) => {
    if (v === "back") {
      this.setState({
        cancelbookings: 0,
      });
      return;
    }
    if (!v) {
      this.handlesidebarCancelbooking();
      window.location.href = "/profile";
      return;
    }
    let mobile = number;
    if (!number) {
      const userInfo = await this.getUserInfo();
      mobile = userInfo.mobile;
    }
    if (v === 1) {
      await this.props.GENERATE_OTP({ mobileNo: mobile });
    }
    this.setState({
      cancelbookings: v,
      otpnumber: number,
    });
  };
  setActiveId(Id) {
    this.setState({ activeId: Id });
  }
  toggleSideBar = (boolean) => {
    this.setState({
      ...this.state,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
      addmmber: false,
      memberSuccessPage: false,
    });
  };
  nextSection = (member, signUpData, l) => {
    if (signUpData === "addMember") {
      this.setState({
        ...this.state,
        signUpData: l ? l : signUpData,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
        memberSuccessPage: false,
        addmmber: true,
      });
      return;
    } else if (member === "addmemberform") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
        addmmber: false,
        signUpData,
      });
    } else if (member === "addmemberotp") {
      if (signUpData) {
        this.setState({
          ...this.state,
          signUpData,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
          addmmber: false,
        });
      } else {
        this.setState({
          ...this.state,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
        });
      }
    } else if (member === "back") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        memberSuccessPage: false,
      });
    } else if (member === "member_succss") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: false,
        memberOtp: false,
        sidebar: true,
        memberSuccessPage: true,
        signUpData,
      });
    } else if (member === "movenextscreen") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        memberSuccessPage: false,
      });
    }
  };

  handelChangeSelect = (v) => {
    this.setState({
      chooseSearch: v,
    });
  };

  containedButton = {
    backgroundColor: "#0071F2",
    borderColor: "#0071F2",
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontWeight: "600",
    fontSize: 12,
  };

  render() {
    const {
      sidebar,
      activeId,
      cancelbooking,
      cancelbookingreason,
      cancelbookings,
    } = this.state;
    return (
      <Div style={{ backgroundColor: "#F4F5F8" }}>
        <TopHeader
          info={this.state.loggedInState}
          noHome
          middleComponent={
            <PatientCard
              info={this.state.loggedInState}
              isAddBtn
              memberList={this.state.choosemember}
              addMember={this.toggleSideBar}
            />
          }
          id={"TopHeader"}
        />
        <PatientDashboardNavBar
          navBarArr={[
            { label: "Appointments", id: 1 },
            // { label: "Bill Info", id: 2 },
          ]}
          btnName="Book New Appointment"
          clickNav={this.setActiveId}
          activeId={activeId}
          btnClick={this.btnClick}
          id="PatientDashboardNavBar"
        />
        {activeId === 1 && !this.props?.appointments?.loading && (
          <React.Fragment>
            <div
              style={{
                height: 'calc(100vh - 129px)',
                overflow: 'hidden',
                padding: '14px 37px'
              }}
            >
              <Row
                key={"0"}
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                spacing="2"
                inLineStyles={{
                  // margin: "14px 37px !important",
                  // border: "1px solid #F0F0F0",
                  borderRadius: "8px",
                  // minHeight: "72vh",
                  backgroundColor: "#F4F5F8",
                  // padding: "15px 7px",
                  height: '100%',
                  overflow: 'auto',
                }}
                id={"dashboard-activeId-Row"}
              >
                {this.props?.appointments?.data?.upcomingAppointment?.length > 0
                  ?
                  <>
                    <CardList
                      title="UPCOMING"
                      id={"dashboard-CardList-UPCOMING"}
                      middleComponent={
                        <>
                          {
                            this.props?.appointments?.data?.upcomingAppointmentToday?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Today"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                this.props?.appointments?.data?.upcomingAppointmentToday.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            this.props?.appointments?.data?.upcomingAppointmentThisWeek?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="This Week"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                this.props?.appointments?.data?.upcomingAppointmentThisWeek.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            this.props?.appointments?.data?.upcomingAppointmentNextWeek?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Next Week"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                this.props?.appointments?.data?.upcomingAppointmentNextWeek.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            this.props?.appointments?.data?.upcomingAppointmentThisMonth?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="This Month"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                this.props?.appointments?.data?.upcomingAppointmentThisMonth.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            this.props?.appointments?.data?.upcomingAppointmentNextMonth?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Next Month"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                this.props?.appointments?.data?.upcomingAppointmentNextMonth.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                        </>
                      } />
                  </>
                  : <CardList
                    title="UPCOMING"
                    id={"dashboard-CardList-UPCOMING"}
                    middleComponent={<EmptyCard />} />}
                <CardList
                  title="FOLLOW UP REMINDER"
                  middleComponent={
                    this.state?.followUpAppointmentData?.length > 0 ? (
                      this.state?.followUpAppointmentData.map((val, i) => (
                        <ViewCard
                          openView={this.openView}
                          showBtn={true}
                          isFollowUp={true}
                          val={val}
                          key={i}
                          handlesidebarCancelbooking={
                            this.handlesidebarCancelbooking
                          }
                          title="FOLLOW UP REMINDER"
                          id={"dashboard-CardList-FOLLOW-UP-REMINDER-ViewCard"}
                        />
                      ))
                    ) : (
                      <EmptyCard />
                    )
                  }
                />

                {
                  this.props?.appointments?.data?.pastAppointment?.length > 0 ? <CardList
                    title="PAST"
                    border={false}
                    middleComponent={ 
                      <>
                      {
                            this.props?.appointments?.data?.pastAppointmentThisWeek?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="This Week"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                this.props?.appointments?.data?.pastAppointmentThisWeek.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                      // upcoming
                                      showBtn={true}
                                      isShowLabel={true}
                                      title="PAST"
                                      id={"dashboard-CardList-PAST-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            this.props?.appointments?.data?.pastAppointmentLastWeek?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Last Week"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                this.props?.appointments?.data?.pastAppointmentLastWeek.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                      // upcoming
                                      showBtn={true}
                                      isShowLabel={true}
                                      title="PAST"
                                      id={"dashboard-CardList-PAST-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            this.props?.appointments?.data?.pastAppointmentThisMonth?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="This Month"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                this.props?.appointments?.data?.pastAppointmentThisMonth.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                      // upcoming
                                      showBtn={true}
                                      isShowLabel={true}
                                      title="PAST"
                                      id={"dashboard-CardList-PAST-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            this.props?.appointments?.data?.pastAppointmentLastMonth?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Last Month"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                this.props?.appointments?.data?.pastAppointmentLastMonth.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                      // upcoming
                                      title="PAST"
                                      showBtn={true}
                                      isShowLabel={true}
                                      id={"dashboard-CardList-PAST-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                      </> 
                    } /> : <CardList
                    title="PAST"
                    border={false}
                    middleComponent={ <EmptyCard /> } /> 
                }
                
              </Row>
            </div>
          </React.Fragment>
        )}
        {activeId === 2 && (
          <React.Fragment>
            <Div
              // spacing="2"
              inLineStyles={{
                margin: "14px !important",
                border: "1px solid #F0F0F0",
                borderRadius: "8px",
                minHeight: "72vh",
                backgroundColor: "#F9F9F9",
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
              id={"dashboard-activeId-div"}
            >
              <BillNav
                activeid={1}
                btnList={[
                  { id: 1, label: "Outstanding" },
                  { id: 2, label: "Paid" },
                ]}
                id={"dashboard-BillNav"}
              />
              <Div style={{ flex: 1 }}>
                <Paper
                  style={{
                    margin: 0,
                    padding: "20px 0px",
                    boxShadow: "0px 10px 25px #0000000A",
                    borderRadius: 8,
                    border: "1px solid #F0F0F0",
                  }}
                  id={"dashboard-Paper"}
                >
                  <TableList
                    tableHeader={tableHeader}
                    tableData={[]}
                    isSerialNo={true}
                    isCheckBox={true}
                    tableDataList={[]}
                    loading={false}
                    isempty={false}
                    id={"dashboard-TableList"}
                  />
                </Paper>
              </Div>
              <Div style={{ textAlign: "center" }}>
                <Button
                  style={this.containedButton}
                  onClick={() => this.selectPayBills()}
                  id={"dashboard-selectPayBills-btn"}
                >
                  Pay Selected Bills
                </Button>
              </Div>
            </Div>
          </React.Fragment>
        )}
        <ModelComponent
          hiddencloseIcon={true}
          open={this.state.open}
          onClose={this.openView}
          childrens={
            <ConfirmBook
              appointmentId={this.state.appointmentId}
              appointmentDetails={true}
              hidden={true}
            />
          }
          width={530}
          inLineStyles={{ width: "530px" }}
          id={"dashboard-ModelComponent"}
        />
        <SideMenu
          open={cancelbooking}
          direction="right"
          width={435}
          color="default"
          onClose={() => this.handlesidebarCancelbooking("closing")}
          id={"dashboard-SideMenu"}
        >
          <CancelBookingPage
            cancelbookingreason={cancelbookingreason}
            cancelbookings={cancelbookings}
            appointmentId={this.state.appointmentId}
            handleSelectCancelbooking={this.handleSelectCancelbooking}
            handlecahngeCancelbooking={this.handlecahngeCancelbooking}
            id={"dashboard-SideMenu-CancelBookingPage"}
          />
        </SideMenu>
        <SideMenu
          open={sidebar}
          direction="right"
          width={445}
          color="default"
          onClose={() => this.toggleSideBar()}
          id={"dashboard-SideMenu-toggleSideBar"}
        >
          {this.state.searchMenber ? (
            <Addmember
              parent_id={'dashboad'}
              name={"Add Member"}
              nextSection={(member, v, l) => this.nextSection(member, v, l)}
              handelChangeSelect={(v) => this.handelChangeSelect(v)}
              signUpData={this.state.signUpData}
              chooseSearch={this.state.chooseSearch}
              addMember
              memberIds={this.state.memberIds}
            />
          ) : this.state.memberForm ? (
            <Addmemberform
              parent_id={'dashboad'}
              addNewMember
              changeSideBar={this.changeSideBar}
              signUpData={this.state.signUpData}
              nextSection={(member, signUpInfo) =>
                this.nextSection(member, signUpInfo)
              }
            />
          ) : this.state.memberOtp ? (
            <Addmemberotp
              parent_id={'dashboad'}
              addmmber={this.state.addmmber}
              nextSection={(member, v) => this.nextSection(member, v)}
              signUpData={this.state.signUpData}
              updateMember={this.changeMembers}
              appointmentDetails={true}
            />
          ) : null}
          {this.state.memberSuccessPage ? (
            <AppointmentAddMemberSuccessPage
              parent_id={'dashboad'}
              addmmber={this.state.addmmber}
              memeberIds={this.state.memberIds}
              signUpData={this.state.signUpData}
              nextSection={(member, v, l) => this.nextSection(member, v, l)}
              toggleSideBar={(member) => this.toggleSideBar(member)}
            />
          ) : null}
        </SideMenu>
      </Div>
    );
  }
}

const mapStateToProps = (state) => ({
  appointments: state?.appointmentReducer?.allAppointments,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
});

export default connect(mapStateToProps, actions)(withRouter(PatientDashBoard));
