import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withRouter } from "react-router-dom";
import { Direct } from "../../assets/img/directconsultation.js";
import { Suggested } from "../../assets/img/suggested.js";
import { Videoconsultation } from "../../assets/img/videoconsultation.js";
const useStyles = makeStyles((theme) => ({
  selectedTab: {
    backgroundColor: "#2A60BC !important",
    color: "#FFFFFF !important",
    fontSize: 12,
    // fontWeight: 600,
    // marginTop: "8%",
    textTransform: "none",
  },
  selectedTabMulipleBook: {
    backgroundColor: "#f1f6fc !important",
    color: "#2a60bc !important",
    fontSize: 12,
    // fontWeight: 600,
    // marginTop: "8%",
    textTransform: "none",
  },
  selecteTab: {
    // marginTop: "4px",
    fontSize: 12,
    color: "#101010",
    textTransform: "none",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    marginTop: "6px",
    justifyContent: "space-around",
    marginBottom: "16%",
  },
  textSize: {
    // marginTop: 4,
    fontSize: 10,
  },
  typeslot: {
    display: "flex",
    justifyContent: "space-between",
    // marginBottom: "2%",
  },
  text: {
    fontFamily: "pc_medium",
    fontSize: 12,
  },
}));

function Tapcomp(props) {
  const classes = useStyles();
  const { parent_id, name } = props;
  const clientCard = {
    height: "auto",
    minWidth: "144px",
    padding: "8px 6px",
    lineHeight: 1,
  };
  // const drCard = { height: "60px", width: "87px" };
  const drCard = { lineHeight: 1, padding: 6, minWidth: 71 };
  return (
    <ToggleButtonGroup
      id={`${parent_id}_tapselect_parent_togglebuttongroup`}
      value={props.stateVal}
      size={"small"}
      exclusive
      d
      aria-label="text alignment"
      onChange={() => props.onChange(props.val)}
      style={{
        marginRight: props.type === "clinic" ? 8 : 4,
        marginTop: props.type === "clinic" ? 8 : 4,
      }}
    >
      <ToggleButton
        disabled={props.val.value === props?.location?.state?.slotId}
        id={`${parent_id}_tapselect_parent_togglebutton`}
        selected={props.isSelected && props.stateVal === props.val._id}
        // selected={props.dirty ? props.stateVal === props.val.value : props.selectedId === props.val.value}
        value={props.val.value}
        aria-label="left"
        size={"small"}
        classes={{
          root: classes.selecteTab,
          selected:
            name === "multiple_appointment"
              ? classes.selectedTabMulipleBook
              : classes.selectedTab,
        }}
        style={
          props?.cardStyle
            ? props?.cardStyle
            : props.type === "clinic"
            ? clientCard
            : drCard
        }
        // style={props?.cardStyle ? props?.cardStyle : props.type === "clinic" ? clientCard : {}}
      >
        <div
          id={`${parent_id}_tapselect_parent_div`}
          // style={{display:props.type !== "clinic" ?"flex" : "block",alignItems:"center"}}
        >
          {props.type === "clinic" ? (
            <div
              id={`${parent_id}_tapselect_clinic_div`}
              className={classes.textSize}
            >
              {/* <div className={classes.typeslot} style={{ color: "#38c20a" }}> */}
              <div
                id={`${parent_id}_tapselect_typelot_div`}
                className={classes.typeslot}
                style={{
                  color: props.val._id === props.stateVal ? "#fff" : "#38c20a",
                  paddingTop: 8,
                }}
              >
                <span
                  className="pc_regular"
                  id={`${parent_id}_tapselect_available_label_span`}
                >
                  Available Slot{" "}
                </span>
                <span
                  id={`${parent_id}_tapselect_available_label_sub_span`}
                  className="pc_regular"
                  style={{
                    fontSize: 10,
                  }}
                >
                  {props.val.maxBooking - props.val.booked}/
                  {props.val.maxBooking}
                </span>{" "}
              </div>
              {/* <div
                className={classes.typeslot}
                style={{
                  color:
                    props.val._id === props.stateVal ? "#fff" : "#d6d6d6",
                }}
              >
                Walk-in (Available) <span>2/5</span>{" "}
              </div> */}
              <div
                id={`${parent_id}_tapselect_waitlist_label_div`}
                className={classes.typeslot}
                style={{
                  color: props.val._id === props.stateVal ? "#fff" : "#f58b00",
                  paddingTop: 8,
                }}
              >
                <span
                  className="pc_regular"
                  id={`${parent_id}_tapselect_waitlist_label_span`}
                >
                  Waitlist
                </span>
                <span
                  id={`${parent_id}_tapselect_waitlist_label_sub_span`}
                  className="pc_regular"
                >
                  {props?.val?.maxWaiting ?? props.val.waiting}
                </span>
              </div>
            </div>
          ) : (
            <span
              id={`${parent_id}_tapselect_waitlist_parent_span`}
              className={classes.icons}
              // style={{display:"flex",alignItems:"center",justifyContent:"center"}}
            >
              {/* {name !== "multiple_appointment" &&
                <span
                  id={`${parent_id}_tapselect_label_span`}
                  className={classes.text}>{props.val.label}
                </span>} */}
              {props.val.issuggest ? (
                <span
                  id={`${parent_id}_tapselect_suggested_span`}
                  style={{ display: "flex" }}
                >
                  <Suggested
                    id={`${parent_id}_tapselect_suggested`}
                    color={
                      props.val._id === props.stateVal ? "#2A60BC" : "#0071F2"
                    }
                  />
                </span>
              ) : null}

              {props.val.isdirect ? (
                <span
                  id={`${parent_id}_tapselect_direct_span`}
                  style={{ display: "flex", padding: "0px" }}
                >
                  <Direct
                    id={`${parent_id}_tapselect_direct`}
                    color={
                      props.val._id === props.stateVal ? "#fff" : "#B6B6B6"
                    }
                  />
                </span>
              ) : null}

              {props.val.isvideo ? (
                <span
                  id={`${parent_id}_tapselect_videoconsultation_span`}
                  style={{ display: "flex", padding: "0px" }}
                >
                  <Videoconsultation
                    id={`${parent_id}_tapselect_videoconsultation`}
                    color={
                      props.val._id === props.stateVal ? "#fff" : "#B6B6B6"
                    }
                  />
                </span>
              ) : null}
            </span>
          )}
          {/* {(props.type === "clinic" || name === "multiple_appointment") && */}
          <span
            id={`${parent_id}_tapselect_label_span`}
            className={classes.text}
          >
            {props.val.label}
          </span>
          {/* <br /> */}
        </div>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default withRouter(Tapcomp);
