import {
  Checkbox,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    "& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root": {
      borderBottom: 0,
    },
    "& .MuiTableRow-head": {
      backgroundColor: "#F0F0F0",
      "& .MuiTableCell-head": {
        padding: 0,
        fontWeight: "600",
        fontSize: "14px",
      },
    },
  },
});

function TableList(props) {
  const {
    tableHeader = [],
    tableData = [],
    isSerialNo = false,
    isCheckBox = false,
    tableDataList = [],
    loading = false,
    isempty = false,
  } = props;
  const classes = useStyles();
  return (
    <TableContainer id={"dashboard-TableContainer-parent"}>
      <Table
        className={classes.table}
        aria-label="simple table"
        id={"dashboard-Table"}
      >
        {tableHeader.length > 0 && (
          <TableHead id={"dashboard-TableHead"}>
            <TableRow id={"dashboard-TableRow"}>
              {isCheckBox && (
                <TableCell align="left" id={"dashboard-TableCell"}>
                  <Checkbox />
                </TableCell>
              )}
              {tableHeader.map((val) => {
                return (
                  <TableCell id={"dashboard-tableHeadermap-cell"}>
                    {val}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody id={"dashboard-TableBody"}>
          {tableData &&
            tableData.length > 0 &&
            !loading &&
            tableData.map((data, i) => {
              return (
                <TableRow key={i}>
                  {isCheckBox && (
                    <TableCell id={`${i}-dashboard-TableBody`} align="left">
                      <Checkbox id={`${i}-dashboard-Checkbox`} />
                    </TableCell>
                  )}
                  {isSerialNo && (
                    <TableCell
                      id={`${i}-dashboard-Checkbox-TableCell`}
                      align="left"
                    >
                      {i + 1}
                    </TableCell>
                  )}
                  {tableDataList.map((val) => (
                    <TableCell
                      align="left"
                      id={`${i}-dashboard-Checkbox-TableCell-left`}
                    >
                      {data[val] ? data[val] : isempty ? "" : "N/A"}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {!((tableData && tableData.length > 0) || loading) && (
        <div
          style={{ textAlign: "center", marginTop: "10px" }}
          id={`$dashboard-tableData-length`}
        >
          {" "}
          No Data Found!
        </div>
      )}
      {loading && (
        <div id={`$dashboard-Loading`} style={{ textAlign: "center" }}>
          Loading...
        </div>
      )}
    </TableContainer>
  );
}

export default TableList;
