import React from "react";
import { Div, Row, Icons, Col } from "qdm-component-library";
import { Button } from "@material-ui/core";

function BillNav(props) {
  const { btnList = [], activeid = 1 } = props;
  return (
    <Div>
      <Row style={{ paddingBottom: 20 }} id={"dashboard-BillNav_parent_row"}>
        <Col
          lg={8}
          md={8}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 0 }}
          id={"dashboard-BillNav_col"}
        >
          {btnList.map((val) => {
            return (
              <Button
                variant="outlined"
                style={{
                  borderRadius: "20px",
                  marginRight: "10px",
                  border: "1.5px solid",
                  fontWeight: 600,
                  color: activeid === val.id ? "#0071F2" : "#101010",
                  borderColor: activeid === val.id ? "#0071F2" : "#707070",
                  textTransform: "capitalize",
                  padding: "3px 20px",
                  minWidth: "90px",
                }}
                id={"dashboard-BillNav_btn_label"}
                // color={activeid === val.id ? "primary" : ""}
              >
                {val.label}
              </Button>
            );
          })}
        </Col>
        <Col
          lg={4}
          md={4}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 0 }}
          id={"dashboard-BillNav_col"}
        >
          <Div
            style={{
              border: "1px solid #E0E0E0",
              padding: "4px 10px",
              borderRadius: "4px",
              color: "#B6B6B6",
              backgroundColor: "#fff",
              display: "inline-block",
              float: "right",
            }}
            id={"dashboard-BillNav_div"}
          >
            <Icons
              key={"2"}
              fontIcon="filter"
              ariaHidden="true"
              className=""
              size="medium"
              style={{ color: "#B6B6B6", fontSize: "12px" }}
              id={"dashboard-BillNav-filter-Icons"}
            ></Icons>
          </Div>
          <Div
            style={{
              border: "1px solid #E0E0E0",
              padding: "4px 10px",
              borderRadius: "4px",
              color: "#B6B6B6",
              marginRight: "8px",
              backgroundColor: "#fff",
              display: "inline-block",
              float: "right",
            }}
            id={"dashboard-BillNav-search-Div"}
          >
            <Icons
              key={"2"}
              fontIcon="search"
              ariaHidden="true"
              className=""
              size="medium"
              inLineStyles={{
                color: "#B6B6B6",
                fontSize: "12px",
              }}
              id={"dashboard-BillNav-search-Icons"}
            ></Icons>
          </Div>
        </Col>
      </Row>
    </Div>
  );
}

export default BillNav;
