import React from "react";
// import { actions } from "primarycare-binder";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Text } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import InputLabel from "@material-ui/core/InputLabel";
import { Selection } from '../';

const styles = (theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: 16,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    '& .MuiInputBase-input': {
      padding: '4.5px 4px !important'
    },
    '& .MuiInputLabel-marginDense': {
      fontSize: 14,
      fontFamily: 'pc_regular'
    },
    "& .MuiOutlinedInput-notchedOutline":{
      borderColor: "#c9c9c93b",
      borderRadius: 2,
      "& hover":{
        borderColor: "#c9c9c93b",
      }
    }
  },
  popupIndicator: {
    transform: 'rotate(0deg) !important',
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M12.265,6a6.265,6.265,0,1,0,3.768,11.256l2.491,2.49a.864.864,0,1,0,1.222-1.222l-2.49-2.491A6.251,6.251,0,0,0,12.265,6Zm0,1.728a4.538,4.538,0,0,1,3.3,7.647.864.864,0,0,0-.193.193,4.537,4.537,0,1,1-3.112-7.84Z')" // your svg icon path here
        }
      }
    },
  },
  nameLable: {
    fontSize: 13,
    color: "#6A7888",
    marginBottom: 14
  }
});
// const top100Films = [
//   { title: "Fever", value: "Fever" },
//   { title: "Running nose", value: "Running nose" },
//   { title: "Cough", value: "Cough" },
//   { title: "Vomiting", value: "Vomiting" },
//   { title: "Nose Congestion", value: "Nose Congestion" },
//   { title: "Nose Pain", value: "Nose Pain" },
// ];
class DoctorAndAppointmentSlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      values: [],
      allMasters: []
    };
  }
  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };
  componentDidMount() {
  }

  handleChip = (n, v, l) => {
    if (l) {
      // array-callback-return
      // eslint-disable-next-line array-callback-return
      const data = this.props.info?.filter((l) => {
        if (l?.value !== v?.value) {
          return v;
        }
      });
      this.props.handleChange(data)
      return false;
    }
    this.props.handleChange(v)
  };

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    const { classes, parent_id } = this.props;
    return (
      <Card
        id={`${parent_id}_collapse_parent_card`}
        style={{
          boxShadow: "none",
          border: "1.5px solid #E0E0E0",
          borderRadius: 8,
        }}
      >
        <CardActions
          id={`${parent_id}_collapse_parent_cardaction`}
          onClick={this.handleExpandClick}
          disableSpacing
          style={{
            padding: 0,
            paddingLeft: 16,
            paddingBottom: 0,
            cursor: "pointer",
          }}
        >
          <img
            id={`${parent_id}_collapse_reason_image`}
            alt="Reasonforappointment"
            src={this.props?.img}
            style={{ marginRight: 12 }}
          />
          <Text
            id={`${parent_id}_collapse_reason_for_appointment_label_span`}
            key={"0"}
            name={this.props?.title}
            style={{ fontSize: "16px", color: "#001C3C", letterSpacing: 0 }}
            className={"pc_semibold"}
          />

          <IconButton
            id={`${parent_id}_collapse_reason_for_appointment_iconbutton`}
            className={clsx(classes.expand, {
              [classes.expandOpen]: this.state.expanded,
            })}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon
              id={`${parent_id}_collapse_reason_for_appointment_expandmoreicon`}
            />
          </IconButton>
        </CardActions>
        <Collapse
          id={`${parent_id}_collapse_body_collapse`}
          className={classes.body}
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <>
          {
          this.props?.multiple ? (
          <>
          <Autocomplete
            id={`${parent_id}_collapse_body_autocomplete`}
            size="small"
            multiple
            options={this.props.options || []}
            getOptionLabel={(option) => option.title}
            filterSelectedOptions={true}
            value={this.props?.info}
            onChange={(event, newValue) => this.handleChip(event, newValue)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => null)
            }
            classes={{
              popupIndicator: classes.popupIndicator
            }}
            renderInput={(params) => (
              <div>
                <InputLabel htmlFor="component-simple" className={classes.nameLable}>Add your health issues here</InputLabel>
                <TextField
                  id={`${parent_id}_collapse_body_autocomplete_label_add_your_health`}
                  {...params}
                  variant="outlined"
                  // label={this.props.label ?? ''}
                  label={'Search your health issues here..'}
                  style={{
                    backgroundColor: "#F1F6FC",
                    color: "#6A7888",
                    fontSize: 14,
                    border: "1px solid #F1F6FC"
                  }}
                />
              </div>
            )}
            style={{
              borderColor: "#F1F6FC",
              border: "none",
              fontSize: 14
            }}
            className={"pc_regular"}
          />
           {this.props?.info?.map((option, index) => {
            return (
              <Chip
                id={`${parent_id}_${index}_collapse_body_autocomplete_title_chip`}
                size="medium"
                label={option.title}
                deleteIcon={<CloseIcon
                  id={`${parent_id}_${index}_collapse_body_autocomplete_closeicon`} style={{ width: 18, height: 18,color:'#01205C' }} />}
                onDelete={(event) => this.handleChip(event, option, "remove")}
                color="primary"
                variant="outlined"
                style={{
                  marginRight: 6,
                  marginTop: 6,
                  color: "#01205C",
                  border: "none",
                  backgroundColor: "#DEE5EC",
                  borderRadius: 8
                }}
              />
            );
          })
          }
          </>
          ) : (
            <Selection
              options={this.props.options || []}
              optionLabel="title"
              value={this.props?.info}
              label={this.props.label ?? ''}
              parent_id={this.props.parent_id}
              onChange={(e,value)=>this.props.handleChange(value)}
              error={this.props.error}

            />
          )
          }
          </>
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(styles)((withRouter(DoctorAndAppointmentSlot))
);
