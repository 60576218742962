import React, {useEffect, useState } from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Routes } from "./../../router/routes";
import Logo from "../../assets/img/logo_B.svg";
import BackgroundUrl from "../../assets/img/backgroundImage.svg";
import {
  Addmember,
  Addmemberform,
  Addmemberotp,
  // AppointmentBooked,
} from "../../components";
import {
  Container,
  // Row,
  Col,
  // Avatar,
  Div,
  Icons,
  Text,
  Divider,
  Button,
  BackgroundImage,
  Paper,
  H6,
  Link,
  OtpInput,
  SideMenu,
  Row,
} from "qdm-component-library";
import { checkError, getUserInfo } from "../../utils";
import AppointmentAddMemberSuccessPage from "../../components/appointment_booked/index";
import { UIColor } from "../../utils/constants";
import { withStyles, Typography } from "@material-ui/core";

function OtpTimer(props) {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    setMinutes(initialMinute)
    setSeconds(initialSeconds)
  }, [initialMinute, initialSeconds])

  useEffect(() => {
    if (seconds === 0 && minutes === 0) {
      props.changeResendState(true);
    }
    let myInterval = setInterval(() => {
      // Block Resend State
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <>
      {minutes === 0 && seconds === 0 ? null : (
        <Typography style={{
          fontSize: "12px",
          margin: "0px",
          letterSpacing: 0,
          lineHeight: 1,
        }}>
          Resend OTP in  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Typography>
      )}
    </>
  );
}

const styles = (theme) => ({
  // otpcomponent: {
  //   borderColor: UIColor.primaryColor.color,
  //   "& .xyz": {
  //     borderColor: UIColor.lineBorderFill.color,
  //   },
  // },
});
class SignInOTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNo: this.props?.location?.state?.mobileNo ?? {},
      otp: "",
      otpSentMessage: "",
      otpSentErrorMessage: "",
      sidebar: false,
      searchMenber: true,
      memberForm: false,
      memberOtp: false,
      addmmber: false,
      signUpData: {},
      otp_state: false,
      otp_minutes: 0,
      otp_seconds: 59,
    };
    this.getUserInfo = getUserInfo.bind(this);
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
      otpSentMessage: "",
      otpSentErrorMessage: ""
    });
  };

  async componentDidMount() {
    const info = await this.getUserInfo();
    if (info.type) {
      if (info.type === "guest") {
        this.props.history.push(Routes.doctorConsultation);
      } else {
        this.props.history.push(Routes.appointmentsListingPage);
      }
    }
  }

  shouldComponentUpdate(props, state) {
    return true;
  }

  changeBtnClick = () => {
    this.props.history.push({ pathname: Routes.signIn });
  };
  toggleSideBar = (boolean) => {
    this.setState({
      ...this.state,
      chooseSearch: boolean ? null : this.state?.chooseSearch || null,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
      addmmber: false,
      memberSuccessPage: false,
    });
  };
  nextSection = (member, signUpData, l) => {
    if (signUpData === "addMember") {
      this.setState({
        ...this.state,
        signUpData: l ? l : signUpData,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
        memberSuccessPage: false,
        addmmber: true,
      });
      return;
    } else if (member === "addmemberform") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
      });
    } else if (member === "addmemberotp") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
      });
    } else if (member === "back") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
      });
    } else if (member === "movenextscreen") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        chooseSearch: null,
      });
      window.location.href = Routes.appointmentsListingPage;
    }
    this.props.history.push(Routes.signIn);
  };
  handelChangeSelect = (v) => {
    this.setState({
      chooseSearch: v,
    });
  };
  sendOTP = () => {
    if (this.props.resendOTP?.loading) {
      return true;
    }
    this.setState({
      otp: "",
    });
    Promise.resolve(
      this.props.GENERATE_OTP({
        mobileNo: `+${this.state.mobileNo?.countryData?.callingCodes ?? "00"}${this.state.mobileNo.mobileNo
          }`,
      })
    ).then((res) => {
      this.setState({
        otpSentMessage: "We have sent you the OTP again, Please check.",
        otpSentErrorMessage: "",
        otp_state: false,
        otp_minutes: 0,
        otp_seconds: 59,
      });
    });
    // window.location.reload();
  };

  onLogIn = () => {
    Promise.resolve(
      this.props.SIGN_IN({
        mobileNo: `+${this.state.mobileNo?.countryData?.callingCodes ?? "00"}${this.state.mobileNo.mobileNo
          }`,
        otp: this.state.otp,
      })
    ).then(async (res) => {
      const status = checkError(this.props.signIn);
      if (!status.isError) {
        // this.props
        //   .READ_PERSON({ phone: getMobileNumber(this.state.mobileNo) })
        //   .then((persondata) => {
        //     if (res?.payload?.data?.access_token) {
        //       localStorage.removeItem("guestInfo");
        //       // localStorage.setItem("idval", persondata?.payload?.data?.id || 0);
        //       localStorage.setItem("token", btoa(JSON.stringify(res?.payload?.data)));
        //     }
        //     this.props.history.push(Routes.appointmentsListingPage);
        //   });
        localStorage.setItem("token", btoa(JSON.stringify(res?.payload?.data)));
        const userInfo = await this.getUserInfo();
        if (userInfo && userInfo.id !== 0) {
          this.props.history.push(Routes.appointmentsListingPage);
        } else {
          localStorage.removeItem("token");
          this.setState({
            otpSentMessage: "Invalid Login. Please Reach Administrator",
            otpSentErrorMessage: ""
          });
        }
      } else {
        this.setState({
          otpSentMessage: "",
          otpSentErrorMessage: status.errMsg
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div id="signinotp_parent-div">
        <Container
          id="signinotp_parent-container"
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: "#e8e8e8",
            // height: "100vh",
            padding: "0px",
          }}
        >
          <Row
            id="signinotp_parent_row"
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{ backgroundColor: "#ffffff", height: "" }}
          >
            <Col
              id="signinotp_parent-col"
              key={"0"}
              name="qdm-col-12"
              alignItems="center"
              direction="row"
              justify="space-between"
              textAlign="left"
              lg="12"
              xs="12"
              md="12"
              sm="12"
              style={{
                height: "64px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "12px 37px",
              }}
            >
              <div
                id="signinotp_header_logo_parent_div"
                style={{
                  width: "96px",
                  height: "32px",
                }}
              >
                <img
                  id="signinotp_header_logo"
                  key={"0"}
                  variant="rounded"
                  letter="Logo"
                  src={Logo}
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>

              <Div
                id="signinotp_header_right_div"
                key={"1"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Icons
                  id="signinotp_header_phone_icon"
                  key={"0"}
                  fontIcon="phone"
                  ariaHidden="true"
                  size="small"
                  className=""
                  style={{ color: UIColor.secondaryText.color, margin: "10px" }}
                />

                <Text
                  id="signinotp_header_number_text"
                  key={"1"}
                  name="+91 9821315141 , +91 9551482612"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 14,
                    color: UIColor.secondaryText.color,
                    letterSpacing: 0,
                  }}
                  className={"pc_regular"}
                />

                <Divider
                  id="signinotp_header_right_side_divider"
                  key={"2"}
                  className=""
                  inLineStyles="[object Object]"
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="vertical"
                  variant="middle"
                  style={{
                    height: "27px",
                    marginTop: "6px",
                    marginLeft: "24px",
                    marginRight: "17px",
                  }}
                />

                <Button
                  id="signinotp_header_support_button"
                  key={"3"}
                  type="button"
                  variant="text"
                  name="Support"
                  style={{
                    borderColor: UIColor.primaryColor.color,
                    borderWidth: "",
                    borderRadius: "8px",
                    color: UIColor.primaryColor.color,
                    height: "36px",
                    lineHeight: 0,
                    fontSize: 14,
                    padding: "8px 16px",
                  }}
                  className={"pc_medium"}
                />
              </Div>
            </Col>
          </Row>

          <Col
            id="signinotp_body_parent_card_col"
            key={"1"}
            name="qdm-col-12"
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            textAlign="left"
            lg="12"
            xs="12"
            md="12"
            sm="12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 64px)",
              backgroundColor: "#F0F7FF",
              overflow: "auto",
            }}
          >
            <BackgroundImage
              id="signinotp_body_background_image"
              key={"0"}
              url={BackgroundUrl}
              alt="Mask-Group-71-2x"
              backgroundPosition="0% 0%"
              backgroundPositionX="left"
              backgroundPositionY="top"
              backgroundRepeat="no-repeat"
              backgroundRepeatX="no-repeat"
              backgroundRepeatY="no-repeat"
              backgroundAttachment="scroll"
              backgroundSize="contain"
              backgroundOrigin="padding-box"
              inLineStyles="[object Object]"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Paper
                id="signinotp_body_parent_paper"
                key={"0"}
                children="Paper"
                variant="elevation"
                elevation="1"
                style={{
                  width: "350px",
                  // height: "",
                  backgroundColor: "#ffffff",
                  borderRadius: "16px",
                  padding: "32px",
                }}
              >
                <H6
                  id="signinotp_title_h6"
                  key={"0"}
                  name="Enter OTP"
                  style={{
                    fontSize: "16px",
                    color: UIColor.primaryText.color,
                    textAlign: "center",
                    letterSpacing: 0,
                    lineHeight: 1,
                  }}
                  inLineStyles={{ fontFamily: "'pc_medium' !important" }}
                />

                <Div
                  id="signinotp_sublevel_parent_div"
                  key={"1"}
                  className="qdm"
                  style={{
                    marginTop: "24px",
                  }}
                >
                  <H6
                    id="signinotp_sublevel_title_h6"
                    key={"0"}
                    name="Please enter the OTP sent to  "
                    style={{
                      color: UIColor.secondaryText.color,
                      fontSize: "14px",
                      margin: "0px",
                      letterSpacing: 0,
                      textAlign: "center",
                    }}
                    className={"pc_regular"}
                  >
                    Please enter the OTP sent to &nbsp;
                    <Text
                      id="signinotp_mobilenumber_discription_text"
                      key={"1"}
                      style={{
                        textAlign: "",
                        fontSize: "14px",
                        color: UIColor.primaryText.color,
                        letterSpacing: 0,
                      }}
                      className="pc_medium"
                    >
                      {/* {this.state.mobileNo?.countryData?.callingCodes ?? "99******66"} */}
                      {this.state.mobileNo?.mobileNo ?? ""}
                      <Link
                        id="signinotp_number_change_link"
                        key={"0"}
                        underline="hide"
                        color="primary"
                        name="Change"
                        underLine="none"
                        style={{
                          textAlign: "center",
                          color: UIColor.secondaryButtonColor.color,
                          borderWidth: "",
                          fontSize: "14px",
                          fontFamily: "pc_regular",
                        }}
                        onClick={this.changeBtnClick}
                      />
                    </Text>
                  </H6>
                </Div>

                {/* <Div
                  id="signinotp_number_change_link_div"
                  key={"2"}
                  className="qdm"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    id="signinotp_number_change_link"
                    key={"0"}
                    underline="hide"
                    color="primary"
                    name="Change"
                    underLine="none"
                    style={{
                      textAlign: "center",
                      color: UIColor.primaryColor.color,
                      borderWidth: "",
                      fontSize: "14px",
                      fontFamily:'pc_regular'
                    }}
                    onClick={this.changeBtnClick}
                  />
                </Div> */}

                <Div
                  id="signinotp_otp_number_parent_div"
                  key={"3"}
                  className="qdm"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 12,
                  }}
                >
                  <div className={classes.otpcomponent}>
                    <OtpInput
                      id="signinotp_otp_number_otpinput"
                      key={"0"}
                      numberOfInputs={6}
                      variant="standard"
                      type="text"
                      focusTextColor={UIColor.secondaryColor.color}
                      focusBorderColor={UIColor.secondaryColor.color}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: "",
                        margin: 8,
                        fontFamily: "pc_regular",
                      }}
                      value={this.state.otp}
                      onChange={(e) => this.changeState("otp", e)}
                      className={this.state.otpSentMessage && "otpError"}
                    />
                  </div>
                </Div>

                <Div
                  id="signinotp_didnt_receive_resend_otp_resend_div"
                  key={"4"}
                  className="qdm"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "28px",
                  }}
                >
                  {this.state.otp_state ? (<>
                    <H6
                      id="signinotp_didnt_receive_resend_otp_resend_h6"
                      key={"0"}
                      name="Didn't receive OTP ? "
                      style={{
                        color: UIColor.secondaryText.color,
                        fontSize: "12px",
                        margin: "0px",
                        letterSpacing: 0,
                        lineHeight: 1,
                      }}
                      className={"pc_regular"}
                    />

                    <Link
                      id="signinotp_didnt_receive_resend_otp_resending_link"
                      key={"1"}
                      name={
                        this.props.resendOTP.loading
                          ? "Resending OTP..."
                          : "Resend OTP"
                      }
                      underline="hide"
                      color="textPrimary"
                      underLine="none"
                      style={{
                        margin: "0px",
                        marginLeft: "8px",
                        color: UIColor.primaryText.color,
                        fontSize: "12px",
                        letterSpacing: 0,
                      }}
                      onClick={this.sendOTP}
                      className={"pc_medium"}
                    />
                  </>) : (<>
                    <OtpTimer
                      initialMinute={this.state.otp_minutes}
                      initialSeconds={this.state.otp_seconds}
                      changeResendState={(status) => {
                        // alert("Hi")
                        this.setState({ otp_state: true });
                      }}
                    />
                  </>)
                  }
                </Div>

                {/* {this.state.otpSentMessage && ( */}
                <Div
                  id="signinotp_resending_otpsendmessage_div"
                  style={{ textAlign: "center" }}
                >
                  {this.state.otpSentMessage &&
                  <H6
                    key={"0"}
                    name={this.state.otpSentMessage}
                    // name={'We have sent you the OTP again, Please check.'}
                    className="pc_regular"
                    style={{
                      color: UIColor.success.color,
                      fontSize: "12px",
                      marginTop: "8px",
                      visibility: this.state.otpSentMessage
                        ? "visible"
                        : "hidden",
                    }}
                  />
                  }
                  {this.state.otpSentErrorMessage &&
                  <H6
                    key={"0"}
                    name={this.state.otpSentErrorMessage}
                    // name={'We have sent you the OTP again, Please check.'}
                    className="pc_regular"
                    style={{
                      color: UIColor.error.color,
                      fontSize: "12px",
                      marginTop: "8px",
                      visibility: this.state.otpSentErrorMessage
                        ? "visible"
                        : "hidden",
                    }}
                  />
                  }
                </Div>
                {/* )} */}

                <Button
                  id="signinotp_signin_confirmotp_button"
                  key={"5"}
                  onClick={this.onLogIn}
                  disabled={this.props.signIn?.loading}
                  type="button"
                  variant="outlined"
                  name={
                    this.props.signIn?.loading ? "Signing In..." : "Confirm OTP"
                  }
                  style={{
                    margin: "",
                    marginTop: "40px",
                    width: "100%",
                    backgroundColor: UIColor.primaryColor.color,
                    borderColor: UIColor.primaryColor.color,
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "normal",
                    height: "40px",
                  }}
                  className={"pc_medium"}
                />
                <SideMenu
                  id="signinotp_signup_sidemenu_parent"
                  open={this.state.sidebar}
                  direction="right"
                  width={435}
                  color="default"
                  onClose={() => this.toggleSideBar(false)}
                >
                  {this.state.searchMenber ? (
                    <Addmember
                      parent_id={"signinotp"}
                      name={"Sign Up"}
                      nextSection={(member, v, l) =>
                        this.nextSection(member, v, l)
                      }
                      handelChangeSelect={(v) => this.handelChangeSelect(v)}
                      signUpData={this.state.signUpData}
                      chooseSearch={this.state.chooseSearch}
                    />
                  ) : this.state.memberForm ? (
                    <Addmemberform
                      parent_id={"signinotp"}
                      signUpData={this.state.signUpData}
                      nextSection={(member) => this.nextSection(member)}
                    />
                  ) : this.state.memberOtp ? (
                    <Addmemberotp
                      parent_id={"signinotp"}
                      addmmber={this.state.addmmber}
                      signUpData={this.state.signUpData}
                      nextSection={(member, v) => this.nextSection(member, v)}
                    />
                  ) : null}
                  {this.state.memberSuccessPage ? (
                    <AppointmentAddMemberSuccessPage
                      parent_id={"signinotp"}
                      addmmber={this.state.addmmber}
                      signUpData={this.state.signUpData}
                      nextSection={(member, v, l) =>
                        this.nextSection(member, v, l)
                      }
                      toggleSideBar={(member) => this.toggleSideBar(member)}
                    />
                  ) : null}
                </SideMenu>

                <Div
                  id="signinotp_send_otp_button_below_divider_div"
                  key={"6"}
                  inLineStyles="[object Object]"
                  className="qdm"
                  style={{
                    marginTop: "24px",
                    marginBottom: "24px",
                    display: "flex",
                    height: "",
                  }}
                >
                  <Divider
                    id="signinotp_send_otp_button_below_divider_left"
                    key={"0"}
                    className=""
                    label=""
                    borderColor=""
                    textColor=""
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{ width: "130px" }}
                  />

                  <Text
                    id="signinotp_send_otp_button_below_divider_center_or"
                    key={"1"}
                    name="Or"
                    style={{
                      color: UIColor.secondaryText.color,
                      width: "28px",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      backgroundColor: "#ffffff",
                      fontSize: 14,
                      marginTop: "4px",
                    }}
                    className={"pc_regular"}
                  />

                  <Divider
                    id="signinotp_send_otp_button_below_divider_right"
                    key={"2"}
                    className=""
                    label=""
                    borderColor=""
                    textColor=""
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{ width: "130px" }}
                  />
                </Div>

                <Button
                  id="signinotp_proceed_as_guest_button"
                  onClick={() => this.props.history.push(Routes.signUp)}
                  key={"7"}
                  type="button"
                  variant="text"
                  name="Proceed as Guest"
                  style={{
                    width: "100%",
                    borderColor: UIColor.primaryColor.color,
                    borderRadius: "8px",
                    color: UIColor.primaryColor.color,
                    fontSize: "14px",
                    height: "40px",
                  }}
                  className={"pc_medium"}
                />

                <Div
                  id="signinotp_dont_have_an_account_div"
                  key={"8"}
                  inLineStyles="[object Object]"
                  className="qdm"
                  style={{
                    marginTop: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    color: UIColor.primaryColor.color,
                  }}
                >
                  <Text
                    id="signinotp_dont_have_an_account_text"
                    key={"0"}
                    name="Don't have an account?"
                    style={{ color: UIColor.secondaryText.color, fontSize: "12px" }}
                    className={"pc_regular"}
                  />

                  <Link
                    id="signinotp_dont_have_an_account_link"
                    key={"1"}
                    underline="hide"
                    color="primary"
                    name="Sign Up"
                    onClick={() => this.toggleSideBar(true)}
                    style={{
                      color: UIColor.secondaryButtonColor.color,
                      marginBottom: "0px",
                    }}
                    className={"pc_regular"}
                  />
                </Div>
              </Paper>
            </BackgroundImage>
          </Col>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  signIn: state?.authSlice?.signIn,
  resendOTP: state?.authSlice?.resendOTP,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
});

export default withStyles(styles)(connect(mapStateToProps, actions)(withRouter(SignInOTP)));
