import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Modal, Div, Icons } from 'qdm-component-library'

class ModelComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const { open, childrens, onClose, hiddencloseIcon } = this.props
    return (
      <div>
        <Modal
          open={open}
          {...this.props}
          onClose={onClose}
          inLineStyles={{ borderRadius: 16, ...this.props?.inLineStyles }}
        >
          {!hiddencloseIcon && (
            <Div
              style={{
                position: 'absolute',
                right: '-30px',
                background: '#fff',
                padding: '0px 4px',
                borderRadius: '3px',
                cursor: 'pointer'
              }}
              onClick={() => onClose()}
            >
              <Icons fontIcon='close' style={{ color: '#a2a2a2' }}></Icons>
            </Div>
          )}
          {childrens && childrens}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, actions)(withRouter(ModelComponent))
