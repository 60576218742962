import React,{ useContext } from 'react';
import Button from '@material-ui/core/Button';
import GoogleIcon from "../../assets/img/google.png";
import moment from 'moment';
import { AlertProps, fetchUserInfo } from "../../utils";
import { AlertContext } from "../../contexts";

export const GoogleCalander = (props) => {
    const alertCtx = useContext(AlertContext);
    var gapi = window.gapi;
    /* 
      Update with your own Client Id and Api key 
    */
    // my gmail   
    // var CLIENT_ID = "612837925934-k0ifg8s7mrjndelllr113sculdp9c8qi.apps.googleusercontent.com";
    // var API_KEY = "AIzaSyCWjlN3u1F3zRyrPubCDhP87Itx0ZLxjiU";

    // client gmail
    var CLIENT_ID = "936492637687-f334fv5c7fbt0n3gv4gmed53e1bs65h7.apps.googleusercontent.com";
    var API_KEY = "AIzaSyBTMTlvl67te19WUxanF9ub8tnd-KTXftg";

    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
    var SCOPES = "https://www.googleapis.com/auth/calendar.events";
    
    const handleClick = () => {
      debugger;
      gapi.load('client:auth2', () => {
        console.log('loaded client')
        debugger;
        gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        debugger;
        gapi.client.load('calendar', 'v1', () => console.log('bam!'))
        debugger;
        gapi.auth2.getAuthInstance().signIn()
        .then(() => {
          debugger;
          var event = {
            'summary': props.appointmentData.appointmentInfo.consultationMode+" Appointment Number "+props.appointmentData.appointmentInfo.appno,
            'location': props.appointmentData.appointmentInfo?.orgid?.name,
            'description': props.appointmentData.appointmentInfo.resourcecode.name[0].prefix.coding[0].display+"."+props.appointmentData.appointmentInfo.resourcecode.name[0].text+" "+props.appointmentData.appointmentInfo.resourcecode.name[0].given+" "+props.appointmentData.appointmentInfo.resourcecode.name[0].suffix.coding[0].display,
            'start': {
              'dateTime': moment(props.appointmentData.appointmentInfo.Slots[0].start * 1000).format("YYYY-MM-DDTHH:mm:06+05:30"),
              'timeZone': 'Asia/Calcutta'
            },
            'end': {
              'dateTime': moment(props.appointmentData.appointmentInfo.Slots[0].end * 1000).format("YYYY-MM-DDTHH:mm:06+05:30"),
              'timeZone': 'Asia/Calcutta'
            },
            'recurrence': [
              'RRULE:FREQ=DAILY;COUNT=1'
            ],
            'reminders': {
              'useDefault': false,
              'overrides': [
                {'method': 'email', 'minutes': 24 * 60},
                {'method': 'popup', 'minutes': 10}
              ]
            }
          }
  
          var request = gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'resource': event,
          })
  
          request.execute(event => {
            debugger;
            console.log(event)
            alertCtx.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg: "Appointment is added to calendar",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
            // window.open(event.htmlLink)
          })
  
        })
      })
  
      
    }
  
  
    return (
      <div className="App">
          <Button variant="outlined" onClick={handleClick} style={{fontSize: 14,textTransform: "none"}}><img src={GoogleIcon} style={{width:20,height:20,marginRight:8}}/>Add to Google Calendar</Button>
      </div>
    );
  }