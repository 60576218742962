import React from 'react';
import {Div} from 'qdm-component-library'
export const ErrorMessage = (props) =>{
    return(
        <Div
            id={`${props?.parentId}_error_div`}
             style={{
                fontSize: "12px",
                color: "#FF4D4A",
                marginBottom: 10,
                ...props.style
            }}
            className={"pc_regular"}
        >
        {props?.label}
    </Div>
    )
}

ErrorMessage.defaultProps={
    label:'',
    parentId:'',
    style:{}
}