import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

import AVCBloodPressure from "../../../assets/img/avc_blood_pressure.svg";
import AvcBreath from "../../../assets/img/avc_breath.svg";
import AvcCholesterol from "../../../assets/img/avc_cholesterol.svg";
import AvcFever from "../../../assets/img/avc_fever.svg";
import AvcHeadC from "../../../assets/img/avc_head_c.svg";
import AvcOxi from "../../../assets/img/avc_oxi.svg";
import AvcPluse from "../../../assets/img/avc_pluse.svg";
import AvcSugar from "../../../assets/img/avc_sugar.svg";
import AvcWaist from "../../../assets/img/avc_waist.svg";
import AvcWeight from "../../../assets/img/avc_weight.svg";
import {FormVitels} from "./formVitels";
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F2F4FC",
	},
    divFirst:{
        padding: "12px 18px",
        borderRadius: 8
    },
    title: {
        fontSize: 16,
        fontWeight: "600",
    },
    dis: {
        color: "#6DBAE4",
        fontSize: 13.3
    },
    keyDiv: {
        backgroundColor:"#fff",
        padding: "2px 6px",
        marginLeft: "1%",
        marginRight: "1%",
        marginBottom: "1%"
    },
    rangeDiv:{
        fontSize:12,
        marginTop: 3,
        alignItems: "baseline",
    },
    rangeValDiv: {
        width:"88%",
        lineHeight:2.2
    }
}));

export const DetailSection = (props) => {
	const classes = useStyles();
    let JsonData = props?.data?.filter((val)=> val?.shotName === props?.vitalsSelect)
	return (
			<Grid item xs={12}>
				<Grid container xs={12} sm={12} className={classes.divFirst} style={{backgroundColor:JsonData[0].color}}>
                    <Grid item xs={1}>
                        <img
                            src={
                            props?.vitalsSelect === "Temp"
                            ? AvcFever
                            : props?.vitalsSelect === "Weight Height"
                            ? AvcWeight
                            : props?.vitalsSelect === "Cholesterol"
                            ? AvcCholesterol
                            : props?.vitalsSelect === "Head"
                            ? AvcHeadC
                            : props?.vitalsSelect === "Pressure"
                            ? AVCBloodPressure
                            : props?.vitalsSelect === "Respiratory"
                            ? AvcBreath
                            : props?.vitalsSelect === "Sugar"
                            ? AvcSugar
                            : props?.vitalsSelect === "Oxygen"
                            ? AvcOxi
                            : props?.vitalsSelect === "Waist"
                            ? AvcWaist
                            : props?.vitalsSelect === "Pulse"
                            ? AvcPluse
                            : AvcOxi
                            }
                            alt={"vitals"}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <span className={classes.title}>Enter {JsonData[0]?.fullName}</span><br/>
                        <span className={classes.dis}>{JsonData[0]?.discretion}</span><br />
                        <Grid item xs={12}>
                            <Grid xs={12} sm={12} container className={classes.rangeDiv}>
                                <Grid item style={{width:"12%"}}>
                                    Normal Range
                                </Grid>
                                <Grid item className={classes.rangeValDiv}>
                                {
                                    JsonData[0]?.range.map((val,i)=> {
                                        return(
                                            <>
                                                <span className={classes.keyDiv}>
                                                    {val.key}
                                                </span>
                                                {val.range}
                                                {(i+1) % 2 === 0 ? <br/> : null}
                                            </> 
                                        )
                                    })
                                }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
				</Grid>
                <FormVitels data={JsonData}/>
			</Grid>
	);
}
