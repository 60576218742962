import * as React from "react";

export const AvcFullScreen = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="15.158"
			viewBox="0 0 16 15.158"
		>
			<path
				id="icons8-advertisement-page"
				d="M7.316,6A2.32,2.32,0,0,0,5.1,7.627s0,0,0,0a2.3,2.3,0,0,0-.092.451s0,0,0,0A2.338,2.338,0,0,0,5,8.316V18.842c0,.075,0,.148.011.22l1.252-1.252v-7.6H19.737v8.632a1.054,1.054,0,0,1-1.053,1.053H8.347L7.1,21.146c.073.007.146.011.22.011H18.684A2.319,2.319,0,0,0,21,18.842V8.316a2.334,2.334,0,0,0-.1-.687s0,0,0,0A2.32,2.32,0,0,0,18.684,6Zm.849,7.579a.632.632,0,1,0,0,1.263h2.258L5.185,20.08a.632.632,0,1,0,.893.893l5.238-5.238V18a.632.632,0,1,0,1.263,0V14.211a.632.632,0,0,0-.632-.632Z"
				transform="translate(-5 -6)"
				fill={props.isactive ? "#0071F2" : "#FFF"}
			/>
		</svg>
	);
};
