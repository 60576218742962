import { makeStyles } from "@material-ui/core";
import React from "react";
import { InfoApp } from "../infoApp";
import Grid from "@material-ui/core/Grid";
import AvcTicket from "../../../assets/img/avc_ticket.svg";
import { ProgressBar } from "../../../components";
import { JSON } from "./json";
import { withAllContexts } from "../../../HOCs";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#fff",
		height: "100%",
		marginRight: "10px",
		padding: 16,
	},
	infoText: {
		fontSize: 10,
		color: "#B6B6B6",
		fontWeight: "600",
	},
	ticket: {
		color: "#FF9F40",
		fontSize: 11,
		backgroundColor: "#ff9f4021",
		padding: "8px 10px",
		borderRadius: 4,
		fontWeight: 600,
		marginTop: 8,
	},
	ticketImg: {
		width: 16,
		height: 9,
		marginRight: 8,
	},
	borderTop: {
		borderTop: "1px solid #F0F0F0",
	},
	progressBarComp: {
		height: "64vh",
		overflow: "auto",
		paddingBottom: 20,
		'& .progressBar':{
			'&:first-child':{
				'& .iconButtonAvatar':{
					'&::before':{
						// top:34,
						display:'none'
					},
				}
			}
		}
	},
}));

function ProgressSideNav(props) {
	const classes = useStyles();
	const { loading, data } = useSelector(
		(state) => state?.avcMeetApiSlice?.avc_info
	);
	const { avcShowList, setAVCShowList } = props?.AVCShowList;

	return (
		<div
			className={classes.root}
			style={props.sidekick ? { minHeight: "114vh" } : {}}
		>
			<InfoApp sidekick={props.sidekick} funSideKick={props.funSideKick} />
			<Grid
				container
				className={classes.borderTop}
				style={{ marginTop: 20, marginBottom: 20 }}
			>
				<Grid item xs={12} style={{ marginTop: 12 }}>
					<span className={classes.infoText}>PROGRESS</span>
				</Grid>
				<Grid
					item
					xs={12}
					className={classes.ticket}
					alignItems={"center"}
					justify="center"
				>
					<img
						src={AvcTicket}
						className={classes.ticketImg}
						alt={"AvcTicket"}
					/>{" "}
					{props.sidekick
						? data?.dataJson?.id ?? 0
						: `YOUR TOKEN NUMBER : ${data?.dataJson?.id ?? 0}`}
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				className={classes.progressBarComp}
				style={props.sidekick ? { minHeight: "72vh" } : {}}
			>
				{avcShowList?.avcList?.roles?.map((val, i) => {
					return <div className="progressBar">
							<ProgressBar 
								sidekick={props.sidekick}
								data={val}
								activeStep={val?.child?.findIndex((val, i) => val?.active)}
							/>
						</div>;
				})}
			</Grid>
		</div>
	);
}

export default withAllContexts(ProgressSideNav);
