import React from "react";
import { Div, Paper, Select, TextInput, H6, Col, Row, Text, Avatar, Divider } from 'qdm-component-library'
import "./style.css"
class PatientNames extends React.Component {
    render() {
        return <Div className="pi-root">
            <Row className={"pn-row-root"}>
                <Col className="pn-image-root">
                    <Paper className="pi-paper pn-image-paper-root">
                        <Avatar variant="square" className={"pn-image"}>K</Avatar>
                    </Paper>
                </Col>
                <Col className="pn-names-root">
                    <Paper className="pi-paper">
                        <Div>
                            <H6 className="pi-title">PATIENT NAMES</H6>
                            <Text className="pi-addbtn">+ Add New</Text>
                        </Div>
                        <Row className="pi-actions-root">
                            <Col md={1} lg={1} sm={6} xs={12}>
                                <Select label="use" placeholder="select..." />
                            </Col>
                            <Col md={1} lg={1} sm={6} xs={12}>
                                <Select label="Prefix" placeholder="select..." />
                            </Col>
                            <Col md={2} lg={2} sm={6} xs={12}>
                                <TextInput label="First Name" />
                            </Col>
                            <Col md={2} lg={2} sm={6} xs={12}>
                                <TextInput label="Middle Name" />
                            </Col>
                            <Col md={2} lg={2} sm={6} xs={12}>
                                <TextInput label="Last Name" />
                            </Col>
                            <Col md={2} lg={2} sm={6} xs={12}>
                                <TextInput label="Surfix" />
                            </Col>
                            <Col md={2} lg={2} sm={6} xs={12}>
                                <i className="fa fa-trash-o" aria-hidden="true"></i>
                                <Text>Show +1 </Text>
                            </Col>
                        </Row>
                        <Divider />
                    </Paper>
                </Col>
            </Row>
        </Div>

    }
}


export default PatientNames;