const JSONArr = {
	result: [
		{
			title: "Your Body Temperature",
			shotName: "Temp",
			fullName: "Temperature",
			value: "100.0",
			normal: false,
			notable: false,
			discretion: "Measure temperature using thermometer and update the value in the below input field",
			range: [
				{
					key: "Temperature",
					range: "97.60 °F - 98.88 °F",
					unit: "F",
				}
			],
			options:[
				{
					bodySite:[
						{title:"Forehead", value:"forehead"},
						{title:"Ear", value:"ear"},
						{title:"Mouth", value:"mouth"},
						{title:"Armpit", value:"armpit"},
					],
					postion:[
						{title:"Standing", value:"standing"},
						{title:"Laying", value:"laying"},
					]
				}
			],
			color: "#ECF9FF"
		},
		{
			title: "Your Body Weight and Height",
			shotName: "Weight Height",
			fullName: "Weight And Height",
			value: "70",
			normal: true,
			notable: false,
			discretion: "Measure weight and height using weight machine, stadiometer / measuring tape and update the value in the below input field",
			range: [
				{
					key: "Weight",
					range: "1 kg - 300 kg",
					unit: "KG",
				},
				{
					key: "Height",
					range: "30 cm - 250 cm",
					unit: "CM",
				}
			],
			color:"#FFF4EE",
			options:[]
		},
		{
			title: "Your Cholesterol Level",
			shotName: "Cholesterol",
			fullName: "Cholesterol Level",
			value: "200",
			unit: "Range",
			normal: true,
			notable: true,
			discretion: "Measure waist using measuring tape and update the value in the below input field",
			range: [
				{
					key: "Total cholesterol level",
					range: "200 mg/dL - 239 mg/dL",
					unit: "mg/dL"
				},
				{
					key: "LDL cholesterol level",
					range: "80 mg/dL - 100 mg/dL",
					unit: "mg/dL"
				},
				{
					key: "HDL cholesterol level",
					range: "60 mg/dL - 80 mg/dL",
					unit: "mg/dL"
				},
				{
					key: "Triglycerides",
					range: "120 mg/dL - 150 mg/dL",
					unit: "mg/dL"
				}
			],
			color:"#FFFAEE",
			options:[]
		},
		{
			title: "Your Head Circumference",
			shotName: "Head",
			fullName: "Head Circumference",
			value: "10",
			unit: "Inches",
			normal: false,
			notable: false,
			discretion: "Measure Head Circumference using measuring tape and update the value in the below input field",
			range: [
				{
					key: "Head Circumference",
					range: "20 cm - 50 cm",
					unit: "cm"
				}
			],
			color:"#EBF9FE",
			options:[]
		},
		{
			title: "Your Blood Pressure",
			shotName: "Pressure",
			fullName: "Blood Pressure",
			value: "",
			unit: "SYS/DIA",
			normal: true,
			notable: false,
			discretion: "Measure blood pressure using sphygmomanometer (blood pressure cuff) and update the value.",
			range: [
				{
					key: "Low BP",
					range: "10 mmHg - 120 mmHg",
					unit: "mmHg",
				},
				{
					key: "High BP",
					range: "80 mmHg - 120 mmHg",
					unit: "mmHg",
				}
			],
			color:"#FAF2FF",
			options:[]
		},
		{
			title: "Your Respiratory Rate",
			shotName: "Respiratory",
			fullName: "Respiratory Rate",
			value: "60",
			unit: "MIN",
			normal: true,
			notable: false,
			discretion: "Measure pulse rate using pulse oximeter and update the value in the below input field.",
			range: [
				{
					key: "Pulse",
					range: "110 mins - 140 mins",
					unit: "mins",
				},
			],
			color:"#FFF5EC",
			options:[]
		},
		{
			title: "Your Blood Sugar Level",
			shotName: "Sugar",
			fullName: "Blood Sugar",
			value: "100.0",
			unit: "",
			normal: true,
			notable: false,
			discretion: "Measure blood sugar using blood glucose meter and update the value in the below input field.",
			range: [
				{
					key: "Blood Sugar",
					range: "70 mg/dL - 180 mg/dL",
					unit: "mg/dL"
				}
			],
			options:[
				{
					testTaken:[
						{title:"Before Meal (Fasting)", value:"before meal (fasting)"},
						{title:"After Meal (1 -2 hrs)", value:"After Meal (1 -2 hrs)"},
						{title:"Before Exercise", value:"Before Exercise"},
						{title:"Bedtime", value:"Bedtime"},
					],
				}
			],
			color:"#F4FFF9"
		},
		{
			title: "Your Oxygen Saturation",
			shotName: "Oxygen",
			fullName: "Oxygen Saturation",
			value: "99",
			unit: "%",
			normal: true,
			notable: false,
			discretion: "Count down respiration for a minute and update the value in the below input field.",
			range: [
				{
					key: "Respiratory Count",
					range: "20 mins - 40 mins",
					unit: "mins"
				},
			],
			color:"#FAF2FF",
			options:[]
		},
		{
			title: "Your Waist Circumference",
			shotName: "Waist",
			fullName: "Waist Circumference",
			value: "32",
			unit: "Inches",
			normal: true,
			notable: false,
			discretion: "Measure waist using measuring tape and update the value in the below input field.",
			range: [
				{
					key: "Waist circumference",
					range: "10 cm - 102 cm",
					unit: "cm",
				}
			],
			color:"#FFF5FA",
			options:[]
		},
		{
			title: "Your Pulse Rate",
			shotName: "Pulse",
			fullName: "Pulse Rate",
			value: "80",
			unit: "P/Min",
			normal: true,
			notable: false,
			discretion: "Measure pulse rate using pulse oximeter and update the value in the below input field.",
			range: [
				{
					key: "Pulse",
					range: "110 mins - 140 mins",
					unit: "mins"
				},
			],
			color:"#F5F7FF",
			options:[]
		},
	],
};
export default JSONArr;
