import React from "react";

export const Eye = (props) => {
  let width = props.width ? props.width : "14";
  let height = props.height ? props.height : "10.18";
  let color = props.color ? props.color : "#6f6f6f";
  return (
    <svg 
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 10.18"
    >
        <defs>
            <style>{`.cls-7{fill:${color};}}`}</style>
        </defs>
      <path class="cls-7" d="M8,4C3.634,4,1.562,7.74,1.06,8.808a.66.66,0,0,0,0,.563C1.562,10.439,3.634,14.18,8,14.18c4.348,0,6.422-3.714,6.933-4.8a.686.686,0,0,0,0-.587C14.419,7.714,12.346,4,8,4ZM8,5.909A3.181,3.181,0,1,1,4.816,9.09,3.182,3.182,0,0,1,8,5.909ZM8,7.181A1.909,1.909,0,1,0,9.907,9.09,1.909,1.909,0,0,0,8,7.181Z" transform="translate(-0.997 -4)"/>
      </svg>
  );
};
