import Background from "../../../assets/img/supportCarousal1.svg";
import Background2 from "../../../assets/img/sc111.svg";
import Carousal0 from "../../../assets/img/supportCarousal.svg";
import Carousal0a from "../../../assets/img/supportCarousal0a.svg";

export const carousalData =  [
    {
      image: Carousal0,
      tiltle: "Title for flow here",
      subTile:
        "Description for the title will be displayed here in one or two lines",
      downHeader: [
        {
          header: "Heading here",
          subHeader:
            "Description for the above heading will be displayed here in one or two lines",
        },
        {
          header: "Heading here",
          subHeader:
            "Description for the above heading will be displayed here in one or two lines",
        },
        {
            header: "Heading here",
            subHeader:
              "Description for the above heading will be displayed here in one or two lines",
          },
          {
            header: "Heading here",
            subHeader:
              "Description for the above heading will be displayed here in one or two lines",
          },
          {
            header: "Heading here",
            subHeader:
              "Description for the above heading will be displayed here in one or two lines",
          },
      ],
    },
    {
      image: Carousal0a,
      tiltle: "Title for flow here",
      subTile:
        "Description for the title will be displayed here in one or two lines",
        downHeader: [
          {
            header: "Heading here",
            subHeader:
              "Description for the above heading will be displayed here in one or two lines",
          },
          {
            header: "Heading here",
            subHeader:
              "Description for the above heading will be displayed here in one or two lines",
          },
        ],
    },
    {
      image: Background,
      tiltle: "Title for flow here",
      subTile:
        "Description for the title will be displayed here in one or two lines",
        downHeader: [
          {
            header: "Heading here",
            subHeader:
              "Description for the above heading will be displayed here in one or two lines",
          },
          {
            header: "Heading here",
            subHeader:
              "Description for the above heading will be displayed here in one or two lines",
          },
        ],
    },
    {
      image: Background2,
      tiltle: "Title for flow here",
      subTile:
        "Description for the title will be displayed here in one or two lines",
        downHeader: [
          {
            header: "Heading here",
            subHeader:
              "Description for the above heading will be displayed here in one or two lines",
          },
          {
            header: "Heading here",
            subHeader:
              "Description for the above heading will be displayed here in one or two lines",
          },
        ],
    },
  ];