import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core";
import { Container, Col, Chip, Div, H6, TapSelect, Icons, Divider, Text } from 'qdm-component-library';
import { UIColor } from '../../utils';

export const handleSymptomsData = (data, props) => {

  if (data.type === 'Speciality') {
    const newData = [...props?.values?.select_spccialities, data];
    handleSymptomChange(newData, data.type, props);
  }
  else if (data?.type === "Doctor") {
    // const newData = [...props?.values?.select_doctors,data];
    handleSymptomChange([data], data.type, props); //single select
  }
  else {
    const newData = [...props?.values?.select_symptoms, data];
    handleSymptomChange(newData, data.type, props);
  }
  const searchData = [...props?.values?.select_symptomsSearch, data.value];
  props?.handlesearchActives && props.handlesearchActives('select_symptomsSearch', searchData)
}

const handleSymptomChange = async (arr, type, props) => {
  if (type === 'Speciality') {
    props.handlesearchActives('select_spccialities', arr);
  }
  else if (type === "Doctor") {
    props.handlesearchActives('select_doctors', arr);
  }
  else {
    const arrVal = arr.map(a => a.label);
    if (props.GET_ALL_SPECIALITY && props.GET_SPECIALITY) {
      if (arrVal.length > 0) {
        const spec = await props.GET_SPECIALITY({ speciality: arrVal });
        const notSpecialities = (props?.values?.allSymptomData || []).filter(val => {
          return val.type !== "Speciality"
        });
        const newSpecialities = props?.values?.select_spccialities?.filter?.(d => {
          const index = spec?.payload?.data?.findIndex?.(dd => dd.value === d.value)
          return index !== -1
        });
        const newSymptoms = [...(spec?.payload?.data || []), ...notSpecialities];
        props.handlesearchActives('select_spccialities', [...newSpecialities]);
        props.changeState("allSymptomData", newSymptoms);
      } else {
        props.handlesearchActives('select_spccialities', []);
        props.GET_ALL_SPECIALITY({ speciality: arrVal });
      }
    }
    props.handlesearchActives('select_symptoms', arr);
  }
}


const styles = (theme) => ({
  tapSelect: {
    '& .selectionDiv': {
      borderRadius: 8,
      margin: '0px 8px 8px 0px',
      borderColor: "#F0F0F0",
      '&:last-of-type': {
        marginRight: 0
      },
      '& span': {
        fontFamily: 'pc_regular',
        fontSize: 14,
        letterSpacing: -0.011
      },
    },
    '& .active': {
      '& span': {
        fontFamily: 'pc_medium !important',
      }
    }
  }
});

class SearchBySpeciality extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: false,
    }

  }

  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }




  handleSymptomremoval = (ele) => {
    this.props.ondeleteSpecialtySymptomsDiseaseDoctor(ele);
  }

  async componentDidMount() {
    // if (this?.props?.location?.state?.symptoms?.length > 0) {
    //   await this.handleSymptomChange(this.props.location.state.symptoms, 'symptom')
    // }
    // if (this?.props?.location?.state?.specialities?.length > 0) {
    //   await this.handleSymptomChange(this.props.location.state.specialities, 'symptom')
    // }
  }

  shouldComponentUpdate(props, state) {
    return true
  }

  giveMeBackground = (type) => {
    switch (type?.toLowerCase()) {
      case 'symptom':
        return '#38C20A1A'
      case 'doctor':
        return '#F58B001A'
      case 'speciality':
        return '#F0F0F0'
      default:
        return '#38C20A1A'
    }
  }

  giveMeColor = (type) => {
    switch (type?.toLowerCase()) {
      case 'symptom':
        return '#258007'
      case 'doctor':
        return '#F58B00'
      case 'speciality':
        return '#6F6F6F'
      default:
        return '#258007'
    }
  }

  render() {
    const {
      ondeleteallSpecialtySymptomsDiseaseDoctor,
      values,
      parent_id,
      classes
    } = this.props


    return (
      <div
        id={`${parent_id}_searchby_speciality_parent_div`}
      >
        {
          values?.symptomsSearch?.length > 0 ? (
            <div
              id={`${parent_id}_searchby_speciality_data_not_found_div`}
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              <div ref={this.props?.refs}
                id={`${parent_id}_searchby_speciality_data_not_found_sub_div`}
                className="search_symptoms_input"
                style={{
                  boxShadow: '0px 8px 23px #0000001A',
                  borderRadius: 8,
                  backgroundColor: '#fff',
                  width: 384,
                  padding: '10px',
                  maxHeight: 400,
                  overflow: 'auto'
                }}>
                {
                  values?.symptomsDataState?.length > 0 ? values?.symptomsDataState?.map((_, i) => {
                    return (
                      <Div
                        id={`${parent_id}_${i}_searchby_speciality_data_not_found_symptoms_sub_div`}
                        key={_?.value}
                        className="search_symptoms_input_options"
                      >
                        <Div
                          id={`${parent_id}_${i}_searchby_speciality_data_not_found_symptoms_sublevel_div`}
                          onClick={() => handleSymptomsData(_, this.props)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 5,
                            cursor: 'pointer'
                          }}>
                          <H6
                            id={`${parent_id}_${i}_searchby_speciality_data_not_found_symptoms_sublevel_h6`}
                            className="pc_regular"
                            style={{
                              margin: 0,
                              flex: 1,
                              color: '#000000',
                              fontSize: 14,
                              letterSpacing: 0
                            }}>{`${_?.label} ${_?.speciality ? ` | ${_?.speciality}` : ''}`}</H6>
                          <Div
                            id={`${parent_id}_${i}_searchby_speciality_data_not_found_symptoms_text_div`}
                            style={{
                              borderRadius: 4,
                              backgroundColor: this.giveMeBackground(_?.type),
                              padding: '5px 10px',
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                            <Text
                              id={`${parent_id}_${i}_searchby_speciality_data_not_found_symptoms_text`}
                              className="pc_medium"
                              style={{
                                color: this.giveMeColor(_?.type),
                                fontSize: 10,
                                letterSpacing: 0
                              }}>{_?.type}</Text>
                          </Div>
                        </Div>
                        {
                          !(i === values?.symptomsDataState?.length - 1) &&
                          <Divider
                            id={`${parent_id}_${i}_searchby_speciality_data_not_found_divider`}
                            style={{
                              margin: 0,
                              border: '1px solid #F9F9F9',
                            }} />
                        }
                      </Div>
                    )
                  }) : (
                    <Div
                      id={`${parent_id}_searchby_speciality_data_not_found_label_div`}
                    >
                      <H6
                        id={`${parent_id}_searchby_speciality_data_not_found_label_h6`}
                        style={{
                          margin: 0,
                          textAlign: 'center',
                          color: '#000000',
                          fontSize: 14,
                          fontWeight: '600'
                        }}>No Data Found</H6>
                    </Div>
                  )
                }
              </div>
            </div>
          ) : (
            <Container
              id={`${parent_id}_searchby_speciality_parent_container`}
              key={'0'}
              name='container'
              fluid={false}
            >
              <div
                id={`${parent_id}_searchby_speciality_clear_all_div`}
                style={{
                  padding: '16px',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  maxHeight: 400,
                  overflow: 'auto'
                }}
                ref={this.props?.refs}>
                {[...values.select_symptoms, ...values.select_spccialities, ...values.select_doctors]?.length !== 0 &&
                  <Col
                    id={`${parent_id}_searchby_speciality_clear_all_col`}
                    key={'0'}
                    name='qdm-col-12'
                    alignItems='stretch'
                    direction='row'
                    justify='flex-start'
                    textAlign='left'
                    lg='12'
                    xs='12'
                    md='12'
                    sm='12'
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      padding: '',
                      paddingTop: '',
                      padding: "0px",
                      marginBottom: 16,
                      flexWrap: 'wrap'
                    }}
                  >
                    {/* QUICK SUGGESTION selected datas */}
                    {[...values.select_symptoms, ...values.select_spccialities, ...values.select_doctors]?.map(
                      (_c, i) => {
                        return (
                          <Chip
                            id={`${parent_id}_${i}_searchby_speciality_quick_suggestion_chip`}
                            className="pc_regular"
                            onClick={() => this.handleSymptomremoval(_c)}
                            key={i}
                            variant='circle'
                            name={_c?.label}
                            style={{
                              borderColor: '#fff',
                              color: '#01205C',
                              backgroundColor: "#F1F6FC",
                              borderRadius: '8px',
                              fontSize: 14,
                              padding: '2px 12px',
                              marginRight: 8,
                              marginBottom: 8
                            }}
                            closeIcon={
                              <Icons
                                id={`${parent_id}_${i}_searchby_speciality_quick_suggestion_close_icon`}
                                key={'1'}
                                fontIcon='close'
                                ariaHidden='true'
                                className=''
                                size='small'
                                style={{
                                  fontWeight: 100,
                                  "-webkitTextStroke": "1px white",
                                  transform: "scale(1) translate(0px, 0px)",
                                }}
                              ></Icons>
                            }
                          ></Chip>
                        )
                      }
                    )}

                    {/* Clear All text */}
                    {[...values.select_symptoms, ...values.select_spccialities, ...values.select_doctors]
                      ?.length > 0 && (
                        <Div
                          id={`${parent_id}_searchby_speciality_parent_label_div`}
                          key={'2'}
                          onClick={() => {
                            ondeleteallSpecialtySymptomsDiseaseDoctor()
                          }}
                        >
                          <H6
                            id={`${parent_id}_searchby_speciality_clear_all_label_h6`}
                            className="pc_regular"
                            key={'0'}
                            name='Clear All'
                            style={{
                              color: '#EC6A49',
                              fontSize: '14px',
                              cursor: 'pointer',
                              letterSpacing: 0,
                              marginLeft: 15,
                              marginBottom: 8,
                            }}
                          ></H6>
                        </Div>
                      )}
                    {/* end */}
                  </Col>
                }
                <H6
                  id={`${parent_id}_searchby_speciality_quick_suggestion_label_h6`}
                  className="pc_medium"
                  key={'1'}
                  name='QUICK SUGGESTION'
                  style={{
                    color: '#6F6F6F',
                    fontSize: '14px',
                    letterSpacing: 0,
                    lineHeight: 1
                  }}
                ></H6>

                <H6
                  id={`${parent_id}_searchby_speciality_quick_suggestion_label_symptoms_h6`}
                  className="pc_regular"
                  key={'2'}
                  name='SYMPTOMS'
                  style={{ color: '#6F6F6F', fontSize: '12px', letterSpacing: 0, lineHeight: 1, margin: '16px 0px 16px' }}
                ></H6>
                <TapSelect
                  id={`${parent_id}_searchby_speciality_quick_suggestion_symptoms_tapselect`}
                  defaultSelected={values.select_symptoms}
                  key={'3'}
                  type='multiple'
                  fullWidth={false}
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  textColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor="#fff"
                  textSize='16'
                  iconHeight='20'
                  iconWidth='20'
                  label=''
                  onChange={(arr) => handleSymptomChange(arr, 'symptom', this.props)}
                  options={
                    this.props.symptoms?.loading || this.props.symptoms?.error
                      ? []
                      : this.props.symptoms?.data
                  }
                  // inLineStyles={{ borderColor: "#E0E0E0", color: "#6f6f6f", fontFamily: "'pc_regular' !important", fontSize: 14 }}
                  className={classes.tapSelect}
                ></TapSelect>

                <H6
                  id={`${parent_id}_searchby_speciality_quick_suggestion_specialities_label_h6`}
                  key={'4'}
                  className="pc_regular"
                  name={true || this.props.speciality?.data.length > 0 ? 'SPECIALTIES' : ''}
                  style={{ color: '#6F6F6F', fontSize: '12px', letterSpacing: 0, lineHeight: 1, margin: '8px 0px 16px' }}
                ></H6>
                <TapSelect
                  id={`${parent_id}_searchby_speciality_quick_suggestion_symptoms_specialities_tapselect`}
                  key={'5'}
                  defaultSelected={values.select_spccialities}
                  type='multiple'
                  fullWidth={false}
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  textColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor="#fff"
                  textSize='16'
                  iconHeight='20'
                  iconWidth='20'
                  label=''
                  onChange={arr => handleSymptomChange(arr, "Speciality", this.props)}
                  options={
                    this.props.speciality?.loading || this.props.speciality?.error
                      ? []
                      : this.props.speciality?.data
                  }
                  // inLineStyles={{ borderColor: "#E0E0E0", color: "#6f6f6f", fontFamily: "'pc_regular' !important", fontSize: 14 }}
                  className={classes.tapSelect}
                ></TapSelect>
              </div>
            </Container>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  symptoms: state?.searhcApiSlice?.symptoms,
  speciality: state?.searhcApiSlice?.speciality,
})

export default withStyles(styles)(connect(mapStateToProps, actions)(withRouter(SearchBySpeciality)));
