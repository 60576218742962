import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { FrontOffice } from "../../../assets/img/avc_frontOffice";
import { NurseStation } from "../../../assets/img/nurseStation";
import { Consultation } from "../../../assets/img/Consultation";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	root1:props=>({
		marginTop: "8%",
		padding: 0,
		overflow:'visible',
		"& .MuiCardActions-root": {
			padding: 0,
		},
		"& .MuiStepConnector-vertical": {
			padding: "0 0 0",
			marginLeft: "0.21em",
		},
		"& .MuiStepLabel-label.MuiStepLabel-completed": {
			color: "#4BC0C0",
		},
		'& .MuiStepLabel-iconContainer':{
			paddingRight:props.sidekick ? 0 : 8
		},
		"& .MuiStepLabel-active": {
			color: "#4BC0C0",
		},
	}),
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	title: {
		fontWeight: 600,
		fontSize: 12,
	},
	subtitle: {
		color: "#B6B6B6",
		fontSize: 10,
	},
	root: {
		width: "100%",
		"& .MuiStepper-root": {
			padding: "16px 14px",
			paddingBottom: 0,
		},
		"& .MuiStepLabel-label": {
			fontSize: 12,
		},
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	sidekick_S: {
		justifyContent: "center",
		alignItems: "center",
		display: "grid",
	},
	iconInactive:props=>({
		'&::before':{
			content:"' '",
			display: 'block',
			height:20,
			width:1,
			backgroundColor:props?.data?.vist ? '#4BC0C0' : '#eaeaf0',
			position:'absolute',
			bottom:34,
			left:17,		
		},
		'& .MuiIconButton-label':{
			zIndex:1
		}
	}),
	iconActive:{
		'&::after':{
			content:"' '",
			display: 'block',
			height:20,
			width:1,
			backgroundColor:'#4BC0C0',
			position:'absolute',
			top:34,
			left:17,
			
		},
	}
}));
const QontoConnector = withStyles({
	alternativeLabel: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	active: {
		"& $line": {
			borderColor: "#4BC0C0",
			marginLeft: 2,
		},
	},
	completed: {
		"& $line": {
			borderColor: "#4BC0C0",
			marginLeft: 2,
		},
	},
	line: {
		borderColor: "#eaeaf0",
		borderTopWidth: 3,
		borderRadius: 1,
	},
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
	root: {
		color: "#eaeaf0",
		display: "flex",
		height: 22,
		alignItems: "center",
	},
	active: {
		color: "#4BC0C0",
	},
	circle: {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor",
	},
	completed: {
		color: "#4BC0C0",
		zIndex: 1,
		fontSize: 12,
	},
});

function QontoStepIcon(props) {
	const classes = useQontoStepIconStyles();
	const { active, completed } = props;

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
			})}
		>
			{completed ? (
				<FiberManualRecordIcon className={classes.completed} />
			) : (
				<div className={classes.circle} />
			)}
		</div>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
};

export function ProgressBar(props) {
	const classes = useStyles(props);
	// const [expanded] = React.useState(props?.data?.active);
	// const [activeStep] = React.useState(
	// 	props?.data?.child?.findIndex((val, i) => val?.active)
	// );


	return (
		<Card
			className={
				props.sidekick
					? clsx(classes.root1, classes.sidekick_S)
					: clsx(classes.root1)
			}
			elevation={0}
		>
			<CardActions disableSpacing>
				<IconButton aria-label="add to favorites" style={{ padding: 0 }} className={`${props?.data?.active ? classes.iconActive : ''} ${classes.iconInactive} iconButtonAvatar`}>
					{props?.data?.name === "Front Office" ? (
						<FrontOffice
							active={props?.data?.active}
							vist={props?.data?.vist}
						/>
					) : props?.data?.name === "Nurse Station" ? (
						<NurseStation
							vist={props?.data?.vist}
							active={props?.data?.active}
						/>
					) : props?.data?.name === "Doctor Consultaion" ? (
						<Consultation
							vist={props?.data?.vist}
							active={props?.data?.active}
						/>
					) : (
						<Consultation
							vist={props?.data?.vist}
							active={props?.data?.active}
						/>
					)}
				</IconButton>
				{props.sidekick ? null : (
					<div>
						<Grid container style={{ paddingLeft: "4%" }}>
							<Grid
								item
								xs={12}
								className={classes.title}
								style={{
									color:
										props?.data?.active || props?.data?.vist
											? "#4BC0C0"
											: "#B6B6B6",
								}}
							>
								<span>{props?.data?.name}</span>
							</Grid>
							<Grid item xs={12} className={classes.subtitle}>
								<span>Receptionist</span>
							</Grid>
						</Grid>
						{/* <IconButton
							className={clsx(classes.expand, {
								[classes.expandOpen]: expanded,
							})}
							aria-expanded={expanded}
							aria-label="show more"
							style={{ padding: 0 }}
						>
							<ExpandMoreIcon style={{ color: "#d7d9e1" }} />
						</IconButton> */}
					</div>
				)}
			</CardActions>
			<Collapse in={props?.data?.active ?? false} timeout="auto" unmountOnExit>
				<div className={classes.root}>
					<Stepper
						activeStep={props?.activeStep}
						orientation="vertical"
						connector={<QontoConnector />}
					>
						{props?.data?.child.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={QontoStepIcon}>
									{props.sidekick ? null : label.name}
								</StepLabel>
							</Step>
						))}
					</Stepper>
				</div>
			</Collapse>
		</Card>
	);
}
