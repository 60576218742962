import React from "react";
import { Upload, Div, H6, Text, Divider, Image } from "qdm-component-library";

function UploadFiles() {
  const [images, setImages] = React.useState([]);
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const uploadFun = (e, data) => {
    let list = images;
    list.push(data);
    setImages(list);
    forceUpdate();
  };
  const getSize = (size) => {
    let fileSize = size / 1024 / 1024;
    if (fileSize < 1) {
      return `${parseInt(size / 1024) + 1} KB`;
    } else {
      return `${parseInt(fileSize)} MB`;
    }
  };
  return (
    <React.Fragment>
      <Div
        className={"up-parent"}
        id={"patientDashboard_topNav-parent-up-parent"}
      >
        <Upload
          handleChange={uploadFun}
          label="Upload Documents"
          labelStyle={{ fontSize: 14, fontFamily: "pc_medium !important" }}
          className={"up-btn"}
          showIcon={false}
          id={"patientDashboard_topNav-Upload_div"}
        />
      </Div>
      {images.map((val, i) => (
        <React.Fragment>
          {i !== 0 && (
            <Divider
              style={{ margin: "0px 10px" }}
              id={`${i}-patientDashboard_topNav-Upload_Divider`}
            />
          )}
          <Div
            className="up-list-parent"
            id={`${i}-patientDashboard_topNav-Upload_up-list-parent`}
          >
            <Div
              style={{ width: 22, height: 22 }}
              id={`${i}-patientDashboard_topNav-Upload_up-list-div`}
            >
              <Image
                src={"images/icons8-file.svg"}
                alt="file"
                id={`${i}-patientDashboard_topNav-Upload_up-list-img`}
              />
            </Div>
            <Div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginLeft: 10,
              }}
              id={`${i}-patientDashboard_topNav-Upload_up-list-name`}
            >
              <H6
                id={`${i}-patientDashboard_topNav-Upload_up-up-title-h6`}
                className="up-title"
              >
                {val.name}
              </H6>
              <Text
                id={`${i}-patientDashboard_topNav-Upload_up-up-title-Text`}
                className="up-size"
              >
                {getSize(val.size)}
              </Text>
            </Div>
            <Div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              id={`${i}-patientDashboard_topNav-Upload_up-up-title-image-div`}
            >
              <Image
                src="images/icons8-eye.svg"
                alt="view"
                style={{ cursor: "pointer", marginRight: 16 }}
                id={`${i}-patientDashboard_topNav-Upload_up-up-title-eye-image`}
              />
              <Image
                src="images/icons8-trash.svg"
                alt="view"
                style={{ cursor: "pointer" }}
                id={`${i}-patientDashboard_topNav-Upload_up-up-title-trash-image`}
              />
            </Div>
          </Div>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export default UploadFiles;
