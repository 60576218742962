import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import { Text, Div, Divider, Button } from "qdm-component-library";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Close from "../../assets/img/close.svg";
import Edit from "../../assets/img/edit.svg";
import { convertTime } from "../../utils";
const styles = (theme) => ({
  body: {
    padding: "0px 10px",
  },
  indexStyle: (props) => ({
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: props?.isError ? "#fff" : "#2A3C50",
    fontSize: 14,
    fontWeight: "600",
    backgroundColor: props?.isError ? "#FF4D4A" : "#F1F6FC",
    borderRadius: "0px 8px 8px 0px",
  }),
  flexRow: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #FF4D4A5E",
    borderRadius: "4px",
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "#FFEDEB",
    "& .flexColumn": {
      display: "flex",
      flexDirection: "column",
    },
  },
  infoIcon: {
    marginRight: 20,
    fontSize: 24,
    color: "#EA1601",
  },
});

class CollapseAppoinmentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props?.open ?? false,
    };
  }

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  componentDidMount() { }

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    const { classes, parent_id } = this.props;
    const {
      index = "",
      date = "",
      time = "",
      slot = "",
      showEdit = false,
      showDelete = false,
      isError = false,
      body = "",
      editFunction = null,
      deleteFunction = null,
    } = this.props;
    return (
      <Card
        id={`${parent_id}_collapse_appoinmnets_parent_card`}
        style={{
          marginBottom: 10,
          border: isError ? "0.5px solid #FF4D4A" : "0.5px solid #E0E0E0",
          boxShadow: "none",
        }}
      >
        <CardActions
          id={`${parent_id}_collapse_appoinmnets_parent_cardaction`}
          onClick={this.handleExpandClick}
          disableSpacing
          style={{
            cursor: "pointer",
            padding: "10px 0px",
            justifyContent: "space-between",
          }}
        >
          {index && <Div className={classes.indexStyle}>{index < 10 ? "0" +index : index}</Div>}
          {date && (
            <Text
              id={`${parent_id}_collapse_appoinmnets_covertime_text`}
              name={convertTime(date, "unix", "DD MMM YY ( ddd )")}
              style={{ fontSize: "14px", fontWeight: "600", color: "#101010" }}
              className={"pc_regular"}
            />
          )}

          <Divider
            id={`${parent_id}_collapse_appoinmnets_covertime_bottom_div`}
            orientation="vertical" inLineStyles={{ height: 17 }} />

          {time && (
            <Text
              id={`${parent_id}_collapse_appoinmnets_time_label_text`}
              name={time}
              style={{ fontSize: "14px", fontWeight: "600", color: "#101010" }}
              className={"pc_regular"}
            />
          )}

          <Divider
            id={`${parent_id}_collapse_appoinmnets_divider`}
            orientation="vertical" inLineStyles={{ height: 17 }} />

          {slot && (
            <Text
              id={`${parent_id}_collapse_appoinmnets_slot_text`}
              name={slot}
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: isError ? "#FF4D4A" : "#38C20A",
              }}
              className={"pc_regular"}
            />
          )}

          <Div
            id={`${parent_id}_collapse_appoinmnets_button_edit_div`}
            style={{ marginRight: "3%", display: "flex", alignItems: "center" }}
          >
            {showEdit && (
              <Button
                id={`${parent_id}_collapse_appoinmnets_edit_image_button`}
                inLineStyles={{
                  backgroundColor: "transparent",
                  border: 0,
                  padding: 0,
                  boxShadow: "none !important",
                }}
                onClick={this.handleExpandClick}
              >
                <img
                  id={`${parent_id}_collapse_appoinmnets_edit_image`}
                  src={Edit} alt="Edit" />
                {/* <Icons
                  fontIcon={"edit"}
                  ariaHidden={"true"}
                  size={"medium"}
                  
                ></Icons> */}
                &nbsp; &nbsp; &nbsp;
              </Button>
            )}
            {showDelete && (
              <Button
                id={`${parent_id}_collapse_appoinmnets_close_image_button`}
                inLineStyles={{
                  backgroundColor: "transparent",
                  border: 0,
                  padding: 0,
                  boxShadow: "none !important",
                }}
                onClick={(e) => {
                  deleteFunction && deleteFunction();
                  e.stopPropagation();
                }}
              >
                <img
                  id={`${parent_id}_collapse_appoinmnets_close_image`}
                  src={Close} alt="Close" />
              </Button>
            )}
          </Div>
        </CardActions>
        <Collapse
          id={`${parent_id}_collapse_appoinmnets_sub_parent_collapse`}
          className={classes.body}
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          {isError && (
            <Div
              id={`${parent_id}_collapse_appoinmnets_convertime_div`}
              className={classes.flexRow}>
              <InfoOutlinedIcon className={classes.infoIcon} />
              <Div
                id={`${parent_id}_collapse_appoinmnets_slot_convertime_div`}
                className="flexColumn">
                <Text
                  id={`${parent_id}_collapse_appoinmnets_slot_convertime_text`}
                  inLineStyles={{
                    color: "#EA1601",
                    fontSize: "12px",
                    fontWeight: "600",
                    marginBottom: "5px",
                  }}
                  className={"pc_regular"}
                >
                  {this.props.slot === "Slot Unavailable"
                    ? `${this.props.time} slot unavailable.`
                    : `There are no slots available on ${convertTime(
                      date,
                      "unix",
                      "DD MMM YY"
                    )}.`}
                </Text>
                <Text
                  id={`${parent_id}_collapse_appoinmnets_label_another_slot_text`}
                  inLineStyles={{
                    color: "#EA1601",
                    fontSize: "11px",
                    fontWeight: "600",
                  }}
                  className={"pc_regular"}
                >
                  Please choose another slot for this particular appointment.
                </Text>
              </Div>
            </Div>
          )}
          {body && body}
        </Collapse>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(CollapseAppoinmentCard))
);
