import React from "react";
import { Button, TextField, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { UIColor } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogTitle-root": {
      paddingBottom: 5,
    },
    "& .MuiDialog-paper": {
      minWidth: "400px",
    },
    "& .MuiTypography-h6": {
      fontWeight: "500",

      color: "#6F6F6F",
      fontSize: "14px",

      fontFamily: "pc_regular !important",
    },
  },
  text: {
    //backgroundColor: "red",
    // border: `1px solid ${UIColor.primaryText.color}`,
    // borderColor: UIColor.primaryText.color
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "5px",
        borderColor: UIColor.lineBorderFill.color
      },
      "&.Mui-focused fieldset": {
        borderColor: UIColor.primaryText.color,
        borderWidth: "1px"
      }
    }
  },
  btn: {
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    borderRadius: 8,
    padding: "5px 15px",
    minWidth: 100,
    fontSize: 12,
    color: "#fff",
    "&:hover": {
      backgroundColor: UIColor.primaryColor.color,
    },
  },
  btncancel: {
    backgroundColor: "#fff",
    borderColor: UIColor.primaryColor.color,
    borderRadius: 8,
    padding: "4px 15px",
    minWidth: 100,
    fontSize: 12,
    color: UIColor.primaryColor.color,
    border: "1px solid rgb(0, 113, 242)",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
}));

function EmailDialog(props) {
  const classes = useStyles();
  const handleClose = () => {
    props.handleClose();
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={}>
        Open alert dialog
      </Button> */}
      <Dialog
        className={classes.root}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><span style={{ color: UIColor.primaryText.color }}>{"Email Id"}</span></DialogTitle>
        <DialogContent>
          <TextField
            //autoFocus={true}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            type={props.key}
            id={props.id}
            variant={"outlined"}
            fullWidth={true}
            disabled={props.disabled}
            size="small"
            inputProps={{ maxLength: props.maxLength }}
            error={props?.error}
            helperText={props?.error && props?.helperText}
            multiline={props.multiline}
            rows={props.rowsMax}
            placeholder={props.placeholder}
            borderColor={UIColor.lineBorderFill.color}
            className={classes.text}
          // InputProps={{
          //   classes: {
          //     notchedOutline: classes.text
          //   }
          // }}
          />
        </DialogContent>
        <DialogActions style={{ padding: "10px 24px 20px 24px" }}>
          <Button
            className={classes.btncancel}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={classes.btn}
            onClick={() => props.handleSend()}
            color="primary"
          >
            {props.loading ? "Sending..." : "Send"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EmailDialog;
