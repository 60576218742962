import jwt from "jsonwebtoken";
import moment from "moment";
import Config from "../config";
import { Routes } from "../router/routes";
import { countryCode_ } from "./countryCode";
import { isValidPhoneNumber  } from "libphonenumber-js/mobile";

/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all helper functions.
 */

/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
	role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
	role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
	default: "default",
	dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
	vertical: {
		top: "top",
		bottom: "bottom",
	},
	horizontal: {
		left: "left",
		right: "right",
		center: "center",
	},
	severity: {
		success: "success",
		error: "error",
		warning: "warning",
		info: "info",
	},
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
	direction: {
		top: "top",
		bottom: "bottom",
		left: "left",
		right: "right",
	},
	variant: {
		permanent: "permanent",
		persistent: "persistent",
		temporary: "temporary",
	},
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
	authToken: "auth_token",
	version: "version",
	deviceToken: "device_token"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
	get: "GET",
	post: "POST",
	put: "PUT",
	delete: "DELETE",
	update: "UPDATE",
};

/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export let ConvertToReactSelect = (data, valueKey, labelKey) => {
	if (!data || !data?.length) {
		return [];
	}

	return data.map((val) => {
		return {
			...val,
			value: val[valueKey],
			label: val[labelKey],
		};
	});
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => resolve(null);
	});

export async function createFile(url, type, name) {
	let response = await fetch(url);
	let data = await response.blob();
	let metadata = {
		type: type,
	};
	let file = new File([data], `${name}.${type.split("/")[1]}`, metadata);
	let Url = ToBase64(file);
	return Url;
}

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string) => {
	if (!string) {
		return string;
	}
	return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex, opacity) => {
	if (hex) {
		let tempHex = hex.replace("#", "");
		let r = parseInt(tempHex.substring(0, 2), 16);
		let g = parseInt(tempHex.substring(2, 4), 16);
		let b = parseInt(tempHex.substring(4, 6), 16);

		return `rgba(${r},${g},${b},${opacity / 100})`;
	}
	return null;
};

/**
 * The below function will open an document node in a full screen.
 */
export let OpenFullScreen = (id) => {
	let elem = document.getElementById(id);
	if (elem.requestFullscreen) {
		elem.requestFullscreen();
	} else if (elem.mozRequestFullScreen) {
		/* Firefox */
		elem.mozRequestFullScreen();
	} else if (elem.webkitRequestFullscreen) {
		/* Chrome, Safari & Opera */
		elem.webkitRequestFullscreen();
	} else if (elem.msRequestFullscreen) {
		/* IE/Edge */
		elem.msRequestFullscreen();
	}
};

export const makeName = (nameObj = {}) => {
	const { prefix = "", given = "", text = "", suffix = "", family = "" } = nameObj;
	let prefixVal = prefix?.coding?.[0]?.display || "";
	let suffixVal = suffix?.coding?.[0]?.display || "";
	return `${prefixVal && prefixVal + "."}${text && text + " "}${
		given && given + " "
	}${family && family + " "}${suffixVal && suffixVal}`;
};

/**
 * The below function will close the full screen of an document
 * node.
 */
export let CloseFullScreen = (id) => {
	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen();
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen();
	} else if (document.msExitFullscreen) {
		document.msExitFullscreen();
	}
};

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
};

/**
 * The below function will check for request'a response error.
 */
export let checkError = (data) => {
	const isError = data?.error || data?.data?.error;
	let errMsg = "Something went wrong! Try Again.";
	if (
		data?.data?.error ||
		data?.data?.errorMessage ||
		data?.data?.error_description
	) {
		if (typeof data?.data?.error === "string") {
			errMsg =
				data?.data?.error ||
				data?.data?.errorMessage ||
				data?.data?.error_description;
		} else {
			errMsg =
				data?.data?.errorMessage ||
				data?.data?.error_description ||
				data?.data?.message ||
				errMsg;
		}
	}
	if (errMsg === "invalid_grant" || errMsg === "Invalid user credentials") {
		errMsg = "Invalid OTP";
	}
	return {
		isError: !!isError,
		errMsg,
	};
};

export async function fetchUserInfo(
	token,
	getInfoFn,
	getPersonInfo,
	isQuery = false
) {
	let data;
	let persondata;
	if (getInfoFn) {
		data = await getInfoFn({ token });
	} else {
		data = await this.props.GET_USER_INFO({ token });
	}
	const { isError } = checkError(data.payload);
	if (isError) {
		if (!isQuery) {
			localStorage.removeItem("query_token");
			localStorage.removeItem("token");
			window.location = "/";
		}
		return;
	}
	if (getPersonInfo) {
		persondata = await getPersonInfo({ phone: data.payload?.data?.mobile });
	} else {
		persondata = await this.props.READ_PERSON({
			phone: data.payload?.data?.mobile,
		});
	}
	localStorage.setItem("token", data.payload?.data?.newToken || token);
	const { id, name, username, email, imgUrl } = persondata?.payload?.data || {};
	return {
		name,
		email,
		mobile: username,
		id,
		img: getImgUrl(imgUrl),
		persondata,
		keyclockdata: data,
		type: "token",
	};
}

const getDayString = (type, start, end) => {
	let string = "";
  
	// var a = moment();
	// var b = moment.unix(end);
	var b = moment(moment().format('DD MMM YYYY'));
	var a = moment(moment.unix(end).format('DD MMM YYYY'));
  
	var years = a.diff(b, 'year');
	b.add(years, 'years');
  
	var months = a.diff(b, 'months');
	b.add(months, 'months');
  
	if(type === "weeks"){
	  var weeks = a.diff(b, 'weeks');
	  b.add(weeks, 'weeks');
	}
  
	var days = a.diff(b, 'days');
  
	if(type === "weeks"){
	  string = Math.abs(weeks) + " Week " + (Math.abs(days) ? Math.abs(days) + ' Day' : "");
	}
	if(type === "months"){
	  string = Math.abs(months) + " Month " + (Math.abs(days) ? Math.abs(days) + ' Day' : "");
	}
  
	return string
}

export const TimeStampToMnthandDays = (val) => {
  let day_ = val?.followupdurval ?? 0;
  let type_ = val?.followupduruom?.display?.toLocaleLowerCase() ?? "days";
  let date = moment
    .unix(val.followupdate)
    .startOf("day")
    .add(day_, type_)
    .unix();
  const CuuDate = moment().startOf("day");
  const para = moment.unix(date).startOf("day");
  const daysDiff = Math.abs(CuuDate.diff(para, "days"));
  const weeksDiff = Math.abs(CuuDate.diff(para, "weeks"));
  const monthDiff = Math.abs(CuuDate.diff(para, "months"));
  if (daysDiff >= 7) {
    if (weeksDiff > 4) {
    //   return monthDiff + " Months";
      return getDayString("months", CuuDate, date); 
    }
    // return weeksDiff + " Weeks";
	return getDayString("weeks", CuuDate, date);
  } else if (daysDiff === 0) {
    return "Today";
  } else {
    return daysDiff + " Days";
  }
};

export async function getUserInfo(
	info,
	setInfo,
	getInfoFn,
	getPersonInfo,
	guestInfo,
	updateState = false
) {
	if (
		!updateState &&
		((info && info.type) || this?.props?.loggedUserInfo?.data?.type)
	) {
		return info || this?.props?.loggedUserInfo?.data;
	}
	let userinfo;
	if (localStorage.getItem("token")) {
		if (getInfoFn && getPersonInfo) {
			userinfo = await fetchUserInfo(
				localStorage.getItem("token"),
				getInfoFn,
				getPersonInfo
			);
		} else {
			userinfo = await fetchUserInfo.bind(this)(localStorage.getItem("token"));
		}
	} else if (
		localStorage.getItem("guestInfo") ||
		guestInfo?.data?.name ||
		this?.props?.guestUserInfo?.data?.name
	) {
		let info = guestInfo?.data?.name
			? guestInfo?.data
			: this?.props?.guestUserInfo?.data;
		if (!(info && info?.name)) {
			info = JSON.parse(localStorage.getItem("guestInfo"));
		}
		userinfo = {
			name: info.name,
			age: info.age.milli,
			info: info,
			type: "guest",
		};
	}
	if (userinfo) {
		if (userinfo.type === "token") {
			if (setInfo) {
				await setInfo(userinfo);
			} else {
				await this.props.LOGGED_USER_INFO(userinfo);
			}
		}
		return userinfo;
	}
	// localStorage.removeItem("query_token");
	return false;
}

export const Logout = () => {
	localStorage.clear();
	window.location.reload();
	window.location.replace("/");
};

export const getTimeString = (hour, minutes) => {
	if (minutes < 10) {
		minutes = `0${minutes}`;
	}
	if (hour === 0) {
		return `12:${minutes} AM`;
	}
	if (hour === 12) {
		return `12:${minutes} PM`;
	}
	if (hour <= 11) {
		if (hour < 10) {
			hour = `0${hour}`;
		}
		return `${hour}:${minutes} AM`;
	}
	return `${hour - 12 < 10 ? "0" : ""}${hour - 12}:${minutes} PM`;
};

export const convertTime = (time, inputFormat, outputFormat, type = "date") => {
	if (type === "date") {
		if (inputFormat !== "unix") {
			return moment(time, inputFormat).format(outputFormat);
		}
		const date = new Date(time * 1000);
		return moment(date).format(outputFormat);
	}
	if (type === "diff") {
		if (inputFormat === "unix") {
			return moment().diff(moment.unix(time), outputFormat);
		}
		return moment().diff(moment(time, inputFormat), outputFormat);
	}
};

export const getMobileNumber = (input) => {
	return `+${input?.countryData?.callingCodes ?? "00"}${input.mobileNo}`;
};

export const getTimeSlot = (date, type = "date", isUpdate = false) => {
	const currentTime = new Date();
	let comparedate = date;
	if (type === "unix") {
		comparedate = convertTime(date, type, "DD-MM-YYYY");
	}
	let time = 0;
	if (!date) {
		comparedate = this.state.date;
	}
	if (moment().format("DD-MM-YYYY") === comparedate || isUpdate) {
		let currentHour = currentTime.getHours();
		if (isUpdate) {
			currentHour = new Date(date * 1000).getHours();
		}
		if (currentHour <= 12) {
			time = 0;
		} else if (currentHour > 12 && currentHour <= 15) {
			time = 1;
		} else if (currentHour > 15 && currentHour <= 18) {
			time = 2;
		} else {
			time = 3;
		}
	}
	return time;
};

export const upsertPerson = async (getfn, upsertfn, data) => {
	try {
		const persondata = await getfn({ phone: data.phone });
		const { isError, errMsg } = checkError(persondata?.payload);
		if (
			isError ||
			(persondata?.payload?.data?.id && persondata?.payload?.data?.id !== 0)
		) {
			if (isError) {
				return {
					error: true,
					data: errMsg,
					getData: null,
				};
			}
			return {
				error: true,
				data: `User Already Exists for ${data.phone}`,
				getData: persondata,
			};
		}
		const upsertData = await upsertfn(data);
		const { isError: isErr, errMsg: msg } = checkError(upsertData?.payload);
		if (isErr) {
			return {
				error: true,
				data: msg,
				getData: null,
			};
		}
		return {
			error: false,
			data: upsertData,
			getData: null,
		};
	} catch (err) {
		return {
			error: true,
			data: "Something went wrong",
			getData: null,
		};
	}
};

const weekOfMonth = (m) => {
	return m.week() - m.startOf("month").week() + 1;
};

export const getReccuranceDates = (
	startDate,
	endType,
	endAfter,
	repeatType,
	repeatVal,
	preferredDays,
	preferredWeeks,
	dates = []
) => {
	const preferrdDaysArr = sortNumbers(preferredDays.map((day) => day.value));
	const preferrdWeeksArr = sortNumbers(preferredWeeks.map((day) => day.value));
	const currentDay = moment(startDate, "DD-MM-YYYY").day();
	const currentWeek = weekOfMonth(moment(startDate, "DD-MM-YYYY"));
	const allDates = [...dates];
	let prevDate = "";
	let previousDay = -1;
	let previousWeek = -1;
	let foundMonthDay = false;
	const isTypeDay = repeatType?.toLowerCase() === "days";
	const isTypeWeek = repeatType?.toLowerCase() === "weeks";
	const isTypeMonth = repeatType?.toLowerCase() === "months";
	if (
		isTypeDay ||
		(isTypeWeek &&
			(preferrdDaysArr.includes(currentDay) || preferrdDaysArr.length === 0)) ||
		(isTypeMonth &&
			(preferrdWeeksArr.includes(currentWeek) || preferrdWeeksArr.length === 0))
	) {
		if (isTypeMonth && preferrdDaysArr.length > 0) {
			if (preferrdDaysArr.includes(currentDay)) {
				foundMonthDay = true;
				previousDay = currentDay;
				previousWeek = currentWeek;
				prevDate = startDate;
				dates.push(
					Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
				);
				allDates.push(
					Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
				);
			} else {
				for (let i = 0; i < preferrdDaysArr.length; i++) {
					const day = preferrdDaysArr[i];
					if (day > currentDay) {
						foundMonthDay = true;
						previousDay = currentDay;
						previousWeek = currentWeek;
						prevDate = moment(startDate, "DD-MM-YYYY")
							.day(day)
							.format("DD-MM-YYYY");
						dates.push(
							Math.floor(
								moment(startDate, "DD-MM-YYYY").day(day).toDate().getTime() /
									1000
							)
						);
						allDates.push(
							Math.floor(
								moment(startDate, "DD-MM-YYYY").day(day).toDate().getTime() /
									1000
							)
						);
					}
				}
			}
		} else {
			foundMonthDay = true;
			previousDay = currentDay;
			previousWeek = currentWeek;
			prevDate = startDate;
			dates.push(
				Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
			);
			allDates.push(
				Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
			);
		}
	} else {
		if (isTypeWeek && !preferrdDaysArr.includes(currentDay)) {
			let isfound = false;
			let tempDay = -1;
			let tempDate;
			for (let i = 0; i < preferrdDaysArr.length; i++) {
				const nextDay = preferrdDaysArr[i];
				if (nextDay > currentDay) {
					isfound = true;
					const newFirstDate = moment(startDate, "DD-MM-YYYY").day(nextDay);
					previousDay = nextDay;
					prevDate = newFirstDate.format("DD-MM-YYYY");
					dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
					allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
					break;
				} else {
					if (tempDay === -1 || currentDay - tempDay < currentDay - nextDay) {
						const newFirstDate = moment(startDate, "DD-MM-YYYY").day(nextDay);
						tempDay = nextDay;
						tempDate = newFirstDate;
					}
				}
			}
			if (!isfound) {
				if (tempDate) {
					previousDay = tempDay;
					prevDate = tempDate.format("DD-MM-YYYY");
				}
			}
		} else if (isTypeMonth && !preferrdWeeksArr.includes(currentWeek)) {
			let isfound = false;
			let tempWeek = -1;
			if (preferrdDaysArr.length > 0) {
				previousDay = preferrdDaysArr[0];
			}
			let tempDate;
			for (let i = 0; i < preferrdWeeksArr.length; i++) {
				const nextWeek = preferrdWeeksArr[i];
				if (nextWeek > currentWeek) {
					foundMonthDay = true;
					isfound = true;
					let newFirstDate = moment(startDate, "DD-MM-YYYY")
						.startOf("week")
						.week(
							moment(startDate, "DD-MM-YYYY").startOf("week").week() +
								(nextWeek - currentWeek)
						);
					if (preferrdDaysArr.length > 0) {
						newFirstDate = newFirstDate.startOf("week").day(previousDay);
					}
					previousWeek = nextWeek;
					prevDate = newFirstDate.format("DD-MM-YYYY");
					dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
					allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
					break;
				} else {
					if (
						tempWeek === -1 ||
						currentWeek - tempWeek < currentWeek - nextWeek
					) {
						let newFirstDate = moment(startDate, "DD-MM-YYYY")
							.startOf("month")
							.add(1, "month")
							.startOf("month")
							.week(
								moment(startDate, "DD-MM-YYYY")
									.startOf("month")
									.add(1, "month")
									.startOf("month")
									.week() +
									(nextWeek - 1)
							);
						if (preferrdDaysArr.length > 0) {
							newFirstDate = newFirstDate.startOf("week").day(previousDay);
						}
						tempWeek = nextWeek;
						tempDate = newFirstDate;
					}
				}
			}
			if (!isfound) {
				if (tempDate) {
					foundMonthDay = true;
					previousWeek = tempWeek;
					prevDate = tempDate.format("DD-MM-YYYY");
					dates.push(Math.floor(tempDate.toDate().getTime() / 1000));
					allDates.push(Math.floor(tempDate.toDate().getTime() / 1000));
				}
			}
		}
	}
	if (isTypeMonth && !foundMonthDay) {
		previousDay = preferrdDaysArr[0];
		let newFirstDate = moment(startDate, "DD-MM-YYYY")
			.startOf("month")
			.add(1, "month")
			.startOf("month")
			.week(
				moment(startDate, "DD-MM-YYYY")
					.startOf("month")
					.add(1, "month")
					.startOf("month")
					.week() +
					(currentWeek - 1)
			);
		if (preferrdDaysArr.length > 0) {
			newFirstDate = newFirstDate.startOf("week").day(previousDay);
		}
		previousWeek = currentWeek;
		prevDate = newFirstDate.format("DD-MM-YYYY");
		dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
		allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
	}
	const isEndTypeDate = endType === "Calendar";
	if (isTypeWeek || isTypeMonth) {
		if (Number(repeatVal) > 1 && dates.length === 0) {
			const date = moment(prevDate, "DD-MM-YYYY").add(Number(1), repeatType);
			if (isTypeWeek) {
				if (preferrdDaysArr.length > 0) {
					preferrdDaysArr.forEach((day) => {
						const weekDay = moment(date).day(day);
						const timeStamp = Math.floor(weekDay.toDate().getTime() / 1000);
						if (day === previousDay) {
							dates.push(timeStamp);
						}
						allDates.push(timeStamp);
					});
				} else {
					const timeStamp = Math.floor(date.toDate().getTime() / 1000);
					dates.push(timeStamp);
					allDates.push(timeStamp);
				}
				prevDate = date.format("DD-MM-YYYY");
			} else {
			}
		}
	}
	if (isEndTypeDate) {
		const endDate = moment(endAfter, "DD-MM-YYYY");
		while (true) {
			const date = moment(prevDate, "DD-MM-YYYY").add(
				Number(repeatVal),
				repeatType
			);
			if (endDate.diff(date, "days") < 0) {
				return [dates, allDates];
			}
			if (isTypeDay) {
				const timeStamp = Math.floor(date.toDate().getTime() / 1000);
				prevDate = date.format("DD-MM-YYYY");
				allDates.push(timeStamp);
				dates.push(timeStamp);
			} else if (isTypeWeek) {
				if (preferrdDaysArr.length > 0) {
					preferrdDaysArr.forEach((day) => {
						const weekDay = moment(date).day(day);
						const timeStamp = Math.floor(weekDay.toDate().getTime() / 1000);
						if (day === previousDay) {
							dates.push(timeStamp);
						}
						allDates.push(timeStamp);
					});
				} else {
					const timeStamp = Math.floor(date.toDate().getTime() / 1000);
					dates.push(timeStamp);
					allDates.push(timeStamp);
				}
				prevDate = date.format("DD-MM-YYYY");
			} else {
				if (preferrdWeeksArr.length > 0) {
					if (preferrdDaysArr.length > 0) {
						const newFirstDate = moment(date, "DD-MM-YYYY")
							.startOf("month")
							.week(
								moment(date, "DD-MM-YYYY").startOf("month").week() +
									(previousWeek - 1)
							)
							.day(previousDay);
						const timeStamp = Math.floor(
							newFirstDate.toDate().getTime() / 1000
						);
						dates.push(timeStamp);
						allDates.push(timeStamp);
					} else {
						let newFirstDate = moment(date, "DD-MM-YYYY")
							.startOf("month")
							.week(
								moment(date, "DD-MM-YYYY").startOf("month").week() +
									(previousWeek - 1)
							);
						const timeStamp = Math.floor(
							newFirstDate.toDate().getTime() / 1000
						);
						dates.push(timeStamp);
						allDates.push(timeStamp);
					}
				} else {
					const timeStamp = Math.floor(date.toDate().getTime() / 1000);
					dates.push(timeStamp);
					allDates.push(timeStamp);
				}
				prevDate = date.format("DD-MM-YYYY");
			}
		}
	}
	for (let i = dates.length; i < Number(endAfter); i++) {
		const date = moment(prevDate, "DD-MM-YYYY").add(
			Number(repeatVal),
			repeatType
		);
		if (isTypeDay) {
			const timeStamp = Math.floor(date.toDate().getTime() / 1000);
			prevDate = date.format("DD-MM-YYYY");
			allDates.push(timeStamp);
			dates.push(timeStamp);
		} else if (isTypeWeek) {
			if (preferrdDaysArr.length > 0) {
				preferrdDaysArr.forEach((day) => {
					const weekDay = moment(date).day(day);
					const timeStamp = Math.floor(weekDay.toDate().getTime() / 1000);
					if (day === previousDay) {
						dates.push(timeStamp);
					}
					allDates.push(timeStamp);
				});
			} else {
				const timeStamp = Math.floor(date.toDate().getTime() / 1000);
				dates.push(timeStamp);
				allDates.push(timeStamp);
			}
			prevDate = date.format("DD-MM-YYYY");
		} else {
			if (preferrdWeeksArr.length > 0) {
				if (preferrdDaysArr.length > 0) {
					const newFirstDate = moment(date, "DD-MM-YYYY")
						.startOf("month")
						.week(
							moment(date, "DD-MM-YYYY").startOf("month").week() +
								(previousWeek - 1)
						)
						.day(previousDay);
					const timeStamp = Math.floor(newFirstDate.toDate().getTime() / 1000);
					dates.push(timeStamp);
					allDates.push(timeStamp);
				} else {
					let newFirstDate = moment(date, "DD-MM-YYYY")
						.startOf("month")
						.week(
							moment(date, "DD-MM-YYYY").startOf("month").week() +
								(previousWeek - 1)
						);
					const timeStamp = Math.floor(newFirstDate.toDate().getTime() / 1000);
					dates.push(timeStamp);
					allDates.push(timeStamp);
				}
			} else {
				const timeStamp = Math.floor(date.toDate().getTime() / 1000);
				dates.push(timeStamp);
				allDates.push(timeStamp);
			}
			prevDate = date.format("DD-MM-YYYY");
		}
	}
	return [dates, allDates];
};

export const sortNumbers = (arr, overwrite = true) => {
	let returnArr = arr;
	if (!overwrite) {
		returnArr = [...arr];
	}
	return returnArr.sort((a, b) => Number(a) - Number(b));
};

export const findAge = (dates) => {
	dates = moment(dates, "DD-MM-YYYY").format("MM-DD-YYYY");
	const days = moment().diff(dates, "days");
	const months = moment().diff(dates, "months");
	const years = moment().diff(dates, "years");
	if (days <= 90) {
		return days + ` ${days <= 1 ? "Day" : "Days"}`;
	} else if (days > 90 && days < 365) {
		return months + " months";
	} else if (days >= 365) {
		return years + ` ${years <= 1 ? "Yr" : "Yrs"}`;
	}
};

export const checkName = (name) => /^[a-z .'-]+$/i.test(name);

export const getImgUrl = (fileId) =>
	fileId ? `${Config.downloadUrl}${fileId}` : "";

//up arrow and down arrow to select
export const keySelection = (divWrap, childName, selectionName, classlist) => {
	if (divWrap?.getElementsByClassName(selectionName)?.length === 0) {
		divWrap.getElementsByClassName(classlist)[0].classList.add(selectionName);
	} else {
		let child =
			childName === "next"
				? divWrap?.getElementsByClassName(selectionName)[0]?.nextElementSibling
				: divWrap.getElementsByClassName(selectionName)[0]
						.previousElementSibling;
		if (child) {
			child.classList.add(selectionName);
			child.scrollIntoViewIfNeeded(false);
			divWrap
				.getElementsByClassName(selectionName)
				[childName === "next" ? 0 : 1].classList.remove(selectionName);
		} else {
			divWrap.scrollTo({
				top: childName === "next" ? divWrap.scrollHeight : 0,
			});
		}
	}
};

// Check the versions
export let semverGreaterThan = (versionA, versionB) => {
	const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
	const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

	while (versionsA.length || versionsB.length) {
		const a = Number(versionsA.shift());

		const b = Number(versionsB.shift());
		// eslint-disable-next-line no-continue
		if (a === b) continue;
		// eslint-disable-next-line no-restricted-globals
		return a > b || isNaN(b);
	}
	return false;
};

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = async () => {
	if (caches) {
		// Service worker cache should be cleared with caches.delete()
		const names = await caches.keys();
		const promArr = [];
		for (const name of names) {
			promArr.push(caches.delete(name));
		}
		await Promise.all(promArr);
	}
	// delete browser cache and hard reload
	window.location.reload(true);
};

// AVC Meeting props
export const DrawerMeetingProps = {
	direction: {
		top: "top",
		bottom: "bottom",
		left: "left",
		right: "right",
	},
	variant: {
		permanent: "permanent",
		persistent: "persistent",
		temporary: "temporary",
	},
};


export const mobileNumberValidation = (mobileNumber, countryCode) => {
	let c_code = countryCode;
	if(countryCode){
		if(countryCode?.includes("+")){
			c_code = countryCode;
		  } else {
			c_code = "+".concat("", countryCode);
		  }
	} else{
		c_code = "+00";
	}
	const code = countryCode_?.filter(cc => cc.dial_code === c_code.toString());
	if(code.length > 0){
	  return isValidPhoneNumber(mobileNumber?.toString(), code?.[0]?.code);
	} else {
	  return "Invalid Country code"
	}
  }
