import React, { useState, useLayoutEffect } from "react";
import { Div } from "qdm-component-library";

import FileViewer from "react-file-viewer";
import { createFile } from "../../utils";
import loading from "../../assets/img/loading.gif";
import Config from "../../config";

const ListDiv = (props) => {
  const [openFile, setopenFile] = useState({
    datauri: null,
    datatype: null,
  });

  useLayoutEffect(() => {
    var filedata = {
      datauri: null,
      datatype: null,
    };
    setopenFile(filedata);
    async function funcall() {
      let data = await createFile(
        Config.downloadUrl + props.uri[0].fileid,
        props.uri[0].filetype,
        props.uri[0].fileName
      );
      var filedata = {
        datauri: data,
        datatype: props.uri[0].filetype.split("/")[1],
      };
      setopenFile(filedata);
    }
    funcall();
  }, [props.uri]);

  if (openFile.datatype && openFile.datauri) {
    return (
      <Div
        key={"0"}
        alignItems="stretch"
        direction="row"
        justify="flex-start"
        spacing="2"
        id={"Viewreport-viewer"}
        className={"viewDiv"}
      >
        <FileViewer
          style={{
            height: "calc(100vh - 100px) !important",
          }}
          id={"Viewreport-viewer"}
          onError={"this.onError"}
          fileType={openFile.datatype}
          filePath={openFile.datauri}
        />
      </Div>
    );
  } else {
    return (
      <div
        alt={"loading"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          inset: 0,
        }}
        id={"Viewreport-viewer-loading-div"}
      >
        <img
          id={"Viewreport-viewer-loading-img"}
          style={{ width: "70px" }}
          alt={"loading"}
          src={loading}
        />
      </div>
    );
  }
};
export default ListDiv;

// import React from "react";
// import { actions } from "primarycare-binder";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import FileViewer from "react-file-viewer";
// import { Row } from "qdm-component-library";
// import { createFile } from "../../utils";
// class Viewdiv extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       openFile: null,
//       fileType: null,
//     };
//   }

//   async componentDidMount() {
//     let data = await createFile(
//       "http://164.52.210.54:5000/primarycare/" + this.props.uri[0].fileid,
//       this.props.uri[0].filetype,
//       this.props.uri[0].fileName
//     );
//     this.setState({
//       openFile: data,
//       fileType: this.props.uri[0].filetype.split("/")[1],
//     });
//   }

//   async shouldComponentUpdate(props, state) {
//     if (props.data) {
//       let data = await createFile(
//         "http://164.52.210.54:5000/primarycare/" + this.props.uri[0].fileid,
//         this.props.uri[0].filetype,
//         this.props.uri[0].fileName
//       );
//       this.setState({
//         openFile: data,
//         fileType: this.props.uri[0].filetype.split("/")[1],
//       });
//       return true;
//     }
//   }

//   render() {
//     if (this.state.openFile && this.state.fileType) {
//       return (
//         <Row
//           key={"0"}
//           alignItems="stretch"
//           direction="row"
//           justify="flex-start"
//           spacing="2"
//           id=""
//           className={"viewDiv"}
//         >
//           <FileViewer
//             style={{
//               height: "calc(100vh - 100px) !important",
//             }}
//             onError={this.onError}
//             fileType={this.state.fileType}
//             filePath={this.state.openFile}
//           />
//         </Row>
//       );
//     } else {
//       return <h1>select doc</h1>;
//     }
//   }
// }

// const mapStateToProps = () => ({});

// export default connect(mapStateToProps, actions)(withRouter(Viewdiv));
