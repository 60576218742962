import React from "react";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import DoneIcon from "@material-ui/icons/Done";
import StepConnector from "@material-ui/core/StepConnector";
// import { withAllContexts } from '../../../HOCs';
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		"& .MuiStepper-root": {
			padding: 0,
		},
		"& .MuiStep-horizontal": {
			padding: 0,
		},
	},
	body: {
		"& .MuiStepLabel-iconContainer": {
			display: "none",
		},
	},
	stepLableActive: {
		padding: "8px 14px",
		backgroundColor: "#36A2EB",
		color: "#fff",
		borderRadius: 30,
		"& .MuiStepLabel-label": {
			color: "#fff",
			fontSize: 12,
		},
	},
	stepLableVist: {
		padding: "8px 14px",
		backgroundColor: "#fff",
		borderRadius: 30,
		border: "1px solid #36A2EB",
		"& .MuiStepLabel-label": {
			color: "#36A2EB",
			fontSize: 12,
		},
	},
	stepLable: {
		padding: "8px 14px",
		backgroundColor: "#fff",
		borderRadius: 30,
		border: "1px solid #B6B6B6",
		"& .MuiStepLabel-label": {
			color: "#B6B6B6",
			fontSize: 12,
		},
	},
}));

const QontoConnector = withStyles({
	active: {
		"& $line": {
			marginLeft: 2,
			borderTop:'2px solid #36A2EB'
		},
	},
	completed: {
		"& $line": {
			marginLeft: 2,
			borderTop:'2px solid #36A2EB'
		},
	},
	line: {
		borderRadius: 0,
		border:0,
		borderTop:'2px dashed #eaeaf0'
	},
})(StepConnector);

// const JSONArr = {
// 	child: [
// 		{ name: "Welcome", active: true, vist: true },
// 		{ name: "Consent Acceptance", active: false, vist: false },
// 		{ name: "Documents upload", active: false, vist: false },
// 		{ name: "Waiting Lobby", active: false, vist: false },
// 	],
// };

function TopStepperBar(props) {
	const classes = useStyles();

	const returnActiveStep = () =>{
		// if(props?.steps?.findIndex((val, i) => val?.active)){
		// 	return props?.steps?.findIndex((val, i) => val?.active)
		// }
		// return 1
		return props?.steps?.findIndex((val, i) => val?.active)
	}

	return (
		<div className={classes.root}>
			<Stepper activeStep={returnActiveStep()} connector={<QontoConnector />}>
				{props?.steps?.map((label, index) => {
					return (
						<Step key={label} className={classes.body}>
							<StepLabel
								className={
									label.active
										? classes.stepLableActive
										: label.vist
										? classes.stepLableVist
										: classes.stepLable
								}
							>
								{label.active || !label.vist ? (
									`${"0"}${index + 1}`
								) : (
									<DoneIcon
										style={{ fontSize: 16, paddingTop: 3, fontWeight: 600 }}
									/>
								)}
								&nbsp;
								{label.name}
							</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</div>
	);
}

export default TopStepperBar