import React from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Div,
  Avatar,
  Text,
  Divider,
  Icons,
  H6,
} from "qdm-component-library";
import { Routes } from "../../../router/routes";
import { getUserInfo, Logout, UIColor } from "../../../utils";
import { Typography, Popover } from "@material-ui/core";
import Logo from "../../../assets/img/Logo_W.svg";
import { connect } from "react-redux";
import { actions } from "primarycare-binder";
// import "./styles.css";
class TopHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      name: "",
      img: ""
    };
    this.getUserInfo = getUserInfo.bind(this)
  }
  redirectLink = (data) => {
    if (data.id !== this.giveMeSelectedTab())
      this.props.history.push(data.link);
  };
  giveMeSelectedTab = () => {
    let id = "";
    const { navBarArr } = this.props;
    navBarArr.map((nav) => {
      if (window.location.pathname === nav.link) {
        id = nav.id;
      }
      return nav;
    });
    return id;
  };
  clickHome = () => {
    this.props.history.push(Routes.dashboard);
  };
  handleProfileClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };
  handleProfileClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };
  async componentDidMount() {
    const info = await this.getUserInfo();
    if (info) {
      this.setState({
        ...this.state,
        img: info.img,
        name: info.name,
      })
    }
  }
  render() {
    const { name } = this.state;
    const { navBarArr = [], noHome, middleComponent = <></> } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <div>
        <Container
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: !this.props.hideBackground
              ? "#e8e8e8"
              : "transparent",
            padding: "0px",
          }}
          id={"patientDashboard_Header_parent_Container"}
        >
          <Row
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              backgroundColor: "#01205C",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 37px",
            }}
            id={"patientDashboard_Header_Row"}
          >
            <Div
              key={"0"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
              id={"patientDashboard_Header_Div"}
            >
              <div style={{display:'flex'}}>
                <img
                  key={"0"}
                  variant="rounded"
                  letter="Logo"
                  src={Logo}
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  id={"patientDashboard_Header_Logo"}
                />
              </div>
              <Div
                key={"1"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  display: "flex",
                  marginLeft: "20px",
                }}
                id={"patientDashboard_Header_qdm_div"}
              >
                {navBarArr.map((val, i) => {
                  return (
                    <Div
                      key={"0"}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        display: "",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "",
                      }}
                      onClick={() => this.redirectLink(val)}
                      id={"patientDashboard_Header_onClick_div"}
                    >
                      <Text
                        name={val.label}
                        style={{
                          color: "#ffffff",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                        inLineStyles={{
                          fontFamily: "'pc_semibold' !important",
                        }}
                        className={"pc_semibold"}
                        id={"patientDashboard_Header_Text_div"}
                      ></Text>
                      {this.giveMeSelectedTab() === val.id && (
                        <Divider
                          key={"1"}
                          id=""
                          className=""
                          label=""
                          borderColor=""
                          textColor=""
                          orientation="horizontal"
                          variant="middle"
                          style={{
                            // width: '40px',
                            height: "3px",
                            borderColor: "#ffffff",
                            backgroundColor: "#ffffff",
                            margin: "4px auto",
                          }}
                          id={"patientDashboard_Header_horizontalDivider"}
                        ></Divider>
                      )}
                    </Div>
                  );
                })}
              </Div>
            </Div>
            <Div id={"patientDashboard_Header_middleComponent"}>
              {middleComponent}
            </Div>
            <Div
              key={"2"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                // alignSelf: "flex-start",
                alignItems: "center",
              }}
              id={"patientDashboard_Header_Home_Div"}
            >
              {!noHome && (
                <Div
                  style={{ cursor: "pointer" }}
                  onClick={() => this.clickHome()}
                  id={"patientDashboard_Header_clickHome_Div"}
                >
                  <i
                    className="fa fa-home"
                    aria-hidden="true"
                    style={{
                      fontSize: "16px !important",
                      paddingRight: "7px",
                      color: "white",
                    }}
                    id={"patientDashboard_Header_clickHome_i"}
                  />
                  <Text
                    id={"patientDashboard_Header_Home_Text"}
                    className={"pc_medium"}
                    style={{ color: "white" }}
                  >
                    Home
                  </Text>
                </Div>
              )}
              <Text
                  key={"0"}
                  name={"version : " + localStorage.getItem("version")}
                  style={{ color: "#FFFFFF", fontSize: 12, marginTop: -2 }}
                  className={"pc_regular"}
                  id={"patientDashboard_Header_version_number_Text"}
                ></Text>
              <Divider
                orientation="vertical"
                style={{ width: "1px", height: "32px" }}
                id={"patientDashboard_Header_vertical_Divider"}
              />
              <Avatar
                key={"1"}
                variant="squre"
                letter={name}
                src={this.state.img}
                alt="G"
                style={{
                  width: "40px",
                  height: "40px",
                  padding: "",
                  // marginLeft: "16px",
                  // marginRight: "10px",
                  backgroundColor: UIColor.lineBorderFill.color,
                  fontSize: "16px",
                  fontFamily:'pc_medium',
                  margin:'0px 10px 0px 16px',
                  borderRadius: "8px"
                }}
                id={"patientDashboard_Header_Avatar_variantCircle"}
              ></Avatar>
              <Popover
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={() => this.handleProfileClose()}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                id={"patientDashboard_Header_Popover"}
              >
                <Typography
                  onClick={() => {
                    Logout();
                    window.location.href = "/";
                  }}
                  style={{ padding: "10px", cursor: "pointer",fontSize:14, color: UIColor.primaryText.color }}
                  id={17}
                  className={"pc_medium"}
                  id={"patientDashboard_HeaderLogOut"}
                >
                  Log Out
                </Typography>
              </Popover>
              <Div
                style={{ padding: "0px 15px 0px 0px" }}
                onClick={(e) => this.handleProfileClick(e)}
                id={"patientDashboard_Header_handleProfileClick"}
              >
                <H6
                  key={"0"}
                  name={name || "Guest"}
                  style={{
                    color: "#FFFFFF",
                    lineHeight: 1,
                    fontSize: "14px",
                  }}
                  id={"patientDashboard_Header_username"}
                  className={"pc_regular"}
                ></H6>
                {/* <Text key={'0'} name='Receptionist Apollo, chennai' style={{ color: '#FFFFFF', fontSize: "12px" }}></Text> */}
              </Div>
              <Icons
                onClick={(e) => this.handleProfileClick(e)}
                key={"2"}
                fontIcon="angle-down"
                ariaHidden="true"
                className=""
                size={18}
                style={{ color: "#ffffff",cursor:'pointer' }}
                id={"patientDashboard_Header_handleProfileClick_icon"}
              ></Icons>
            </Div>
          </Row>
        </Container>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
});

export default connect(mapStateToProps, actions)(withRouter(TopHeader));
