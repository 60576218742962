import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Routes } from "./../../router/routes";
import { Addmember, Addmemberform, Addmemberotp } from "../../components";
import Logo from "../../assets/img/logo_B.svg";
import BackgroundUrl from "../../assets/img/backgroundImage.svg";
import {
  Container,
  Row,
  Col,
  Div,
  Icons,
  Text,
  Divider,
  Button,
  BackgroundImage,
  Paper,
  H6,
  MobileNumberWithCode,
  Link,
  SideMenu,
} from "qdm-component-library";
import { checkError, getUserInfo, mobileNumberValidation } from "../../utils";
import { AlertContext } from "../../contexts";
import AppointmentAddMemberSuccessPage from "../../components/appointment_booked/index";
import { withStyles } from "@material-ui/core";
import { isValidPhoneNumber } from "libphonenumber-js";
import { UIColor } from "../../utils/constants";
import countryCode from "../../utils/countryCode.json"

const styles = (theme) => ({
  mobile: {
    "& .callingCodes,input": {
      fontSize: 14,
      color: UIColor.primaryText.color,
      fontFamily: "pc_medium",
      letterSpacing: "-0.34px",
      fontWeight: "normal",
    },
    "& input::placeholder": {
      fontFamily: "pc_medium",
    },
    "& #qdm-euu-0877": {
      borderColor: "#DEE5EC",
    },
    "& .mobileCountryDivider": {
      borderColor: UIColor.lineBorderFill.color,
    },
  },
});

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNo: {},
      error: "",
      sidebar: false,
      searchMenber: true,
      memberForm: false,
      memberOtp: true,
      chooseSearch: null,
      addmmber: false,
      signUpData: {},
      dropdownData: [],
    };
    this.getUserInfo = getUserInfo.bind(this);
    this.mobileRef = React.createRef()
  }
  handelChangeSelect = (v) => {
    this.setState({
      chooseSearch: v,
    });
  };
  changeState = (key, value) => {
    debugger
    // if(value.mobileNo.length < 5){
    this.setState({
      [key]: value,
      error:""
    });
    // }
  };

  getCountryCodelist = async() => {
    let code = await this.props.GET_COUNTRY_CODE_LIST()
    // this.setState({
    //   ...this.state,
    //   dropdownData: code?.payload?.data
    // })

    // let value = {
    //   countryData: code?.payload?.data?.filter(val => val?.callingCodes === '91')?.[0],
    //   mobileNo: "",
    // };

    // this.setState({
    //   ...this.state,
    //   mobileNo: value,
    // })

  }

  async componentDidMount() {
    console.clear()
    document.getElementById("signin_number_mobilenumberwithcode_input").focus()
    await this.getCountryCodelist();
    const info = await this.getUserInfo();
    if (info.type) {
      if (info.type === "guest") {
        this.props.history.push(Routes.doctorConsultation);
      } else {
        this.props.history.push(Routes.appointmentsListingPage);
      }
    }
    // let value = {
    //   // countryData: this.state.dropdownData[0],
    //   countryData: this.state?.dropdownData.filter(val => val?.callingCodes === '91')?.[0],
    //   mobileNo: "",
    // };

    // if (this.state.dropdownData.length === 1) {
    //   this.setState({
    //     mobileNo: value,
    //   });
    // }
    
  }

  shouldComponentUpdate(props, state) {
    return true;
  }

  sendOtp = () => {
    // let valid = isValidPhoneNumber(this.state.mobileNo.mobileNo, "IN");
    let res__ = mobileNumberValidation(this.state.mobileNo?.mobileNo, this.state.mobileNo?.countryData?.callingCodes);
        debugger
        if (res__ === "Invalid Country code") {
          let errorMessage = res__;
          this.setState({
          error: errorMessage,
          });
          return
        } else if (res__ === false) {
          let errorMessage = "Please enter valid mobile number";
          this.setState({
          error: errorMessage,
          });
          return
        }

    // if (this.state.mobileNo?.mobileNo?.length < 10) {
    //   let errorMessage = "Please enter the Valid mobile number";
    //   this.setState({
    //     error: errorMessage,
    //   });
    //   return;
    // }
    if (
      this.state.mobileNo?.mobileNo &&
      this.state.mobileNo?.countryData?.callingCodes
    ) {
      this.setState({
        error: "",
      });
      Promise.resolve(
        this.props.GENERATE_OTP({
          mobileNo: `+${
            this.state.mobileNo?.countryData?.callingCodes ?? "00"
          }${this.state.mobileNo?.mobileNo}`,
        })
      ).then((res) => {
        const status = checkError(this.props.generateOTP);
        if (!status.isError) {
          this.props.history.push({
            pathname: Routes.signInOTP,
            state: {
              mobileNo: this.state.mobileNo,
            },
          });
        } else {
          this.setState({
            error: status.errMsg,
          });
        }
      });
    } else {
      let errorMessage = "Please enter the registered mobile number";
      if (!this.state?.mobileNo?.mobileNo) {
        errorMessage = "Please enter the registered mobile number";
      } else if (!this.state?.mobileNo?.countryData?.callingCodes) {
        errorMessage = "Please select the country code";
      }
      // this.context.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.error,
      //   msg: errorMessage,
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.right,
      //   tone: true,
      // });

      this.setState({
        error: errorMessage,
      });
    }
  };

  toggleSideBar = (boolean) => {
    this.setState({
      ...this.state,
      chooseSearch: boolean ? null : this.state?.chooseSearch || null,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
      addmmber: false,
      memberSuccessPage: false,
    });
  };
  nextSection = (member, signUpData, l) => {
    if (signUpData === "addMember") {
      this.setState({
        ...this.state,
        signUpData: l ? l : signUpData,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
        memberSuccessPage: false,
        addmmber: true,
      });
      return;
    } else if (member === "addmemberform") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
        addmmber: false,
        signUpData,
      });
    } else if (member === "addmemberotp") {
      if (signUpData) {
        this.setState({
          ...this.state,
          signUpData,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
          addmmber: false,
        });
      } else {
        this.setState({
          ...this.state,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
        });
      }
    } else if (member === "back") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        memberSuccessPage: false,
      });
    } else if (member === "member_succss") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: false,
        memberOtp: false,
        sidebar: true,
        memberSuccessPage: true,
        signUpData,
      });
    } else if (member === "movenextscreen") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        memberSuccessPage: false,
      });

      this.props.history.push(Routes.appointmentsListingPage);
      // window.location.href = "/appointments_listing_page";
    }
  };
  render() {
    const { classes } = this.props;
    let change = document?.getElementById("signin_number_mobilenumberwithcode_input")?.focus()
    return (
      <div id="signin_parent_div">
        <Container
          id="signin_parent_container"
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: "#e8e8e8",
            // height: "100vh",
            padding: "0px",
          }}
        >
          <Row
            id="signin_row_one"
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{ backgroundColor: "#ffffff", height: "" }}
          >
            <Col
              id="signin_col_one"
              key={"0"}
              name="qdm-col-12"
              alignItems="center"
              direction="row"
              justify="space-between"
              textAlign="left"
              lg="12"
              xs="12"
              md="12"
              sm="12"
              style={{
                height: "64px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "12px 37px",
              }}
            >
              <div
                id="signin_logo_div"
                style={{
                  width: "96px",
                  height: "32px",
                }}
              >
                <img
                  id="signin_logo_image"
                  key={"0"}
                  variant="rounded"
                  letter="Logo"
                  src={Logo}
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>

              <Div
                id="signin_phone_icon_div"
                key={"1"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Icons
                  id="signin_phone_icon"
                  key={"0"}
                  fontIcon="phone"
                  ariaHidden="true"
                  size="small"
                  className=""
                  style={{ color: UIColor.secondaryText.color, margin: "10px" }}
                />

                <Text
                  id="signin_mobilenumber_text"
                  key={"1"}
                  className="pc_regular"
                  name="+91 9821315141 , +91 9551482612"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 14,
                    color: UIColor.secondaryText.color,
                    letterSpacing: 0,
                  }}
                />

                <Divider
                  id="signin_header_vertical_divider"
                  key={"2"}
                  className=""
                  inLineStyles="[object Object]"
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="vertical"
                  variant="middle"
                  style={{
                    height: "27px",
                    marginTop: "6px",
                    marginLeft: "24px",
                    marginRight: "17px",
                  }}
                />

                <Button
                  id="signin_header_support_button"
                  key={"3"}
                  type="button"
                  variant="text"
                  name="Support"
                  style={{
                    borderColor: UIColor.primaryColor.color,
                    borderWidth: "",
                    borderRadius: "8px",
                    color: UIColor.primaryColor.color,
                    height: "36px",
                    lineHeight: 0,
                    fontSize: 14,
                    padding: "8px 16px",
                  }}
                  className="pc_medium"
                />
              </Div>
            </Col>
          </Row>

          <Col
            id="signin_body_col_one"
            key={"1"}
            name="qdm-col-12"
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            textAlign="left"
            lg="12"
            xs="12"
            md="12"
            sm="12"
            style={{
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "calc(100vh - 64px)",
              backgroundColor: "#F0F7FF",
              overflow: "auto",
            }}
          >
            <BackgroundImage
              id="signin_body_background_image"
              key={"0"}
              // url="https://i.ibb.co/sm1YmHk/Mask-Group-71-2x.png"
              url={BackgroundUrl}
              alt="Mask-Group-71-2x"
              backgroundPosition="0% 0%"
              backgroundPositionX="left"
              backgroundPositionY="top"
              backgroundRepeat="no-repeat"
              backgroundRepeatX="no-repeat"
              backgroundRepeatY="no-repeat"
              backgroundAttachment="scroll"
              backgroundSize="contain"
              backgroundOrigin="padding-box"
              inLineStyles="[object Object]"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Paper
                id="signin_card_parent_paper"
                key={"0"}
                children="Paper"
                variant="elevation"
                elevation="1"
                style={{
                  width: "350px",
                  // height: "400px",
                  backgroundColor: "#ffffff",
                  borderRadius: "16px",
                  padding: "32px",
                  display: "",
                }}
              >
                <H6
                  id="signin_title_h6"
                  className={"pc_semibold"}
                  key={"0"}
                  name="Sign In"
                  style={{
                    fontSize: "24px",
                    color: UIColor.primaryText.color,
                    letterSpacing: 0,
                    lineHeight: 1,
                    marginBottom: 12,
                  }}
                />

                <Text
                  id="signin_discription_text"
                  key={"1"}
                  name="Sign In with your registered mobile number"
                  style={{
                    fontSize: "12px",
                    color: UIColor.secondaryText.color,
                    textAlign: "left",
                    letterSpacing: 0,
                  }}
                  className={"pc_regular"}
                />

                <H6
                  id="signin_textfield_label_h6"
                  name={
                    <span id="signin_textfield_label_span">
                      Registered Mobile number{" "}
                      <span
                        id="signin_textfield_label_starticon"
                        style={{ color: "red" }}
                      >
                        *
                      </span>
                    </span>
                  }
                  style={{
                    fontSize: "12px",
                    color: UIColor.secondaryText.color,
                    marginTop: "32px",
                    lineHeight: 1,
                    letterSpacing: 0,
                    marginBottom: 8,
                  }}
                  className={"pc_regular"}
                />
                <div id="signin_number_flag_div" className={classes.mobile} ref={this.mobileRef}>
                  <MobileNumberWithCode
                    id="signin_number_mobilenumberwithcode"
                    className={
                      this.state.error ? "pc_medium errorBorder" : "pc_medium"
                    }
                    key={"2"}
                    type="number"
                    label="Mobile No"
                    borderColor="#DEE5EC"
                    borderRadius="6"
                    // id="qdm  -euu-0877"
                    name=""
                    maxLength={30}
                    max={30}
                    width=""
                    value={this.state.mobileNo?.mobileNo}
                    // dropdown={
                    //   this.state.dropdownData.length > 0
                    //     ? this.state.dropdownData?.filter(val => val?.callingCodes === '91')?.[0]
                    //     : this.state.mobileNo.countryData
                    // }
                    dropdown={
                      this.state.dropdownData?.length > 0
                        ? this.state.dropdownData?.[0]
                        : this.state.mobileNo?.countryData
                    }
                    dropdownData={this?.props?.CountryCode?.data}
                    // dropdownData={countryCode}
                    disabled={false}
                    
                    size=""
                    autoFocus={true}
                    marginAll="0"
                    marginLR="0"
                    marginTB="0"
                    borderWidth="0"
                    textWidth="0"
                    style={{
                      fontWeight: "bold",
                      input: { fontWeight: "bold" },
                    }}
                    onChange={(e) => this.changeState("mobileNo", e)}
                  />
                </div>

                {this.state.error && (
                  <H6
                    id="signin_mobilenumberwithcode_error_h6"
                    name={this.state.error}
                    style={{
                      fontSize: "12px",
                      color: "#FF4D4A",
                      textAlign: "left",
                    }}
                    className={"pc_regular"}
                  />
                )}

                <Button
                  id="signin_send_otp_button"
                  key={"3"}
                  onClick={this.sendOtp}
                  type="button"
                  variant="outlined"
                  disabled={this.props.generateOTP?.loading}
                  name={
                    this.props.generateOTP?.loading
                      ? "Sending OTP..."
                      : "Send OTP"
                  }
                  style={{
                    margin: "",
                    marginTop: "48px",
                    width: "100%",
                    backgroundColor: UIColor.primaryColor.color,
                    borderColor: UIColor.primaryColor.color,
                    borderRadius: "8px",
                    fontSize: "14px",
                    height: "40px",
                  }}
                  className={"pc_medium"}
                />

                <Div
                  id="signin_send_otp_button_below_divider_div"
                  key={"4"}
                  inLineStyles="[object Object]"
                  className="qdm"
                  style={{ marginTop: "26px", display: "flex", height: "" }}
                >
                  <Divider
                    key={"0"}
                    id="signin_send_otp_button_below_divider_left"
                    className=""
                    label=""
                    borderColor="#DEE5EC"
                    textColor=""
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{ width: "130px" }}
                  />
                  <Text
                    id="signin_send_otp_button_below_divider_center_or"
                    key={"1"}
                    name="Or"
                    style={{
                      marginTop: "4px",
                      color: UIColor.secondaryText.color,
                      width: "28px",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      backgroundColor: "#ffffff",
                      fontSize: 14,
                    }}
                    className={"pc_regular"}
                  />

                  <Divider
                    key={"2"}
                    id="signin_send_otp_button_below_divider_right"
                    className=""
                    label=""
                    borderColor="#DEE5EC"
                    textColor=""
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{ width: "130px" }}
                  />
                </Div>

                <Button
                  id="signin_proceed_as_guest_button"
                  key={"5"}
                  onClick={() => {
                    this.props.history.push(Routes.signUp);
                  }}
                  type="button"
                  variant="text"
                  name="Proceed as Guest"
                  style={{
                    width: "100%",
                    borderColor: UIColor.primaryColor.color,
                    borderRadius: "8px",
                    color: UIColor.primaryColor.color,
                    marginTop: "25px",
                    fontSize: "14px",
                    height: "40px",
                  }}
                  className={"pc_medium"}
                ></Button>

                <Div
                  id="signin_dont_have_an_account_div"
                  key={"6"}
                  inLineStyles="[object Object]"
                  className="qdm"
                  style={{
                    marginTop: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    color: UIColor.primaryColor.color,
                  }}
                >
                  <Text
                    id="signin_dont_have_an_account_text"
                    key={"0"}
                    name="Don't have an account?"
                    className={"pc_regular"}
                    style={{
                      color: UIColor.secondaryText.color,
                      fontSize: "12px",
                      letterSpacing: 0,
                    }}
                  />

                  <Link
                    id="signin_dont_have_an_account_link"
                    onClick={() => this.toggleSideBar(true)}
                    key={"1"}
                    underline="always"
                    color="primary"
                    name="Sign Up"
                    style={{ color: UIColor.primaryColor.color }}
                    inLineStyles={{ textDecoration: "unset" }}
                    className={"pc_regular"}
                  />
                  <SideMenu
                    id="signinpage_signup_sidemenu_parent"
                    open={this.state.sidebar}
                    direction="right"
                    width={435}
                    color="default"
                    onClose={() => this.toggleSideBar(false)}
                  >
                    {this.state.searchMenber ? (
                      <Addmember
                        signUp
                        parent_id={"signin"}
                        name={"Sign Up"}
                        nextSection={(member, v, l) =>
                          this.nextSection(member, v, l)
                        }
                        handelChangeSelect={(v) => this.handelChangeSelect(v)}
                        signUpData={this.state.signUpData}
                        chooseSearch={this.state.chooseSearch}
                      />
                    ) : this.state.memberForm ? (
                      <Addmemberform
                        parent_id={"signin"}
                        addNewMember={false}
                        signUpData={this.state.signUpData}
                        nextSection={(member, signUpInfo) =>
                          this.nextSection(member, signUpInfo)
                        }
                      />
                    ) : this.state.memberOtp ? (
                      <Addmemberotp
                        parent_id={"signin"}
                        addmmber={this.state.addmmber}
                        addNewMember={false}
                        signUpData={this.state?.signUpData || {}}
                        nextSection={(member, v) => this.nextSection(member, v)}
                      />
                    ) : null}
                    {this.state.memberSuccessPage ? (
                      <AppointmentAddMemberSuccessPage
                        parent_id={"signin"}
                        addmmber={this.state.addmmber}
                        signUpData={this.state.signUpData}
                        nextSection={(member, v, l) =>
                          this.nextSection(member, v, l)
                        }
                        toggleSideBar={(member) => this.toggleSideBar(member)}
                      />
                    ) : null}
                  </SideMenu>
                </Div>
              </Paper>
            </BackgroundImage>
          </Col>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  generateOTP: state?.authSlice?.generateOTP,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
  CountryCode: state?.authSlice?.country_code_list
});

SignIn.contextType = AlertContext;

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(SignIn))
);
