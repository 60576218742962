import { Grid, makeStyles, Button } from "@material-ui/core";
import React from "react";
import JSONArr from "./vitalsJson";
import { VitalComp } from "./vitalsComp";
import AvcBrochure from "../../../assets/img/avc_brochure.svg";
import {DetailPage} from "./detailPage";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F2F4FC",
	},
	actions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: "20px",
	},
	ButtonAlign: {
		fontSize: "12px",
		textTransform: "none",
		borderRadius: "8px",
		fontFamily: "pc_regular !important",
		padding: "8px ​26px",
		backgroundColor: "#fff",
		color: "#000",
		fontWeight: 600,

		marginLeft: "10px",
		border: "1px solid #E0E0E0",
		"&:hover": {
			backgroundColor: "#0071F2",
			color: "#fff",
		},
	},
	titleDiv: {
		color:"#6DBAE4",
		backgroundColor:"#F5FAFF",
		border: "1px solid #F9F9F9",
		margin: "8px",
		padding: "14px 0px",
		width: "100%",
		borderRadius: 8
	},
	title:{
		fontSize: 16,
		fontWeight: 600,
		color: "#2F9CD8"
	},
	dis:{
		fontSize: 14
	},
	imgea:{
		textAlign: "center"
	}
}));

export function VitalAvcPage(props) {
	const classes = useStyles();
	const [isDetailScreen, setisDetailScreen] = React.useState({
		state: false,
		vitalsName: "",
	});
	const updateVitals = () => {
		props?.updateVitals && props.updateVitals();
	};
	const moveDetail = (vitalNameArrg) => {
		setisDetailScreen({
			state: !isDetailScreen.state,
			vitalsName: vitalNameArrg,
		});
	}
	const skipBtn = () => {
		let index = JSONArr?.result?.map((val,i)=> val?.shotName === isDetailScreen?.vitalsName)?.indexOf(true);
		setisDetailScreen({
			state: JSONArr?.result[index + 1]?.shotName ? true : false,
			vitalsName: JSONArr?.result[index + 1]?.shotName ? JSONArr?.result[index + 1]?.shotName : "",
		});
    }
	return (
		<div style={{ padding: 20 }}>
			{!isDetailScreen.state ? (
				<div>
					<Grid container spacing={3}>
						<Grid container className={classes.titleDiv} spacing={0}>
							<Grid item xs={1} className={classes.imgea}>
								<img src={AvcBrochure }/>
							</Grid>
							<Grid item xs={11}>
								<span className={classes.title}>Patient Vitals Updation</span><br/>
								<span  className={classes.dis}>We request check the below vitals option and update the same. Which will be helpful for your consultation.</span>
							</Grid>
						</Grid>
						{JSONArr.result.map((val, i) => {
							return <VitalComp data={val} moveDetail={(vitalName)=> moveDetail(vitalName)}/>;
						})}
					</Grid>
					<div className={classes.actions}>
						<Button
							className={classes.ButtonAlign}
							style={{
								backgroundColor: "#F0F0F0",
								color: "#B6B6B6",
							}}
							// disabled={fileUpload.length < 1 ? true : false}
						>
							Update Vitals and Confirm
						</Button>
						<Button onClick={updateVitals} className={classes.ButtonAlign}>
							Skip
						</Button>
					</div>
				</div>
			) : <DetailPage data={JSONArr.result} isDetailScreen={isDetailScreen} moveDetail={()=>moveDetail} skipBtn={skipBtn}/>}
		</div>
	);
}
