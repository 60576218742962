import React, { useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import SearchLocationInput from './search'

// const useStyles = makeStyles((theme) => ({

// }));

const LocationParent = (props) => {
  // const classes = useStyles();

  const detectLocation = () => {
    if (navigator.geolocation && (!props?.value?.longitude)) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU`)
          .then(data => data.json())
          .then(({ results }) => {
            if (Array.isArray(results)) {
              const [info] = results;
              let city = info?.address_components?.find(comp => comp?.types?.includes("locality"));
              let location = info?.address_components?.find(comp => comp?.types?.includes("administrative_area_level_2"));
              let _obj = {};
              _obj["address"] = `${city?.long_name}, ${location?.long_name}`;
              _obj["latitude"] = lat;
              _obj["longitude"] = long;
              _obj["city"] = city?.long_name;
              _obj["location"] = location?.long_name;
              props.handleChange(_obj);
            }
          }).catch(console.error);
      });
    }
  };

  
  useEffect(() => {
    detectLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { parent_id } = props;
  return (
    <div
      id="searchlocation_parent_div"
    >
      <SearchLocationInput
        parent_id={`searchlocation`}
        value={props?.value?.address?.length > 20 ? props?.value?.address?.slice(0, 17) + '...' : props?.value?.address}
        address={props?.value?.address}
        label={props?.label}
        handleChange={props?.handleChange}
        isReadonly={props?.isReadonly ?? false}
        isrequired={props?.isrequired ?? false}
        active={props.active}
      />
    </div>
  );
};
LocationParent.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
};

export default LocationParent;
