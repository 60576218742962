import Config from "../config";

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const filterListingData = (filterList) => {
    let queryObj = {
        consultmode: [
            "Direct",
            "Video",
            "Both"
        ],
        gender: "",
        lang: "",
        speciality: [],
    };
    if (filterList.consultationMode) {
        const capitalizedConsultationMode = capitalizeFirstLetter(filterList.consultationMode);
        queryObj["consultmode"] = [capitalizedConsultationMode];
    }
    if (filterList.practitionerGender) {
        const genderVal = filterList.practitionerGender.toUpperCase();
        if (genderVal === "BOTH") {
            queryObj["gender"] = [
                "MALE",
                "FEMALE"
            ]
        } else {
            queryObj["gender"] = [genderVal]  
        }
    }
    if (filterList.specialties && Object.keys(filterList.specialties).length > 0) {
        queryObj["speciality"] = Object.keys(filterList.specialties).filter(k => {
            return filterList.specialties[k];
        }).map(s => Number(s));
    }
    if (filterList.practitionerPreferredLanguage && filterList.practitionerPreferredLanguage.length > 0) {
        queryObj["lang"] = filterList.practitionerPreferredLanguage.map(i => i.val);
    }
    return queryObj;
}


const checkAllBoth = (filterdata) => {
    let count = 0;
    if (filterdata.practitionerGender.toLocaleLowerCase() === "both") {
        count = count + 1;
    }
    if (filterdata.consultationMode.toLocaleLowerCase() === "both") {
        count = count + 1;
    }
    if (filterdata.practitionerPreferredLanguage.length === 0) {
        count = count + 1;
    }
    if (Object.keys(filterdata.specialties).length === 0) {
        count = count + 1;
    } else {
        const selectedVal = Object.keys(filterdata.specialties).find(key => filterdata.specialties[key]);
        if (!selectedVal) {
            count = count + 1;
        }
    }
    if (count === 4) {
        return true
    } else {
        return false
    }
}

export const UploadFun = async (file) => {
    return new Promise(async (resolve, reject) => {
        if (file.originalData) {
            return resolve({
                fileid: file.originalData.fileid,
                fileName: file.originalData.fileName,
                filetype: file.originalData.filetype,
                objectid: file.originalData.objectid,
            });
        }
        let formData = new FormData();
        formData.append('files', file);
        await fetch(Config.uploadUrl, {
            method: "POST",
            body: formData,
            redirect: 'follow'
        })
            .then((response) => response.json())
            .then(res => {
                resolve({
                    fileid: res.fileid,
                    fileName: file.name,
                    filetype: file.type,
                    objectid: res.objectid
                })
            }).catch(err => {
                resolve({})
            })
    })
}

export const uploadImage = (fileList) => {
    return new Promise(async (resolve, reject) => {
        if (!fileList && !Array.isArray(fileList)) {
            resolve([]);
            return;
        }
        let calls = fileList.map(async file => {
            return await UploadFun(file)
        })
        Promise.all(calls).then(data => {
            let dataList = [];
            data.map(val => {
                if (val.fileid) {
                    dataList.push({
                        fileid: val.fileid,
                        filetype: val.filetype,
                        objectid: val.objectid,
                        fileName: val.fileName
                    })
                }
                return false
            })
            resolve(dataList);
        })
            .catch(err => {
                resolve([]);
                console.log(err);
            })
    })
    // fetch("http://164.52.210.54:5000/primarycare/11", requestOptions)
}