import { Grid, makeStyles, Button } from "@material-ui/core";
import React from "react";
import AVCBloodPressure from "../../../assets/img/avc_blood_pressure.svg";
import AvcBreath from "../../../assets/img/avc_breath.svg";
import AvcCholesterol from "../../../assets/img/avc_cholesterol.svg";
import AvcFever from "../../../assets/img/avc_fever.svg";
import clsx from "clsx";
import AvcHeadC from "../../../assets/img/avc_head_c.svg";
import AvcHeight from "../../../assets/img/avc_height.svg";
import AvcOxi from "../../../assets/img/avc_oxi.svg";
import AvcPluse from "../../../assets/img/avc_pluse.svg";

import AvcSugar from "../../../assets/img/avc_sugar.svg";
import AvcWaist from "../../../assets/img/avc_waist.svg";
import AvcWeight from "../../../assets/img/avc_weight.svg";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F2F4FC",
	},
	title: {
		fontSize: 16,
		fontWeight: 500,
	},
	box: {
		border: "1px solid #F0F0F0",
		borderRadius: 6,
		margin: 9,
		minWidth: "30%",
		width: "30%",
		minHeight: "90px",
	},
	updateBtn: {
		padding: "4px 14px",
		fontSize: 12,
		marginTop: "4%",
		color: "#0071F2",
		border: "1px solid #36A2EB",
		fontWeight: 500,
		"&:hover": {
			color: "#0071F2",
			border: "1px solid #36A2EB",
		},
	},
	titleIfValue: {
		color: "#6F6F6F",
		fontSize: 15,
		fontWeight: 600,
	},
	units: {
		fontSize: 12,
		color: "#B6B6B6",
		marginLeft: "2%",
	},
	value: {
		fontSize: 18,
		fontWeight: "bold",
	},
	isnormal: {
		color: "#FF4D4A",
		fontSize: 11,
		fontWeight: 600,
	},
}));

export function VitalComp(props) {
	const classes = useStyles();
	let component = props?.data?.shotName;
	const [styleCustm, setStyle] = React.useState(false);
	return (
		<Grid
			item
			xs={12}
			sm={3}
			md={3}
			lg={3}
			className={classes.box}
			onMouseEnter={(e) => {
				setStyle(true);
			}}
			onMouseLeave={(e) => {
				setStyle(false);
			}}
			style={
				styleCustm
					? {
							boxShadow: "rgb(0 0 0 / 20%) 0px 5px 12px -4px",
					  }
					: null
			}
		>
			<Grid container xs={12} sm={12} className={classes.titleDiv}>
				<Grid item xs={3}>
					<img
						src={
							component === "Temp"
								? AvcFever
								: component === "Weight Height"
								? AvcWeight
								: component === "Cholesterol"
								? AvcCholesterol
								: component === "Head"
								? AvcHeadC
								: component === "Pressure"
								? AVCBloodPressure
								: component === "Respiratory"
								? AvcBreath
								: component === "Sugar"
								? AvcSugar
								: component === "Oxygen"
								? AvcOxi
								: component === "Waist"
								? AvcWaist
								: component === "Pulse"
								? AvcPluse
								: AvcOxi
						}
						alt={"vitals"}
					/>
				</Grid>
				{styleCustm ? (
					<Fade
						in={styleCustm}
						style={{ transitionDelay: styleCustm ? "70ms" : "0ms" }}
					>
						<Grid item xs={9}>
							<Grid container xs={12} sm={12}>
								<span className={classes.title} style={{ fontSize: 12 }}>
									{props?.data?.title}
								</span>
							</Grid>
							<Grid container xs={12} sm={12}>
								<Button
									variant="outlined"
									color="primary"
									className={classes.updateBtn}
									onClick={()=> props.moveDetail(component)}
								>
									update value
								</Button>
							</Grid>
						</Grid>
					</Fade>
				) : null}

				{!styleCustm ? (
					props?.data?.value ? (
						<Grid item xs={9}>
							<Grid container xs={12} sm={12}>
								<span className={classes.titleIfValue}>
									{props?.data?.title}
								</span>
							</Grid>
							<Grid container xs={12} sm={12} style={{ paddingTop: "4%" }}>
								<Grid
									container
									xs={6}
									sm={6}
									style={{ alignItems: "baseline" }}
								>
									<span className={classes.value}>{props?.data?.value}</span>
									<span className={classes.units}>{props?.data?.unit}</span>
								</Grid>
								<Grid
									container
									xs={6}
									sm={6}
									style={{ justifyContent: "end", alignItems: "center" }}
								>
									<span className={classes.isnormal}>
										{!props?.data?.normal ? "Abnormal" : null}
									</span>
								</Grid>
							</Grid>
						</Grid>
					) : (
						<Grid item xs={9}>
							<span className={classes.title}>{props?.data?.title} value</span>
						</Grid>
					)
				) : null}
			</Grid>
		</Grid>
	);
}
