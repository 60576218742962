import { Grid, makeStyles, Button } from "@material-ui/core";
import React from "react";
import { DetailPageList } from "./detailList";
import {DetailSection} from "./detailSection";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F2F4FC",
	},
    DetailSection:{
    },
    listSection:{
        paddingRight: "3%",
    },
    btn: {
        textTransform: "none"
    },
    rightDiv: {
        textAlign: "right"
    },
    btnUpd: {
        backgroundColor: "#0071F2"
    }
}));

export const DetailPage = (props) => {
	const classes = useStyles();
    debugger;
    const [select, setSelect] = React.useState(props?.isDetailScreen?.vitalsName);
    React.useEffect(()=>{
        setSelect(props?.isDetailScreen?.vitalsName)
    },[props?.isDetailScreen?.vitalsName])

    const listClick = (shotName) => {
        setSelect(shotName)
    }
    
	return (
			<Grid item xs={12}>
				<Grid container xs={12} sm={12}>
                    <Grid container className={classes.titleDiv} spacing={0}>
                        <Grid item xs={3} className={classes.listSection}>
                            <DetailPageList data={props.data} vitalsSelect={select} listClick={(shotName)=>listClick(shotName)}/>
                        </Grid>
                        <Grid item xs={9} className={classes.DetailSection}>
                            <DetailSection data={props.data} vitalsSelect={select} />
                        </Grid>
                    </Grid>
				</Grid>
                <Grid container style={{marginBottom: 8, marginTop: 24,borderTop: "1px solid #ddd", paddingTop: "2%"}} spacing={2}>
                    <Grid item xs={6}>
                        <Button variant="outlined" className={classes.btn} onClick={()=>props.moveDetail()}>
                            Close
                        </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.rightDiv}>
                        <Button variant="outlined" className={classes.btn} onClick={()=> props.skipBtn()}>
                            Skip
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="contained" color="primary" className={classes.btnUpd} onClick={()=>props.moveDetail()}>
                           Update
                        </Button>
                    </Grid>
                </Grid>
			</Grid>
	);
}
