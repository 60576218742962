import React from "react";
import CarousalSupport from "./carousalSupport";
import { TopNavBarAvc } from "../../../components";
import {SupportHome} from "./supportHome";

export const SupportPages = (props) => {
	const [startTour, setstartTour] = React.useState(false);
	const startTourbtn = () => {
		setstartTour(!startTour);
	}
	return (
		<div>
			<TopNavBarAvc clicksupportPageBtn={props.clicksupportPageBtn}/>
			{startTour ? <CarousalSupport startTourbtnFun={()=> startTourbtn()}/> : <SupportHome startTourbtnFun={()=> startTourbtn()}/>}
		</div>
	);
}

