import React from 'react';
import { TextField,makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(()=>({
    textField:{
        '& input':{   
            fontFamily:'pc_medium',
            fontSize:14
        }
    }
}))

export const Selection = (props) =>{

    const classes =  useStyles(props);

    return(
        <div>
            <Autocomplete
                id={`${props?.parent_id}_collapse_body_autocomplete`}
                size="small"
                multiple={props?.multiple}
                options={props.options}
                getOptionLabel={(option) => option?.[props?.optionLabel]}
                filterSelectedOptions={props?.filterSelectedOptions}
                value={props?.value}
                onChange={(event, newValue) => props.onChange(event, newValue)}
                // classes={{
                // popupIndicator: classes.popupIndicator
                // }}
                renderInput={(params) => (
                <TextField
                    id={`${props?.parent_id}_collapse_body_autocomplete_label_add_your_health`}
                    {...params}
                    variant="outlined"
                    label={props.label ?? ''}
                    className={classes.textField}
                    error={props.error}
                />
                )}
                style={{
                borderColor: "#E0E0E0",
                // flex:1
                }}
                className={"pc_regular"}
                disabled={props?.disabled ? true : false}
            />
        </div>
    )
}

Selection.defaultProps={
    multiple:false,
    options:[],
    optionLabel:'label',
    filterSelectedOptions:false,
    value:null,
    label:"",
    parent_id:''

}