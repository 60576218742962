import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  Div,
  Text,
  Avatar,
  H6,
  Row,
  Col,
  Container
} from 'qdm-component-library'
let location = {
  city: [
    {
      title: 'Chennai',
      image: 'https://i.ibb.co/yYjKLD4/Group-1516-2x.png'
    },
    {
      title: 'Nagarcoil',
      image: 'https://i.ibb.co/C6qfRkM/Group-1711-2x.png'
    },
    {
      title: 'Pondicherry',
      image: 'https://i.ibb.co/d7q9HHM/Group-1558-2x.png'
    },
    {
      title: 'Coimbatore',
      image: 'https://i.ibb.co/MZdVWG5/Group-1591-2x.png'
    },
    {
      title: 'Salem',
      image: 'https://i.ibb.co/6ggymYq/Group-1656-2x.png'
    }
  ],
  localities: {
    popular: [
      {
        value: 'Alwarpet',
        label: 'Alwarpet'
      },
      {
        value: 'Mount Road',
        label: 'Mount Road'
      },
      {
        value: 'ECR',
        label: 'ECR'
      },
      {
        value: 'T.Nagar',
        label: 'T.Nagar'
      },
      {
        value: 'Guindy',
        label: 'Guindy'
      }
    ],
    other: [
      {
        value: 'Guindy',
        label: 'Guindy'
      },
      {
        value: 'Guindy',
        label: 'Guindy'
      },
      {
        value: 'Guindy',
        label: 'Guindy'
      },
      {
        value: 'Mount Road',
        label: 'Mount Road'
      },
      {
        value: 'Mount Road',
        label: 'Mount Road'
      },
      {
        value: 'T.Nagar',
        label: 'T.Nagar'
      }
    ]
  }
}
class LocationSearchResult extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  componentDidMount () {}

  shouldComponentUpdate (props, state) {
    return true
  }

  handleCityClick = async title => {
    this.props.handlesearchActives('select_city', title);
    if (this.props.GET_LOCATIONS) {
      this.props.GET_LOCATIONS({ city: title })
    }
  }

  render () {
    const { handlesearchActives, values } = this.props
    return (
      <div>
        <Container
          key={'0'}
          name='container'
          fluid={false}
          style={{
            backgroundColor: '#fff',
            borderRadius: '16px',
            width: '792px',
            padding: "0px",
          }}
        >
          <Div
            key={'0'}
            inLineStyles='[object Object]'
            className='qdm'
            style={{
              width: 'auto',
              height: '',
              padding: '16px',
              backgroundColor: '#ffffff',
              borderRadius: '8px'
            }}
          >
            <Text
              key={'0'}
              name='SELECT YOUR CITY'
              style={{ fontSize: '12PX', color: '#6F6F6F' }}
            ></Text>

            <Div
              inLineStyles='[object Object]'
              className='qdm'
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginTop: '12px'
              }}
            >
              {location?.city?.map((city, key) => (
                // {this.props?.cities?.data?.map((city, key) => (
                <Div
                  key={key}
                  inLineStyles='[object Object]'
                  className='qdm'
                  style={{
                    backgroundColor: '#ffffff',
                    borderColor:
                      values.select_city === city.title ? '#007aff' : '#ffffff',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderRadius: '8px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    height: "100px",
                    width: "100px",
                  }}
                  onClick={() => this.handleCityClick(city.title)}
                >
                  <Avatar
                    variant='rounded'
                    letter='Avatar'
                    src={city.image}
                    alt='Image'
                    style={{
                      padding: '8PX',
                      width: '64px',
                      height: '64px',
                      backgroundColor: '#ffffff',
                      margin: "0 auto",
                    }}
                  />

                  <H6
                    name={city.title}
                    style={{
                      textAlign: 'center',
                      color: values.select_city === city.title ? '#0071F2' : '#6f6f6f',
                      fontSize: '12px',
                      paddingBottom: "12px",
                      fontWeight: values.select_city === city.title ? "bold" : "normal",
                    }}
                    inLineStyles={{
                      fontFamily:
                        values.select_city === key
                          ? "'pc_medium' !important"
                          : "'pc_regular' !important"
                    }}
                  />
                </Div>
              ))}
            </Div>

            <Div
              key={'2'}
              inLineStyles='[object Object]'
              className='qdm'
              style={{ display: 'flex', marginTop: '24px' }}
            >
              <Div
                key={'0'}
                inLineStyles='[object Object]'
                className='qdm'
                style={{ marginRight: '12px', width: '350px' }}
              >
                <H6
                  key={'0'}
                  name={
                    true || this.props.locations?.data?.popular?.length > 0
                      ? 'POPULAR LOCLITIES'
                      : ''
                  }
                  style={{
                    color: '#6F6F6F',
                    fontSize: '12px',
                    marginBottom: "8px",
                  }}
                ></H6>

                <Row
                  key={'1'}
                  alignItems='flex-start'
                  direction='row'
                  justify='flex-start'
                  spacing='2'
                  style={{ marginTop: '24px', marginLeft: '' }}
                >
                  {location?.localities.popular?.map((pop, key) => (
                    // {this.props?.locations?.data?.popular?.map((pop, key) => (
                    <Col
                      key={key}
                      name='qdm-col-12'
                      alignItems='stretch'
                      direction='row'
                      justify='flex-start'
                      textAlign='left'
                      lg='6'
                      xs='6'
                      md='6'
                      sm='6'
                      style={{ marginTop: '8px', paddingLeft: "0" }}
                    >
                      <H6
                        name={pop.label}
                        style={{
                          color:
                            values.select_city_name === pop.label
                              ? '#007aff'
                              : '#101010',
                          fontSize: '12px',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          handlesearchActives('select_city_name', pop.label)
                        }
                      ></H6>
                    </Col>
                  ))}
                </Row>
              </Div>

              <Div
                key={'1'}
                inLineStyles='[object Object]'
                className='qdm'
                style={{ width: '350px' }}
              >
                <H6
                  key={'0'}
                  name={
                    true || this.props.locations?.data?.other?.length > 0
                      ? 'OTHER LOCALITIES'
                      : ''
                  }
                  style={{
                    color: '#6F6F6F',
                    fontSize: '12PX',
                    marginBottom: "8px",
                  }}
                    
                ></H6>

                <Row key={'1'}>
                  {location?.localities.other?.map((other, key) => (
                    //  {this.props?.locations?.data?.other?.map((other, key) => (
                    <Col
                      key={key}
                      name='qdm-col-12'
                      alignItems='stretch'
                      direction='row'
                      justify='flex-start'
                      textAlign='left'
                      lg='6'
                      xs='6'
                      md='6'
                      sm='6'
                      style={{ marginTop: '8px', paddingLeft: "0" }}
                    >
                      <H6
                        key={'0'}
                        name={other.label}
                        style={{
                          color:
                            values.select_city_name === other.label
                              ? '#007aff'
                              : '#101010',
                          fontSize: '12px',
                          cursor: 'pointer'
                        }}
                        onClick={() =>
                          handlesearchActives('select_city_name', other.label)
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </Div>
            </Div>
          </Div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  locations: state?.searhcApiSlice?.locations,
  cities: state?.searhcApiSlice?.cities,
})

export default connect(
  mapStateToProps,
  actions
)(withRouter(LocationSearchResult))
