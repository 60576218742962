import { makeStyles } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import DoctorStethoscope from "../../../assets/img/avc_doc.svg";
import Pinkvideo from "../../../assets/img/avc_pink_video.svg";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#fff",
	},
	infoText: {
		fontSize: 10,
		color: "#B6B6B6",
		fontWeight: 600,
	},
	title: {
		fontSize: 12,
		color: "#101010",
		marginBottom: 3,
		marginTop: 3,
		fontWeight: 600,
	},
	dis: {
		fontSize: 12,
		color: "#6f6f6f",
		marginBottom: 3,
		marginTop: 3,
		fontWeight: 400,
	},

	Avatar: {
		width: 32,
		height: 32,
	},
	infoDiv: {
		padding: 12,
		border: "1px solid #F0F0F0",
		borderRadius: 9,
		marginTop: "4%",
		marginBottom: "4%",
		position: "relative",
	},
	iconN: {
		position: "absolute",
		right: "-30px",
		backgroundColor: "#0071F2",
		borderRadius: "50px",
		color: "#fff",
		top: 0,
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "#025fc9",
		},
	},
}));

export function InfoApp(props) {
	const { loading, data } = useSelector((state) => state?.avcMeetApiSlice?.avc_info);
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item xs={12}>
					<span className={classes.infoText}>
						{props.sidekick ? "INFO" : "APPOINTMENT INFO"}
					</span>
				</Grid>
			</Grid>
			{props.sidekick ? (
				<Grid
					container
					alignItems={"center"}
					justify={"center"}
					className={classes.infoDiv}
				>
					<Grid container alignItems={"center"} justify={"center"}>
						<Grid item xs={12} sm={8}>
							<Avatar
								alt="Remy Sharp"
								src={Pinkvideo}
								className={classes.Avatar}
							/>
						</Grid>
					</Grid>
					<ChevronLeftIcon
						className={classes.iconN}
						onClick={() => props.funSideKick()}
						style={{
							transform: "rotate(180deg)",
						}}
					/>
					<Grid container alignItems={"center"} justify={"center"}>
						<Grid item xs={12} sm={8} style={{ paddingTop: 22 }}>
							<Avatar
								alt="Remy Sharp"
								src={DoctorStethoscope}
								className={classes.Avatar}
							/>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Grid container alignItems={"center"} className={classes.infoDiv}>
					<Grid item xs={12} sm={2}>
						<Avatar
							alt="Remy Sharp"
							src={Pinkvideo}
							className={classes.Avatar}
						/>
					</Grid>
					<ChevronLeftIcon
						className={classes.iconN}
						onClick={() => props.funSideKick()}
					/>
					<Grid item xs={12} sm={10} style={{ paddingLeft: 12 }}>
						<section class="ticket__section">
							<p className={classes.title}>{data?.doctor?.name??""}</p>
							<p className={classes.dis}>{data?.doctor?.type??""}</p>
						</section>
					</Grid>
					<Grid item xs={12} sm={2} style={{ paddingTop: 12 }}>
						<Avatar
							alt="Remy Sharp"
							src={DoctorStethoscope}
							className={classes.Avatar}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={10}
						style={{ paddingLeft: 12, paddingTop: 12 }}
					>
						<section class="ticket__section">
							<p className={classes.title}>{data?.date? moment(data?.dataJson?.avc?.start_time?.trim()).format("DD MMM YYYY hh:mm a") : ""}</p>
							<p className={classes.dis}>{data?.app_id??""}</p>
						</section>
					</Grid>
				</Grid>
			)}
		</div>
	);
}
