import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Verification from "../../assets/img/verification.svg";
import {
  Container,
  Div,
  //   Icons,
  Text,
  Divider,
  // Card,
  H6,
  // ListItem,
  Avatar,
  //   Dropdown,
  TextArea,
  Button,
  OtpInput,
} from "qdm-component-library";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import {
  checkError,
  AlertProps,
  convertTime,
  getUserInfo,
  makeName,
  utcTOLocal,
  UIColor,
} from "../../utils";
import { AlertContext } from "../../contexts";
import { Selection } from '../'

const styles = (theme) => ({
  select: {
    marginTop:16,
    "& fieldset": {
      borderColor: UIColor.lineBorderFill.color,
      borderRadius:8
  },
  '& input':{
    height:8
  }
}
});

class CancelBookingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resend: "",
      successResend: "",
      appointmentInfo: {},
      otp: "",
      reasonError:false,
    };
    this.getUserInfo = getUserInfo.bind(this);
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    this.fetchInfo();
  }

  async fetchInfo() {
    const data = await this.props.APPOINTMENT_READ({
      appointmentId: Number(this.props.appointmentId),
    });
    this.props.REASON_FOR_APPT_CANCEL_MODIFY({type:'APPCANCELBYUSER'})
    this.changeState("appointmentInfo", data?.payload?.data || {});
  }

  handleOtpChange = (val) => {
    this.setState({
      otp: val,
      resend:"",
      successResend: ""
    });
  };

  shouldComponentUpdate(props, state) {
    return true;
  }
  resendOTP = async () => {
    this.changeState("otp", "");
    let mobile = this.props.loggedUserInfo.data.mobile;
    const res = await this.props.RESEND_OTP({
      mobileNo: mobile,
    });

    const status = checkError(this.props.resendOTP);
    if (status.isError) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else {
      this.setState({
        successResend: res?.payload?.data?.message,
        resend: ""
      });
    }
  };
  getPractionerName(val) {
    if ((val?.resourcetype || "")?.toLowerCase() === "practitioner") {
      return makeName(val?.resourcecode?.name?.[0] ?? {});
    }
    return val?.resourcecode?.shortdesc;
  }
  render() {
    const { cancelbookings, cancelbookingreason, parent_id, classes } =
      this.props;
    return (
      <div id={`${parent_id}_cancelbooking_parent_div`}>
        <Container
          id={`${parent_id}_cancelbooking_parent_container`}
          key={"0"}
          name="container"
          fluid={false}
          style={{
            padding: "16px 25px",
          }}
        >
          <H6
            id={`${parent_id}_cancelbooking_cancelappointment_span`}
            key={"0"}
            className="pc_semibold"
            style={{
              fontSize: 20,
              color: UIColor.primaryText.color,
              letterSpacing: 0,
              margin: 0,
              lineHeight: 1,
            }}
            name={"Cancel Appointment"}
          ></H6>

          <Divider
            id={`${parent_id}_cancelbooking_bottom_divider`}
            key={"1"}
            textWidth={"50"}
            textColor={"#000"}
            borderColor={UIColor.lineBorderFill.color}
            borderWidth={"1"}
            orientation={"horizontal"}
            variant={"middle"}
            style={{ margin: "16px 0px" }}
            //   style={qdmstyles.BKv}
          ></Divider>
          {cancelbookings === 2 && (
            <Div
              id={`${parent_id}_cancelbooking_verification_div`}
              key={"0"}
              style={{ textAlign: "center", marginTop: 30 }}
            >
              <img
                id={`${parent_id}_cancelbooking_verification_image`}
                alt={"Verification"}
                src={Verification}
              />
            </Div>
          )}

          {cancelbookings === 2 && (
            <Div
              id={`${parent_id}_cancelbooking_your_appointment_div`}
              key={"1"}
              className="pc_semibold"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
                marginBottom: 30,
                letterSpacing: 0,
              }}
            >
              <Text
                id={`${parent_id}_cancelbooking_your_appointment_text`}
                key={"0"}
                name="Your appointment has been cancelled !"
                className="pc_semibold"
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: UIColor.success.color,
                  letterSpacing: 0,
                }}
              ></Text>
            </Div>
          )}

          <Div
            id={`${parent_id}_cancelbooking_div`}
            style={{
              marginTop: "10px",
              boxShadow: "none",
              borderTop: cancelbookings === 2 && `1px solid ${UIColor.lineBorderFill.color}`,
              borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
              display: "flex",
              paddingBottom: "16px",
              paddingTop: cancelbookings === 2 && "16px",
              justifyContent: "space-around",
            }}
          >
            <Div
              id={`${parent_id}_cancelbooking_sub_div`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderRight: `1px solid ${UIColor.lineBorderFill.color}`,
                // paddingRight: '13px'
              }}
            >
              <Div
                id={`${parent_id}_cancelbooking_image_div`}
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: "50%",
                  marginRight: "8px",
                  overflow: "hidden",
                }}
              >
                <img
                  id={`${parent_id}_cancelbooking_image`}
                  alt="img"
                  style={{ width: "100%", height: "auto" }}
                  src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                />
              </Div>
              <Div
                id={`${parent_id}_cancelbooking_appointmentinfo_div`}
                style={{ flex: 1 }}
              >
                <H6
                  id={`${parent_id}_cancelbooking_appointmentinfo_h6`}
                  className="pc_regular"
                  style={{
                    fontSize: "14px",
                    color: UIColor.primaryText.color,
                    letterSpacing: 0,
                    lineHeight: 1.3,
                  }}
                >
                  {this.getPractionerName(this.state.appointmentInfo)}
                </H6>
                <Text
                  id={`${parent_id}_cancelbooking_appointmentinfo_span`}
                  className="pc_regular"
                  style={{
                    fontSize: "10px",
                    letterSpacing: "-0.08px",
                    color: UIColor.secondaryText.color,
                  }}
                >
                  {this.state.appointmentInfo?.orgid?.name || ""}
                </Text>
              </Div>
            </Div>
            <Div
              id={`${parent_id}_cancelbooking_logo_div`}
              style={{
                width: 1,
                height: 42,
                backgroundColor: UIColor.lineBorderFill.color,
                margin: "0px 10px",
              }}
            />
            <Div
              id={`${parent_id}_cancelbooking_logo_sub_div`}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Div id={`${parent_id}_cancelbooking_logo_calender_div`}>
                <Avatar
                  id={`${parent_id}_cancelbooking_logo_calender_image`}
                  key={"0"}
                  variant="circle"
                  letter="Logo"
                  src="https://d27zlipt1pllog.cloudfront.net/pub/static/version1608753130/frontend/Apollo/mobile/en_US/images/new-img/logo_2.png"
                  alt="Image"
                  style={{
                    backgroundColor: UIColor.differentiationBackground.color,
                    paddingTop: "0px",
                    width: "32px",
                    height: "32px",
                    margin: "0px",
                    marginRight: "8px",
                  }}
                >
                  <Avatar
                    id={`${parent_id}_cancelbooking_calender_image`}
                    src="/static/media/calendar.374dbd8e.svg"
                    alt="Image"
                    style={{
                      backgroundColor: "transparent",
                      height: "auto",
                      width: "14px",
                    }}
                    variant="square"
                  ></Avatar>
                  {/* <Icons
                    key={"2"}
                    fontIcon="calendar"
                    ariaHidden="true"
                    className=""
                    size="small"
                    style={{
                      color: UIColor.tertiaryText.color,
                      fontSize: "12px",
                      padding: "12px",
                    }}
                  ></Icons> */}
                </Avatar>
              </Div>
              <Div id={`${parent_id}_cancelbooking_slots_div`}>
                <H6
                  id={`${parent_id}_cancelbooking_slots_h6`}
                  className="pc_regular"
                  style={{
                    fontSize: "14px",
                    color: UIColor.primaryText.color,
                    letterSpacing: 0,
                    lineHeight: 1.3,
                  }}
                >
                  {utcTOLocal(this.state.appointmentInfo?.Slots?.[0]?.start,"DD MMM YY | hh:mm a")}
                </H6>
                <Text
                  id={`${parent_id}_cancelbooking_consultationmode_span`}
                  className="pc_regular"
                  style={{
                    fontSize: "10px",
                    letterSpacing: "-0.08px",
                    color: UIColor.secondaryText.color,
                  }}
                >
                  {this.state.appointmentInfo?.consultationMode}
                </Text>
              </Div>
            </Div>
          </Div>
          <Div
            id={`${parent_id}_cancelbooking_content_parent_div`}
            style={{
              padding: "10px",
              border: `1px solid ${UIColor.lineBorderFill.color}`,
              background: UIColor.greyBackground.color,
              margin: "16px 0px",
              borderRadius: "6px",
              // height: '12vh'
            }}
          >
            <Div
              id={`${parent_id}_cancelbooking_description_div`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "9px",
              }}
            >
              <H6
                id={`${parent_id}_cancelbooking_description_span`}
                className="pc_medium"
                style={{
                  fontSize: "12px",
                  color: UIColor.secondaryText.color,
                  letterSpacing: 0,
                  margin: 0,
                }}
              >
                DESCRIPTION
              </H6>
              <H6
                id={`${parent_id}_cancelbooking_description_span`}
                className="pc_medium"
                style={{
                  fontSize: "12px",
                  color: UIColor.secondaryText.color,
                  letterSpacing: 0,
                  margin: 0,
                }}
              >
                TOTAL
              </H6>
            </Div>
            <Div
              id={`${parent_id}_cancelbooking_cancellation_charge_div`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "9px",
                borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
              }}
            >
              <H6
                id={`${parent_id}_cancelbooking_cancellation_charge_span`}
                className="pc_regular"
                style={{
                  fontSize: "14px",
                  color: UIColor.primaryText.color,
                  letterSpacing: 0,
                  margin: 0,
                }}
              >
                Cancellation charge ( Nil )
              </H6>
              <H6
                id={`${parent_id}_cancelbooking_cancellation_charge_amount_span`}
                className="pc_regular"
                style={{
                  fontSize: "14px",
                  color: UIColor.primaryText.color,
                  letterSpacing: 0,
                  margin: 0,
                }}
              >
                ₹ 0.00
              </H6>
            </Div>
          </Div>
          {/* <br /> */}
          <H6
            id={`${parent_id}_cancelbooking_reason_span`}
            className="pc_regular"
            style={{ fontSize: "14px", margin: 0, lineHeight: 1 }}
          >
            <span
              className="pc_regular"
              id={`${parent_id}_cancelbooking_reason_for_span`}
              style={{ fontSize: "14px", color: UIColor.secondaryText.color, letterSpacing: 0 }}
            >
              Reason for cancellation
              <label
                id={`${parent_id}_cancelbooking_reason_for_label`}
                style={{ color: UIColor.error.color, marginLeft: 4 }}
              >
                *
              </label>
            </span>
          </H6>

          <Div
            id={`${parent_id}_cancelbooking_reason_cancel_div`}
            key={"2"}
            className={classes.select}
          >
            <Selection 
              options={this.props?.reasonForApptCancelModify.data || []}
              optionLabel="title"
              value={cancelbookingreason}
              label={''}
              parent_id={parent_id}
              onChange={(e,value)=> { 
                this.setState({
                reasonError:false
              });
              this.props.handleSelectCancelbooking(value) } }
              error={this.state.reasonError}
              disabled={cancelbookings === 2 ? true : false}
            />
          </Div>
          {cancelbookings === 1 && (
            <Div
              id={`${parent_id}_cancelbooking_otp_div`}
              inLineStyles={{
                backgroundColor: UIColor.differentiationBackground.color,
                padding: 20,
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "24px",
              }}
            >
              <H6
                id={`${parent_id}_cancelbooking_otp_span`}
                className="pc_medium"
                style={{
                  fontSize: 16,
                  color: UIColor.primaryText.color,
                  letterSpacing: 0,
                  margin: 0,
                  marginBottom: 41,
                }}
              >
                OTP Verification
              </H6>
              <H6
                id={`${parent_id}_cancelbooking_please_enter_otp_span`}
                className="pc_regular"
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  color: UIColor.secondaryText.color,
                  letterSpacing: 0,
                  margin: 0,
                }}
              >
                Please enter the OTP sent to <br />
                <H6
                  id={`${parent_id}_cancelbooking_mobile_span`}
                  className="pc_medium"
                  style={{
                    color: UIColor.primaryText.color,
                    fontSize: 14,
                    letterSpacing: 0,
                    margin: 0,
                  }}
                >
                  {this.props.loggedUserInfo.data.mobile}
                </H6>
              </H6>
              <Div
                id={`${parent_id}_cancelbooking_otpinput_div`}
                style={{ marginBottom: 30 }}
              >
                <OtpInput
                  id={`${parent_id}_cancelbooking_otpinput`}
                  onChange={(e) => {
                    this.handleOtpChange(e);
                  }}
                  numberOfInputs={6}
                  variant="standard"
                  type="text"
                  focusTextColor={UIColor.secondaryColor.color}
                  focusBorderColor={UIColor.secondaryColor.color}
                  inLineStyles={{ backgroundColor: "transparent" }}
                  value={this.state.otp}
                  // style={ { display : "flex",justifyContent : "center",alignItems : "center",paddingLeft : "" } }
                  className={this.state.resend === "Invalid OTP" && "otpError"}
                />
              </Div>
              <Text
                id={`${parent_id}_cancelbooking_didn't_receive_span`}
                className="pc_regular"
                inLineStyles={{ color: UIColor.secondaryText.color, fontSize: 12 }}
              >
                Didn't receive OTP ?{" "}
                <Text
                  id={`${parent_id}_cancelbooking_resend_otp_span`}
                  className="pc_medium"
                  inLineStyles={{
                    color: UIColor.primaryText.color,
                    fontSize: 12,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => this.resendOTP()}
                >
                  Resend OTP
                </Text>
              </Text>
              {this.state.resend && (
                <Text
                  id={`${parent_id}_cancelbooking_resend_otp_error_span`}
                  name={
                    <span
                      id={`${parent_id}_cancelbooking_otp_error_span`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                        marginBottom: "6px",
                        color: UIColor.error.color,
                      }}
                    >
                      {this.state.resend}
                    </span>
                  }
                ></Text>
              )}
              {this.state.successResend && (
                <Text
                  id={`${parent_id}_cancelbooking_successresend_otp_error_span`}
                  name={
                    <span
                      id={`${parent_id}_cancelbooking_otp_error_span`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                        marginBottom: "6px",
                        color: UIColor.success.color,
                      }}
                    >
                      {this.state.successResend}
                    </span>
                  }
                ></Text>
              )}
            </Div>
          )}
          {cancelbookingreason?.value ===
            "Other reason ( enter your reason manually )" &&
            cancelbookings === 0 && (
              <TextArea
                id={`${parent_id}_cancelbooking_enter_manually_textarea`}
                placeholder="Enter your reason manually"
                style={{
                  marginTop: "16px",
                  padding: 14,
                  resize: "none",
                  height: "14vh",
                  borderColor: UIColor.lineBorderFill.color,
                  borderRadius: 8,
                  fontSize: 14,
                  fontFamily: "pc_regular",
                  color: UIColor.primaryText.color,
                }}
              />
            )}
          <Button
            id={`${parent_id}_cancelbooking_confirm_cancel_otp_button`}
            key={"1"}
            type="button"
            disabled={
              this.props?.signIn?.loading ||
              this.props?.cancelAppointment?.loading
            }
            className="pc_medium"
            variant="outlined"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              marginTop: "40px",
              fontSize: "14px",
              padding: "8px 16px",
              marginBottom: "10px",
              backgroundColor: UIColor.primaryColor.color,
              border: "none",
              borderRadius: 8,
              height: 40,
            }}
            onClick={async () => {
              if (cancelbookings === 1) {
                this.setState({
                  ...this.state,
                  resend: "",
                  successResend: ""
                })
                // let mobile = this.state.appointmentInfo?.AppointmentNotification?.[0]?.mobile;
                let mobile = this.props.loggedUserInfo.data.mobile;
                if (!mobile) {
                  const userInfo = await this.getUserInfo();
                  mobile = userInfo.mobile;
                }
                const data = await this.props.SIGN_IN({
                  mobileNo: mobile,
                  otp: this.state.otp,
                });
                const status = checkError(data?.payload || {});
                if (!status.isError) {
                  const appointmentData = JSON.parse(
                    JSON.stringify(this.state.appointmentInfo)
                  );
                  appointmentData["cancelationReason"] = [cancelbookingreason?._id];

                  const cancelData = await this.props.APPOINTMENT_DELETE(
                    appointmentData
                  );
                  const cancelStatus = checkError(cancelData?.payload || {});
                  if (!cancelStatus.isError) {
                    this.props.handlecahngeCancelbooking(2);
                  } else {
                    this.changeState("resend", status.errMsg);
                  }
                } else {
                  this.changeState("resend", status.errMsg);
                }
              }
              else {
                if(!cancelbookingreason?.value){
                  this.context.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: 'Please select reason for cancellation',
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                    tone: true,
                  })
                  this.setState({
                    reasonError:true
                  })
                  return
                }
                this.props.handlecahngeCancelbooking(
                  cancelbookings === 0 ? 1 : null,
                  this.props.loggedUserInfo.data.mobile
                );
              }
            }}
          >
            {this.props?.signIn?.loading ||
            this.props?.cancelAppointment?.loading
              ? "Canceling..."
              : cancelbookings === 0
              ? this.props?.generateOTP?.loading ? "Sending OTP..." : "Send OTP & Cancel"
              : cancelbookings === 1
              ? this.props?.sigin?.loading || this.props?.cancelAppointment?.loading ? "Cancelling" : "Confirm & Cancel"
              : cancelbookings === 2 && "Back to Home"}
          </Button>
          {cancelbookings === 1 && (
            <Text
              id={`${parent_id}_cancelbooking_back_span`}
              onClick={() => {
                this.setState({
                  ...this.state,
                  resend: "",
                  successResend: ""
                })
                this.props.handlecahngeCancelbooking("back")
              }}
              name={"Back"}
              style={{
                display: "flex",
                justifyContent: "center",
                color: UIColor.primaryColor.color,
                textDecoration: "underline",
                marginTop: "18px",
                cursor: "pointer",
              }}
            ></Text>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resendOTP: state?.authSlice?.resendOTP,
  sigin: state?.authSlice?.signIn,
  cancelAppointment: state?.appointmentReducer?.deleteAppointment,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
  reasonForApptCancelModify: state?.appointmentReducer?.reasonForApptCancelModify,
  generateOTP: state?.authSlice?.generateOTP
});
CancelBookingPage.contextType = AlertContext;

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(CancelBookingPage))
);
