import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography, Popover } from "@material-ui/core";
import {
  Container,
  Row,
  Div,
  Avatar,
  Col,
  Divider,
  H6,
  Icons,
  Text,
} from "qdm-component-library";
import { getImgUrl, getUserInfo, Logout, UIColor } from "../../utils";
import CloseIcon from "@material-ui/icons/Close";

import Logo from "../../assets/img/Logo_W.svg";
import { Routes } from "../../router/routes";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: "Guest",
      },
      anchorEl: null,
    };
    this.getUserInfo = getUserInfo.bind(this);
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    this.getInfo();
  }

  async getInfo() {
    const info = await this.getUserInfo();
    if (info.type === "token") {
      this.changeState("user", info);
    } else if (info.type === "guest") {
      this.changeState("user", info);
      await this.props.GUEST_INFO?.(info.info);
    }
  }

  shouldComponentUpdate(props, state) {
    return true;
  }

  handleProfileClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleProfileClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };
  clickHome = () => {
    this.props.history.push("/");
  };
  render() {
    //   state declare
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    if (!this.props?.values?.specialities) {
      window.location.href = Routes.doctorConsultation;
      return "";
    }
    const { parent_id } = this.props
    return (
      <div
        id={`${parent_id}_header_parent_div`}
        style={{ width: "100%" }}>
        <Container
          id={`${parent_id}_header_parent_container`}
          key={"0"}
          name="container"
          fluid={true}
          style={{ backgroundColor: "", height: "", padding: "0px", width: "" }}
        >
          <Row
            id={`${parent_id}_header_parent_row_one`}
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              backgroundColor: "#01205C",
              height: "",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Div
              id={`${parent_id}_header_logo_div`}
              key={"0"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <img
                id={`${parent_id}_header_logo_image`}
                key={"0"}
                variant="rounded"
                letter="Logo"
                src={Logo}
                alt="Logo"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Div>

            <Div
              id={`${parent_id}_header_subtitile_parent_div`}
              key={"1"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "",
              }}
            >
              <Col
                id={`${parent_id}_header_subtitile_parent_col`}
                key={"0"}>
                {!this.props.IsModifyUser && (
                  <Div
                    id={`${parent_id}_header_subtitile_div`}
                    key={"0"}
                    inLineStyles="[object Object]"
                    className="qdm"
                    style={{
                      backgroundColor: "#fff",
                      marginLeft: "",
                      marginRight: "",
                      borderRadius: "36px",
                      marginBottom: "",
                      marginTop: "",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      height: "40px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <Div
                      id={`${parent_id}_header_subtitile_selectuser_div`}
                      key={"0"}
                      onClick={() => this.props.onHeaderClicked(1)}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginLeft: "",
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        id={`${parent_id}_header_subtitile_selectuser_avatar`}
                        key={"0"}
                        variant="circle"
                        letter={
                          this.props?.values?.selectedUser?.name ||
                          this.state?.user?.name
                        }
                        src={getImgUrl(this.props?.values?.selectedUser?.fileid)}
                        alt="Image"
                        style={{
                          width: "28px",
                          height: "28px",
                          marginLeft: "",
                          padding: "0px",
                          fontSize: "14px",
                          margin: 0,
                          color:'#101010',
                          backgroundColor:'#E0E0E0'
                        }}
                        className={"pc_medium"}
                      ></Avatar>
                    </Div>

                    <Div
                      id={`${parent_id}_header_subtitile_sub_div`}
                      key={"1"}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        width: "",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Divider
                        id={`${parent_id}_header_subtitile_rightside_divider`}
                        key={"0"}
                        className=""
                        label=""
                        borderColor=""
                        textColor=""
                        orientation="vertical"
                        variant="fullWidth"
                        style={{ height: "24px",margin:'0px 8px' }}
                      ></Divider>
                      {
                        this.props?.values?.location?.address && <>
                          <Div
                            id={`${parent_id}_header_subtitile_location_div`}
                            key={"1"}
                            onClick={() => this.props.onHeaderClicked(2)}
                            inLineStyles="[object Object]"
                            className="qdm"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              marginLeft: "",
                              cursor: "pointer",
                            }}
                          >
                            <H6
                              id={`${parent_id}_header_subtitile_location_h6`}
                              key={"0"}
                              name={this.props?.values?.location?.address?.length > 20 ? this.props?.values?.location?.address?.slice(0, 17) + '...' : this.props?.values?.location?.address}
                              //name={`${this.props?.values?.location?.address ?? ' '}`}
                              // ${this.props?.values?.cityName}
                              title={this.props?.values?.location?.address}
                              style={{
                                color: "#101010",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                              className={"pc_regular"}
                            ></H6>

                            <Icons
                              id={`${parent_id}_header_subtitile_location_map_icon`}
                              key={"1"}
                              fontIcon="map-marker"
                              ariaHidden="true"
                              className=""
                              size="small"
                              style={{ marginLeft: "8px", color: "#101010" }}
                            ></Icons>
                          </Div>

                          <Divider
                            id={`${parent_id}_header_subtitile_location_map_icon_rightside_divider`}
                            key={"2"}
                            className=""
                            label=""
                            borderColor=""
                            textColor=""
                            orientation="vertical"
                            variant="fullWidth"
                            style={{ height: "24px",margin:'0px 8px' }}
                          ></Divider>
                        </>
                      }
                      <Div
                        id={`${parent_id}_header_subtitile_location_specialities_div`}
                        key={"3"}
                        onClick={() => this.props.onHeaderClicked(3)}
                        inLineStyles="[object Object]"
                        className="qdm"
                        style={{ display: "flex", cursor: "pointer" }}
                      >
                        <Div
                          id={`${parent_id}_header_subtitile_location_specialities_sub_div`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          {[
                            ...(this.props?.values?.symptoms || []),
                            ...(this.props?.values?.specialities || []),
                            ...(this.props?.values?.doctors || []),
                          ]
                            ?.filter((l, i) => i < 1)
                            ?.map((val, i) => (
                              <span
                                id={`${parent_id}_${i}_header_subtitile_location_specialities_span`}
                                style={{
                                  borderColor: "#e0e0e0",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderRadius: "4px",
                                  color: "#101010",
                                  cursor: "pointer",
                                  fontSize: 14,
                                  marginRight: 8,
                                  padding: "2px 8px",
                                  display: "flex",
                                  alignItems: "center",
                                  lineHeight:1.4
                                }}
                                className={"pc_regular"}
                              >
                                {val?.label}{" "}
                                <CloseIcon
                                  id={`${parent_id}_${i}_header_subtitile_location_specialities_closeicon`}
                                  style={{ marginLeft: 8, fontSize: 16,color:'#6f6f6f' }}
                                />
                              </span>
                              // <Chip
                              //   key={i}
                              //   variant="circle"
                              //   name={val?.label}
                              //   style={{
                              //     borderColor: "#1890ff",
                              //     borderWidth: "1px",
                              //     borderStyle: "solid",
                              //     borderRadius: "8px",
                              //     color: "#1890ff",
                              //     cursor: "pointer",
                              //   }}
                              // ></Chip>
                            ))}
                          {[
                            ...this.props?.values?.symptoms,
                            ...this.props?.values?.specialities,
                            ...this.props?.values?.doctors,
                          ]?.length > 0 &&
                            [
                              ...this.props?.values?.symptoms,
                              ...this.props?.values?.specialities,
                              ...this.props?.values?.doctors,
                            ]?.length > 1 && (
                              <span
                                id={`${parent_id}_header_subtitile_location_specialities_span`}
                                style={{
                                  borderColor: "#e0e0e0",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderRadius: "4px",
                                  color: "#101010",
                                  cursor: "pointer",
                                  fontSize: 14,
                                  padding: "2px 8px",
                                  display: "flex",
                                  alignItems: "center",
                                  lineHeight:1.4
                                }}
                                className={"pc_regular"}
                              >
                                {"+"}
                                {[
                                  ...this.props?.values?.symptoms,
                                  ...this.props?.values?.specialities,
                                  ...this.props?.values?.doctors,
                                ]?.length - 1}
                                {" More"}
                              </span>
                            )}
                        </Div>
                      </Div>

                      <Divider
                        id={`${parent_id}_header_subtitile_location_specialities_rightside_divider`}
                        key={"4"}
                        className=""
                        label=""
                        borderColor=""
                        textColor=""
                        orientation="vertical"
                        variant="fullWidth"
                        style={{ height: "24px",margin:'0px 8px' }}
                      ></Divider>

                      <Div
                        id={`${parent_id}_header_subtitile_search_hospital_div`}
                        key={"5"}
                        onClick={() => this.props.onHeaderClicked(4)}
                        inLineStyles="[object Object]"
                        className="qdm"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {/* <Text
                          id={`${parent_id}_header_subtitile_search_hospital_text`}
                          key={"0"}
                          name={this.props?.values?.hospital?.label}
                          style={{ color: "#101010" }}
                          className={"pc_light"}
                        ></Text> */}
                        {this.props?.values?.hospital?.length === 1 && 
                         <span
                         id={`${parent_id}_header_subtitile_hosptials_span`}
                         style={{
                          borderColor: "#e0e0e0",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderRadius: "4px",
                          color: "#101010",
                          cursor: "pointer",
                          fontSize: 14,
                          padding: "2px 8px",
                          display: "flex",
                          alignItems: "center",
                          lineHeight:1.4
                         }}
                         className={"pc_regular"}
                       >
                       {this.props?.values?.hospital?.[0]?.label}
                       </span>
                        }
                        {this.props?.values?.hospital?.length > 1 && 
                         <span
                         id={`${parent_id}_header_subtitile_hosptials_span`}
                         style={{
                          borderColor: "#e0e0e0",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderRadius: "4px",
                          color: "#101010",
                          cursor: "pointer",
                          fontSize: 14,
                          padding: "2px 8px",
                          display: "flex",
                          alignItems: "center",
                          lineHeight:1.4
                         }}
                         className={"pc_regular"}
                       >
                        {"+"}{this.props?.values?.hospital?.length - 1}{" More"}
                       </span>
                        }
                        <Icons
                          id={`${parent_id}_header_subtitile_search_hospital_icon`}
                          key={"1"}
                          fontIcon="angle-down"
                          ariaHidden="true"
                          className=""
                          size="small"
                          style={{ marginLeft: "8px" }}
                        ></Icons>
                      </Div>
                    </Div>

                    <Div
                      id={`${parent_id}_header_subtitile_search_hospital_search_div`}
                      key={"2"}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#EC6A49",
                        borderRadius: "50px",
                        marginLeft: "8px",
                        width: "28px",
                        height: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* eslint-disable-next-line */}
                      <img
                        id={`${parent_id}_header_subtitile_search_hospital_search_image`}
                        src="https://i.ibb.co/QD4fvNs/icons8-search.png"
                        alt="Image"
                        style={{
                          width: 12,
                          height: 12,
                        }}
                      />
                    </Div>
                  </Div>
                )}
              </Col>
            </Div>

            <Div
              id={`${parent_id}_header_subtitile_side_home_div`}
              key={"2"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Div
                id={`${parent_id}_header_subtitile_side_home_div`}
                style={{ cursor: "pointer" }}
                onClick={() => this.clickHome()}
              >
                <i
                  id={`${parent_id}_header_subtitile_side_home_icon`}
                  className="fa fa-home"
                  aria-hidden="true"
                  style={{
                    fontSize: "16px !important",
                    paddingRight: "7px",
                    color: "white",
                  }}
                />
                <Text
                  id={`${parent_id}_header_subtitile_side_home_text`}
                  style={{ color: "white" }} className={"pc_regular"}>
                  Home
                </Text>
              </Div>
              <div
                id={`${parent_id}_header_subtitile_side_name_avatar_div`}
                onClick={(e) => this.handleProfileClick(e)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  id={`${parent_id}_header_subtitile_side_name_avatar`}
                  key={"1"}
                  variant="square"
                  letter={this.state?.user?.name}
                  src={this.state?.user?.img}
                  alt="G"
                  style={{
                    width: "40px",
                    height: "40px",
                    padding: "",
                    margin:'0px 8px 0px 16px',
                    fontSize: "14px",
                    color:'#6f6f6f',
                    backgroundColor:'#E0E0E0',
                    borderRadius: "8px"
                  }}
                  className="pc_regular"
                ></Avatar>

                <Icons
                  id={`${parent_id}_header_subtitile_side_name_icon`}
                  key={"2"}
                  fontIcon="angle-down"
                  ariaHidden="true"
                  className=""
                  size="medium"
                  style={{ color: "#ffffff" }}
                ></Icons>
              </div>
            </Div>
            <Popover
              id={id}
              open={open}
              anchorEl={this.state.anchorEl}
              onClose={() => this.handleProfileClose()}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography
                onClick={() => Logout()}
                style={{ padding: "10px", cursor: "pointer", color: UIColor.primaryText.color }}
              >
                Log Out
              </Typography>
            </Popover>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
});

export default connect(mapStateToProps, actions)(withRouter(Header));
