import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import qdmstyles from './styles.json'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { AlertProps, UIColor } from "../../utils";
import { AlertContext } from '../../contexts'

import {
  Container,
  H6,
  Divider,
  Div,
  // Icons,
  Text,
  Avatar,
  Button,
  Checkbox
} from 'qdm-component-library'

class AppointmentAddMemberSuccessPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addmemberlist: null,
      choosemember: []
    }
  }

  makeName = ({ firstName, middleName, lastName }) => {
    let name = firstName;
    if (middleName) {
      name += ` ${middleName}`
    }
    if (lastName) {
      name += ` ${lastName}`
    }
    return name;
  };

  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }
  handleCheked = (e, key, member) => {
    this.setState({
      ...this.state,
      addmemberlist: key,
      slcData: member
    })
  }
  fetchInfo = async () => {
    let userId = this.props.signUpData?.id || 0;
    const data = await this.props.GET_RELATED_PERSON({ userid: userId });
    if (data?.payload?.data && Array.isArray(data?.payload?.data)) {
      this.setState({
        choosemember: data.payload.data,
      })
    }
  }
  componentDidMount = () => {
    this.fetchInfo()
  }

  shouldComponentUpdate(props, state) {
    return true
  }
  getAge = date => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, 'DD/MM/YYYY'), 'years', true)
      ) + 'Yrs'
    )
  }
  senOtpMMr = async () => {
    const state = this.state.slcData
    const SearchUserData = await this.props.GENERATE_OTP({
      mobileNo: `+${state?.telecom[0]?.value?.slice(1, 3) ?? '00'}${state?.telecom[0]?.value?.slice(3)}`
    })
    if (SearchUserData.payload.data.error) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: SearchUserData.payload.data.errorMessage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true
      })
      return;
    } else {
      const signUpData = {
        mobileNo: {
          mobileNo: `${state?.telecom[0]?.value?.slice(3)}`,
          countryData: {
            callingCodes: `${state?.telecom[0]?.value?.slice(1, 3) ?? '00'}`
          }
        },
        selectData: state,
        id: state?.PersonID,
        firstName: state?.person?.[0]?.name?.[0]?.text || state?.person?.[0]?.name?.[0]?.given,
        lastName: state?.person?.[0]?.name?.[0]?.family,
        dob: state?.person?.[0]?.birthDay,
        gender: state?.person?.[0]?.gender,
        emailId: state?.telecom?.find(tel => (tel.system === "Email" && tel.use === "Home"))?.value,
        middleName: ""
      }
      this.props.nextSection('addmemberotp', 'addMember', signUpData)
    }
  }
  render() {
    const { addmemberlist } = this.state
    const { parent_id } = this.props
    return (
      <div
        id={`${parent_id}_appointment_booked_parent_div`}
      >
        <Container
          id={`${parent_id}_appointment_booked_parent_container`}
          key={'0'} name={'container'} fluid={true}>
          <H6
            id={`${parent_id}_appointment_booked_addmember_title_h6`}
            key={'0'} name={'Add Member'} style={qdmstyles.jHSPZ}></H6>

          <Divider key={'1'}
            id={`${parent_id}_appointment_booked_title_bottom_divider`}
          ></Divider>

          <Div key={'2'} className={'qdm'}
            id={`${parent_id}_appointment_booked_membrelisting_parent_div`}
            style={qdmstyles.LWmuO}>
            {/* <Icons
              key={"0"}
              fontIcon={"check"}
              ariaHidden={"true"}
              size={"small"}
              style={qdmstyles.ZxFi}
            ></Icons> */}
            <Avatar
              id={`${parent_id}_appointment_booked_membrelisting_verification_avatar`}
              src='images\icons8-instagram-verification-badge.svg'
              alt='Image'
              style={{
                backgroundColor: 'transparent',
                height: 'auto',
                width: '16px'
              }}
              variant='square'
            ></Avatar>
            <Text
              id={`${parent_id}_appointment_booked_membrelisting_succes_message_text`}
              key={'1'}
              name={'Member added successfully'}
              style={{
                "fontSize": "14px",
                "color": UIColor.success.color
              }}
            ></Text>
          </Div>

          <Div
            id={`${parent_id}_appointment_booked_membrelisting_parent_div`}
            key={'3'} className={'qdm'} style={{
              "display": "flex",
              "justifyContent": "flex-start",
              "alignItems": "center",
              "border": `1px solid ${UIColor.lineBorderFill.color}`,
              "borderStyle": "solid",
              "borderRadius": "10px",
              "padding": "4px"
            }}>
            <Div key={'0'}
              id={`${parent_id}_appointment_booked_membrelisting_sublevel_div`}
            >
              <Avatar
                id={`${parent_id}_appointment_booked_membrelisting_avtar`}
                key={'0'}
                variant={'circle'}
                letter={this.props.signUpData.firstName ? this.props.signUpData.firstName[0] : ''}
                src={this.props.signUpData?.image?.url || undefined}
                alt={'Image'}
                style={{
                  width: '40px',
                  height: '40px',
                  fontSize: '16px'
                }}
              ></Avatar>
            </Div>
            <Div key={'1'}
              id={`${parent_id}_appointment_booked_membrelisting_rightside_content_div`}
            >
              <H6
                id={`${parent_id}_appointment_booked_membrelisting_rightside_content_name`}
                key={'0'}
                name={this.makeName(this.props?.signUpData || {})}
                style={{
                  fontSize: '14px',
                  color: '#242424',
                  fontWeight: 600
                }}
              ></H6>

              <Text
                id={`${parent_id}_appointment_booked_membrelisting_rightside_content_discription`}
                key={'1'}
                name={`${this.getAge(this.props.signUpData?.dob)} ${this.props.signUpData?.gender
                  }, +${this.props.signUpData?.mobileNo?.countryData
                    ?.callingCodes ?? '00'}${this.props.signUpData?.mobileNo
                      ?.mobileNo ?? 'XXXXXXXXX'}, ${this.props.signUpData?.emailId
                  }`}
                // name={'30Yrs,Male , +91 9900887766 , sudharsa@mail.com'}
                style={{
                  "color": UIColor.tertiaryText.color ,
                  "fontSize": "12px"
                }}
              ></Text>
            </Div>
          </Div>
          {this.props.addmmber && (
            <Divider
              id={`${parent_id}_appointment_booked_membrelisting_bottom_divider`}
              key={'1'}
              style={{
                marginRight: 0,
                marginLeft: 0,
                backgroundColor: UIColor.lineBorderFill.color
              }}
            ></Divider>
          )}
          {this.props.addmmber && this.state.choosemember.length > 0 && (
            <H6
              id={`${parent_id}_appointment_booked_membrelisting_family_member_h6`}
              className='pc_regular'
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                // border: `1px solid ${UIColor.secondaryColor.color}`,
                // padding: '10px 4px',
                // backgroundColor: UIColor.secondaryColor.color,
                borderRadius: '8px',
                fontSize: 12,
                color: UIColor.secondaryColor.color,
                marginTop: '4%'
              }}
            >
              <span style={{ marginRight: '2%', marginTop: '6px' }}
                id={`${parent_id}_appointment_booked_membrelisting_family_member_span`}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='30px'
                  height='20px'
                  viewBox='0 0 14 14'
                  id={`${parent_id}_appointment_booked_membrelisting_family_member_image`}
                >
                  <defs></defs>
                  <path
                    fill={UIColor.secondaryColor.color}
                    class='a'
                    d='M11,4a7,7,0,1,0,7,7A7.008,7.008,0,0,0,11,4Zm0,1.05A5.95,5.95,0,1,1,5.05,11,5.942,5.942,0,0,1,11,5.05ZM11,7.5a.7.7,0,1,0,.7.7A.7.7,0,0,0,11,7.5Zm-.008,2.442a.525.525,0,0,0-.517.533v3.85a.525.525,0,1,0,1.05,0v-3.85a.525.525,0,0,0-.533-.533Z'
                    transform='translate(-4 -4)'
                  />
                </svg>
              </span>{' '}
              We found the other family member from your record.
            </H6>
          )}

          {this.props.addmmber && this.state.choosemember.length > 0 && (
            <div
              id={`${parent_id}_appointment_booked_membrelisting_family_member_likeadd_div`}
              style={{
                "fontSize": "12px",
                "color": UIColor.secondaryText.color,
                "lineHeight": 2.8,
                "marginBottom": "-8px",
                "marginTop": "10px"
              }}>
              Would you like to add them ?
            </div>
          )}

          {this.props.addmmber &&
            this.state.choosemember?.map((member, key) => {
              return <Div
                id={`${parent_id}_appointment_booked_membrelisting_family_member_div`}
                key={key}
                className={'qdm'}
                style={addmemberlist !== key ? qdmstyles.htwc : qdmstyles.htwc1}
                onClick={e => this.handleCheked(e, key, member)}
              >
                <Checkbox
                  id={`${parent_id}_appointment_booked_membrelisting_family_member_checkbox`}
                  key={'0'}
                  size={'medium'}
                  color={'primary'}
                  name={''}
                  checked={addmemberlist === key ? true : false}
                  style={{ marginRight: 0 }}
                ></Checkbox>
                <Avatar
                  id={`${parent_id}_appointment_booked_membrelisting_family_member_avatar`}
                  key={'1'}
                  variant={'circle'}
                  letter={'Avatar'}
                  src={'https://i.pravatar.cc/150?img=65'}
                  alt={'Image'}
                  inLineStyles={{ padding: 0, height: 'auto', width: 42 }}
                ></Avatar>
                <Div key={'2'}
                  id={`${parent_id}_appointment_booked_membrelisting_family_member_parent_div`}
                >
                  <Div key={'0'}
                    id={`${parent_id}_appointment_booked_membrelisting_family_member_sublevel_div`}
                  >
                    <H6
                      id={`${parent_id}_appointment_booked_membrelisting_family_member_name`}
                      key={'0'}
                      name={member.name ? (member.name[0].text || member.name[0].given) : ''}
                      style={qdmstyles.cccb_ef_d_db_efbfbd}
                    ></H6>

                    <Text
                      id={`${parent_id}_appointment_booked_membrelisting_family_member_discription`}
                      key={'1'}
                      name={member.birthDate || member?.gender
                        ? (this.getAge(member?.birthDate) ?? '') +
                        ' | ' +
                        (member?.gender ?? '')
                        : ''}
                      style={{
                        "color": "#807e7e",
                        "fontSize": "12px",
                        "marginTop": ""
                      }}
                    ></Text>
                  </Div>
                </Div>
                {this.props?.memeberIds?.includes(member.PersonID) &&
                <Button
                  id={`${parent_id}_appointment_booked_membrelisting_family_member_added_button`}
                  key={'1'}
                  type={'button'}
                  variant={'outlined'}
                  name={this.props?.memeberIds?.includes(member.PersonID) ? 'Added' : ""}
                  style={{
                    "color": UIColor.secondaryColor.color,
                    "backgroundColor": UIColor.secondaryColor.color,
                    "borderColor": UIColor.secondaryColor.color,
                    "marginTop": "-22px",
                    "marginRight": "8px",
                    "fontSize": "12px",
                    "position": "absolute",
                    "right": "0",
                    "padding": "0px 6px"
                  }}
                ></Button>
                }
              </Div>
            })

          }
          {!this.props.addmmber && (
            <Button
              id={`${parent_id}_appointment_booked_backhome_button`}
              key={'4'}
              type={'button'}
              variant={'outlined'}
              name={'Back to Home'}
              onClick={() => this.props.toggleSideBar(false)}
              style={{
                "display": "flex",
                "justifyContent": "center",
                "alignItems": "center",
                "margin": "auto",
                "marginTop": "20px",
                "marginBottom": "10px",
                "borderRadius": "10px",
                "padding": "10px 12px",
                "fontSize": "12px",
                "backgroundColor": UIColor.primaryColor.color,
                "borderColor": UIColor.primaryColor.color
              }}
            ></Button>
          )}
          {this.state.addmemberlist !== null && (
            <Button
              key={'4'}
              type={'button'}
              id={`${parent_id}_appointment_booked_confirmadded_button`}
              variant={'outlined'}
              name={'Confirm & Add Member'}
              onClick={() => {
                if (this.props?.memeberIds?.includes(this.state.slcData.PersonID)) {
                  this.context.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Member Already Added To Your Profile",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                    tone: true
                  })
                } else {
                  const state = this.state.slcData;
                  const signUpData = {
                    mobileNo: {
                      mobileNo: `${state?.telecom[0]?.value?.slice(3)}`,
                      countryData: {
                        callingCodes: `${state?.telecom[0]?.value?.slice(1, 3) ?? '00'}`
                      }
                    },
                    selectData: state,
                    id: state?.PersonID,
                    firstName: state?.person?.[0]?.name?.[0]?.text || state?.person?.[0]?.name?.[0]?.given,
                    lastName: state?.person?.[0]?.name?.[0]?.family,
                    dob: state?.person?.[0]?.birthDay,
                    gender: state?.person?.[0]?.gender,
                    emailId: state?.telecom?.find(tel => (tel.system === "Email" && tel.use === "Home"))?.value,
                    middleName: ""
                  }
                  this.props.addmmber ? this.senOtpMMr() :
                    this.props.nextSection('addmemberotp', 'addMember', signUpData)
                }
              }}
              style={{
                "display": "flex",
                "justifyContent": "center",
                "alignItems": "center",
                "margin": "auto",
                "marginTop": "20px",
                "marginBottom": "10px",
                "borderRadius": "10px",
                "padding": "10px 12px",
                "fontSize": "12px",
                "backgroundColor": UIColor.primaryColor.color,
                "borderColor": UIColor.primaryColor.color
              }}
            ></Button>
          )}
        </Container>
      </div>
    )
  }
}
AppointmentAddMemberSuccessPage.contextType = AlertContext

const mapStateToProps = state => ({})

export default connect(
  mapStateToProps,
  actions
)(withRouter(AppointmentAddMemberSuccessPage))
