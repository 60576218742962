import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { TicketComp } from "../../../components";
import AvcIcon from "../../../assets/img/avc_vidoe_icon.svg";
import MicNoneIcon from "@material-ui/icons/MicNone";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import IconButton from "@material-ui/core/IconButton";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Button from "@material-ui/core/Button";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#FFF",
		height: "100vh",
	},
	cameraPreview: {
		backgroundColor: "#424447",
		height: "100vh",
		width: "auto",
		borderTopLeftRadius: 30,
		borderBottomLeftRadius: 30,
		"& video": {
			width: "100%",
		},
	},
	joinTitle: {
		fontSize: 16,
		textAlign: "center",
		fontWeight: 700,
		padding: 0,
		margin: 0,
	},
	TicketCompDiv: {
		backgroundColor: "#FAFCFF",
		height: "100vh",
		padding: "0px 28px",
		display: "grid",
		justifyContent: "center",
		alignItems: "center",
		overflowY: "auto",
		paddingTop: "6%",
	},
	image: { textAlign: "end", paddingTop: 8 },
	p_1: {
		margin: 0,
		fontSize: 15,
		fontWeight: 600,
	},
	p_2: {
		margin: 0,
		fontSize: 14,
		fontWeight: 400,
		color: "#B6B6B6",
	},
	p_3: {
		margin: 0,
		fontSize: 12,
		color: "#101010",
		padding:18,
		textAlign:'center'
	},
	div: {
		display: "flex",
		justifyContent: "center",
	},
	ControlBtn: {
		boxShadow: "0px 3px 8px #0000001A",
		border: "1px solid #F0F0F0",
		borderRadius: 8,
		padding: 10,
		"& svg": {
			fontSize: 20,
		},
	},
	joinBtn: {
		fontSize: 13,
		backgroundColor: "#0071F2",
		padding: "6px 20px",
		color: "white",
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: "#0071F2",
		},
	},
}));

export function LeftJoinDiv(props) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Grid container>
				<div className={classes.TicketCompDiv}>
					<Grid container alignItems={"center"} justify={"center"}>
						<Grid item xs={2} justify={"end"} style={{ textAlign: "end" }}>
							<img src={AvcIcon} className={classes.image} />
						</Grid>
						<Grid item xs={9}>
							<p className={classes.joinTitle}>Join the Consultation Call</p>
						</Grid>
					</Grid>
					{props?.info?.patient?.name && 
					<Grid
						container
						style={{
							padding: "16px 0px",
							backgroundColor: "white",
							border: "1px solid #F0F0F0",
							borderRadius: 12,
							margin: "20px 0px",
						}}
					>
						<Grid container justify={"center"}>
							<p className={classes.p_3}>
								{props?.info?.patient?.name} are in the waiting room
							</p>
						</Grid>
					</Grid>}
					<TicketComp 
						data={props?.info}
					/>
					<span
						style={{
							color: "#B6B6B6",
							fontSize: 12,
							textAlign: "center",
							marginTop: "8%",
							marginBottom: "8%",
						}}
					>
						Check your mic & video cam before joining call
					</span>
					<Grid
						container
						justify={"center"}
						alignItems={"center"}
						style={{ textAlign: "center", marginBottom: "8%" }}
						spacing={6}
					>
						<Grid item xs={2}>
							<IconButton
								className={classes.ControlBtn}
								style={props.micControl ? { backgroundColor: "#FF4D4A" } : null}
								onClick={() => props.ControlBtnClick("mic")}
							>
								{props.micControl ? (
									<MicOffIcon style={{ color: "#fff" }} />
								) : (
									<MicNoneIcon />
								)}
							</IconButton>
						</Grid>
						<Grid item xs={2}>
							<IconButton
								className={classes.ControlBtn}
								style={
									props.videoControl ? { backgroundColor: "#FF4D4A" } : null
								}
								onClick={() => props.ControlBtnClick("video")}
							>
								{props.videoControl ? (
									<VideocamOffIcon style={{ color: "#fff" }} />
								) : (
									<VideocamOutlinedIcon />
								)}
							</IconButton>
						</Grid>
						<Grid item xs={2}>
							<IconButton
								className={classes.ControlBtn}
								onClick={() => props.ControlBtnClick("setting")}
							>
								<SettingsOutlinedIcon />
							</IconButton>
						</Grid>

						<Grid item xs={6}>
							<Button
								variant="contained"
								className={classes.joinBtn}
								onClick={() => props.ControlBtnClick("meeting")}
							>
								Join the call
							</Button>
						</Grid>
					</Grid>
				</div>
			</Grid>
		</div>
	);
}
