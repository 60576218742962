import React, { useState } from "react";
import { Avatar, makeStyles, Typography, Button } from "@material-ui/core";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
	header: {
		color: "#B6B6B6",
		fontSize: "10px",
		padding: "16px 8px 8px",
		fontFamily: "pc_medium !important",
	},
	colorAvatar: {
		width: "28px",
		height: "28px",
		fontSize: "12px",
		backgroundColor: "#4BC0C0",
	},
	avatarDiv: {
		margin: "0px 16px 16px",
		backgroundColor: "#eee",
		padding: "17px",
		borderRadius: "8px",
		display: "flex",
		justifyContent: "center",
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	avatarCard: {
		width: "90%",
		backgroundColor: "#eee",
		padding: "50px 0px 0px",
		margin: "auto",
		marginBottom: "16px",
		borderRadius: "8px",
	},
	avatarCardSize: {
		width: "48px",
		height: "48px",
		margin: "auto",
		backgroundColor: "#4BC0C0",
	},
	textBody: {
		fontFamily: "pc_medium !important",
		fontSize: "12px",
		padding: "30px 5px 12px 12px",
		color: "#B6B6B6",
	},
}));

const participants = {
	"You": {
		name: "Sudarshan",
		participant: "You",
		color: "#ff9f4021",
		innerColor: "#ff9f40",
	},
	"Son":{
		name: "James Bond",
		participant: "Son",
		color: "#C3EEF1",
		innerColor: "#4BC0C0",
	},
	Receptionist:{
		name: "Queen Mary",
		participant: "Receptionist",
		color: "#FFF5F7",
		innerColor: "#ed778f",
	},
	Nurse: {
		name: "Diana",
		participant: "Nurse",
		color: "#FAEEFF",
		innerColor: "#bb5fe1",
	},
	doctor: {
		name: "Noel",
		participant: "doctor",
		color: "#53668524",
		innerColor: "#00000029",
	},
};

export function ParticipateSide(props) {
	const classes = useStyles();
	const [visibilityOver, setVisibilityover] = useState(true);

	const handleVisiblity = () => {
		if (visibilityOver === true) {
			setVisibilityover(false);
		} else {
			setVisibilityover(true);
		}
	};
	return (
		<>
			<div className={"root"} onMouseEnter={handleVisiblity}>
				<div style={{ width: "100%", margin: "auto" }}>
					<Typography className={classes.header}>PARTICIPANTS</Typography>
				</div>
				{props?.participants?.length > 0 && props?.participants?.map((val) => (
					<div
						className={classes.avatarDiv}
						style={{ backgroundColor: participants[val?.participant]?.color??participants["Receptionist"]?.color }}
					>
						<Avatar
							className={classes.colorAvatar}
							style={{ backgroundColor: participants[val?.participant]?.innerColor??participants["Receptionist"]?.innerColor }}
						>
							{val.name.slice(0, 2).toUpperCase()}
						</Avatar>
					</div>
				))}
			</div>
			{/* <div style={{position:"relative"}}> */}
			<div
				className={"hiddenDiv"}
				style={{ visibility: visibilityOver ? "hidden" : "visible" }}
				onMouseLeave={handleVisiblity}
			>
				<div style={{ width: "100%", margin: "auto" }}>
					<Typography
						className={classes.header}
						style={{ padding: "20px 0px 10px 17px" }}
					>
						PARTICIPANTS
					</Typography>
				</div>
				<div
					style={{
						height: "125vh",
						overflow: "hidden",
						paddingBottom: "350px",
						overflowY: "scroll",
					}}
				>
					{props?.participants?.length > 0 && props?.participants.map((val) => (
						<div
							className={classes.avatarCard}
							style={{ backgroundColor: participants[val?.participant]?.color??participants["Receptionist"]?.color }}
						>
							<Avatar
								className={classes.avatarCardSize}
								style={{ backgroundColor: participants[val?.participant]?.innerColor??participants["Receptionist"]?.innerColor }}
							>
								{val.name.slice(0, 2).toUpperCase()}
							</Avatar>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Typography
									className={classes.textBody}
									style={{ color: participants[val?.participant]?.innerColor??participants["Receptionist"]?.innerColor }}
								>
									{val.name}
								</Typography>
								<Typography
									className={classes.textBody}
									style={{ padding: "30px 0px 10px" }}
								>
									- {val.participant}
								</Typography>
							</div>
						</div>
					))}
				</div>
				{/* </div> */}
			</div>
		</>
	);
}
