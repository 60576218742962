import React from "react";

export const PrintIcon = (props) => {
  let width = props.width ? props.width : "14";
  let height = props.height ? props.height : "13.3";
  let color = props.color ? props.color : "#6f6f6f";
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 13.3">
        <path id="icons8-print_1_" data-name="icons8-print (1)" d="M8.375,5A1.583,1.583,0,0,0,6.8,6.575V7.1H6.275A2.283,2.283,0,0,0,4,9.375v4.9A1.583,1.583,0,0,0,5.575,15.85H6.8v.875A1.583,1.583,0,0,0,8.375,18.3h5.25A1.583,1.583,0,0,0,15.2,16.725V15.85h1.225A1.583,1.583,0,0,0,18,14.275v-4.9A2.283,2.283,0,0,0,15.725,7.1H15.2V6.575A1.583,1.583,0,0,0,13.625,5Zm0,1.05h5.25a.517.517,0,0,1,.525.525V7.1H7.85V6.575A.517.517,0,0,1,8.375,6.05Zm-2.1,2.1h9.45A1.217,1.217,0,0,1,16.95,9.375v4.9a.517.517,0,0,1-.525.525H15.2V13.575A1.583,1.583,0,0,0,13.625,12H8.375A1.583,1.583,0,0,0,6.8,13.575V14.8H5.575a.517.517,0,0,1-.525-.525v-4.9A1.217,1.217,0,0,1,6.275,8.15Zm2.1,4.9h5.25a.517.517,0,0,1,.525.525v3.15a.517.517,0,0,1-.525.525H8.375a.517.517,0,0,1-.525-.525v-3.15A.517.517,0,0,1,8.375,13.05Z" transform="translate(-4 -5)" fill="#2a60bc"/>
    </svg>
  );
};