import React from "react";
import {
  Div,
  Card,
  ListItem,
  Avatar,
  Icons,
  Divider,
} from "qdm-component-library";
import IconButton from '@material-ui/core/IconButton';
import { UIColor } from "../../../utils";

function PatientCard(props) {
  const { name = "", url = "" } = props?.info || {};
  const { addMember = () => null, isAddBtn = false, memberList = [] } = props;
  const styleBg = {};
  if (url) {
    styleBg.background = "transparent";
  }
  return (
    <React.Fragment>
      <Div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          // paddingTop: "4px",
        }}
        id={"patientDashboard_patientCard_TopHeader_parent_div"}
      >
        <Card
          style={{
            height: "50px",
            marginTop: "-1px",
            padding: "0px 10px 0px 0px",
          }}
          id={"patientDashboard_patientCard_TopHeader_Card"}
        >
          <ListItem
            style={{
              height: "48px",
              color: "rgb(16, 16, 16)",
              fontWeight: 600,
              padding: "1px 1px 0px 1px",
              gridTemplateColumns: "55px 128px 38px",
              display: "grid",
              paddingBottom: "2px",
            }}
            id={"patientDashboard_patientCard_TopHeader_ListItem"}
            profile={
              // <img
              //   alt="img"
              //   style={{ borderRadius: "10px", marginTop: "-8px" }}
              //   src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
              // />
              <Avatar
                alt={"B"}
                name={name}
                src={url}
                letter={name?.toUpperCase()}
                variant={"square"}
                inLineStyles={{
                  margin: "-4px 1px 1px 7px",
                  height: "40px",
                  width: "40px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  ...styleBg,
                }}
                className={"pc_medium my_avatar_img"}
                id={"patientDashboard_patientCard_TopHeader_Avatar"} 
              >
                {/* {} */}
              </Avatar>
            }
            action={
              <Icons
                inLineStyles={{ color: " #B6B6B6" }}
                fontIcon="ellipsis-v"
                id={"patientDashboard_patientCard_TopHeader_Icons"}
              ></Icons>
            }
            title={
              <div
                style={{
                  fontSize: "14px",
                  color: UIColor.primaryText.color,
                  letterSpacing:0,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "130px",
                  textAlign: "left"
                }}
                className={"pc_medium"}
              >
              {name}
              </div>
              //name
            }
            description={
              <span
                style={{
                  fontSize: "12px",
                  marginTop: "-4px",
                  color: "#B6B6B6",
                  letterSpacing:'-0.1px'
                }}
                id={"patientDashboard_patientCard_TopHeader_description_span"}
                className={"pc_regular"}
              >
                {/* PID ---- */}
              </span>
            }
          />
        </Card>
        {(isAddBtn || memberList.length > 0) && (
          <Divider
            key={"1"}
            label=""
            borderColor=""
            textColor=""
            orientation="vertical"
            variant="middle"
            style={{
              height: "34px",
              borderColor: "#ffffff",
              backgroundColor: "#ffffff",
              marginRight: 0,
              marginLeft: 10,
            }}
            id={"patientDashboard_patientCard_TopHeader_Divider"}
            className={"pc_medium"}
          ></Divider>
        )}
        {(isAddBtn || memberList.length > 0) && (
          <Div
            inLineStyles={{ display: "flex", alignItems: "center" }}
            id={"patientDashboard_patientCard_TopHeader_Div"}
          >
            {memberList.slice(0, 4).map((val) => {
              return (
                <React.Fragment>
                  {val.src ? (
                    <Avatar
                      title={val.label}
                      variant="rounded"
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                        color: "#6F6F6F",
                        margin: "0px 1px 0px 9px",
                        fontSize: "16px",
                        borderRadius: "8px"
                      }}
                      src={val.src}
                      id={"patientDashboard_patientCard_TopHeader_memberList"}
                      className={"pc_medium"}
                    />
                  ) : (
                    <Avatar
                      variant="rounded"
                      title={val.label}
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                        color: "#6F6F6F",
                        margin: "0px 1px 0px 9px",
                        fontSize: "16px",
                        borderRadius: "8px"
                      }}
                      letter={val.label}
                      id={
                        "patientDashboard_patientCard_TopHeader_memberList_Avatar"
                      }
                      className={"pc_medium"}
                    />
                  )}
                </React.Fragment>
              );
            })}
            {memberList.length - 4 > 0 && (
              <Div
                variant="rounded"
                style={{
                  height: "40px",
                  width: "40px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  color: "#101010",
                  margin: "0px 1px 0px 9px",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: 6,
                }}
                id={"patientDashboard_patientCard_TopHeader_member_more_div"}
                className={"pc_medium"}
              >
                +{memberList.length - 4} More
              </Div>
            )}
            {isAddBtn && (
              <IconButton
                style={{
                  height: "40px",
                  width: "40px",
                  cursor: "pointer",
                  backgroundColor: "#F5FAFF",
                  margin: "0px 1px 0px 9px",
                  color: "#6F6F6F",
                  fontSize: "16px",
                  borderRadius: 8
                }}
                onClick={() => addMember(true)}
                id={"patientDashboard_patientCard_TopHeader_member_plus_Avatar"}
                className={"pc_medium"}
              >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <path id="icons8-add" d="M14,4A10,10,0,1,0,24,14,10.011,10.011,0,0,0,14,4Zm0,1.5A8.5,8.5,0,1,1,5.5,14,8.489,8.489,0,0,1,14,5.5Zm-.012,3.489a.75.75,0,0,0-.738.761v3.5H9.75a.75.75,0,1,0,0,1.5h3.5v3.5a.75.75,0,1,0,1.5,0v-3.5h3.5a.75.75,0,1,0,0-1.5h-3.5V9.75a.75.75,0,0,0-.762-.761Z" transform="translate(-4 -4)" fill="#01205c"/>
                  </svg>
              </IconButton>
            )}
          </Div>
        )}
      </Div>
    </React.Fragment>
  );
}

export default PatientCard;
