import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";

import {
  Container,
  Card,
  Icons,
  H6,
  Text,
  Div,
  Avatar,
  Button,
} from "qdm-component-library";

class YourAppointmentHasBeenBooked extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {}

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    return (
      <div>
        <Container
          key={"0"}
          name={"container"}
          fluid={false}
          style={qdmstyles.HtBRj}
          id={"yourAppointmentHasBeenBooked-parent-Container"}
        >
          <Card
            key={"0"}
            children={"Card"}
            style={qdmstyles.MAPVN}
            id={"yourAppointmentHasBeenBooked-Card"}
          >
            <Icons
              key={"0"}
              fontIcon={"check"}
              ariaHidden={"true"}
              size={"medium"}
              style={qdmstyles.___f_fcc}
              id={"yourAppointmentHasBeenBooked-Card-check-Icons"}
            ></Icons>

            <H6
              key={"1"}
              name={"Your appointment has been booked !"}
              style={qdmstyles.db_db_fef__bdbb}
              id={"yourAppointmentHasBeenBooked-Card-title-H6"}
            ></H6>

            <Text
              key={"2"}
              name={
                "Confirmation has been sent to +91 9988774455,+91 8877445511 & Sudharsan@mail.com,rajesh23@mail.com"
              }
              style={qdmstyles.abb_db_c_ab_abcfe}
              id={"yourAppointmentHasBeenBooked-Card-info-Text"}
            ></Text>

            <Div
              key={"3"}
              className={"qdm"}
              style={qdmstyles.faiUY}
              id={"yourAppointmentHasBeenBooked-Card-Div1"}
            >
              <Div
                key={"0"}
                className={"qdm"}
                style={qdmstyles.UhwT}
                id={"yourAppointmentHasBeenBooked-Card-Div2"}
              >
                <Div
                  key={"0"}
                  className={"qdm"}
                  style={qdmstyles.f__f__edabafa}
                  id={"yourAppointmentHasBeenBooked-Card-Div3"}
                >
                  <Div
                    key={"0"}
                    id={"yourAppointmentHasBeenBooked-Card-Avatar-Div"}
                  >
                    <Avatar
                      key={"0"}
                      id={"yourAppointmentHasBeenBooked-Card-Avatar-img"}
                    ></Avatar>
                  </Div>

                  <Div key={"1"} id={"yourAppointmentHasBeenBooked-Card-Div4"}>
                    <H6
                      key={"0"}
                      name={"Dr. Priya Dharshini"}
                      style={{ fontSize: "14px" }}
                      id={"yourAppointmentHasBeenBooked-Card-dr-Name-h6"}
                    ></H6>

                    <Text
                      key={"1"}
                      name={"General Physician"}
                      style={qdmstyles.BtFEO}
                      id={"yourAppointmentHasBeenBooked-Card-splic-text"}
                    ></Text>

                    <H6
                      key={"2"}
                      name={"apollo hospital mount road"}
                      id={"yourAppointmentHasBeenBooked-Card-hospital-anme-h6"}
                      style={qdmstyles.vQIA}
                    ></H6>
                  </Div>
                </Div>
              </Div>

              <Div
                key={"1"}
                className={"qdm"}
                style={qdmstyles.XXeJ}
                id={"yourAppointmentHasBeenBooked-Card-XXeJ-div6"}
              >
                <Div
                  key={"0"}
                  className={"qdm"}
                  style={qdmstyles.cbb_a__af_dfc}
                  id={"yourAppointmentHasBeenBooked-Card-XXeJ-div7"}
                >
                  <Div
                    key={"0"}
                    id={"yourAppointmentHasBeenBooked-Card-XXeJ-div7=8"}
                  >
                    <Avatar
                      key={"0"}
                      id={"yourAppointmentHasBeenBooked-Card-XXeJ-Avatar1"}
                    ></Avatar>
                  </Div>

                  <Div key={"1"}>
                    <H6
                      key={"0"}
                      name={"Dr. Priya Dharshini"}
                      style={{ fontSize: "14px" }}
                      id={"yourAppointmentHasBeenBooked-Card-XXeJ-Dr-name-h6"}
                    ></H6>

                    <Text
                      key={"1"}
                      name={"General Physician"}
                      style={qdmstyles.Nal}
                      id={"yourAppointmentHasBeenBooked-Card-splic-text"}
                    ></Text>

                    <H6
                      key={"2"}
                      name={"apollo hospital mount road"}
                      style={qdmstyles.piJT}
                      id={"yourAppointmentHasBeenBooked-Card-hospital-anme-h6"}
                    ></H6>
                  </Div>
                </Div>
              </Div>
            </Div>

            <Div
              key={"4"}
              className={"qdm"}
              style={qdmstyles.faec_b_af_a_adaccd}
            >
              <Div
                key={"0"}
                className={"qdm"}
                style={qdmstyles.aa_fc__a_dfcc}
                id={"yourAppointmentHasBeenBooked-Card-Div9"}
              >
                <Text
                  key={"0"}
                  name={"S NO"}
                  style={qdmstyles.KOPD}
                  id={"yourAppointmentHasBeenBooked-Card-Text1"}
                ></Text>

                <Text
                  key={"1"}
                  name={"APPOINTMENT NUMBER"}
                  style={qdmstyles.kqH}
                  id={"yourAppointmentHasBeenBooked-Card-Text2"}
                ></Text>

                <Text
                  key={"2"}
                  name={"DATE &TIME"}
                  style={qdmstyles.IIMlT}
                  id={"yourAppointmentHasBeenBooked-Card-Text3"}
                ></Text>
              </Div>

              <Div
                key={"1"}
                className={"qdm"}
                style={qdmstyles.dd_e_ac_be_fdd}
                id={"yourAppointmentHasBeenBooked-Card-div11"}
              >
                <Text
                  key={"0"}
                  name={"01"}
                  style={qdmstyles.TsJR}
                  id={"yourAppointmentHasBeenBooked-Card-text4"}
                ></Text>

                <Text
                  key={"1"}
                  name={"AP-123457"}
                  style={qdmstyles.Iwjd}
                  id={"yourAppointmentHasBeenBooked-Card-text5"}
                ></Text>

                <Text
                  key={"2"}
                  name={"30 Apr 2021 , 09:00 am"}
                  style={qdmstyles.umckV}
                  id={"yourAppointmentHasBeenBooked-Card-text6"}
                ></Text>
              </Div>

              <Div key={"2"} className={"qdm"} style={qdmstyles.UgA}>
                <Text
                  key={"0"}
                  name={"02"}
                  style={qdmstyles.dJl}
                  id={"yourAppointmentHasBeenBooked-Card-text7"}
                ></Text>

                <Text
                  key={"1"}
                  name={"AP-123457"}
                  style={qdmstyles.SNLao}
                  id={"yourAppointmentHasBeenBooked-Card-text7"}
                ></Text>

                <Text
                  key={"2"}
                  name={"30 Apr 2021 , 09:00 am"}
                  style={qdmstyles.CMHUc}
                  id={"yourAppointmentHasBeenBooked-Card-text8"}
                ></Text>
              </Div>
            </Div>

            <Div
              key={"5"}
              className={"qdm"}
              style={qdmstyles.aad_c_b_ace_babbab}
              id={"yourAppointmentHasBeenBooked-Card-div14"}
            >
              <Div key={"0"}>
                <Icons
                  key={"0"}
                  fontIcon={"download"}
                  ariaHidden={"true"}
                  size={"medium"}
                  style={qdmstyles.ebdef_bb_d_aebe_dfdf}
                  id={"yourAppointmentHasBeenBooked-Card-icon1"}
                ></Icons>

                <Text
                  key={"1"}
                  name={"Appointment Summary"}
                  style={qdmstyles.e_a_a_bbe_ebf}
                  id={"yourAppointmentHasBeenBooked-Card-text9"}
                ></Text>
              </Div>

              <Div key={"1"}>
                <Icons
                  key={"0"}
                  fontIcon={"download"}
                  ariaHidden={"true"}
                  size={"medium"}
                  style={qdmstyles.aAbvO}
                  id={"yourAppointmentHasBeenBooked-Card-icon2"}
                ></Icons>

                <Text
                  key={"1"}
                  name={"Invoice"}
                  style={qdmstyles.JjUGq}
                  id={"yourAppointmentHasBeenBooked-Card-Text10"}
                ></Text>
              </Div>

              <Div key={"2"}>
                <Icons
                  key={"0"}
                  fontIcon={"Add print"}
                  ariaHidden={"true"}
                  size={"medium"}
                  style={qdmstyles.PzNtk}
                  id={"yourAppointmentHasBeenBooked-Card-print-icons1"}
                ></Icons>

                <Icons
                  key={"1"}
                  fontIcon={"Add print"}
                  ariaHidden={"true"}
                  size={"medium"}
                  style={qdmstyles.ZCU}
                  id={"yourAppointmentHasBeenBooked-Card-print-icons2"}
                ></Icons>

                <Icons
                  key={"2"}
                  fontIcon={"print"}
                  ariaHidden={"true"}
                  size={"medium"}
                  style={qdmstyles.ga}
                  id={"yourAppointmentHasBeenBooked-Card-print-icons3"}
                ></Icons>

                <Text
                  key={"3"}
                  name={"Print"}
                  style={qdmstyles.fff_a__bc_eedc}
                  id={"yourAppointmentHasBeenBooked-Card-printText"}
                ></Text>
              </Div>

              <Div key={"3"}>
                <Icons
                  key={"0"}
                  fontIcon={"share"}
                  ariaHidden={"true"}
                  size={"medium"}
                  style={qdmstyles.BcQ}
                  id={"yourAppointmentHasBeenBooked-Card-share-icon"}
                ></Icons>

                <Text
                  key={"1"}
                  name={"Share"}
                  style={qdmstyles.RJqB}
                  id={"yourAppointmentHasBeenBooked-Card-share-text"}
                ></Text>
              </Div>
            </Div>

            <Button
              key={"6"}
              type={"button"}
              variant={"outlined"}
              name={"Back to Home"}
              style={qdmstyles.cf_c_cbb_fd_eaeffa}
              id={"yourAppointmentHasBeenBooked-CardBack-to-Home-btn"}
            ></Button>

            <Text
              key={"7"}
              name={"Want to cancel appointment ?  Click here"}
              style={qdmstyles.cd_cc_e_aab_fdef}
              id={"yourAppointmentHasBeenBooked-CardBack-Click-Text"}
            ></Text>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  actions
)(withRouter(YourAppointmentHasBeenBooked));
