import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Container, H6, List, ListItem, Div } from "qdm-component-library";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  checkBoxStyle: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      '& .MuiCheckbox-root': {
        padding: 0
      },
      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#01205C'
      },
      '& svg':{
        width:18,
        height:18,
      }
    }
  }
});

export const handleSelectHospital = (val,props) => {
  let data = props?.values?.select_hospitsl;
  if (data?.filter(l => l?.value === val?.value)?.length > 0) {
    data = data?.filter(l => l?.value !== val?.value)
  }
   else {
    data = [...data, val]
  }
  props?.handlesearchActives && props.handlesearchActives("select_hospitsl", data)
}
class SearchHospitalResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    // this.getHospitals();
  }

  getHospitals = async () => {
    const {
      city = "",
     location = "",
    } = this.props?.values?.select_city_name;
    if (this.props.GET_HOSPITALS_LOCALITY) {
      this.props.GET_HOSPITALS_LOCALITY({
        city,
        locality: location,
        location: this.props?.values?.select_city_name,
      });
    }
  };

  shouldComponentUpdate(props, state) {
    return true;
  }

  checkTrue = (stateVal, val) => {
    return stateVal?.filter(l => l?.value === val)?.length > 0 ? true : false
  };

  handleSelectAllHospitals = (isChecked, allHospital) => {
    console.log(isChecked, allHospital, "handleSelectAllHospitals");
    if (isChecked) {
      this.props?.handlesearchActives &&
        this.props.handlesearchActives("select_hospitsl", allHospital);
    } else {
      this.props?.handlesearchActives &&
        this.props.handlesearchActives("select_hospitsl", []);
    }
  };

  checkSelectData = (originalData, selectedData, value) => {
    if (value?.length) {
      const oldData =
        originalData?.length &&
        originalData?.filter((m) =>
          m?.label?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
        );
      const newData =
        selectedData?.length &&
        selectedData?.filter((m) =>
          m?.label?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
        );
      return oldData?.length == newData?.length ? true : false;
    }

    if (value == "") {
      return originalData?.length == selectedData?.length ? true : false;
    }
  };

  render() {
    const { values, classes, parent_id } = this.props;
    const hospitalSelection = values?.select_doctors?.[0]?.value ? this.props?.getAllHospitalsAgainstDoctor : this.props?.hospitals;

    return (
      <div 
      id={`${parent_id}_search_hospital_parent_div`}
      >
        <Container
          id={`${parent_id}_search_hospital_parent_container`}
          key={"0"}
          name="container"
          fluid={false}
          style={{ maxWidth: 992 }}
        >
          <div
            id={`${parent_id}_search_hospital_sub_parent_div`}
            style={{
              padding: '16px 0px',
              backgroundColor: '#fff',
              borderRadius: '8px',
              width: 557,
              marginLeft: 'auto'
            }}
            ref={this.props?.refs}>
            <H6
              id={`${parent_id}_search_hospital_label_h6`}
              className="pc_regular"
              key={"0"}
              name="HOSPITAL"
              style={{ color: "#6F6F6F", fontSize: "12px",lineHeight:1,letterSpacing:0,padding:'0px 16px', }}
            ></H6>

            <List
            id={`${parent_id}_search_hospital_list`}
            className="hospital_listing"
            key={"1"}
          children="List"
          orientation="vertical"
          listItemWidth="100%"
          marginVertical="0"
          marginHorizontal="0"
          paddingVertical="8"
          paddingHorizontal="8"
              style={{ padding: "0px", marginTop: "4px", maxHeight: 400, overflow: 'auto',}}
            >
              {!hospitalSelection?.loading &&
               hospitalSelection?.data?.length > 0 && hospitalSelection?.data?.filter(m => m?.label?.toLowerCase()?.includes(values?.search_hospital_value?.toLowerCase() ?? ''))?.length > 0 ? (
                hospitalSelection?.data?.filter(m => m?.label?.toLowerCase()?.includes(values?.search_hospital_value?.toLowerCase() ?? ''))?.map((hospital, i) => {
                  let label = hospital.name ? hospital.name : hospital.label;
                  let value = hospital._id ? hospital._id : hospital.value;
                  let distance = hospital.distance || " ";
                    return (
                      <>
                        {i == 0 && (
                          <Div
                            id={`${parent_id}_search_hospital_label_sub_div`}
                            style={{
                              flex: 1,

                              paddingTop: "4px",
                              paddingBottom: "8px",
                              paddingLeft: "16px",
                              paddingRight: "16px",
                              color: "#6F6F6F",
                              cursor: "pointer",
                            }}
                            className={classes.checkBoxStyle}
                          >
                            <FormControlLabel
                              id={`${parent_id}_search_hospital_formcontrollabel`}
                              control={
                                <Checkbox
                                  id={`${parent_id}_search_hospital_label_sub_checkbox`}
                                  checked={
                                    // hospitalSelection?.data?.filter((m) =>
                                    //   m?.label
                                    //     ?.toLowerCase()
                                    //     ?.includes(
                                    //       values?.search_hospital_value?.toLowerCase() ??
                                    //         ""
                                    //     )
                                    // )?.length ===
                                    // values?.select_hospitsl?.length
                                    this.checkSelectData(
                                      hospitalSelection?.data,
                                      values?.select_hospitsl,
                                      values?.search_hospital_value
                                    )
                                  }
                                  onChange={(event) => {
                                    this.handleSelectAllHospitals(
                                      event?.target?.checked,
                                      hospitalSelection?.data?.filter((m) =>
                                        m?.label
                                          ?.toLowerCase()
                                          ?.includes(
                                            values?.search_hospital_value?.toLowerCase() ??
                                              ""
                                          )
                                      )
                                    );
                                  }}
                                />
                              }
                              label={
                                <H6
                                  id={`${parent_id}_search_hospital_label_sub_h6`}
                                  className="pc_medium"
                                  style={{
                                    fontSize: 14,
                                    color: "#101010",
                                    marginLeft: 5,
                                    letterSpacing: 0,
                                    lineHeight: 1,
                                  }}
                                >
                                  Select All
                                </H6>
                              }
                            />
                          </Div>
                        )}
                        <ListItem
                          id={`${parent_id}_search_hospital_select_hospital_listitem`}
                          className="hospital_listing_options"
                          key={i}
                          title={label}
                          description=""
                          profile=""
                          onClick={() =>
                            handleSelectHospital({ label, value },this.props)
                          }
                          action={distance}
                          marginVertical="0"
                          marginHorizontal="0"
                          paddingVertical="8"
                          paddingHorizontal="16"
                          style={{
                            marginLeft: "",
                            paddingTop: "4px",
                            paddingBottom: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            color: "#6F6F6F",
                            cursor: "pointer",
                          }}
                        >
                          <Div
                            id={`${parent_id}_search_hospital_label_div`}
                            style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <Div
                              id={`${parent_id}_search_hospital_label_sub_div`}
                              style={{ flex: 1 }} className={classes.checkBoxStyle}>
                              <FormControlLabel
                                id={`${parent_id}_search_hospital_formcontrollabel`}
                                control={<Checkbox
                                  id={`${parent_id}_search_hospital_label_sub_checkbox`}
                                  checked={this.checkTrue(values?.select_hospitsl, value)} />}
                                label={<H6
                                    id={`${parent_id}_search_hospital_label_sub_h6`}
                                    className="pc_medium"
                                    style={{ fontSize: 14, color: '#101010', marginLeft: 5,letterSpacing:0,lineHeight:1 }}>{label ? label : ' '}</H6>}
                              />
                            </Div>
                            <H6
                          id={`${parent_id}_search_hospital_label_h6`}
                          className="pc_medium" style={{ fontSize: 12, color: '#101010',letterSpacing:0,lineHeight:1 }}>{distance}</H6>
                          </Div>
                        </ListItem>
                      </>
                    );
                  })) : (
                <Div 
                id={`${parent_id}_search_hospital_no_hospitals_label_div`}
                >
                  <H6
                    id={`${parent_id}_search_hospital_no_hospitals_label_h6`}
                    style={{
                      margin: 0,
                      marginBottom: 15,
                      textAlign: 'center',
                      color: '#000000',
                      fontSize: 14,
                      fontWeight: '600'
                    }}>No Hospitals Found</H6>
                </Div>
              )
              }
              {/* <div
              style={{
                display: "flex",
                maxWidth: "100%"
              }}
              onClick={() =>
                handlesearchActives(
                  'select_hospitsl',
                  'Apollo Hospitals - Greams Road Chennai'
                )
              }
            >
              <Checkbox
                key={'0'}
                size={'medium'}
                color={'primary'}
                name={''}
                style={{ marginLeft: 0 }}
              ></Checkbox>
              <ListItem
                key={'0'}
                title='Apollo Hospitals - Greams Road Chennai'
                description=''
                profile=''
                action='2.2kms'
                marginVertical='0'
                marginHorizontal='0'
                paddingVertical='8'
                paddingHorizontal='16'
                style={{
                  marginLeft: '',
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: '#101010',
                  padding: '0px',
                  paddingBottom: '8px',
                  minWidth: "auto",
                  cursor: 'pointer'
                }}
              ></ListItem>
            </div>
            <div
              style={{
                display: "flex",
                maxWidth: "100%"
              }}
              onClick={() =>
                handlesearchActives(
                  'select_hospitsl',
                  'pollo Hospitals - Teynampet Chennai'
                )
              }
            >
              <Checkbox
                key={'0'}
                size={'medium'}
                color={'primary'}
                name={''}
                style={{ marginLeft: 0 }}
              ></Checkbox>
              <ListItem
                key={'1'}
                title='Apollo Hospitals - Teynampet, Chennai'
                description=''
                profile=''
                action='3.2 kms'
                marginVertical='0'
                marginHorizontal='0'
                paddingVertical='8'
                paddingHorizontal='16'
                style={{
                  marginLeft: '',
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: '#101010',
                  padding: '0px',
                  paddingBottom: '8px',
                  minWidth: "auto",
                  cursor: 'pointer'
                }}
              ></ListItem>
            </div> */}
            </List>
            {/* 
          <H6
            key={'2'}
            name='CLINIC'
            style={{ color: '#6F6F6F', fontSize: '12PX', marginTop: '16PX' }}
          ></H6>

          <List
            key={'3'}
            children='List'
            orientation='vertical'
            listItemWidth='100%'
            marginVertical='0'
            marginHorizontal='0'
            paddingVertical='8'
            paddingHorizontal='8'
            style={{ marginTop: '4PX', padding: '0px' }}
          >
            <div
              style={{
                display: "flex",
                maxWidth: "100%"
              }}
              onClick={() =>
                handlesearchActives(
                  'select_hospitsl',
                  'Apollo Clinic - Saidapet Chennai'
                )
              }
            >
              <Checkbox
                key={'0'}
                size={'medium'}
                color={'primary'}
                name={''}
                style={{ marginLeft: 0 }}
              ></Checkbox>
              <ListItem
                key={'0'}
                title='Apollo Clinic - Saidapet
 Chennai'
                description=''
                profile=''
                action='4.6kms'
                marginVertical='0'
                marginHorizontal='0'
                paddingVertical='8'
                paddingHorizontal='16'
                style={{
                  marginLeft: '',
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: '#101010',
                  padding: '0px',
                  paddingBottom: '8px',
                  minWidth: "auto",
                  cursor: 'pointer'
                }}
              ></ListItem>
            </div>
            <div
              style={{
                display: "flex",
                maxWidth: "100%"
              }}
              onClick={() =>
                handlesearchActives(
                  'select_hospitsl',
                  'Apollo Medical Center - Gunidy Chennai'
                )
              }
            >
              <Checkbox
                key={'0'}
                size={'medium'}
                color={'primary'}
                name={''}
                style={{ marginLeft: 0 }}
              ></Checkbox>
              <ListItem
                key={'1'}
                title='Apollo Medical Center - Gunidy
 Chennai'
                description=''
                profile=''
                action='6.6kms'
                marginVertical='0'
                marginHorizontal='0'
                paddingVertical='8'
                paddingHorizontal='16'
                style={{
                  marginLeft: '',
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: '#101010',
                  padding: '0px',
                  paddingBottom: '8px',
                  minWidth: "auto",
                  cursor: 'pointer'
                }}
              ></ListItem>
            </div>
          </List>
       
        */}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hospitals: state?.searhcApiSlice?.hospitals,
  getAllHospitalsAgainstDoctor: state?.searhcApiSlice?.getPractitionerAgainstOrganization,
});

export default withStyles(styles)(connect(
  mapStateToProps, 
  actions
  )(withRouter(SearchHospitalResult)));
