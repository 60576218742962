/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc The private route check wheather the user is logged in or not and also check
 * wheather the request route from the user is accessible to them or not using the Access(role,path)
 * function then allow to the particular route or else it will redirect to login page.
 */

import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { Routes } from "./routes";
import { connect } from 'react-redux'
import { actions } from "primarycare-binder";
import { useDispatch } from "react-redux";
// import { Access } from "./access";
// import AppDrawer from "../App.drawer";
import { getUserInfo } from '../utils'
const PrivateRoute = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isAuthenticated, setisAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    checkIsAuthenticated().then(data => {
      setisAuthenticated(data);
      setLoading(false);
    })
  }, [])
  
  const checkIsAuthenticated = async () => {
    if (location.state?.fromQueryPage && localStorage.getItem("query_token")) {
      return true;
    }
    const info = await getUserInfo(
      rest.loggedUserInfo,
      rest.LOGGED_USER_INFO,
      rest.GET_USER_INFO,
      rest.READ_PERSON,
      rest.guestInfo,
    );
    if (info.type) {
      // const _ = Access(1, router?.match?.path);
      // if (_ >= 0) {
      //   return true;
      // };
      if (info.type === "token") {
        const token = localStorage.getItem("token");
        dispatch(actions.REFRESH_TOKEN({ token, isOrg: false })).then(userInfo => {
          localStorage.setItem("token", userInfo.payload?.data || token);
        }).catch(err => {
          console.log(err);
        });
      }
      return true;
    } else {
      return false;
    }
  };
 
  return (
    <Route
      {...rest}
      render={(_) =>
        loading
        ? null
        :
        isAuthenticated ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: Routes.signIn,
                state: { from: _?.location },
              }}
            />
          )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestInfo: state?.authSlice?.guestInfo,
});

export default connect(mapStateToProps, actions)(PrivateRoute);
