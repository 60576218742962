import * as React from "react";

export const AvcSideDock = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<g
				id="Group_94343"
				data-name="Group 94343"
				transform="translate(3774 10299.211)"
			>
				<path
					id="icons8-restore-down"
					d="M7.75,8.789A2.761,2.761,0,0,0,5,11.539v10.5a2.761,2.761,0,0,0,2.75,2.75h10.5A2.761,2.761,0,0,0,21,22.039v-10.5a2.761,2.761,0,0,0-2.75-2.75Zm0,1.5h10.5a1.239,1.239,0,0,1,1.25,1.25v10.5a1.239,1.239,0,0,1-1.25,1.25H7.75a1.239,1.239,0,0,1-1.25-1.25v-10.5A1.239,1.239,0,0,1,7.75,10.289Z"
					transform="translate(-3779 -10308)"
					fill={props.isactive ? "#0071F2" : "#FFF"}
				/>
				<path
					id="Path_94883"
					data-name="Path 94883"
					d="M2-2H3c1.1,0,1,.9,1,2V5c0,1.1.1,2-1,2H2C.9,7,1,6.1,1,5V0C1-1.1.9-2,2-2Z"
					transform="translate(-3765.474 -10294)"
					fill={props.isactive ? "#0071F2" : "#FFF"}
				/>
			</g>
		</svg>
	);
};
