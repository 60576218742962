const Routes = {
	signIn: "/signin",
	signInOTP: "/otp_verification",
	signUp: "/guest_signin",
	doctorConsultation: "/doctor_consultation",
	doctorListing: "/appointment_booking",
	confirmBooking: "/appointment_confirmation",
	appointMentPage: "/appointment/:id",
	appointmentsListingPage: "/profile",
	yourAppointmentHasBeenBooked: "/your_appointment_has_been_booked",
	assemblePatioentRegister: "/assemble-patient-register",
	confirmbookingscreen: "/confirmbookingscreen",
	PatientDashBoard: "/patient-dashboard",
	viewreport: "/view_report",
	queryPage: "/appointments",
	avcLogin: "/avc_login/:id",
	avcLoginRedirect: "/avc_login",
	// avcLogin: "/avc_login",
	avcSupport: "/avc_support",
	avcLanding: "/avcLanding",
	videoCall: "/video_call",
};

export { Routes };
