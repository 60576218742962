import React from "react";
import noData from "../../../assets/img/noData.svg";
import { Text } from "qdm-component-library";

function EmptyCard() {
    return <div
        style={{
            // marginTop: "10px",
            boxShadow: "none",
            border: "1px solid #F0F0F0",
            backgroundColor: "white",
            padding: "15px 17px 18px 15px",
            borderRadius: 8,
            minHeight: 133,
        }}
    >
        <div style={{ display: "flex" }}>
            <img alt={"No Appoinment data"} src={noData} style={{ marginRight: "20px" }} />
            <div style={{ marginTop: "14px" }}>
                <Text
                    className="pc_medium"
                    key={"0"}
                    name="No appointment"
                    style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        letterSpacing:0
                    }}
                ></Text>
                <div>
                    <span className="pc_medium" style={{ color: "#6F6F6F", fontSize: "12px",letterSpacing:0 }}>
                        Hope,your are fit and healthy
          </span>
                </div>
            </div>
        </div>
    </div>
}

export default EmptyCard;