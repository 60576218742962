import React from "react";
import { Div } from 'qdm-component-library'
import { TopNavbar, PatientIdentification, PatientNames } from "../../../components"
class PatientRegistration extends React.Component {
    render() {
        return <Div id="assembleregistration_div">
            <TopNavbar />
            <PatientIdentification />
            <PatientNames />
        </Div>

    }
}


export default PatientRegistration;