import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import FileViewer from 'react-file-viewer';
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import { Collapse, Modal } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import clsx from "clsx";
// import Eye from "../../assets/img/eye.svg";
import { ToBase64, UIColor, AlertProps } from "../../utils";
import {
  Div,
  Row,
  Icons,
  TextArea,
  Text,
  Divider,
  TapSelect,
  // Upload
} from "qdm-component-library";
import { Lounge, ValetParking, PickUpDrop, WheelChair } from '../../components/svg'

import Reportandadditionalservices from "../../assets/img/report_and_additional_services.svg";
import { AlertContext } from "../../contexts";

const styles = (theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: 16,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  TapSelect: {
    "& div": {
      minWidth: "40%",
      // backgroundColor: "yellow",
      "& span": {
        fontSize: 12,
        fontFamily: "pc_medium !important",
      },
    },
  },
  picker: {
    fontSize: "14px",
    color: "#001C3C",
    margin: "10px 10px 0px 0px",
    border: "1px solid #e0e0e0",
    borderRadius: "12px",
    padding: "8px",
  },
  activepicker: {
    fontSize: "14px",
    color: "#fff",
    margin: "10px 10px 10px 0px",
    border: "1px solid #e0e0e0",
    borderRadius: "12px",
    padding: "8px",
    background: "#0071F2",
  },
});

class DoctorAndAppointmentSlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      isActiveservice: [],
      uploading: [],
      openFile: null,
      fileType: null
    };
  }

  changeState = (key, value) => {
    this.setState({
      ...this.state, [key]: value
    })
  }

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  componentDidMount() { }

  shouldComponentUpdate(props, state) {
    return true;
  }
  handleUpload = async (e) => {
    console.clear()
    console.log(e.target.files)
    if(e.target?.files?.[0]?.type === "image/png" || e.target?.files?.[0]?.type === "application/pdf" || e.target?.files?.[0]?.type ===  "image/jpeg") {
      const { upload } = this.props;
      upload.push(e.target.files[0]);
      this.props.uploadFun(upload);
      this.setState({
        uploading: []
      });
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "The format of file is not supported.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };
  deleteFile = (index) => {
    const { upload } = this.props;
    upload.splice(index, 1);
    this.props.uploadFun(upload);
    this.setState({
      uploading: []
    });
    // this.setState({
    //   upload
    // });
  }

  createFile = async (url, name, type) => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type,
    };
    const file = new File([data], name, metadata);
    return {
      file,
      size: data.size,
    }
  }

  viewFile = async (file) => {
    let data = ""
    if (file.fileUrl) {
      const {
        file: fileData,
      } = await this.createFile(file.fileUrl, file.name, file.type);
      data = await ToBase64(fileData);
    } else {
      data = await ToBase64(file)
    }
    this.setState({
      openFile: data,
      viewFileName: file.name,
      fileType: file.type.split('/')[1],
      open: true
    })
  }
  handleClose = () => {
    this.setState({
      openFile: null,
      fileType: null,
      open: false
    })
  }
  render() {
    const { classes, parent_id } = this.props;
    const { upload } = this.props;
    return (
      <Card
        id={`${parent_id}_report_and_additional_parent_card`}
        style={{
          boxShadow: "none",
          border: "1.5px solid #E0E0E0",
          borderRadius: 8,
        }}
      >
        <CardActions
          id={`${parent_id}_report_and_additional_parent_cardactions`}
          onClick={this.handleExpandClick}
          disableSpacing
          style={{
            padding: 0,
            paddingLeft: 16,
            paddingBottom: 0,
            cursor: "pointer",
          }}
        >
          <img
            id={`${parent_id}_report_and_additional_image`}
            alt="Reportandadditionalservices"
            src={Reportandadditionalservices}
            style={{ marginRight: 12 }}
          />
          <Text
            id={`${parent_id}_report_and_additional_label_reportand_additional_text`}
            name="Report and Additional Services"
            style={{ fontSize: "16px", color: "#001C3C", letterSpacing: 0 }}
            className={"pc_semibold"}
          />

          <IconButton
            id={`${parent_id}_report_and_additional_label_reportand_show_more_iconbutton`}
            className={clsx(classes.expand, {
              [classes.expandOpen]: this.state.expanded,
            })}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon
              id={`${parent_id}_report_and_additional_label_reportand_expandmoreicon`}
            />
          </IconButton>
        </CardActions>
        <Collapse
          id={`${parent_id}_report_and_additional_reportand_collapse`}
          className={classes.body}
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <Div
            id={`${parent_id}_report_and_additional_collpase_div`}
            key={"0"}>
            <Row
              id={`${parent_id}_report_and_additional_collpase_row`}
              key={"0"}
              alignItems="center"
              direction="row"
              justify="space-between"
              spacing="2"
            >
              <Text
                id={`${parent_id}_report_and_additional_collpase_reports_doc_label_text`}
                key={"0"}
                name="Reports / documents If any"
                style={{ color: '#2A3C50', fontSize: "14px", letterSpacing: 0 }}
                className={"pc_regular"}
              />

              <div
                id={`${parent_id}_report_and_additional_collpase_div`}
                style={{
                  position: "relative",
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                <input
                  id={`${parent_id}_report_and_additional_collpase_upload_input`}
                  type="file"
                  name="myfile"
                  value=""
                  onChange={(e) => this.handleUpload(e)}
                  style={{
                    fontSize: "100px",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    opacity: 0,
                    width: "100%",
                    height: "100%",
                    cursor: 'pointer'
                  }}
                />
                <button
                  id={`${parent_id}_report_and_additional_collpase_upload_button`}
                  style={{
                    border: "1px solid #F1F6FC",
                    color: "#2A60BC",
                    background: "#F1F6FC",
                    padding: "5px 20px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  className={"pc_regular"}
                >
                  Upload
                </button>
              </div>
            </Row>

            <Row
              id={`${parent_id}_report_and_additional_upload_delete_row`}
            >
              {upload.map((v, i) => {
                return (
                  <div
                    id={`${parent_id}_${i}_report_and_additional_upload_delete_div`}
                    className={classes.picker}
                    style={{ color: '#0071f2',fontSize:12,fontFamily:'pc_medium' }}
                  >
                    <span
                      id={`${parent_id}_${i}_report_and_additional_upload_delete_span`}
                      style={{ paddingRight: 10 }}>{i < 9 ? `0${i + 1}` : i + 1}</span>{v.name}
                    {/* <img alt={"Eye"} src={Eye} onClick={() => this.viewFile(v)} style={{ cursor: 'pointer', marginLeft: 10 }} /> */}
                    <Icons
                      id={`${parent_id}_${i}_report_and_additional_viewfile_icon`}
                      // key={"1"}
                      onClick={() => this.viewFile(v)}
                      className="pi-upload-doc-close"
                      fontIcon={"eye"}
                      ariaHidden={"true"}
                      inLineStyles={{ marginLeft: "10px",cursor:'pointer' }}
                      // onClick={() => this.deleteFile(i)}
                      size={14}
                    />
                    <Icons
                      id={`${parent_id}_${i}_report_and_additional_upload_close_icon`}
                      // key={"1"}
                      inLineStyles={{ marginLeft: "10px",cursor:'pointer' }}
                      className="pi-upload-doc-close"
                      fontIcon={"close"}
                      ariaHidden={"true"}
                      onClick={() => this.deleteFile(i)}
                      size={14}
                    />
                  </div>
                )
              })}
            </Row>

            <Div
              id={`${parent_id}_report_and_additional_service_div`}
              key={"1"}>
              <Divider
                id={`${parent_id}_report_and_additional_service_divider`}
                key={"0"}
                className=""
                label=""
                borderColor=""
                textColor=""
                orientation="horizontal"
                variant="middle"
                style={{ margin: "0px", marginTop: "16px" }}
              />

              <Div
                id={`${parent_id}_report_and_additional_service_sub_div`}
                key={"1"}
                style={{
                  display: this.props.isVideo ? "none" : "unset"
                }}
              >
                <Row
                  id={`${parent_id}_report_and_additional_service_div`}
                  key={"0"}
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  spacing="2"
                  style={{
                    marginTop: "",
                    padding: "",
                    paddingTop: "16px",
                    margin: "",
                  }}
                >
                  <Text
                    id={`${parent_id}_report_and_additional_service_span`}
                    key={"0"}
                    name="Additional Service"
                    style={{
                      fontSize: "14px",
                      color: "#6A7888",
                      letterSpacing: "0px",
                    }}
                    className={"pc_regular"}
                  />
                </Row>

                <TapSelect
                  id={`${parent_id}_report_and_additional_service_tapselect`}
                  label=""
                  type="multiple"
                  fullWidth={true}
                  textSize="medium"
                  iconHeight="20"
                  iconWidth="20"
                  selectedBorderColor={UIColor.secondaryButtonColor.color} 
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor="#fff"
                  borderColor={"#ECF0F7"}
                  backgroundColor={"#ECF0F7"}
                  textColor={UIColor.secondaryText.color}
                  options={[
                    {
                      value: "Pick & Drop",
                      label: "Pick & Drop",
                      icon: <PickUpDrop
                        id={`${parent_id}_report_and_additional_pick_drop_svg`}
                      />
                    },
                    {
                      value: "Avail Lounge",
                      label: "Avail Lounge",
                      icon: <Lounge
                        id={`${parent_id}_report_and_additional_avail_svg`}
                      />
                    },
                    {
                      value: "Wheel Chair",
                      label: "Wheel Chair",
                      icon: <WheelChair
                        id={`${parent_id}_report_and_additional_wheel_svg`}
                      />
                    },
                    {
                      value: "Valet Parking",
                      label: "Valet Parking",
                      icon: <ValetParking
                        id={`${parent_id}_report_and_additional_valet_svg`}
                      />
                    },
                  ]}
                  inLineStyles={{
                    color: "#001C3C",
                    borderColor: "#e0e0e0",
                    padding: "6px 24px",
                    borderRadius: 12,
                    margin: '8px 8px 0px 0px'
                  }}
                  onChange={(data) => this.changeState('isActiveservice', data)}
                  defaultSelected={this.state.isActiveservice}
                  className={classes.TapSelect}
                />
              </Div>

              <Div
                id={`${parent_id}_report_and_additional_label_message_div`}
                key={"2"} className="qdm" style={{ marginTop: "16px" }}>
                <Text
                  id={`${parent_id}_report_and_additional_specific_massage_text`}
                  key={"0"}
                  name="Would you like to share any specific message ?"
                  style={{
                    fontSize: "14px",
                    letterSpacing: 0,
                    color: "#2A3C50",
                  }}
                  className={"pc_regular"}
                />

                <Div
                  id={`${parent_id}_report_and_additional_typeyour_massage_div`}
                  key={"1"}
                  className="qdm"
                  style={{ marginTop: "8px" }}
                >
                  <TextArea
                    id={`${parent_id}_report_and_additional_typeyour_massage_textarea`}
                    key={"0"}
                    name="Type your message here..."
                    borderColor="#9b9b9b"
                    placeholder="Type your message here..."
                    style={{
                      borderColor: "#E0E0E0",
                      borderWidth: "1px",
                      borderRadius: "8px",
                      padding: 18,
                      resize: "none",
                      height: "auto"
                    }}
                    maxLength={500}
                    value={this.props.patientInstruction}
                    onChange={(e) => {
                      this.props.changeState("patientInstruction", e.target.value)
                    }}
                    className={"pc_regular"}
                  />
                </Div>
              </Div>
            </Div>
          </Div>
        </Collapse>
        {/* <Modal
          open={this.state.open}
          onClose={this.handleClose}
          className='docc-modal'
        >
          <div style={{ width: "100%", height: "100vh", display: "flex" }}>
            <FileViewer onError={this.onError} fileType={this.state.fileType} filePath={this.state.openFile} />
          </div>
        </Modal> */}
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          className='docc-modal'
          id={`${parent_id}_report_and_additional_upload_modal`}
        >
          <div style={{ width: "100%", height: "100vh" }}
            id={`${parent_id}_report_and_additional_upload_modal_div`}
          >
            <Grid container style={{ background: "#d3d3d3e0" }}
              id={`${parent_id}_report_and_additional_upload_grid`}
            >
              <Grid item style={{ width: "50%" }} className={"documenthead"}
                id={`${parent_id}_report_and_additional_upload_sub_grid`}
              >
                <Grid
                  id={`${parent_id}_report_and_additional_upload_filename_grid`}
                >
                  <Typography
                    id={`${parent_id}_report_and_additional_upload_filename_text`}
                    style={{ color: "black", marginTop: "9px" }}>{this.state.viewFileName}</Typography>
                </Grid>
              </Grid>
              <Grid item
                id={`${parent_id}_report_and_additional_upload_filenameicon_grid`}
                style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "50%", paddingRight: "30px" }}>
                <Typography variant="h6"
                  id={`${parent_id}_report_and_additional_upload_filenameicon_text`}
                >
                  <Button
                    id={`${parent_id}_report_and_additional_upload_filenameicon_button`}
                  >
                    <Icons
                      id={`${parent_id}_report_and_additional_upload_filenameicon_closeicon`}
                      inLineStyles={{ color: "black", fontSize: '20px ​!importan' }}
                      className="pi-upload-doc-close"
                      fontIcon={"close"}
                      ariaHidden={"true"}
                      onClick={() => this.handleClose()}
                      // onClick={() => this.deleteFile(i)}
                      size={14}
                    />
                  </Button>
                </Typography>
              </Grid>

            </Grid>
            <Grid
              id={`${parent_id}_report_and_additional_upload_fileviwer_grid`}
              className="openFile-parent">
              <FileViewer
                id={`${parent_id}_report_and_additional_upload_fileviwer`}
                onError={this.onError} fileType={this.state.fileType} filePath={this.state.openFile} />
            </Grid>
          </div>
        </Modal>
      </Card>
    );
  }
}

DoctorAndAppointmentSlot.contextType = AlertContext;

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(DoctorAndAppointmentSlot))
);
