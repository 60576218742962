import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import AppointmentAddMemberSuccessPage from '../../components/appointment_booked/index'
import { Div, Text, Avatar, H6, Icons, Container, SideMenu } from 'qdm-component-library'
// import { Routes } from "../../router/routes";
import {
  Addmember,
  Addmemberform,
  Addmemberotp
} from '../../components'
import { getImgUrl, getMobileNumber, makeName } from "../../utils";
import Plus from '../../assets/img/+.svg'

class ChoosememberSearchResult extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebar: false,
      chooseSearch: null,
      memberForm: false,
      searchMember: false,
      memberOtp: false
    }
  }

  changeSideBar = () => {
    this.setState({
      ...this.state,
      sidebar: false,
      memberForm: false,
      searchMenber: false,
      memberOtp: false,
    }, () => {
      window.location.reload();
    })
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }
  handelChangeSelect = v => {
    this.setState({
      chooseSearch: v
    })
  }

  toggleSideBar = boolean => {
    this.setState({
      ...this.state,
      sidebar: boolean,
      memberForm: false,
      searchMember: true,
      addmmber: false,
      memberSuccessPage: false
    })
  }

  nextSection = (member, signUpData, l) => {
    if (signUpData === 'addMember') {
      this.setState({
        ...this.state,
        signUpData: l ? l : signUpData,
        searchMember: false,
        memberForm: false,
        memberOtp: true,
        memberSuccessPage: false,
        addmmber: true
      })
      return
    } else if (member === 'addmemberform') {
      this.setState({
        ...this.state,
        searchMember: false,
        memberForm: true,
        memberOtp: false,
        addmmber: false,
        signUpData
      })
    } else if (member === 'addmemberotp') {
      if (signUpData) {
        this.setState({
          ...this.state,
          signUpData,
          searchMember: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
          addmmber: false
        })
      } else {
        this.setState({
          ...this.state,
          searchMember: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false
        })
      }
    } else if (member === 'back') {
      this.setState({
        ...this.state,
        searchMember: true,
        memberForm: false,
        memberOtp: false,
        memberSuccessPage: false
      })
    } else if (member === 'member_succss') {
      const user = [
        {
          name: [
            {
              given: signUpData.firstName || "",
            },
          ],
          telecom: [
            {
              system: "Email",
              use: "Work",
              value: signUpData?.selectData?.telecom?.find?.(t => t?.system === "Email")?.value || "",
            },
            {
              system: "Phone",
              value: getMobileNumber(signUpData.mobileNo),
              use: "Mobile",
            }
          ],
          PersonID: signUpData.id,
          person: [
            {
              birthDay: signUpData.dob,
              gender: signUpData.gender,
              username: getMobileNumber(signUpData.mobileNo) || "",
            },
          ],
          photo: [{
            fileid: signUpData?.selectData?.photo?.[0]?.fileid,
          }],
          birthDate: signUpData.dob,
          gender: signUpData.gender,
        },
      ];
      this.props.changeState("choosemember", [
        ...this.props.choosemember,
        ...user,
      ])
      this.setState({
        ...this.state,
        searchMember: false,
        memberForm: false,
        memberOtp: false,
        sidebar: true,
        memberSuccessPage: true,
        signUpData,
      })
    } else if (member === 'movenextscreen') {
      this.setState({
        ...this.state,
        searchMember: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        memberSuccessPage: false
      })
    }
  }

  componentDidMount() { }

  shouldComponentUpdate(props, state) {
    return true
  }
  getAge = date => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, 'DD/MM/YYYY'), 'years', true)
      ) + 'Yrs'
    )
  }

  render() {
    const { parent_id } = this.props;

    return (
      <div
        id={`${parent_id}_choose_member_parent_div`}
      >
        <Container
          id={`${parent_id}_choose_member_parent_container`}
          key={'0'}
          name='container'
          fluid={false}
          style={{
            padding: '0px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            width: '792px',
            boxShadow: '0px 8px 23px #0000006e'
          }}
        >
          <div
            id={`${parent_id}_choose_member_sublevel_div`}
            key={'0'}
            inLineStyles='[object Object]'
            className='qdm'
            ref={this.props?.refs}
            style={{
              width: 'auto',
              height: '',
              padding: '16px 16px 6px',
              backgroundColor: '#ffffff',
              borderRadius: '8px'
            }}
          >
            <H6
              id={`${parent_id}_choose_member_choose_member_text`}
              className="pc_regular"
              key={'0'}
              name='CHOOSE MEMBER'
              style={{ fontSize: '12px', color: '#6F6F6F',letterSpacing:0,lineHeight:1,margin:0 }}
            ></H6>

            <Div
              id={`${parent_id}_choose_member_member_div`}
              inLineStyles='[object Object]'
              className='qdm'
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginTop: '8px',
                flexWrap: 'wrap',
                maxHeight: 250,
                overflow: 'auto'
              }}
            >
              {this.props?.choosemember?.map((member, key) => (
                <Div
                  id={`${parent_id}_choose_member_memberid_div`}
                  key={key}
                  inLineStyles='[object Object]'
                  className='qdm'
                  style={{
                    backgroundColor: '#ffffff',
                    // borderColor:
                    //   values.select_city === city.title ? '#007aff' : '#ffffff',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderRadius: '8px',
                    marginLeft: '',
                    marginRight: '24px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    border: `1px solid ${this.props.selectedId === member?.PersonID ? '#0071F2' : '#F0F0F0'}`,
                    padding: '15px',
                    cursor: 'pointer'
                  }}
                  onClick={e => {
                    this.props.handlememberClick(e, {
                      name: makeName(member?.name?.[0]),
                      email: member?.telecom?.find(val => (val.system === "Email" && val.use === "Work"))?.value || "",
                      userId: member?.PersonID,
                      birthDay: member?.person?.[0]?.birthDay,
                      gender: member?.person?.[0]?.gender,
                      fileid: member?.person?.[0]?.photo?.[0]?.fileid,
                      mobile: member?.telecom?.find(val => (val.system === "Phone" && val.use === "Mobile"))?.value || "",
                    })
                  }}
                >
                  <Avatar
                    id={`${parent_id}_choose_member_memberid_avatar`}
                    variant='rounded'
                    letter={member?.name?.[0]?.text || member?.name?.[0]?.given}
                    src={getImgUrl(member?.person?.[0]?.photo?.[0]?.fileid)}
                    alt='Image'
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#ffffff',
                      borderColor: '',
                      borderWidth: '',
                      borderRadius: '50%',
                      margin: '0px',
                      marginRight: '10px'
                    }}
                  />

                  <Div>
                    <H6
                      id={`${parent_id}_choose_member_memberid_name_h6`}
                      className="pc_medium"
                      name={makeName(member.name?.[0])}
                      style={{
                        textAlign: 'center',
                        fontSize: '14px',
                        color: '#000000',
                        letterSpacing:0
                      }}
                    />
                    <Text
                      id={`${parent_id}_choose_member_memberid_text`}
                      className="pc_regular"
                      name={
                        member.birthDate || member?.gender
                          ? (this.getAge(member?.birthDate) ?? '') +
                          ' | ' +
                          (member?.gender ?? '')
                          : ''
                      }
                      style={{
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#6F6F6F',
                        letterSpacing:0
                      }}
                    />
                  </Div>
                </Div>
              ))}
              <Div
                id={`${parent_id}_choose_member_sidebar_toggle_div`}
                inLineStyles='[object Object]'
                className='qdm'
                style={{
                  backgroundColor: '#ffffff',
                  // borderColor:
                  //   values.select_city === city.title ? '#007aff' : '#ffffff',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderRadius: '8px',
                  marginLeft: '',
                  marginRight: '24px',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  border: `1px solid #F0F0F0`,
                  padding: '15px',
                  cursor: (this.props?.type === 0 || this.props?.type === 1) ? 'auto' : 'pointer'
                }}
                onClick={e => {
                  !(this.props?.type === 0 || this.props?.type === 1) &&
                    // this.props.history.push({
                    //   pathname: Routes.appointmentsListingPage,
                    //   state: {
                    //     addMember: true
                    //   }
                    // })
                    this.toggleSideBar(true)
                }}
              >
                <Div
                  id={`${parent_id}_choose_member_icon_div`}
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#F9F9F9',
                    borderRadius: '50px',
                    display: 'flex',
                    alignItems:'center',
                    justifyContent:'center'
                  }}
                >
                  {' '}
                  {/* <Icons
                    id={`${parent_id}_choose_member_icon`}
                    key={'1'}
                    fontIcon='plus'
                    ariaHidden='true'
                    className=''
                    size={14}
                    style={{ color: '#6F6F6F' }}
                  ></Icons> */}
                  <img src={Plus} alt="+"/>
                </Div>

                <Div
                  id={`${parent_id}_choose_member_addmember_label_div`}
                >
                  <H6
                    id={`${parent_id}_choose_member_addmember_label_h6`}
                    className="pc_medium"
                    name={'Add Member'}
                    style={{
                      textAlign: 'center',
                      fontSize: '12px',
                      marginLeft: '8px',
                      color: '#6F6F6F',
                      letterSpacing:'0.1px',
                      lineHeight:1
                    }}
                  />
                </Div>
              </Div>
            </Div>
            { this?.state?.sidebar && <SideMenu
              className='sideBarMenu'
              id={`${parent_id}_choose_member_sidemenu_parent_sidemenu`}
              open={this.state.sidebar}
              direction='right'
              width={435}
              color='default'
              onClose={() => this.toggleSideBar(false)}
            >
              {this.state.searchMember ? (
                <Addmember
                  parent_id={this.props.parent_id}
                  name={'Add Member'}
                  nextSection={(member, v, l) => this.nextSection(member, v, l)}
                  signUpData={this.state.signUpData}
                  handelChangeSelect={v => this.handelChangeSelect(v)}
                  chooseSearch={this.state.chooseSearch}
                />
              ) : this.state.memberForm ? (
                <Addmemberform
                  parent_id={this.props.parent_id + '-choosemember'}
                  addNewMember
                  changeSideBar={this.changeSideBar}
                  signUpData={this.state.signUpData}
                  nextSection={(member, signUpData) =>
                    this.nextSection(member, signUpData)
                  }
                />
              ) : this.state.memberOtp ? (
                <Addmemberotp
                  parent_id={this.props.parent_id}
                  addmmber={this.state.addmmber}
                  appointmentDetails={true}
                  signUpData={this.state.signUpData}
                  nextSection={(member, v) => this.nextSection(member, v)}
                />
              ) : null}
              {this.state.memberSuccessPage ? (
                <AppointmentAddMemberSuccessPage
                  parent_id={this.props.parent_id+ '-choosemember'}
                  addmmber={this.state.addmmber}
                  insideSubmit={true}
                  signUpData={this.state.signUpData}
                  nextSection={(member, v, l) => this.nextSection(member, v, l)}
                  toggleSideBar={member => this.toggleSideBar(member)}
                />
              ) : null}
            </SideMenu>}
          </div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

export default connect(
  mapStateToProps,
  actions
)(withRouter(ChoosememberSearchResult))
