/**
 * @author Rahul
 * @email rahul@qdmplatforms.com
 * @create 12/11/2021
 * @modify 12/11/2021
 * @desc Providing the consultant from /src/context which is used in /src/App.js
 */

import React from "react";
import { avcList } from "../utils";

import { AVCShowContext } from "./";
const AVCContext = (props) => {
	let [avcShowList, setAVCList] = React.useState({
		avcList: avcList,
	});

	return (
		<AVCShowContext.Provider value={{ avcShowList, setAVCList }}>
			{props.children}
		</AVCShowContext.Provider>
	);
};

export default AVCContext;
