import * as React from "react";

export const Consultation = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="34"
			height="34"
			viewBox="0 0 32 32"
		>
			<g id="Consultation" transform="translate(-172 -86)">
				<g
					id="Group_92522"
					data-name="Group 92522"
					transform="translate(0 -35)"
				>
					<g
						id="Group_92520"
						data-name="Group 92520"
						transform="translate(0 3)"
					>
						<g
							id="Ellipse_129343"
							data-name="Ellipse 129343"
							transform="translate(172 118)"
							fill={
								!props?.active && !props?.vist
									? "#fff"
									: props?.active
									? "#4bc0c0"
									: "#fff"
							}
							stroke={
								!props?.active && !props?.vist
									? "#B6B6B6"
									: props?.active
									? "#4bc0c0"
									: "#4bc0c0"
							}
							stroke-width="1"
						>
							<circle cx="16" cy="16" r="16" stroke="none" />
							<circle cx="16" cy="16" r="15.5" fill="none" />
						</g>
						<path
							id="icons8-stethoscope"
							d="M10,5.636a1.821,1.821,0,0,0,1.781-1.455H12a.91.91,0,0,1,.909.909A2.962,2.962,0,0,1,12.024,7.2l-2.8,3.051a1.415,1.415,0,0,1,.611.948l2.977-3.244A4.028,4.028,0,0,0,14,5.091a2,2,0,0,0-2-2h-.336A1.817,1.817,0,1,0,10,5.636ZM8,12.749a.363.363,0,0,0,.261-.111L9,11.877l.025-.037a.727.727,0,0,0-.616-1.113H7.6A.727.727,0,0,0,7,11.877l.735.761A.36.36,0,0,0,8,12.749Zm7.636-3.477a2.362,2.362,0,0,0-.582,4.652,3.544,3.544,0,0,1-3.449,2.985c-1.938,0-2.8-1.733-3.008-3.614a1.063,1.063,0,0,1-1.09.058C7.8,16.206,9.337,18,11.605,18a4.629,4.629,0,0,0,4.563-4.063,2.363,2.363,0,0,0-.532-4.665Zm0,1.818a.545.545,0,1,1-.545.545A.546.546,0,0,1,15.636,11.091Zm-9.335-.3a1.449,1.449,0,0,1,.474-.533L3.96,7.188a2.948,2.948,0,0,1-.869-2.1A.908.908,0,0,1,3.859,4.2a1.82,1.82,0,1,0,.113-1.1A2,2,0,0,0,2,5.091,4.015,4.015,0,0,0,3.172,7.943L6.164,11.2A1.449,1.449,0,0,1,6.3,10.789Z"
							transform="translate(178 124)"
							fill={
								!props?.active && !props?.vist
									? "#B6B6B6"
									: props?.active
									? "#fff"
									: "#4bc0c0"
							}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
