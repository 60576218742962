import React from "react";
import { Col, H6, ListItem } from "qdm-component-library";

function CardList(props) {
  const { middleComponent = <></>, title } = props;
  return (
    <Col
      key={"0"}
      name="qdm-col-12"
      alignItems="stretch"
      direction="row"
      justify="flex-start"
      textAlign="left"
      lg="4"
      xs="12"
      md="4"
      sm="4"
      style={{
        // borderRight:props?.border && "1px solid #E0E0E0",
        padding: 0,
        overflow: 'auto',
        height: '100%',
        backgroundColor: "#fff",
        maxWidth: "32.333333%",
        marginRight: props?.border ? "1.4%" : 0,
        borderRadius: 12,
        boxShadow: "0px 10px 25px #0000000A"
      }}
      id={"patientDashboard-cardList-col-12"}
    >
      <ListItem
        title={title}
        inLineStyles={{
          // marginTop: "3%",
          marginBottom: "5px",
          paddingLeft: 17,
          paddingTop: 17,
          fontSize: 12
        }}
        description={false}
        profile={false}
        action={
          <div
            style={{
              display: "flex",
              right: "-12px",
              position: "absolute",
              top: "-12px",
              cursor: "pointer",
            }}
            id={"patientDashboard-cardList-div"}
          >
            {/* <Div
              style={{
                border: "1px solid #E0E0E0",
                padding: "4px 10px",
                borderRadius: "4px",
                color: "#B6B6B6",
                marginRight: "8px",
                backgroundColor: "#fff",
              }}
            >
              <Icons
                key={"2"}
                fontIcon="search"
                ariaHidden="true"
                className=""
                size="medium"
                inLineStyles={{
                  color: "#B6B6B6",
                  fontSize: "12px",
                }}
              ></Icons>
            </Div>
            <Div
              style={{
                border: "1px solid #E0E0E0",
                padding: "4px 10px",
                borderRadius: "4px",
                color: "#B6B6B6",
                backgroundColor: "#fff",
              }}
            >
              <Icons
                key={"2"}
                fontIcon="filter"
                ariaHidden="true"
                className=""
                size="medium"
                style={{ color: "#B6B6B6", fontSize: "12px" }}
              ></Icons>
            </Div> */}
          </div>
        }
      />
      {
        props.title === 'UPCOMING' || props.title === 'PAST' ? <></> : <H6
          key={"0"}
          name="Today"
          className="pc_medium"
          style={{
            color: "#6a7888",
            fontSize: "12px",
            margin: 0,
            textTransform: "uppercase",
            letterSpacing: 0,
            lineHeight: 1,
            padding: '0px 17px',
            marginBottom: 8,
          }}
          id={"patientDashboard-Today-Text"}
        ></H6>
      }

      <div
        style={{ height: "calc(100% - 79px)", overflowY: "auto", padding: '0px 17px' }}
        id={"patientDashboard-Today-div"}
      >
        {middleComponent}
      </div>
    </Col>
  );
}

CardList.defaultProps = {
  border: true
}

export default CardList;
