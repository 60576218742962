import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Div,
  Icons,
  Text,
  Row,
  Image,
  Avatar,
  Divider,
  Button,
} from "qdm-component-library";
import moment from "moment";

import Verification from "../../assets/img/verification.svg";
import { utcTOLocal } from "../../utils";
class ConfirmationScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      booker: this.props?.location?.state?.booker || {},
      patient: this.props?.location?.state?.patient || {},
    };
  }

  componentDidMount() {
    this.props.APPOINTMENT_READ({
      appointmentId: this.props?.location?.state?.appointmentId || 0,
    });
  }

  getDate(dateStr) {
    const dateVal = moment(dateStr).format("DD MMM YYYY");
    const timeVal = moment(dateStr).format("h:mm a");
    return `${dateVal} | ${timeVal}`;
  }

  getMobileNumber(mobileNo = {}) {
    return `+${mobileNo?.countryData?.callingCodes ?? "99******66"} ${
      mobileNo?.mobileNo ?? ""
    }`;
  }

  render() {
    return (
      <Div
        inLineStyles={{
          margin: "auto",
          transform: "translate(10px, 50px)",
          width: "40%",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px #0000001A",
          padding: "20px",
        }}
        id={"confirmBookingScreen-parent-div"}
      >
        <Div
          inLineStyles={{
            justifyContent: "center",
            borderRadius: "8px",
            display: "flex",
          }}
          id={"confirmBookingScreen-title-div"}
        >
          <Div
            style={{ textAlign: "center" }}
            id={"confirmBookingScreen-title-div2"}
          >
            <img
              style={{ marginBottom: 14 }}
              src={Verification}
              alt={"Verification"}
              id={"confirmBookingScreen-Verification-img"}
            />
            <br />
            <Text
              style={{
                color: "#38C20A",
                fontSize: "22px",
                fontWeight: "800",
              }}
              id={"confirmBookingScreen-Verification-Text"}
            >
              Your appointment has been booked !
            </Text>
            <Div
              inLineStyles={{ marginTop: 8 }}
              id={"confirmBookingScreen-Confirmation-div3"}
            >
              <Text
                style={{
                  color: "#6F6F6F",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
                id={"confirmBookingScreen-Confirmation-text2"}
              >
                Confirmation has been sent to your{" "}
                {this.props.appointMent?.data?.Person?.[0]?.telecom?.[0]?.value}
              </Text>
            </Div>
          </Div>
        </Div>

        {/* <Text
          inLineStyles={{
            color: "#B6B6B6",
            fontSize: 12,
            marginLeft: "4%",
            fontWeight: 700,
            letterSpacing: 0.9,
          }}
        >
          PREVIOUS APPOINTMENT
        </Text>

        <Div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "#f1f1f1",
            margin: "0px 20px",
            borderRadius: 10,
            padding: "4px 0px",
          }}
        >
          <Div>
            <Text style={{ color: "#6F6F6F", fontSize: 14, fontWeight: 600 }}>
              AP-123456
            </Text>
          </Div>
          <Div key={"0"} className="qdm" style={{ display: "flex" }}>
            <Row key={"0"}>
              <Avatar
                key={"0"}
                variant="circle"
                letter="Avatar"
                src="https://www.fillmurray.com/640/360"
                alt="Image"
                style={{ width: "35px", height: "35px" }}
              ></Avatar>
            </Row>

            <Row
              key={"1"}
              alignItems="stretch"
              direction="row"
              justify="flex-start"
              spacing="2"
              style={{
                display: "inline",
                marginLeft: "",
                paddingLeft: "8px",
              }}
            >
              <Div key={"0"} className="qdm">
                <Text
                  key={"0"}
                  name={
                    <span
                      style={{
                        fontWeight: "800",
                        fontSize: "13px",
                      }}
                    >
                      Dr. Priya dharshini N
                    </span>
                  }
                ></Text>
              </Div>

              <Div key={"1"} className="qdm">
                <Text
                  key={"0"}
                  name="07 Apr 2021 | 09:30 am"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#6F6F6F",
                  }}
                ></Text>
              </Div>
            </Row>
          </Div>

          <Div>
            <Text style={{ color: "#6F6F6F", fontSize: 14, fontWeight: 600 }}>
              Cancelled
            </Text>
          </Div>
        </Div> */}
        <Row
          key={"4"}
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          spacing="2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "",
            borderColor: "#E0E0E0",
            borderWidth: "1",
            borderStyle: "dashed",
            borderRadius: "8px",
          }}
          inLineStyles={{ margin: "3% !important" }}
          id={"confirmBookingScreen-Confirmation-Row"}
        >
          <Row
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{ width: "40%", transform: "translate(4px, 6px)" }}
            id={"confirmBookingScreen-Confirmation-Row2"}
          >
            <Div
              key={"0"}
              className="qdm"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "14px",
              }}
              id={"confirmBookingScreen-Confirmation-Div4"}
            >
              <Image
                key={"0"}
                alt="Image"
                disabled={false}
                src={`https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=${this.props.appointMent?.data?.id}`}
                style={{
                  width: "100px",
                  height: "100%",
                }}
                id={"confirmBookingScreen-Confirmation-Image"}
              ></Image>
            </Div>

            <Div
              key={"1"}
              className="qdm"
              style={{
                width: "100%",
                display: "inline",
                justifyContent: "center",
              }}
              id={"confirmBookingScreen-Confirmation-Div5"}
            >
              <Div
                key={"0"}
                className="qdm"
                style={{ display: "flex", justifyContent: "center" }}
                id={"confirmBookingScreen-Confirmation-Div6"}
              >
                <Text
                  key={"0"}
                  name={
                    <span style={{ fontWeight: "600", fontSize: 14 }}>
                      {this.props.appointMent?.loading
                        ? null
                        : utcTOLocal(this.props.appointMent?.data?.Slots?.[0]?.start ,"DD MMM YYYY | h:mm a" ) 
                        // this.getDate(
                        //     this.props.appointMent?.data?.Slots?.[0]?.start
                        //   )
                      }
                    </span>
                  }
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                  id={"confirmBookingScreen-Confirmation-Text3"}
                ></Text>
              </Div>

              <Div
                key={"1"}
                className="qdm"
                style={{ display: "flex", justifyContent: "center" }}
                id={"confirmBookingScreen-Confirmation-Div7"}
              >
                <Text
                  key={"0"}
                  name={`${this.props.appointMent?.data?.appno}`}
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#6F6F6F",
                    marginTop: "",
                    paddingBottom: "20px",
                    paddingTop: "14px",
                  }}
                  id={"confirmBookingScreen-Confirmation-Text4"}
                ></Text>
              </Div>
            </Div>
          </Row>

          <Div
            key={"1"}
            className="qdm"
            style={{
              width: "58%",
              marginTop: "",
              padding: "12px 20px 0px",
              paddingBottom: "0px",
              borderRadius: "8px",
              borderLeft: "3px dashed #e0e0e0",
            }}
            id={"confirmBookingScreen-Confirmation-Div8"}
          >
            <Div
              key={"0"}
              className="qdm"
              style={{ display: "flex", marginBottom: "24px" }}
              id={"confirmBookingScreen-Confirmation-Div9"}
            >
              <Row key={"0"}>
                <Avatar
                  key={"0"}
                  variant="circle"
                  letter="Avatar"
                  src="https://www.fillmurray.com/640/360"
                  alt="Image"
                  style={{ width: "40px", height: "40px" }}
                  id={"confirmBookingScreen-Confirmation-Avatar"}
                ></Avatar>
              </Row>

              <Row
                key={"1"}
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                spacing="2"
                style={{
                  display: "inline",
                  marginLeft: "",
                  paddingLeft: "8px",
                }}
                id={"confirmBookingScreen-Confirmation-Row3"}
              >
                <Div
                  key={"0"}
                  className="qdm"
                  style={{ marginBottom: "2%" }}
                  id={"confirmBookingScreen-Confirmation-Div10"}
                >
                  <Text
                    key={"0"}
                    name={
                      <span
                        style={{
                          fontWeight: "800",
                          fontSize: "15px",
                        }}
                      >
                        {
                          this.props.appointMent?.data?.Practioner?.[0]
                            ?.name?.[0]?.text
                        }
                      </span>
                    }
                    id={"confirmBookingScreen-Confirmation-Text5"}
                  ></Text>
                </Div>

                <Div key={"1"} className="qdm">
                  <Text
                    key={"0"}
                    name={
                      this.props.appointMent?.data?.Practioner?.[0]?.name?.[0]
                        ?.use
                    }
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "#6F6F6F",
                    }}
                    id={"confirmBookingScreen-Confirmation-Div9"}
                  ></Text>
                </Div>

                <Div key={"2"} id={"confirmBookingScreen-Confirmation-Div10"}>
                  <Text
                    key={"0"}
                    name=""
                    style={{
                      fontSize: "13px",
                      fontWeight: "800",
                      marginBottom: "10px",
                    }}
                    id={"confirmBookingScreen-Confirmation-Text6"}
                  ></Text>
                </Div>
              </Row>
            </Div>

            <Divider
              key={"1"}
              style={{ backgroundColor: "#d3d3d347" }}
              id={"confirmBookingScreen-Confirmation-Divider"}
            ></Divider>

            <Div
              key={"2"}
              className="qdm"
              style={{
                display: "flex",
              }}
              id={"confirmBookingScreen-Confirmation-Div11"}
            >
              <Row key={"0"} id={"confirmBookingScreen-Confirmation-Row5"}>
                <Avatar
                  key={"0"}
                  variant="circle"
                  letter="s"
                  src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                  alt="Image"
                  style={{ backgroundColor: "#E0E0E0", width: 40, height: 40 }}
                  id={"confirmBookingScreen-Confirmation-Avatar2"}
                ></Avatar>
              </Row>

              <Row
                key={"1"}
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                spacing="2"
                style={{
                  display: "inline",
                  paddingLeft: "8px",
                }}
                id={"confirmBookingScreen-Confirmation-Row4"}
              >
                <Row
                  key={"0"}
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  spacing="2"
                  style={{
                    paddingBottom: "2%",
                  }}
                  id={"confirmBookingScreen-Confirmation-Row5"}
                >
                  <Text
                    key={"0"}
                    name={
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        {
                          this.props.appointMent?.data?.Person?.[0]?.name?.[0]
                            ?.text
                        }
                      </span>
                    }
                    style={{
                      fontSize: "22px",
                      fontWeight: "500",
                    }}
                    id={"confirmBookingScreen-Confirmation-Text7"}
                  ></Text>
                </Row>

                <Row
                  key={"1"}
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  spacing="2"
                  id={"confirmBookingScreen-Confirmation-Row6"}
                >
                  <Text
                    key={"0"}
                    name={`${moment().diff(
                      moment(
                        this.props.appointMent?.data?.Person?.[0]?.birthDay,
                        "DD/MM/YYYY"
                      ),
                      "years"
                    )} yrs, ${
                      this.props.appointMent?.data?.Person?.[0]?.gender
                    }`}
                    style={{
                      fontSize: "13px",
                      color: "#6F6F6F",
                    }}
                    id={
                      "confirmBookingScreen-Confirmation-Text-years-date-months"
                    }
                  ></Text>
                </Row>

                <Row
                  key={"2"}
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  spacing="2"
                  style={{ paddingBottom: "20px" }}
                  id={"confirmBookingScreen-Confirmation-Row-telecom"}
                >
                  <Text
                    key={"0"}
                    name={
                      this.props.appointMent?.data?.Person?.[0]?.telecom?.[0]
                        ?.value
                    }
                    style={{
                      fontSize: "13px",
                      marginTop: "6px",
                      fontWeight: 800,
                    }}
                    id={"confirmBookingScreen-Confirmation-telecom-text"}
                  ></Text>
                </Row>
              </Row>
            </Div>
          </Div>
        </Row>

        <Row
          key={"5"}
          inLineStyles={{ display: "flex", justifyContent: "space-around" }}
          id={"confirmBookingScreen-Confirmation-row11"}
        >
          <Row
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{ display: "flex", alignItems: "center" }}
            id={"confirmBookingScreen-Confirmation-row12"}
          >
            <Icons
              key={"0"}
              fontIcon="download"
              ariaHidden="true"
              size="small"
              style={{ color: "#0071F2" }}
              id={"confirmBookingScreen-Icons-download"}
            ></Icons>

            <Text
              key={"1"}
              name="Appointment Summary"
              style={{
                paddingLeft: "10px",
                fontSize: "15px",
                color: "#0071F2",
                fontWeight: "500",
              }}
              id={"confirmBookingScreen-Summary-Text"}
            ></Text>
          </Row>
          <Div key={"0"}>
            <Icons
              key={"0"}
              fontIcon="download"
              ariaHidden="true"
              size="small"
              style={{ color: "#0071F2" }}
              id={"confirmBookingScreen-Icons-download1"}
            ></Icons>

            <Text
              key={"1"}
              name="Invoice"
              style={{
                fontSize: "15px",
                paddingLeft: "8px",
                color: "#0071F2",
                fontWeight: "500",
              }}
              id={"confirmBookingScreen-Invoice-text"}
            ></Text>
          </Div>

          <Div key={"1"}>
            <Icons
              key={"0"}
              fontIcon="print"
              ariaHidden="true"
              size="small"
              style={{ color: "#0071F2" }}
              id={"confirmBookingScreen-print-Icons"}
            ></Icons>

            <Text
              key={"1"}
              name="Print"
              style={{
                fontSize: "15px",
                paddingLeft: "8px",
                color: "#0071F2",
                fontWeight: "500",
              }}
              id={"confirmBookingScreen-Print-text"}
            ></Text>
          </Div>

          <Div key={"2"}>
            <Icons
              key={"0"}
              fontIcon="share"
              ariaHidden="true"
              size="small"
              style={{ color: "#0071F2" }}
              id={"confirmBookingScreen-share-Icons"}
            ></Icons>

            <Text
              key={"1"}
              name="Share"
              style={{
                paddingLeft: "10px",
                fontSize: "15px",
                color: "#0071F2",
                fontWeight: "500",
              }}
              id={"confirmBookingScreen-share-text"}
            ></Text>
          </Div>
        </Row>
        <Row
          key={"6"}
          alignItems="center"
          direction="row"
          justify="flex-start"
          spacing="2"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            paddingTop: "40px",
          }}
          id={"confirmBookingScreen-button-Home-row"}
        >
          <Button
            key={"0"}
            type="button"
            variant="outlined"
            name="Back to Home"
            style={{
              padding: "10px 22px",
              borderRadius: "10px",
              backgroundColor: "#0071F2",
              fontSize: "14px",
            }}
            id={"confirmBookingScreen-Home-btn"}
          ></Button>
        </Row>
        <Div
          key={"7"}
          className="qdm"
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          id={"confirmBookingScreen-cancel-appointment-div"}
        >
          <Text
            key={"0"}
            name="Want to cancel appointment ?"
            style={{ fontSize: "14px" }}
            id={"confirmBookingScreen-cancel-appointment-text"}
          ></Text>

          <Button
            key={"1"}
            type="button"
            variant="text"
            name="Click here"
            style={{
              width: "",
              height: "",
              marginLeft: "8px",
              color: "#0071F2",
              fontSize: "14px",
            }}
            id={"confirmBookingScreen-cancel-Click-btn"}
          ></Button>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (state) => ({
  appointMent: state?.appointmentReducer?.readAppointment,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(ConfirmationScreen));
