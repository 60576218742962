import React, { useContext, useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory, useLocation } from "react-router-dom";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

import { Routes } from "../../router/routes";
import { AlertProps, fetchUserInfo } from "../../utils";
import { AlertContext } from "../../contexts";

const QueryPage = ({ children, ...rest }) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const alertCtx = useContext(AlertContext);
  React.useEffect(() => {
    handleRedirect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAppInfo = async (id) => {
    const data = await dispatch(
      actions.APPOINTMENT_READ({ appointmentId: id })
    );
    return data?.payload?.data || {};
  };

  const handleRedirect = async () => {
    const paramsString = location.search.slice(1,);
    const searchParams = new URLSearchParams(paramsString);
    const queryInfo = searchParams.get("appointmentInfo");
    try {
      const {
        token,
        org,
        specialities,
        doctors,
        redirectUrl,
        successUrl,
        appointmentId,
      } = JSON.parse(atob(queryInfo));
      const userInfo = await fetchUserInfo(
        token,
        rest.GET_USER_INFO,
        rest.READ_PERSON,
        true,
      );
      if (userInfo && userInfo?.type === "token") {
        localStorage.setItem("query_token", token);
        const info = JSON.parse(JSON.stringify(userInfo));
        info["gender"] = info.persondata?.payload?.data?.gender;
        info["birthDay"] = info.persondata?.payload?.data?.birthDay;
        info["email"] = info.persondata?.payload?.data?.email || "";
        info["fileid"] = info.persondata?.payload?.data?.imgUrl;
        info["userId"] = info.id;
        let state = {
          cityName: 0,
          location: {
            address: "",
            city: "",
            latitude: 0,
            location: "",
            longitude: 0,
          },
          hospital: org?.map?.(d => ({
            label: "", value: d,
          })) || [],
          specialities: specialities?.map?.(d => ({
            label: "", value: d, type: "Speciality"
          })) || [],
          symptoms: [],
          doctors: [],
          symptomsSearch: [],
          selectedUser: info,
          fromQueryPage: true,
          successUrl,
          redirectUrl,
        };
        if (doctors && doctors.length > 0) {
          state.doctors = [{
            value: doctors[0],
          }]
        }
        if (appointmentId) {
          const appInfo = JSON.parse(JSON.stringify(await fetchAppInfo(appointmentId)));
          if (!(!!appInfo.status)) {
            setOpen(false);
            alertCtx.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Invalid Appointment ID....",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
            return;
          }
          appInfo["HealthcareService"] = appInfo["healthservice"];
          delete appInfo["healthservice"];
          state = {
            cityName: 0,
            location: {
              address: "Chennai, Tamil Nadu, India",
              latitude: 13.0826802,
              longitude: 80.2707184,
              city: "Chennai",
              location: "Chennai",
            },
            hospital: {
              label: "",
              value: appInfo?.HealthcareService?.[0]?.Organization?.[0]?.id || 0,
            },
            specialities: [],
            symptoms: [
              {
                label: "Fever",
                type: "Symptom",
                value: "SymptomsMapping/10000",
              },
            ],
            slotId : appInfo.slotID[0],
            symptomsSearch: [],
            modifyUser: true,
            newBook: false,
            appointMentInfo: appInfo,
            selectedUser: info,
            fromQueryPage: true,
            successUrl,
            redirectUrl,
          };
        }
        history.push({
          pathname: Routes.doctorListing,
          state,
        });
      } else {
        // handle for error
        console.error("Invalid input....");
        setOpen(false);
        alertCtx.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Invalid input....",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    } catch (err) {
      setOpen(false);
      console.error("Err....", err);
      alertCtx.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something went wrong. Please check the input...",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  }

  return (
    <Backdrop
      style={{
        zIndex: 9999,
        color: '#fff',
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}


const mapStateToProps = (state) => ({
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestInfo: state?.authSlice?.guestInfo,
});

export default connect(mapStateToProps, actions)(QueryPage);